;(function() {
    "use strict";

    function Panier () {
        return function () {
            this.Id = null;
            this.JetonPanier = null;
            this.Client = null;
            this.Precommandes = [];
        };
    };

    module.exports = {
        name: "Panier",
        fn: Panier
    }

})();
