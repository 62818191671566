module.exports=﻿{
    "1": {
        "sections": [
            {
                "libelle": "STEPPER_INFOS",
                "entrees": [
                    {
                        "defaut": "STEPPER_FONCTION",
                        "propriete": "Email",
                        "url": "TUNNEL_CMA_SIRET"
                    },
                    {
                        "defaut": "STEPPER_SIRET",
                        "propriete": "RaisonSociale",
                        "multiActif": ["TUNNEL_CTR_ADRESSE_SAISIE", "TUNNEL_CTR_SIRET"],
                        "url": "TUNNEL_CMA_ADRESSE"
                    },
                    {
                        "defaut": "STEPPER_COORDONNEES",
                        "propriete": "Interlocuteur",
                        "url": "TUNNEL_CMA_COORDONNEES"
                    }
                ]
            },
            {
                "libelle": "STEPPER_PANIER",
                "entrees": [
                    {
                        "defaut": "STEPPER_SALARIE",
                        "propriete": "Salaries",
                        "suffixe": " salarié(s)",
                        "url": "TUNNEL_CMA_NB_BENEFICIAIRE"
                    },
                    {
                        "defaut": "STEPPER_VF",
                        "propriete": "ValeurFaciale",
                        "suffixe": "€ par titre",
                        "url": "TUNNEL_CMA_VALEUR"
                    },
                    {
                        "defaut": "STEPPER_CONTRAT",
                        "propriete": "Contrat",
                        "suffixe": "€/mois par salarié",
                        "url": "TUNNEL_CMA_PRESTATIONS"
                    },
                ]
            },
            {
                "libelle": "STEPPER_PAIEMENT",
                "entrees": [
                    {
                        "defaut": "STEPPER_RECAP",
                        "propriete": "Recapitulatif",
                        "url": "TUNNEL_CMA_RECAPITULATIF"
                    },
                    {
                        "defaut": "STEPPER_CONFIRMATION",
                        "propriete": "Confirmation",
                        "url": "TUNNEL_CMA_CONFIRMATION",
                        "ignore": true
                    }
                ]
            },
        ]
    },
    "2": {
        "sections": [
            {
                "libelle": "STEPPER_INFOS",
                "entrees": [
                    {
                        "defaut": "STEPPER_FONCTION",
                        "propriete": "Email",
                        "url": "TUNNEL_CMA_SIRET"
                    },
                    {
                        "defaut": "STEPPER_SIRET",
                        "propriete": "RaisonSociale",
                        "multiActif": ["TUNNEL_CTR_ADRESSE_SAISIE", "TUNNEL_CTR_SIRET"],
                        "url": "TUNNEL_CMA_ADRESSE"
                    },
                    {
                        "defaut": "STEPPER_COORDONNEES",
                        "propriete": "Interlocuteur",
                        "url": "TUNNEL_CMA_COORDONNEES"
                    }
                ]
            },
            {
                "libelle": "STEPPER_PANIER",
                "entrees": [
                    {
                        "defaut": "STEPPER_SALARIE",
                        "propriete": "Salaries",
                        "suffixe": " salarié(s)",
                        "url": "TUNNEL_CMA_NB_BENEFICIAIRE"
                    },
                    {
                        "defaut": "STEPPER_VF",
                        "propriete": "ValeurFaciale",
                        "suffixe": "€ par titre",
                        "url": "TUNNEL_CMA_VALEUR"
                    },
                    {
                        "defaut": "STEPPER_CONTRAT",
                        "propriete": "Contrat",
                        "suffixe": "€/mois par salarié",
                        "url": "TUNNEL_CMA_PRESTATIONS"
                    },
                ]
            },
            {
                "libelle": "STEPPER_PAIEMENT",
                "entrees": [
                    {
                        "defaut": "STEPPER_RECAP",
                        "propriete": "Recapitulatif",
                        "url": "TUNNEL_CMA_RECAPITULATIF"
                    },
                    {
                        "defaut": "STEPPER_CONFIRMATION",
                        "propriete": "Confirmation",
                        "url": "TUNNEL_CMA_CONFIRMATION",
                        "ignore": true
                    }
                ]
            },
        ]
    },
    "3": {
        "sections": [
            {
                "libelle": "STEPPER_INFOS",
                "entrees": [
                    {
                        "defaut": "STEPPER_FONCTION",
                        "propriete": "Email",
                        "url": "TUNNEL_CMA_SIRET"
                    },
                    {
                        "defaut": "STEPPER_SIRET",
                        "propriete": "RaisonSociale",
                        "multiActif": ["TUNNEL_CTR_ADRESSE_SAISIE", "TUNNEL_CTR_SIRET"],
                        "url": "TUNNEL_CMA_ADRESSE"
                    },
                    {
                        "defaut": "STEPPER_COORDONNEES",
                        "propriete": "Interlocuteur",
                        "url": "TUNNEL_CMA_COORDONNEES"
                    }
                ]
            },
            {
                "libelle": "STEPPER_PANIER",
                "entrees": [
                    {
                        "defaut": "STEPPER_SALARIE",
                        "propriete": "Salaries",
                        "suffixe": " salarié(s)",
                        "url": "TUNNEL_CMA_NB_BENEFICIAIRE"
                    },
                    {
                        "defaut": "STEPPER_VF",
                        "propriete": "ValeurFaciale",
                        "suffixe": "€ par titre",
                        "url": "TUNNEL_CMA_VALEUR"
                    },
                    {
                        "defaut": "STEPPER_CONTRAT",
                        "propriete": "Contrat",
                        "suffixe": "€/mois par salarié",
                        "url": "TUNNEL_CMA_PRESTATIONS"
                    },
                ]
            },
            {
                "libelle": "STEPPER_PAIEMENT",
                "entrees": [
                    {
                        "defaut": "STEPPER_RECAP",
                        "propriete": "Recapitulatif",
                        "url": "TUNNEL_CMA_RECAPITULATIF"
                    },
                    {
                        "defaut": "STEPPER_CONFIRMATION",
                        "propriete": "Confirmation",
                        "url": "TUNNEL_CMA_CONFIRMATION",
                        "ignore": true
                    }
                ]
            },
        ]
    },
    "4": {
        "sections": [
            {
                "libelle": "STEPPER_INFOS",
                "entrees": [
                    {
                        "defaut": "STEPPER_FONCTION",
                        "propriete": "Email",
                        "url": "TUNNEL_CMA_SIRET"
                    },
                    {
                        "defaut": "STEPPER_SIRET",
                        "propriete": "RaisonSociale",
                        "multiActif": ["TUNNEL_CTR_ADRESSE_SAISIE", "TUNNEL_CTR_SIRET"],
                        "url": "TUNNEL_CMA_ADRESSE"
                    },
                    {
                        "defaut": "STEPPER_COORDONNEES",
                        "propriete": "Interlocuteur",
                        "url": "TUNNEL_CMA_COORDONNEES"
                    }
                ]
            },
            {
                "libelle": "STEPPER_PANIER",
                "entrees": [
                    {
                        "defaut": "STEPPER_SALARIE",
                        "propriete": "Salaries",
                        "suffixe": " salarié(s)",
                        "url": "TUNNEL_CMA_NB_BENEFICIAIRE"
                    },
                    {
                        "defaut": "STEPPER_VF",
                        "propriete": "ValeurFaciale",
                        "suffixe": "€ par titre",
                        "url": "TUNNEL_CMA_VALEUR"
                    },
                    {
                        "defaut": "STEPPER_CONTRAT",
                        "propriete": "Contrat",
                        "suffixe": "€/mois par salarié",
                        "url": "TUNNEL_CMA_PRESTATIONS"
                    },
                ]
            },
            {
                "libelle": "STEPPER_PAIEMENT",
                "entrees": [
                    {
                        "defaut": "STEPPER_RECAP",
                        "propriete": "Recapitulatif",
                        "url": "TUNNEL_CMA_RECAPITULATIF"
                    },
                    {
                        "defaut": "STEPPER_CONFIRMATION",
                        "propriete": "Confirmation",
                        "url": "TUNNEL_CMA_CONFIRMATION",
                        "ignore": true
                    }
                ]
            },
        ]
    },
     "5": {
        "sections": [
            {
                "libelle": "STEPPER_INFOS",
                "entrees": [
                    {
                        "defaut": "STEPPER_FONCTION",
                        "propriete": "Email",
                        "url": "TUNNEL_CMA_SIRET"
                    },
                    {
                        "defaut": "STEPPER_SIRET",
                        "propriete": "RaisonSociale",
                        "multiActif": ["TUNNEL_CTR_ADRESSE_SAISIE", "TUNNEL_CTR_SIRET"],
                        "url": "TUNNEL_CMA_ADRESSE"
                    },
                    {
                        "defaut": "STEPPER_COORDONNEES",
                        "propriete": "Interlocuteur",
                        "url": "TUNNEL_CMA_COORDONNEES"
                    }
                ]
            },
            {
                "libelle": "STEPPER_PANIER",
                "entrees": [
                    {
                        "defaut": "STEPPER_SALARIE",
                        "propriete": "Salaries",
                        "suffixe": " salarié(s)",
                        "url": "TUNNEL_CMA_NB_BENEFICIAIRE"
                    },
                    {
                        "defaut": "STEPPER_VF",
                        "propriete": "ValeurFaciale",
                        "suffixe": "€ par titre",
                        "url": "TUNNEL_CMA_VALEUR"
                    },
                    {
                        "defaut": "STEPPER_CONTRAT",
                        "propriete": "Contrat",
                        "suffixe": "€/mois par salarié",
                        "url": "TUNNEL_CMA_PRESTATIONS"
                    },
                ]
            },
            {
                "libelle": "STEPPER_PAIEMENT",
                "entrees": [
                    {
                        "defaut": "STEPPER_RECAP",
                        "propriete": "Recapitulatif",
                        "url": "TUNNEL_CMA_RECAPITULATIF"
                    },
                    {
                        "defaut": "STEPPER_CONFIRMATION",
                        "propriete": "Confirmation",
                        "url": "TUNNEL_CMA_CONFIRMATION",
                        "ignore": true
                    }
                ]
            },
        ]
    },
     "6": {
        "sections": [
            {
                "libelle": "STEPPER_INFOS",
                "entrees": [
                    {
                        "defaut": "STEPPER_FONCTION",
                        "propriete": "Email",
                        "url": "TUNNEL_CMA_SIRET"
                    },
                    {
                        "defaut": "STEPPER_SIRET",
                        "propriete": "RaisonSociale",
                        "multiActif": ["TUNNEL_CTR_ADRESSE_SAISIE", "TUNNEL_CTR_SIRET"],
                        "url": "TUNNEL_CMA_ADRESSE"
                    },
                    {
                        "defaut": "STEPPER_COORDONNEES",
                        "propriete": "Interlocuteur",
                        "url": "TUNNEL_CMA_COORDONNEES"
                    }
                ]
            },
            {
                "libelle": "STEPPER_PANIER",
                "entrees": [
                    {
                        "defaut": "STEPPER_SALARIE",
                        "propriete": "Salaries",
                        "suffixe": " salarié(s)",
                        "url": "TUNNEL_CMA_NB_BENEFICIAIRE"
                    },
                    {
                        "defaut": "STEPPER_VF",
                        "propriete": "ValeurFaciale",
                        "suffixe": "€ par titre",
                        "url": "TUNNEL_CMA_VALEUR"
                    },
                    {
                        "defaut": "STEPPER_CONTRAT",
                        "propriete": "Contrat",
                        "suffixe": "€/mois par salarié",
                        "url": "TUNNEL_CMA_PRESTATIONS"
                    },
                ]
            },
            {
                "libelle": "STEPPER_PAIEMENT",
                "entrees": [
                    {
                        "defaut": "STEPPER_RECAP",
                        "propriete": "Recapitulatif",
                        "url": "TUNNEL_CMA_RECAPITULATIF"
                    },
                    {
                        "defaut": "STEPPER_CONFIRMATION",
                        "propriete": "Confirmation",
                        "url": "TUNNEL_CMA_CONFIRMATION",
                        "ignore": true
                    }
                ]
            },
        ]
    }
}
