;(function() {
  "use strict";

    function customOrder () {
        return function (items, field, order) {
            var filtered = [];
            angular.forEach(items, function (item) {
                filtered.push(item);
            });
            if (field) {
                if (field === "Nom" || field === "Prenom") {
                    filtered.sort(function (a, b) {
                        var compare = a[field].localeCompare(b[field]);
                        return order ? compare : -compare;
                    });
                } else if (field === "NombreTitres") {
                    filtered.sort(function (a, b) {
                        var compare = a[field] - b[field];
                        return order ? compare : -compare;
                    });
                } else {
                    filtered.sort(function (a, b) {
                        var compare = moment(a[field]).toDate() - moment(b[field]).toDate();
                        return order ? compare : -compare;
                    });
                }
            }
            return filtered;
        }
    };

    module.exports = {
        name: "customOrderBenef",
        fn: customOrder
    }

})();
