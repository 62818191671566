;(function() {
    "use strict";

    function ConfigurationOperationnelle () {
        return function () {
            this.Id = null;
            this.IdClientProspect = null;
            this.BIC = null;
            this.IBAN = null;
            this.IdProduit = null;
        };
    };

    module.exports = {
        name: "ConfigurationOperationnelle",
        fn: ConfigurationOperationnelle
    }

})();
