;(function() {
    "use strict";

    function slideInOut ($window) {
        var duration = 350,
            $body = $('body');

        return {
            enter: function(element, done) {
                $body.toggleClass('overflow-body');
                element.css({ display: 'none' });
                element.velocity(
                    {
                        opacity: [ 1, 0 ],
                        translateY: [ '0%', '35%' ]
                    },
                    {
                        easing: [ .26, .6, .67, .97 ],
                        duration: duration,
                        display: 'block',
                        delay: duration,
                        complete: function(){
                            $(element).removeAttr('style');
                        }
                    }
                )
            },
            leave: function(element, done) {
                element.velocity({ opacity: [ 0, 1 ], translateY: [ '-35%', '0%' ], display: 'none' }, { easing: [.26, .6, .67, .97], duration: duration });
                $window.setTimeout(function(){
                    done();
                }, duration);
                $window.setTimeout(function(){
                    $(element).removeAttr('style');
                    $body.toggleClass('overflow-body');
                }, duration*3);
            }
        };
    }

    module.exports = {
        className: '.slide-in-out',
        fn: slideInOut
    };

})();
