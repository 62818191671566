;(function() {
    "use strict";

    function promiseBtns(angularPromiseButtonsProvider) {
        angularPromiseButtonsProvider.extendConfig({
            spinnerTpl:
                '<span class="spinner-wrapper">' +
                    '<span class="spinner-dot"></span>' +
                    '<span class="spinner-dot"></span>' +
                    '<span class="spinner-dot"></span>' +
                '</span>',
            disableBtn: true,
            btnLoadingClass: 'button-loading'
        });
    };

    module.exports = {
        fn: promiseBtns
    };
})();