;(function () {
    "use strict";

    var factoryFolders = (function () {var f = require("./index.js");f["adresse.model"]=require("./adresse.model.js");f["beneficiaire.model"]=require("./beneficiaire.model.js");f["client.model"]=require("./client.model.js");f["configurationOperationnelle.model"]=require("./configurationOperationnelle.model.js");f["contact.model"]=require("./contact.model.js");f["contract.model"]=require("./contract.model.js");f["contractConfig.model"]=require("./contractConfig.model.js");f["devis.model"]=require("./devis.model.js");f["index"]=require("./index.js");f["interlocuteur.model"]=require("./interlocuteur.model.js");f["paiement.model"]=require("./paiement.model.js");f["panier.model"]=require("./panier.model.js");f["partenaire.model"]=require("./partenaire.model.js");f["pointLivraison.model"]=require("./pointLivraison.model.js");f["precommande.model"]=require("./precommande.model.js");f["valorisation.model"]=require("./valorisation.model.js");f["wording.model"]=require("./wording.model.js");return f;})(),
        factory = angular.module('app.models', []);

    for (var prop in factoryFolders) {
        if (prop !== "index") {
            var factoryObject = require("./" + prop + ".js");
            factory.factory(factoryObject.name, factoryObject.fn);
        }
    }

    module.exports = factory;

})();
