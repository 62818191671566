;(function () {
  "use strict";

    var componentFolders = (function () {var f = require("./index.js");f["index"]=require("./index.js");return f;})(),
        app = angular.module('app.component', []);

    for (var prop in componentFolders) {
        if (prop !== "index") {
            var componentObject = require("./" + prop + ".js");
            app.component(componentObject.name, componentObject.obj);
        }
    }

    module.exports = app;

})();
