; (function () {
    "use strict";

    /* */

    var counterConnected = {
        bindings: {
            limit: '<',
            count: '<',
            onUpdate: '&'
        },
        transclude: true,
        templateUrl: componentsPath + 'counter/counter.html',
        controller: function ($element) {
            var self = this;

            self.$onInit = function () {
                self.count = self.count || 0;
                self.limit = self.limit || [];
            };

            self.increment = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                if (self.count >= parseInt(self.limit[1], 10)) {
                    return false;
                }
                self.count++;
                self.updateCount();
            };

            self.decrement = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                if (self.count <= parseInt(self.limit[0], 10)) {
                    return false;
                }
                self.count--;
                self.updateCount();
            };

            self.$postLink = function () {
                $element.find('input').blur(function (e) {
                    if ($(e.relatedTarget).hasClass('increment') || $(e.relatedTarget).hasClass('decrement') || self.count == '')
                        return false;
                    else
                        self.updateCount();
                });
            };

            self.updateCount = function () {
                if (self.onUpdate) {
                    self.onUpdate({
                        $event: {
                            count: self.count,
                            fieldName: $element.find('input').attr('name')
                        }
                    });
                }
            };
        },
        controllerAs: 'counterCtrl'
    };

    module.exports = {
        name: 'counterConnected',
        obj: counterConnected
    };

})();
