; (function () {
    'use strict';

    var app = angular.module('tunnel.connected.timeline', [
        'ui.router'
    ]).config(function ($stateProvider) {
        var timelineUrl = 'tunnel.connected.confirmation';

        $stateProvider.state(timelineUrl, {
            url: '/mes-commandes/ctr/confirmation',
            views: {
                'content': {
                    template: '<timeline items="items"></timeline>',
                    controller: function ($scope, items) {
                        $scope.items = items;
                    }
                },
                'header@': {
                    template: '<header-connected breadcrumbs="breadcrumbs"></header-connected>',
                    controller: function ($scope, breadcrumbs) {
                        for (var i = 0, length = breadcrumbs.length; i < length; i++) {
                            breadcrumbs[i].clickable = false;
                        }

                        $scope.breadcrumbs = breadcrumbs;
                    }
                }
            },
            resolve: {
                items: function ($window, StateService, Ressources) {
                    var panier = StateService.getPanier();

                    if (!panier.Precommandes) {
                        $window.parent.location.href = Ressources.ESPACE_CLIENT_URL;
                        return false;
                    }

                    var precommande = panier.Precommandes[0];

                    var referenceClient = precommande.ReferenceClient ? '#' + precommande.ReferenceClient : '';
                    return [
                        {
                            text: 'Votre commande <strong>' + referenceClient + '</strong> est envoyée',
                            checked: true,
                        },
                        {
                            text: '<strong>Votre paiement</strong> a bien été enregistré',
                            checked: true,
                        },
                        {
                            text: 'Création de <strong>votre contrat</strong>',
                            checked: false,
                        },
                        {
                            text: 'Envoi de votre <strong>facture proformat</strong>',
                            checked: false,
                        }
                    ];
                },
                breadcrumbs: function (BreadcrumbService) {
                    return BreadcrumbService.selectBreadcrumb(timelineUrl);
                }
            }
        });
    });

    var componentFolders = ({"timeline.component":require("./timeline.component.js"),"timeline":require("./timeline.js")});

    for (var prop in componentFolders) {
        if (prop.indexOf('component') != -1) {
            app.component(componentFolders[prop].name, componentFolders[prop].obj);
        }
    }

    module.exports = app;
})();