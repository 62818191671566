; (function () {
    'use strict';

    var emailSent = {
        bindings: {
            forgotPasswordObj: '<'
        },
        templateUrl: 'Content/views/authentication/email-sent.html',
        controller: function ($scope, $timeout, AuthenticationService) {
            var self = this;

            self.resendEmail = function () {
                $scope.$emit('toggleLoadingToaster', { header: "Envoi en cours", caption: "Veuillez patienter s'il vous plaît..." });
                AuthenticationService.sendForgetPasswordEmail(self.forgotPasswordObj.email).then(function (result) {
                    $timeout(function () {
                        $scope.$emit('closeLoadingToaster');
                    }, 2000);
                }).catch(function (error) {
                    $timeout(function () {
                        $scope.$emit('closeLoadingToaster');
                    }, 2000);
                });
            }
        }
    };

    module.exports = {
        name: 'emailSent',
        obj: emailSent
    }
})();