;(function() {
    "use strict";

    var tooltip = {
        transclude: true,
        templateUrl: componentsPath + 'tooltip/tooltip.icon.html',
        controller: function () {
            this.hover = false;
        }
    };

    module.exports = {
        name: 'tooltip',
        obj: tooltip
    };

})();
