;(function() {
    "use strict";

    function Paiement () {
        return function () {
            this.Id = null;
            this.Libelle = null;
            this.IBAN = null;
            this.BIC = null;
            this.IsVirement = null;
            this.IsCB = null;
            this.Montant = null;
            this.Statut = null;
            this.ModePaiement = null;
        };
    };

    module.exports = {
        name: "Paiement",
        fn: Paiement
    }

})();
