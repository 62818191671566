; (function () {
    "use strict";
    function siretKoController($scope, $stateParams, StateService, Ressources) {
        var self = this,
            panier = StateService.getPanier();

        self.errorCode = $stateParams.errorCode;
        self.siretKo = $stateParams.siretKo;

        if (!self.errorCode || !self.siretKo) {
            StateService.go(Ressources.TUNNEL_CTR_SIRET);
        }

        if (panier && panier.Client && panier.Client.Value && panier.Client.Value.Interlocuteur) {
            self.interlocuteur = panier.Client.Value.Interlocuteur[0];
        }

        self.editSiret = function() {
            StateService.go('tunnel-ctr.siret', { siret: $stateParams.siretKo });
        };

        self.editPersona = function () {
            panier.Client.Value.Siret = null;
            panier.Client.Value.RaisonSociale = null;
            $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
            StateService.go('tunnel-ctr.persona');
        };
    };

    module.exports = {
        name: "SiretKoController",
        fn: siretKoController
    };
})();
