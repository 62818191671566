;(function() {
    "use strict";

    function cartRecovery ($rootScope, $timeout, $location, Ressources, localStorageService, PanierService) {
        
    }

    module.exports = {
        runFn: cartRecovery
    };

})();
