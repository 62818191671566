;(function() {
    "use strict";

    function restAngularConfig (RestangularProvider) {
        RestangularProvider.setBaseUrl('./api');

        RestangularProvider.setDefaultHeaders({
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
        });
        
        RestangularProvider.addResponseInterceptor(function (data, operation, what, url, response, deferred) {
            var extractedData = data.Content;
            if (extractedData && extractedData.length == 1)
                extractedData = extractedData[0];
            return extractedData;
        });
    };

    module.exports = {
        fn: restAngularConfig
    };

})();
