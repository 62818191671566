module.exports=﻿{
    "tunnel-ctr.siret": [
        {
            "Name": "Recherche Siret",
            "templateUrl": "Content/views/feedly/siret.html"
        },
        {
            "Name": "Liste Siret",
            "templateUrl": "Content/views/feedly/siret-liste.html"
        }
    ],
    "tunnel-ctr.siret-devis": [
        {
            "Name": "Recherche Siret",
            "templateUrl": "Content/views/feedly/siret.html"
        },
        {
            "Name": "Liste Siret",
            "templateUrl": "Content/views/feedly/siret-liste.html"
        }
    ],
    "tunnel-cma.siret": [
        {
            "Name": "Recherche Siret",
            "templateUrl": "Content/views/feedly/siret.html"
        },
        {
            "Name": "Liste Siret",
            "templateUrl": "Content/views/feedly/siret-liste.html"
        }
    ],
    "tunnel-cma.siret-devis": [ 
        {
            "Name": "Recherche Siret",
            "templateUrl": "Content/views/feedly/siret.html"
        },
        { 
            "Name": "Liste Siret",
            "templateUrl": "Content/views/feedly/siret-liste.html"
        }
    ]
}