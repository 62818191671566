; (function () {
    'use strict';

    var login = {
        templateUrl: function ($state) {
            return $state.current.name == 'tunnel.login' ? 'Content/views/authentication/login.html' : 'Content/views/authentication/login-after-reinit.html';
        },
        controllerAs: 'login',
        controller: function ($scope, $rootScope, $stateParams, $location, $window, $http, $injector, $grecaptcha, $document, AuthenticationService, Ressources) {
            var self = this;
            var googleRecaptcha;
            var loginFailedTimes = 0;
            var MAX_LOGIN_TIME_BEFORE_DISPLAY_CAPTCHA = 5;
            var $validationProvider = $injector.get('$validation');
            var returnUrl = $stateParams.returnUrl;
            var version = $stateParams.version;
            var siteSource = $stateParams.source;
            self.trOnline = $stateParams.source === 'tronline' ? true : false; 

            if (self.trOnline) {
                self.imageUrl = "https://click.edenred.fr/Documents_Espace_Client/Portail/HPV2_visuel_tronline.png";
            } else {
                self.imageUrl = "https://click.edenred.fr/Documents_Espace_Client/Portail/HPV2_visuel_url.png";
            }

            self.username = $stateParams.login;
            self.registerUrl = Ressources.ESPACE_CLIENT_URL + "account";
    
            self.submitLogin = function (form) {

                self.connectionKo = false;
                self.captchaKo = false;

                return $validationProvider.validate(form).then(function () {
                    if (googleRecaptcha) {
                        return checkRecaptcha(googleRecaptcha.getResponse()).then(function (result) {
                            if (result.data.Content[0].success) {
                                return submitForm();
                            } else {
                                self.captchaKo = true;
                                return googleRecaptcha.reset();
                            }
                        }).catch(function () {
                            self.captchaKo = true;
                            googleRecaptcha.reset();
                        });
                    } else {
                        return submitForm();
                    }
                });

            };

            function renderRecaptcha() {
                var options = {
                    sitekey: Ressources.RECAPTCHA_SITE_KEY
                };

                googleRecaptcha = $grecaptcha(options);

                var el = $document[0].querySelector('#recaptcha');

                googleRecaptcha.render(el);
            }

            function checkRecaptcha(recaptchaToken) {
                return AuthenticationService.verifyCaptcha(recaptchaToken);
            }

            function submitForm() {
                return AuthenticationService.getAccessToken(self.username, self.password).then(function (result) {
                    if (returnUrl) {
                        if (version && version == 1) {
                            if (returnUrl[returnUrl.length - 1] != '/' && returnUrl.indexOf('?') == -1) returnUrl += '/';
                            $window.location.href = Ressources.ESPACE_CLIENT_URL + 'account/SignInToken?token=' + result.data.access_token + "&redirectUrl=" + encodeURIComponent(returnUrl);
                        } else {
                            $location.search('returnUrl', null);
                            $location.path(returnUrl);
                        }
                    } else {
                        $window.location.href = Ressources.ESPACE_CLIENT_URL + 'account/SignInToken?token=' + result.data.access_token;
                    }

                    self.disabled = true;
                    return false;
                })
                .catch(function (error) {
                    self.connectionKo = true;
                    self.disabled = false;
                    loginFailedTimes++;
                    
                    if (loginFailedTimes >= MAX_LOGIN_TIME_BEFORE_DISPLAY_CAPTCHA) {
                        if (googleRecaptcha) {
                            googleRecaptcha.reset();
                        } else {
                            renderRecaptcha();
                        }
                    }
                });
            }
        }
    };

    module.exports = {
        name: 'login',
        obj: login
    };

})();