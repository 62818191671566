;(function() {
    "use strict";

    function partenairesController($scope, $state, $filter, PartenairesService, localStorageService, StateService, Ressources, Partenaire) {
        var self = this;
        self.partenaires = null;

        PartenairesService.getAll().then(function (result) {
            if (result) {
                self.partenaires = result.plain();
            } else {
                self.partenaires = [];
            }
        }).catch(function(error) {
            //console.log("truc de ouf");
        });

        self.addNewPartenaire = function (index) {
            self.partenaires.splice(index + 1, 0, new Partenaire());
        };

        self.removePartenaire = function (index) {
            self.partenaires.splice(index, 1);
        };

        self.submitPartenaires = function (form) {
            if (form.$invalid) return false;

            var orderedPartenaires = $filter('orderBy')(self.partenaires, ['Id', 'Nom']);

            PartenairesService.updatePartenaires(orderedPartenaires).then(function () {
                alert("Succes");
            });
        };

    };

    module.exports = {
        name: "PartenairesController",
        fn: partenairesController
    };

})();
