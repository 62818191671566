;(function () {
  "use strict";

    var directiveFolders = (function () {var f = require("./index.js");f["accordionToggle.directive"]=require("./accordionToggle.directive.js");f["autocomplete.directive"]=require("./autocomplete.directive.js");f["carousel.directive"]=require("./carousel.directive.js");f["disableInputOnSubmission.directive"]=require("./disableInputOnSubmission.directive.js");f["emitLastRepeaterElement.directive"]=require("./emitLastRepeaterElement.directive.js");f["focus.directive"]=require("./focus.directive.js");f["index"]=require("./index.js");f["inputmask.directive"]=require("./inputmask.directive.js");f["isoToDate.directive"]=require("./isoToDate.directive.js");f["mask.directive"]=require("./mask.directive.js");f["navbarToggle.directive"]=require("./navbarToggle.directive.js");f["numberToString.directive"]=require("./numberToString.directive.js");f["position.directive"]=require("./position.directive.js");f["selectric.directive"]=require("./selectric.directive.js");f["wording.directive"]=require("./wording.directive.js");return f;})(),
        app = angular.module('app.directives', []);

    for (var prop in directiveFolders) {
        if (prop !== "index") {
            var directiveObject = require("./" + prop + ".js");
            app.directive(directiveObject.name, directiveObject.fn);
        }
    }

    module.exports = app;

})();
