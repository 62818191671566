; (function () {
    "use strict";

    var submitButton = {
        transclude: true,
        bindings: {
            form: '<'
        },
        template: function($element, $attrs) {
            var className = $attrs.class;
             $element[0].className = '';

            return [
                '<button type="submit" class="button ' + className + '" ng-class="{ \'button-loading\': $ctrl.async }" ng-disabled="$ctrl.async">',
                    '<span class="inner-text" ng-transclude></span>',
                    '<span class="spinner-wrapper" ng-show="$ctrl.async">',
                        '<span class="spinner-dot"></span>',
                        '<span class="spinner-dot"></span>',
                        '<span class="spinner-dot"></span>',
                    '</span>',
                '</button>'
            ].join('');
        },
        controller: function($rootScope, $scope, $timeout) {
            var self = this;
            
            //$('body').on('submit', "[name='" + self.form.$name + "']", function (e) {
                self.async = false;

                $rootScope.$on('cfpLoadingBar:loading', function () {
                    self.async = true;       
                });

                $rootScope.$on('cfpLoadingBar:loaded', function () {
                    self.async = false;
                    //$('body').off('submit', "[name='" + self.form.$name + "']");
                });
            //});

        }
    };

    module.exports = {
        name: 'submitButton',
        obj: submitButton
    };

})();
