; (function () {
    'use strict';

    var app = angular.module('tunnel.connected.parametrageComptabilite', [
        'ui.router'
    ]).config(function ($stateProvider) {
        var parametrageUrl = 'tunnel.connected.parametrage-comptable';
        var parametrageUrlFinCeremonie = 'tunnel.connected.fin-ceremonie-sepa';

        $stateProvider.state(parametrageUrl, {
            url: '/parametres/comptabilite?division',
            params: {
                estFinCeremonie: false
            },
            views: {
                'header@': {
                    template: '<header-actions breadcrumb-label="state"></header-actions>',
                    controller: function ($scope) {
                        $scope.state = 'Moyens de paiement';
                    }
                },
                'content': {
                    template: '<parametrage-comptabilite message="message" parametres-compte="parametresCompte" client="client" interlocuteur="interlocuteur"></parametrage-comptabilite>',
                    controller: function ($scope, $filter, $state, $stateParams, token, parametresCompte) {
                        if ($stateParams.estFinCeremonie)
                            $scope.message = 'SEPA_OK';
                        $scope.parametresCompte = parametresCompte;
                        $scope.client = {
                            idClient: token.ClientId,
                            client: token.RaisonSociale
                        };
                        var civilite = 1;
                        if (["Madame", "Mademoiselle", "Frau", "Fraulein"].indexOf(token.Civilite) >= 0)
                            civilite = 2;

                        $scope.interlocuteur = {
                            Id: token.IdInterlocuteur,
                            Nom: token.Nom,
                            Prenom: token.Prenom,
                            Email: token.Email,
                            Phone: token.Phone,
                            Civilite: civilite
                        };
                    }
                }
            },
            resolve: {
                client: function (ClientService) {
                    return ClientService.getClient();
                },
                token: function (localStorageService, Ressources, AuthenticationService) {
                    var token = localStorageService.get(Ressources.TOKEN_KEY);
                    if (token) return token;
                    return AuthenticationService.getAccessTokenV1();
                },
                parametresCompte: function (token, $stateParams, $q, ClientService, Ressources, localStorageService) {
                    if (!$stateParams.division)
                        return $q.reject();
                    return ClientService.rechercherParametrageComptabilite($stateParams.division).then(function(result) {
                        return result.plain();
                    });
                }
            }
        }).state(parametrageUrlFinCeremonie, {
            url: '/parametres/fin-ceremonie-sepa',
            views: {
                'content': {
                    template: '',
                    controller: function ($scope, $state) {
                        $state.go(parametrageUrl, { estFinCeremonie: true });
                    }
                }
            }
        });
    });

    var componentFolders = ({"parametrage.component":require("./parametrage.component.js"),"parametrage":require("./parametrage.js")});

    for (var prop in componentFolders) {
        if (prop.indexOf('component') != -1) {
            app.component(componentFolders[prop].name, componentFolders[prop].obj);
        }
    }

    module.exports = app;
})();