;
(function() {
    "use strict";

    function devisController($scope, $state, DevisService, ClientService, localStorageService, StateMachineService, Ressources, panier) {
        var self = this;
    };

    module.exports = {
        name: "DevisController",
        fn: devisController
    };

})();
