; (function () {
    "use strict";

    var headerConnected = {
        bindings: {
            breadcrumbs: '<'
        },
        templateUrl: componentsPath + 'header/header.connected.html',
        controller: function ($timeout, $window, localStorageService, Ressources) {
            var self = this;
            var storedToken = localStorageService.get(Ressources.TOKEN_KEY);

            self.urlHomePageV1 = Ressources.ESPACE_CLIENT_URL + "Account/SignInToken?token=" + storedToken.access_token;
            
            self.$postLink = function () {
                $timeout(function () {
                    var $burgerToggle = $('#burgerToggle'), // by default the checked value is false
                    $burgerMenu = $('.burger-menu'),
                    $burgerIcon = $('.icon-burger'),
                    $burgerSections = $burgerMenu.find('section');
                    $burgerToggle.on('change', function (e) {
                        $burgerMenu.toggleClass('open');
                        $burgerIcon.toggleClass('active');
                        if ($burgerIcon.hasClass('active')) {
                            $burgerSections.velocity("transition.slideUpIn", { duration: 350, stagger: 150 })
                            .delay(350);
                        }
                    });
                });
            };
        }
    };

    module.exports = {
        name: 'headerConnected',
        obj: headerConnected
    };

})();
