; (function () {
    "use strict";

    function simulationController($scope, $stateParams, $timeout, $injector, $q, StateService, Ressources, PanierService, ClientService, Precommande) {
        var self = this,
            $validationProvider = $injector.get('$validation'),
            panier = StateService.getPanier();

        self.valeurFaciale = Ressources.VALEUR_FACIAL_PAR_DEFAUT;
        self.partPatronale = Ressources.PART_PATRONALE_PAR_DEFAUT;
        self.nbBeneficiaires = $stateParams.nbBeneficiaires || Ressources.NB_BENEFS_PAR_DEFAUT;
        self.nbTitres = Ressources.NB_TITRES_PAR_DEFAUT;

        self.feedlyUrl = "Content/views/feedly/simulation-envoyer.html";

        $scope.$on('PPP_updated', function (e, newValue) {
            var ppp = parseFloat(newValue, 10);
            self.partPatronale = ppp;
            $timeout(_updateSimulation(self.valeurFaciale, ppp));
        });

        self.totalBilled = 0;

        var _updateSimulation = function (vf, ppp) {

            var _benefs = self.nbBeneficiaires,
                _titres = self.nbTitres;

            if (!ppp) {
                ppp = PART_PATRONALE_PAR_DEFAUT;
            }

            self.totalBilled = _benefs * _titres * vf * 11 / 100;
            self.totalBilledByMonth = self.totalBilled / 12;

            /***************************
            ***
            ***    Equations de calculs du simulateur
            ***    RG #67379
            ***    •  Dépense totale = nbBenef x 11 x nbJours x VF
            ***    •  L'entreprise dépense = nbBenef x 11 x nbJours x VF x PP
            ***    •  Le salarié dépense = 11 x nbJours x VF x (1 - PP)
            ***    •  L'entreprise économise = nbBenef x 11 x nbJours x VF x PP x 50%
            ***    •  Le salarié économise  = 11 x nbJours x VF x PP x 23%
            ***
            ***************************/

            self.company = {
                total: 11 * _titres * vf * (ppp / 100) * _benefs,
                totalByMonth: ppp / 100 * vf * _benefs * _titres,
                savings: 11 * _titres * vf * (ppp / 100) * _benefs * (50 / 100),
                savingsByMonth: _titres * vf * ppp / 100 * 50 / 100 * _benefs
            };

            self.worker = {
                total: (1 - ppp / 100) * _titres * vf * 11,
                totalByMonth: (1 - ppp / 100) * _titres * vf,
                savings: 11 * _titres * vf * ppp / 100 * 23 / 100,
                savingsByMonth: _titres * vf * ppp / 100 * 23 / 100
            };

        };

        /**
         * Slider VF options declarations
         */
        self.sliderVFP = {
            options: {
                animate: true,
                start: [self.valeurFaciale],
                range: {
                    'min': [Ressources.VF_MIN],
                    'max': [Ressources.VF_MAX]
                },
                tooltips: wNumb({
                    decimals: 2,
                    encoder: function (a) {
                        return a / 100;
                    },
                }),
                connect: 'lower',
                step: 10,
                behaviour: 'snap'
            },
            events: {
                change: function (value) {
                    self.valeurFaciale = parseInt(value[0].toString(), 10);
                }
            }
        };

        $scope.$watchGroup([
            angular.bind(self, function () { return self.valeurFaciale; }),
            angular.bind(self, function () { return self.partPatronale; }),
            angular.bind(self, function () { return self.nbBeneficiaires; }),
            angular.bind(self, function () { return self.nbTitres; })],
            function () {
                _updateSimulation(self.valeurFaciale, self.partPatronale);
            });

        self.commander = function (form) {
            panier = StateService.getPanier();
            $validationProvider.validate(form)
                .then(function () {
                    return panier && panier.Id
                        ? $q.resolve()
                        : PanierService.creerPanier()
                            .then(function (result) {
                                panier = result.plain();
                                localStorage.setItem(Ressources.APPLICATION_PREFIX + Ressources.PANIER_KEY, panier.Id);
                            });
                })
                .then(function () {
                    if (panier.Precommandes && panier.Precommandes[0]) {
                        if (panier.Precommandes[0].NbBeneficiaireDevis !== self.nbBeneficiaires) {
                            return PanierService.enregistrerPrecommande(panier.Id, panier.Precommandes[0].Id, { NbBeneficiaireDevis: self.nbBeneficiaires })
                                .then(function (result) {
                                    panier.Precommandes[0].NbBeneficiaireDevis = result.plain().NbBeneficiaireDevis;
                                });
                        } else {
                            return $q.resolve();
                        }
                    } else {
                        var precommande = new Precommande();
                        precommande.NbBeneficiaireDevis = self.nbBeneficiaires;
                        precommande.IdPointLivraisonParDefaut = panier.Client.Value.PointLivraison[0].Id;
                        precommande.ReferencePLParDefaut = panier.Client.Value.PointLivraison[0].Reference;
                        return PanierService.creerPrecommande(panier.Id, precommande)
                            .then(function (result) {
                                panier.Precommandes = [result.plain()];
                            });
                    }
                })
                .then(function () {
                    if (!panier.Client.Value.Effectif) {
                        panier.Client.Value.Effectif = parseInt(self.nbBeneficiaires);
                        $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                        return ClientService.enregistrerClient(panier.Client.Id, { Effectif: panier.Client.Value.Effectif });
                    } else {
                        return $q.resolve();
                    }
                })
                .then(function () {
                    return StateService.next();
                })
                .catch(function () {
                    $("#mCSB_1_container").css('top', 0);
                });
        };
    };

    module.exports = {
        name: "SimulationController",
        fn: simulationController
    };

})();
