;(function() {
    "use strict";

    function Client () {
        return function () {
            this.Id = null;
            this.JetonClientProspect  = null;
            this.RaisonSociale = null;
            this.Siret = null;
            this.CodeNaf = null;
            this.NumeroDivision = null;
            this.CodeStatutDivision = null;
            this.DateCreationSociete = null;
            this.Effectif = null;
            this.EstPrive = null;
            this.EstSolvable = null;
            this.AConditionCommercialeActive = null;
            this.EstXSell = null;
            this.Adresse = null;
            this.Interlocuteur = [];
            this.Contrat = [];
        };
    };

    module.exports = {
        name: "Client",
        fn: Client
    }

})();
