;
(function() {
    'use strict';

    var app = angular.module('tunnel.login', ['ui.router']);

    var componentFolders = ({"activationExpired.component":require("./activationExpired.component.js"),"login.component":require("./login.component.js"),"login":require("./login.js")});

    for (var prop in componentFolders) {
        if (prop.indexOf('component') != -1) {
            app.component(componentFolders[prop].name, componentFolders[prop].obj);
        }
    }

    app.config(function($stateProvider) {

        $stateProvider.state('tunnel.login', {
                url: '/login?returnUrl&version&token&login&source',
                views: {
                    'header@': {
                        template: '<header-connection></header-connection>'
                    },
                    'content': {
                        template: '<login class="connection-wrapper"></login>',
                        controller: function($state, activationAccount, AuthenticationService) {
                            if (activationAccount) {
                                AuthenticationService.setUserInfo(false, activationAccount.Login, activationAccount.UserId);
                                switch (activationAccount.TokenStatus) {
                                    case 2: //VALIDÉ
                                        $state.go('.', { token: null, login: activationAccount.Login });
                                        break;
                                    case 1: // PÉRIMÉ
                                        AuthenticationService.setUserInfo(false, activationAccount.Login, activationAccount.UserId);
                                        $state.go('tunnel.activation');
                                        break;
                                }
                            }
                        }
                    }
                },
                resolve: {
                    redirect: function(Ressources) {
                        if (Ressources.V3_LOGIN){
                            location.replace(Ressources.V3_LOGIN);
                        }
                    },
                    activationAccount: function($stateParams, AuthenticationService) {
                        var activationToken = $stateParams.token;
                        if (activationToken) {
                            return AuthenticationService.activateAccount(activationToken).then(function(result) {
                                return result.data.Content[0];
                            });
                        } else {
                            return null;
                        }
                    }
                }
            })
            .state('tunnel.activation', {
                url: '/activation/expired',
                views: {
                    'header@': {
                        template: '<header-connection></header-connection>'
                    },
                    'content': {
                        template: '<activation-expired user-guid="userId" class="connection-wrapper"></activation-expired>',
                        controller: function($scope, $state, userId) {
                            if (!userId) {
                                $state.go('tunnel.login');
                                return;
                            }
                            $scope.userId = userId;
                        }
                    }
                },
                resolve: {
                    userId: function(AuthenticationService) {
                        return AuthenticationService.getUserInfo().userId;
                    }
                }
            });

    });
})();