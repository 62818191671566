;(function() {
    "use strict";

    function Precommande () {
        return function () {
            this.Id = null;
            this.JetonPrecommande = null;
            this.ValeurFacialeParDefaut = null;
            this.PartPatronaleParDefaut = null;
            this.NbBeneficiaireDevis = null;
            this.Contrat = null;
            this.IdContrat = null;
            this.IdInterlocuteurCGV = null;
            this.UrlPdf = null;
            this.ReferenceEtapeTunnel = null;
            this.IdPointLivraisonParDefaut = null;
            this.ReferencePLParDefaut = null;
            //this.Statut = null;
        };
    };

    module.exports = {
        name: "Precommande",
        fn: Precommande
    }

})();
