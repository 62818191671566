; (function () {
    "use strict";

    var dynamicTable = {
        bindings: {
            head: '<',
            body: '<',
            searchText: '<',
            filterColumnsIndex: '<',
            templateWhileEmpty: '<',
            onSelectRow: '&'
        },
        transclude: {
            'head': '?actionsHead',
            'body': '?actionsBody'
        },
        templateUrl: componentsPath + 'table/dynamicTable.html',
        controller: function () {
            var self = this;

            self.$onInit = function () {
                self.sortIndex = 1;
                self.reverse = true;
                self.toggle = function (idx) {
                    self.sortIndex = idx;
                    self.reverse = !self.reverse;
                };
            }

            self.openOptions = function (row) {
                 
                self.selectedRow = row;
                self.onSelectRow({ row: row });
            }
            self.saveRow = function (row) {
                 
                self.onSelectRow({ row: row });
            }
            self.closeOptions = function () {
                self.selectedRow = null;
            }

            self.RemoveCompUp_6 = true;
            self.RemoveCompDown_6 = true;

        }
    };

    module.exports = {
        name: 'dynamicTable',
        obj: dynamicTable
    };
})();