; (function () {
    "use strict";

    function clientService(Restangular, RestangularSansIntercepteur, Client, Interlocuteur, PanierService, localStorageService, Ressources, StateService, $q) {
        var baseClients = Restangular.all('clients');
        var baseClientsSI = RestangularSansIntercepteur.all('clients');

        var _client, _interlocuteur;

        var setClient = function (client) {
            _client = client;
        }

        var getClient = function () {
            return _client;
        }

        var setInterlocuteur = function (interlocuteur) {
            _interlocuteur = interlocuteur;
        }

        var getInterlocuteur = function () {
            return _interlocuteur;
        }

        var enregistrerClient = function (clientId, client, idPrecommande) {
            var queryParams = idPrecommande
                ? { idPrecommande: idPrecommande }
                : undefined;
            return baseClients.one(clientId).patch(client, queryParams);
        };

        var creerClient = function (client) {
            return baseClients.post(client);
        };

        var enregistrerInterlocuteur = function (clientId, interlocuteurId, interlocuteur) {
            return baseClients.one(clientId).one("interlocuteurs", interlocuteurId).patch(interlocuteur);
        };

        /* QAS_KO */
        var creerInterlocuteur = function (clientId, interlocuteur) {
            return baseClients.one(clientId).post("interlocuteurs", interlocuteur);
        };

        var creerConfigurationOperationnelle = function (clientId, configurationOperationnelle) {
            return baseClients.one(clientId).post("configurationOperationnelle", configurationOperationnelle);
        };

        var envoiEmailDevis = function (htmlPdf) {
            var panier = localStorageService.get(Ressources.PANIER_KEY);

            var panierId = panier.Id;

            var precommandeId = panier.Precommandes[0].Id;

            var htmlCodePdf = { html: htmlPdf };
            // Name of route = PostEmailWithDevis
            return Restangular.one('paniers', panierId).one("precommandes", precommandeId).one('devis').post('', htmlCodePdf);
        };

        var rechercherPanierEnCours = function () {
            var panier = localStorageService.get(Ressources.PANIER_KEY);
            if (panier) return {
                panier: panier,
                reprisePanier: false
            };

            var _initializePrecommandeByInterlocuteur = function () {
                return PanierService.initialiserPrecommande(_client.Id, _interlocuteur.Id, _client.NumeroDivision, Ressources.QUOTITE_PAR_DEFAUT, 1, 'PRINCIPAL', false, _interlocuteur)
                    .then(function (precommandeResult) {
                        return precommandeResult.plain().Precommande.IdPanier;
                    });
            };

            var _chargerPanierCompletByIdPanier = function (idPanier) {
                return PanierService.chargerPanierComplet(idPanier);
            };

            var _rechercherReemissionCarteByPanier = function (panierResult) {
                return rechercherReemissionCarte(panierResult.plain());
            }

            var _setAndReturnPanier = function (panier, reprisePanier) {
                StateService.setPanier(panier);
                return {
                    panier: panier,
                    reprisePanier: reprisePanier
                };
            };

            var _initializePrecommande = function(clientId, interlocuteur){
                return _initializePrecommandeByInterlocuteur()
                    .then(_chargerPanierCompletByIdPanier)
                    .then(_rechercherReemissionCarteByPanier);
            }
            if (!_client.Id) {
                return creerClient(_client)
                    .then(function(clientResult) {
                        setClient(clientResult.plain());
                     })
                     .then(_initializePrecommande)
                     .then(function (rechercherResult) {
                         return _setAndReturnPanier(rechercherResult, false);
                     });
            } else {
                return baseClients.one(_client.Id).one('panierencours').get({
                    identifiantProduit: Ressources.CODE_PRODUIT.CARTE_TICKET_RESTAURANT
                })
                    .then(_rechercherReemissionCarteByPanier)
                    .then(function (rechercherResult) {
                        return _setAndReturnPanier(rechercherResult, true);
                    }).catch(function () {
                        return _initializePrecommande(_client.Id, _interlocuteur)
                            .then(function (rechercherResult) {
                                return _setAndReturnPanier(rechercherResult, false);
                            });
                    });
            }
        };

        var rechercherPointLivraison = function (idClient, numeroDivision, referencePointRattachement) {
            return baseClients.one(idClient).one('pointlivraison').get({ identifiantProduit: Ressources.CODE_PRODUIT.CARTE_TICKET_RESTAURANT, numeroDivisionClient: numeroDivision, referencePointRattachement: referencePointRattachement });
        };

        var estInterlocuteurClientV2 = function (idClient, email) {
            return baseClients.one(idClient).one('EstClientV2').get({ email: email });
        };

        var rechercherParametrageComptabilite = function (idClient) {
            return baseClients.one(idClient).getList('ParametrageComptabilite');
        };

        var rechercherModePaiement = function () {
            if (!_client.ClientId) return null;
            return baseClients.one(_client.ClientId).one('modepaiement').get({ idProduit: Ressources.CODE_PRODUIT.CARTE_TICKET_RESTAURANT });
        }

        var enregistrerCoordonneesBancaires = function (idClient, compte) {
            return baseClients.one(idClient).post('ParametrageComptabilite', compte);
        };

        var passerAuPrelevement = function (idClient, compte) {
            return baseClients.one(idClient).one('ParametrageComptabilite').post('DemanderPassageAuPrelevement', compte);
        };

        var rechercherDescriptifs = function (numeroDivision) {
            return baseClients.get('descriptifs?numeroDivision=' + numeroDivision);
        };

        var createDescriptif = function (descriptif) {
            return baseClients.one('').post('descriptifs', descriptif);
        };

        var toggleDescriptifState = function (numeroDivision, descriptifCode, descriptif) {
            
            return baseClients.one('descriptifs', descriptifCode).patch(descriptif, { numeroDivision: numeroDivision });
        };

        var rechercherContrat = function (numeroDivision) {
            return baseClients.get('contrats?numeroDivision=' + numeroDivision);
        };

        var rechercherReemissionCarte = function (panier) {
            var idSalaries = _.map(panier.Beneficiaires, function (b) {
                return b.IdSalarie;
            });
            return baseClients.one(_client.Id).one('rechercherreemissioncarte').post('', {
                NumeroDivision: _client.NumeroDivision,
                IdProduit: Ressources.CODE_PRODUIT.CARTE_TICKET_RESTAURANT,
                IdArticle: Ressources.ID_ARTICLE_CARTE_TICKET_RESTAURANT,
                IdSalaries: idSalaries
            }).then(function (reemissionCarteResult) {
                var benefs = reemissionCarteResult.plain();

                _.forEach(panier.Beneficiaires, function (b1) {
                    var benef = _.find(benefs, function (b2) {
                        return b2.IdSalarie === b1.IdSalarie;
                    });
                    if (benef) {
                        b1.TypeCreation = benef.TypeCreation;
                    }
                });

                return panier;
            });
        };

        var patchCmaSiret = function(idClient, idPrecommande, siret) {
            return baseClients.all('CmaSiret').one(idClient).one(idPrecommande).patch({
                siret: siret
            });
        };
        
        var getFactures = function(numeroDivision) {
            return baseClients.get('factures?numeroDivision=' + numeroDivision);
        };

        var getFacture = function(identifiantArchivage) {
            return baseClientsSI.get('factures/' + identifiantArchivage);
        };

        var sendFacture = function(numeroFacture, identifiantFacture, numeroCommande, raisonSociale) {
            return baseClients.all('factures/mail').customPUT({
                "numeroFacture": numeroFacture,
                "identifiantFacture": identifiantFacture,
                "numeroCommande": numeroCommande,
                "raisonSociale": raisonSociale
            });
        };

        return {
            getInterlocuteur: getInterlocuteur,
            setInterlocuteur: setInterlocuteur,
            getClient: getClient,
            setClient: setClient,
            creerClient: creerClient,
            enregistrerClient: enregistrerClient,
            enregistrerInterlocuteur: enregistrerInterlocuteur,
            creerInterlocuteur: creerInterlocuteur,
            creerConfigurationOperationnelle: creerConfigurationOperationnelle,
            envoiEmailDevis: envoiEmailDevis,
            rechercherDescriptifs: rechercherDescriptifs,
            createDescriptif: createDescriptif,
            toggleDescriptifState: toggleDescriptifState,
            rechercherPanierEnCours: rechercherPanierEnCours,
            rechercherPointLivraison: rechercherPointLivraison,
            estInterlocuteurClientV2: estInterlocuteurClientV2,
            rechercherParametrageComptabilite: rechercherParametrageComptabilite,
            enregistrerCoordonneesBancaires: enregistrerCoordonneesBancaires,
            passerAuPrelevement: passerAuPrelevement,
            rechercherReemissionCarte: rechercherReemissionCarte,
            rechercherContrat: rechercherContrat,
            rechercherModePaiement: rechercherModePaiement,
            patchCmaSiret: patchCmaSiret,
            getFactures: getFactures,
            getFacture: getFacture,
            sendFacture: sendFacture
        };
    }

    module.exports = {
        name: "ClientService",
        fn: clientService
    }

})();
