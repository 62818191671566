;(function() {
    "use strict";

    function accordionToggle() {
        return {
            restrict: 'A',
            scope: {},
            priority: 9999,
            link: function (scope, el, attrs) {


                $(el).on('click', function(e){

                    var stateToggle = $(el).attr('aria-expanded'),
                    anchor = $(el).attr('aria-controls');
                    $(this).attr('aria-expanded', !JSON.parse(stateToggle));
                    $('#'+anchor).attr('aria-hidden', JSON.parse(stateToggle));

                });

            }
        };
    };

    module.exports = {
        name: 'accordionToggle',
        fn: accordionToggle
    };

})();
