; (function () {
    "use strict";

    var factures = {
        bindings: {
            factures: '<'
        },
        templateUrl: componentsPath + 'factures/factures.html',
        controllerAs: 'facturesCtrl',
        controller: function ($scope, $stateParams, $filter, $timeout, $window, $http, ClientService, Ressources, AuthenticationService) {
            var self = this;
            var pageSize = 50;
            self.raisonSociale = $stateParams.raisonSociale;

            self.$onInit = function () {
                self.errorMessage = null;
                if (self.factures.length === 0) {
                    self.errorMessage = 'Aucun document n\'a été trouvé.';
                } else {
                    self.displayedFactures = self.factures;

                    for (var i = self.displayedFactures.length - 1; i >= 0; i--) {
                        if (self.displayedFactures[i].Produit.Id != 1 && self.displayedFactures[i].Produit.Id != 240 && self.displayedFactures[i].Produit.Id != 80 && self.displayedFactures[i].Produit.Id != 221 &&
                            self.displayedFactures[i].Produit.Id != 220 && self.displayedFactures[i].Produit.Id != 2 && self.displayedFactures[i].Produit.Id != 9 && self.displayedFactures[i].Produit.Id != 24) {
                            self.displayedFactures.splice(i, 1);
                        }
                    }

                    console.log(self.displayedFactures);
                    self.filteredByProduct = false;
                    self.filteredByYear = false;

                    if (self.displayedFactures && self.displayedFactures.length > 0) {
                        _.map(self.displayedFactures, function (facture) {
                            if (facture.Statut.indexOf('Soldé') >= 0) {
                                facture.StatutColor = 'statut-green';
                            } else if (facture.Statut.indexOf('Impayé') >= 0) {
                                facture.StatutColor = 'statut-red';
                            } else if (facture.Statut.indexOf('Partiellement soldé') >= 0) {
                                facture.StatutColor = 'statut-orange';
                            }

                            facture.DateEmission = {
                                Value: facture.DateEmission,
                                Label: moment(facture.DateEmission).format('DD/MM/YYYY')
                            };
                        });

                        if ($stateParams.numeroFacture) {
                            self.numeroFacture = $stateParams.numeroFacture;
                            var filteredFacture = _.filter(self.factures, function (facture) {
                                return facture.NumeroFacture == self.numeroFacture;
                            });

                            if (filteredFacture && filteredFacture.length > 0) {
                                self.displayedFactures = filteredFacture;
                            }
                            self.filtered = true;
                        }
                    }

                    self.elementsToDisplay = pageSize;

                    $scope.$emit('facturesLoaded', self.factures);
                }
            };

            self.removeFilter = function () {
                self.displayedFactures = self.factures;

                self.filtered = false;
            };

            self.displayMore = function () {
                self.elementsToDisplay += pageSize;
            };

            $scope.$on('yearUpdated', function (event, selectedYear) {
                if (selectedYear != 'Toutes') {
                    self.filteredByYear = true;

                    self.yearFilteredFactures = _.filter(self.factures, function (facture) {
                        return moment(facture.DateEmission.Value).year() == selectedYear;
                    });

                    if (self.filteredByProduct) {
                        self.displayedFactures = _.intersection(self.yearFilteredFactures, self.productFilteredFactures);
                    } else {
                        self.displayedFactures = self.yearFilteredFactures;
                    }
                } else {
                    self.filteredByYear = false;

                    if (self.filteredByProduct) {
                        self.displayedFactures = self.productFilteredFactures;
                    } else {
                        self.displayedFactures = self.factures;
                    }
                }
            });

            $scope.$on('productUpdated', function (event, selectedProductId) {
                if (selectedProductId != 'Tous') {
                    self.filteredByProduct = true;

                    self.productFilteredFactures = _.filter(self.factures, function (facture) {
                        return facture.Produit.Id == selectedProductId;
                    });

                    if (self.filteredByYear) {
                        self.displayedFactures = _.intersection(self.yearFilteredFactures, self.productFilteredFactures);
                    }
                    else {
                        self.displayedFactures = self.productFilteredFactures;
                    }
                } else {
                    self.filteredByProduct = false;

                    if (self.filteredByYear) {
                        self.displayedFactures = self.yearFilteredFactures;
                    } else {
                        self.displayedFactures = self.factures;
                    }
                }
            });

            self.downloadFile = function (numeroFacture, identifiantArchive) {
                $scope.$emit('toggleLoadingToaster', { header: "Votre document est en cours de génération", caption: "Veuillez patientez s'il vous plaît..." });
                $http({
                    url: 'api/clients/factures/' + identifiantArchive,
                    method: "GET",
                    headers: {
                        Authorization: 'Bearer ' + AuthenticationService.getToken().access_token
                    },
                    contentType: "application/json",
                    responseType: 'arraybuffer'
                }).then(function (response) {
                    $timeout(_.noop, 5000).then(function () {
                        download(response.data, numeroFacture + '.pdf', 'application\/pdf');
                        $scope.$emit('closeLoadingToaster');
                    });
                }).catch(function (error) {
                    $timeout(_.noop, 5000).then(function () {
                        self.errorMessage = 'Erreur lors de la génération du document';
                    });
                    $timeout(_.noop, 9000).then(function () {
                        self.errorMessage = null;
                        $scope.$emit('closeLoadingToaster');
                    });
                });
            }

            function download(data, filename, type) {
                var a = document.createElement("a"),
                    file = new Blob([data], { type: type });

                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(file, filename);
                } else {
                    var url = URL.createObjectURL(file);
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function () {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 50);
                };
            }

            self.sendFile = function (numeroFacture, identifiantFacture, numeroCommande, raisonSociale) {
                $scope.$emit('toggleLoadingToaster', { header: "Votre document est en cours d'envoi", caption: "Veuillez patientez s'il vous plaît..." });
                ClientService.sendFacture(numeroFacture, identifiantFacture, numeroCommande, raisonSociale).then(function (response) {
                    $timeout(_.noop, 4000).then(function () {
                        $scope.$emit('closeLoadingToaster');
                    });
                }).catch(function (error) {
                    $timeout(_.noop, 4000).then(function () {
                        self.errorMessage = "Votre facture n'a pas pu vous être envoyée.";
                        $scope.$emit('closeLoadingToaster');
                    });

                    $timeout(_.noop, 9000).then(function () {
                        self.errorMessage = null;
                    });
                });
            };

            function disableAllSorts() {
                self.sortedByNumeroFacture = false;
                self.sortedByDateEmission = false;
                self.sortedByType = false;
                self.sortedByProduit = false;
                self.sortedByMontant = false;
                self.sortedByNumeroCommande = false;
                self.sortedByAdresseFacturation = false;
                self.sortedByStatut = false;
            };

            self.sortByNumeroFacture = function () {
                if (!self.reverse) {
                    self.displayedFactures = _.sortBy(self.displayedFactures, function (facture) {
                        return facture.NumeroFacture;
                    });
                } else {
                    self.displayedFactures = _.sortBy(self.displayedFactures, function (facture) {
                        return -facture.NumeroFacture;
                    });
                }

                disableAllSorts();
                self.sortedByNumeroFacture = true;
                self.reverse = !!!self.reverse;
            };

            self.sortByDateEmission = function () {
                if (!self.reverse) {
                    self.displayedFactures = _.sortBy(self.displayedFactures, function (facture) {
                        return facture.DateEmission.Value;
                    });
                } else {
                    self.displayedFactures = _.sortBy(self.displayedFactures, function (facture) {
                        return -facture.DateEmission.Value;
                    });
                }

                disableAllSorts();
                self.sortedByDateEmission = true;
                self.reverse = !!!self.reverse;
            };

            self.sortByType = function () {
                if (!self.reverse) {
                    self.displayedFactures = _.sortBy(self.displayedFactures, function (facture) {
                        return facture.Type;
                    });
                } else {
                    self.displayedFactures = _.sortBy(self.displayedFactures, function (facture) {
                        return -facture.Type;
                    });
                }

                disableAllSorts();
                self.sortedByType = true;
                self.reverse = !!!self.reverse;
            };

            self.sortByProduit = function () {
                if (!self.reverse) {
                    self.displayedFactures = _.sortBy(self.displayedFactures, function (facture) {
                        return facture.Produit.Id;
                    });
                } else {
                    self.displayedFactures = _.sortBy(self.displayedFactures, function (facture) {
                        return -facture.Produit.Id;
                    });
                }

                disableAllSorts();
                self.sortedByProduit = true;
                self.reverse = !!!self.reverse;
            };

            self.sortByMontant = function () {
                if (!self.reverse) {
                    self.displayedFactures = _.sortBy(self.displayedFactures, function (facture) {
                        return facture.Montant;
                    });
                } else {
                    self.displayedFactures = _.sortBy(self.displayedFactures, function (facture) {
                        return -facture.Montant;
                    });
                }

                disableAllSorts();
                self.sortedByMontant = true;
                self.reverse = !!!self.reverse;
            };

            self.sortByNumeroCommande = function () {
                if (!self.reverse) {
                    self.displayedFactures = _.sortBy(self.displayedFactures, function (facture) {
                        return facture.NumeroCommande;
                    });
                } else {
                    self.displayedFactures = _.sortBy(self.displayedFactures, function (facture) {
                        return -facture.NumeroCommande;
                    });
                }

                disableAllSorts();
                self.sortedByNumeroCommande = true;
                self.reverse = !!!self.reverse;
            };

            self.sortByAdresseFacturation = function () {
                if (!self.reverse) {
                    self.displayedFactures = _.sortBy(self.displayedFactures, function (facture) {
                        return facture.AdresseFacturation;
                    });
                } else {
                    self.displayedFactures = _.sortBy(self.displayedFactures, function (facture) {
                        return -facture.AdresseFacturation;
                    });
                }

                disableAllSorts();
                self.sortedByAdresseFacturation = true;
                self.reverse = !!!self.reverse;
            };

            self.sortByStatut = function () {
                if (!self.reverse) {
                    self.displayedFactures = _.sortBy(self.displayedFactures, function (facture) {
                        return facture.Statut;
                    });
                } else {
                    self.displayedFactures = _.sortBy(self.displayedFactures, function (facture) {
                        return -facture.Statut;
                    });
                }

                disableAllSorts();
                self.sortedByStatut = true;
                self.reverse = !!!self.reverse;
            };
        }
    };

    module.exports = {
        name: 'factures',
        obj: factures
    };
})();