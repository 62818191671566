;(function() {
  "use strict";

  function partenairesService(Restangular, $q) {
        self.partenaires = null;
        self.partenaire = null;

        var getAll = function () {
            if (self.partenaires === null) {
                self.partenaires = Restangular.one('partenaires').get();
            }
            return self.partenaires;
        }

        var get = function (idFederation) {
            if (self.partenaire === null) {
                self.partenaire = Restangular.one('partenaires').one('GetOnePartenaires', idFederation).get();
            }
            return self.partenaire;
        }

        var updatePartenaires = function (partenaires) {
            return Restangular.one("partenaires").patch(partenaires);
        };

        return {
            getAll: getAll,
            updatePartenaires: updatePartenaires,
            get: get
        };
    };

    module.exports = {
        name: "PartenairesService",
        fn: partenairesService
    }

})();
