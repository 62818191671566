; (function () {
    "use strict";

    var descriptifsTitre = {
        bindings: {
            descriptifs: '<',
            contrats: '<'
        },
        templateUrl: componentsPath + 'descriptifs/descriptifs.html',
        controllerAs: 'descriptifCtrl',
        controller: function ($scope, $stateParams, $filter, $timeout, ClientService, DescriptifService, Ressources, ngDialog) {
            var self = this;
            this.select = function (row) {
                self.selectedRow = row;
            }
            var header;
            var facialValueSettings;
            var nameOnPaperTypeList;
            var SEUIL_EXO = Ressources.LIMITE_EXONERATION; // was 5.43;
            var TRBV_CODE = 24;
            var TR_CODE = 1;
            var CTR_CODE = 240;
            var TRBV_BENEVOLE_SUBPRODUCT_CODE = 139;
            var _numeroDivision = $stateParams.division;
            var _feedlyopen = $stateParams.feedlyopen;
            var _produitParam = $stateParams.produit;
            var _sousproduitParam = $stateParams.sousproduit;
            var _valeurParam = ($stateParams.valeur && !isNaN($stateParams.valeur)) ? parseFloat($stateParams.valeur) / 100 : null;
            var _parteParam = ($stateParams.parte && !isNaN($stateParams.parte)) ? parseFloat($stateParams.parte) / 100 : null;
            var _partpParam = ($stateParams.partp && !isNaN($stateParams.partp)) ? parseFloat($stateParams.partp.replace(',', '.')) : null;
            self.activer = "Activer";
            self.vfMaskOptions = self.ppeMaskOptions = {
                'alias': 'numeric',
                'groupSeparator': ' ',
                'autoGroup': true,
                'digits': 2,
                'digitsOptional': false,
                'suffix': ' €',
                'placeholder': '0'
            };

            self.pppMaskOptions = {
                'alias': 'numeric',
                'groupSeparator': ' ',
                'autoGroup': true,
                'digits': 2,
                'digitsOptional': false,
                'suffix': ' %',
                'placeholder': '0'
            };


            self.$onInit = function () {
                initVariables();

                var currentURL = window.location.href;
                var needToOpenFeedly = !!(currentURL.split("?")[1].split("&")[0].split("=")[0] == "ajoutvaleurfaciale" ? currentURL.split("?")[1].split("&")[0].split("=")[1] : undefined);
                var _feedlyopen = needToOpenFeedly;

                if (_feedlyopen) {
                    initCreationDescriptif(_produitParam, _sousproduitParam);
                    self.openCreationDescriptive(_valeurParam, _parteParam, _partpParam);
                } else {
                    initCreationDescriptif();
                }

                // $timeout(function () {
                //     $(".icon-button").hide();
                // }, 1000);
            };

            self.filteredColumnsIndex = [];
            self.vfSliderConfig = null;
            self.pppSliderConfig = DescriptifService.getPPPSetting();
            self.ppeSliderConfig = {
                min: null,
                max: null,
                'default': null
            };

            $scope.$on('query:updated', function (event, query) {
                self.searchText = query;
            });

            $scope.$on('action:fromHeader', function (event, action) {
                self[action]();
            });

            /**
             * Update filter when user changes input or select (vf, ppe, ppp, product...)
             * @param {type} column - descriptifs table column
             **/
            self.updateFilter = function (column) {
                if (column.filterValue) {
                    if (self.filteredColumnsIndex.indexOf(self.head.indexOf(column)) === -1) {
                        self.filteredColumnsIndex.push(self.head.indexOf(column));
                    }
                } else {
                    self.removeFilter(self.head.indexOf(column));
                }
            };

            /**
             * Remove a filter whose value is cleared by user (vf, ppe, ppp, product...)
             * @param {type} indexFilteredColumn - index of column
             **/
            self.removeFilter = function (indexFilteredColumn) {
                if (self.filteredColumnsIndex.indexOf(indexFilteredColumn) != -1) {
                    self.filteredColumnsIndex.splice(self.filteredColumnsIndex.indexOf(indexFilteredColumn), 1);
                    self.head[indexFilteredColumn].filterValue = null;
                }
            };

            /**
             * Reset all filter
             **/
            self.resetFilter = function () {
                self.filteredColumnsIndex = [];
                _.forEach(self.head, function (f) {
                    f.filterValue = null;
                });
                self.closeMenu();
            };

            /**
             * Open filter feedly
             **/
            self.openFilter = function () {
                self.feedlyOpen = true;
                self.filterOpen = true;
            };

            /**
             * Open descriptif creation feedly  
             **/
            self.openCreationDescriptive = function (vf, ppe, ppp) {
                if (self.creationProducts.length === 0) {
                    self.creerVide = true;
                    return;
                }
                if (self.creationDescriptif.product) {
                    self.onProductChange(vf, ppe, ppp);
                    checkVfpp();
                }
                if (self.creationDescriptif.product && self.creationDescriptif.product.showSubProduct && self.creationDescriptif.subProduct) {
                    self.onSubProductChange(vf, ppe, ppp);
                }
                self.feedlyOpen = true;
                self.createDescriptiveOpen = true;
            };

            /**
             * Close feedly
             **/
            self.closeMenu = function () {
                self.feedlyOpen = false;
                self.filterOpen = false;
                self.createDescriptiveOpen = false;
                initCreationDescriptif();
            };

            /**
             * Find mono vf descriptifs by product and order them by DateCreation desc
             * @param {type} bySubProduct - product needs to determine sub products ? true : false
             * @returns {type} descriptifs - a list of ordered mono vf descriptifs
             **/
            function findProductDescriptifBasedOnProductIdOrSubProduct(bySubProduct) {
                var monoProductDescriptifs;
                if (bySubProduct) {
                    monoProductDescriptifs = _.filter(self.descriptifs, function (d) {
                        return d.Produit === self.creationDescriptif.product.value && self.creationDescriptif.subProduct && d.IdentifiantSegment === self.creationDescriptif.subProduct.value;
                    });
                } else {
                    monoProductDescriptifs = _.filter(self.descriptifs, function (d) {
                        return d.Produit === self.creationDescriptif.product.value;
                    });
                }

                monoProductDescriptifs = _.orderBy(monoProductDescriptifs, function (d) {
                    return d.DateCreation;
                }, "desc");

                return monoProductDescriptifs;
            }

            /**
             * When user change product
             **/
            self.onProductChange = function (vf, ppe, ppp) {
                self.creerDroitKo = !DescriptifService.canBeDuplicated(self.creationDescriptif.product.value, self.contrats);
                if (self.creerDroitKo) return;
                initKoVariables();

                if (!_sousproduitParam && self.creationDescriptif.subProduct) {
                    self.creationDescriptif.subProduct = null;
                }

                var monoProductDescriptifs;
                if (!self.creationDescriptif.product.showSubProduct) {
                    monoProductDescriptifs = findProductDescriptifBasedOnProductIdOrSubProduct(false);
                    initSliderSettings(vf, ppe, ppp);
                } else if (self.creationDescriptif.product.creationSubProducts && self.creationDescriptif.product.creationSubProducts.length === 1) {
                    self.creationDescriptif.subProduct = self.creationDescriptif.product.creationSubProducts[0];
                    monoProductDescriptifs = findProductDescriptifBasedOnProductIdOrSubProduct(true);
                    initSliderSettings(vf || monoProductDescriptifs[0].Montant, ppe, ppp);
                }

                if (monoProductDescriptifs && monoProductDescriptifs.length) {
                    self.creationDescriptif.identifiantDescriptif = monoProductDescriptifs[0].IdentifiantDescriptif;
                }
            };

            /**
             * When user changes sub product
             **/
            self.onSubProductChange = function (vf, ppe, ppp) {
                var monoProductDescriptifs = findProductDescriptifBasedOnProductIdOrSubProduct(true);

                if (monoProductDescriptifs && monoProductDescriptifs.length) {
                    self.creationDescriptif.identifiantDescriptif = monoProductDescriptifs[0].IdentifiantDescriptif;
                    initKoVariables();
                    initSliderSettings(vf || monoProductDescriptifs[0].Montant, ppe, ppp);
                }
            };

            /**
             * When user clicks on value labels (vf, ppe, ppp)
             * @param {type} type - vf? ppe? ppp?
             **/
            self.editVFPP = function (type) {
                if (self.vfKo || self.pppKo || self.ppeKo) {
                    return;
                }

                self['onEdit' + type.toUpperCase()] = true;
                self.creationDescriptif[type] = Math.round(parseFloat(self.creationDescriptif[type]) * 100) / 100;
            };

            /**
             * When value input is blured (vf, ppe, ppp)
             * @param {type} type - vf? ppe? ppp?
             **/
            self.blurVFPP = function (type) {

                self.creationDescriptif[type] = Math.round(parseFloat(String(self.creationDescriptif[type]).replace(',', '.')) * 100) / 100;

                checkVfpp();

                if (self.vfKo || self.pppKo || self.ppeKo) {
                    return;
                }

                switch (type) {
                    case 'vf':
                        self.onEditVF = false;
                        self.vfSlider.options.start = self.creationDescriptif.vf;
                        onVfChange([self.creationDescriptif.vf]);
                        break;
                    case 'ppe':
                        self.onEditPPE = false;
                        self.creationDescriptif.ppp = Math.round(self.creationDescriptif.ppe / self.creationDescriptif.vf * 10000) / 100;
                        self.pppSlider.options.start = self.creationDescriptif.ppp;
                        onPppChange([self.creationDescriptif.ppp]);
                        break;
                    case 'ppp':
                        self.onEditPPP = false;
                        self.pppSlider.options.start = self.creationDescriptif.ppp;
                        onPppChange([self.creationDescriptif.ppp]);
                        break;
                }
            };

            self.activerOuDesactiverDescriptifsLine = function (produit, etat) {
                self.count = -1;
                _.forEach(self.descriptifs, function (d) {
                    self.count++;
                    if (d.IdentifiantDescriptif == produit) {
                        self.descriptifs[self.count].Etat = etat;
                    }
                });
            }
            self.activerOuDesactiverValeurFaciale = function () {

                if (self.valeurActive != null && self.valeurActive != undefined) {
                    ClientService.toggleDescriptifState(_numeroDivision, self.valeurActive.IdentifiantDescriptif, { Etat: false });
                    self.activer = "Désactiver";
                    self.activerOuDesactiverDescriptifsLine(self.valeurActive.IdentifiantDescriptif, false);
                    self.valeurActive = null;
                    self.createDescriptif();
                }
                else if (self.selectedRow[6] && self.selectedRow != null && self.selectedRow != undefined) {
                    ClientService.toggleDescriptifState(_numeroDivision, self.selectedRow[0], { Etat: false });
                    self.selectedRow[6] = false;
                    self.activer = "Activer";
                    self.activerOuDesactiverDescriptifsLine(self.selectedRow[0], false);

                }
                else {
                    ClientService.toggleDescriptifState(_numeroDivision, self.selectedRow[0], { Etat: true });
                    self.selectedRow[6] = true;
                    self.activer = "Désactiver";
                    self.activerOuDesactiverDescriptifsLine(self.selectedRow[0], true);
                }
            };

            self.CheckVFActive = function () {
                checkVfpp();
                if (self.vfKo || self.pppKo || self.ppeKo) {
                    self.openCreationDescriptive();
                    return;
                }
                self.count = 0;
                _.forEach(self.descriptifs, function (d) {
                    if (d.Etat && d.Produit == self.creationDescriptif.product.value) {
                        self.count++;
                    }
                });
                if (self.count == 1) {
                    _.forEach(self.descriptifs, function (d) {
                        if (d.Etat && d.Produit == self.creationDescriptif.product.value) {
                            self.valeurActive = d;
                            self.libellePopup = "Souhaitez-vous désactiver l'ancienne valeur faciale du produit afin qu'elle ne soit plus proposée dans les tunnels de commande " + self.creationDescriptif.product.text + " ?";
                            self.feedlyOpen = false;
                            ngDialog.open({
                                template: '<popup-valeurs ok-fn="descriptifCtrl.activerOuDesactiverValeurFaciale()" ko-fn="descriptifCtrl.createDescriptif()" cancel-fn="closeThisDialog()">{{ descriptifCtrl.libellePopup }}</popup-valeurs>',
                                plain: true,
                                scope: $scope
                            });
                        }
                    });
                }
                else {
                    self.createDescriptif();
                }
            }
            /**
             * Create descriptif
             **/
            self.createDescriptif = function () {
                self.createDescriptifKo = false;
                self.duplicatedDescriptifKo = false;

                var client = ClientService.getClient();

                if (!client) {
                    return;
                }

                var descriptif = {
                    IdentifiantClient: _numeroDivision,
                    Produit: self.creationDescriptif.product.value,
                    IdentifiantDescriptif: self.creationDescriptif.identifiantDescriptif,
                    Montant: self.creationDescriptif.vf
                };
                if (self.creationDescriptif.product.showSubProduct && self.creationDescriptif.subProduct) {
                    descriptif.IdentifiantSegment = self.creationDescriptif.subProduct.value;
                } else {
                    descriptif.PartPatronaleMontant = self.creationDescriptif.ppe;
                    descriptif.PartPatronalePourcentage = self.creationDescriptif.ppp / 100;
                }

                $scope.$emit('toggleLoadingToaster', { header: "Création en cours", caption: "Veuillez patienter s'il vous plaît..." });

                return ClientService.createDescriptif(descriptif).then(function () {

                    //self.descriptifs = descriptifsResult.plain();
                    initVariables();
                    initCreationDescriptif();

                    var product = _.find(Ressources.PRODUITS, function (x) {
                        return descriptif.Produit === x.id;
                    });

                    self.newDescriptifCreated = {
                        'justCreated': true,
                        'type': product.label
                    };

                    $timeout(function () {
                        self.newDescriptifCreated = null;
                    }, 10000);

                    $timeout(function () {
                        $scope.$emit('closeLoadingToaster');
                        self.closeMenu();
                        $(".icon-button").hide();
                    }, 1000);

                    location.reload();

                }).catch(function (err) {
                    self.openCreationDescriptive();
                    var errorCode = err.data.Content[0].ErrorCode;;

                    if (errorCode === "DESCRIPTIF_CREER_DOUBLON_KO") {
                        self.duplicatedDescriptifKo = true;
                    } else {
                        self.createDescriptifKo = true;
                    }

                    $timeout(function () {
                        $scope.$emit('closeLoadingToaster');
                    }, 1000);
                });
            };

            /**
             * Validation (vf, ppe, ppp)  
             **/
            function checkVfpp() {
                initKoVariables();
                if (self.vfSliderConfig) {
                    self.vfKo = isNaN(self.creationDescriptif.vf) || self.creationDescriptif.vf < self.vfSliderConfig.min || self.creationDescriptif.vf > self.vfSliderConfig.max;
                    self.pppKo = isNaN(self.creationDescriptif.ppp) || self.creationDescriptif.ppp < self.pppSliderConfig.min || self.creationDescriptif.ppp > self.pppSliderConfig.max;
                    self.ppeKo = isNaN(self.creationDescriptif.ppe) || self.creationDescriptif.ppe < self.ppeSliderConfig.min || self.creationDescriptif.ppe > self.ppeSliderConfig.max;
                }

                if (self.creationDescriptif.product && (self.creationDescriptif.product.value === TR_CODE || self.creationDescriptif.product.value === CTR_CODE)) {
                    self.ppeExo = self.creationDescriptif.ppe > (SEUIL_EXO / 100);
                } else {
                    self.ppeExo = false;
                }
            }

            /** 
            * Initialize variables 
            **/
            function initVariables() {
                self.creationProducts = DescriptifService.getProducts(self.contrats, self.descriptifs);
                facialValueSettings = DescriptifService.getFacialValueSettings(self.descriptifs, self.creationProducts);
                header = DescriptifService.getTableHeaders(self.descriptifs);
                nameOnPaperTypeList = DescriptifService.getNameOnPaperType();

                var body = [];
                var descriptifData = [];

                _.forEach(self.descriptifs, function (d) {
                    if (d.Etat) {
                        self.activer = "Désactiver";
                    }
                    else {
                        self.activer = "Activer";
                    }
                    _.forEach(header, function (h) {
                        descriptifData.push(h.getValue(d));
                    });
                    body.push(descriptifData);
                    descriptifData = [];
                });

                self.head = header;
                self.body = body;
            }

            /**
            * Initialize creation descriptif configuration 
            **/
            function initCreationDescriptif(productId, subProductId) {
                self.creationDescriptif = {
                    product: self.creationProducts.length === 1 ? self.creationProducts[0] : null,
                    subProduct: null,
                    identifiantDescriptif: null,
                    vf: null,
                    ppe: null,
                    ppp: null
                };

                if (productId) {
                    var product = _.find(self.creationProducts, function (p) {
                        return p.value === productId;
                    });

                    if (product) {
                        self.creationDescriptif.product = product;

                        if (subProductId && product.showSubProduct && product.creationSubProducts && product.creationSubProducts.length) {
                            var subProduct = _.find(product.creationSubProducts, function (sp) {
                                return sp.value === subProductId;
                            });

                            if (subProduct) {
                                self.creationDescriptif.subProduct = subProduct;
                            }
                        }
                    }
                }

                self.onEditFacialValue = false;
                self.onEditPatronalPercent = false;
                self.onEditPatronalAmount = false;

                initKoVariables();
            }

            /**
             * Initialize all KO variables
             **/
            function initKoVariables() {
                self.creerDroitKo = false;
                self.vfKo = false;
                self.pppKo = false;
                self.ppeKo = false;
                self.ppeExo = false;
                self.createDescriptifKo = false;
                self.duplicatedDescriptifKo = false;

                // Variable pour l'affichage du div si monoproduit.
                self.MonoProduct = self.creationProducts.length === 1 ? true : false;
            }

            /**
             * Assure value is between the range (min, max)
             * @param {type} value : value
             * @param {type} max : max
             * @param {type} min : min
             * @returns {type} 
             **/
            function transformValue(value, max, min, who) {
                if ((!value && value != 0) || (!max && max != 0) || (!min && min != 0)) {
                    return;
                }

                if (min > value) {
                    return (who !== 'vf') ? min : (SEUIL_EXO * 2 / 100);
                } else if (max < value) {
                    return (who !== 'vf') ? max : (SEUIL_EXO * 2 / 100);
                } else {
                    return value;
                }
            }

            self.eventHandlersVF = {
                change: function (values) {
                    onVfChange(values);
                }
            }

            self.eventHandlersPP = {
                change: function (values) {
                    onPppChange(values);
                }
            }

            /**
             * Initialize sliders
             */
            function initSliderSettings(vf, ppe, ppp) {

                if (self.creerDroitKo || !self.creationDescriptif.product) {
                    return;
                }

                var currentVfSetting = _.find(facialValueSettings, function (s) {
                    return s.productId === self.creationDescriptif.product.value;
                });

                if (!currentVfSetting) {
                    return;
                }

                if (self.creationDescriptif.product.value === TRBV_CODE && self.creationDescriptif.subProduct) {
                    self.vfSliderConfig = currentVfSetting.facialValues.length === 2 ? currentVfSetting.facialValues[self.creationDescriptif.subProduct.value === TRBV_BENEVOLE_SUBPRODUCT_CODE ? 0 : 1] : currentVfSetting.facialValues[0];
                } else {
                    self.vfSliderConfig = currentVfSetting.facialValues[0];
                }

                if (vf === null || typeof (vf) === 'undefined') {
                    vf = self.vfSliderConfig.default;
                }

                vf = transformValue(vf, self.vfSliderConfig.max, self.vfSliderConfig.min, 'vf');
                self.creationDescriptif.vf = vf;
                self.ppeSliderConfig.min = Math.round(self.creationDescriptif.vf * self.pppSliderConfig.min) / 100;
                self.ppeSliderConfig.max = Math.round(self.creationDescriptif.vf * self.pppSliderConfig.max) / 100;

                if (ppp) {
                    ppp = transformValue(ppp, self.pppSliderConfig.max, self.pppSliderConfig.min, 'ppp');
                    self.creationDescriptif.ppp = (ppp && self.pppSliderConfig.min <= ppp && self.pppSliderConfig.max >= ppp) ? ppp : self.pppSliderConfig.default;
                    self.creationDescriptif.ppe = Math.round(self.creationDescriptif.vf * self.creationDescriptif.ppp) / 100;
                } else if (ppe) {
                    ppe = transformValue(ppe, self.ppeSliderConfig.max, self.ppeSliderConfig.min, 'ppe');
                    self.creationDescriptif.ppe = (ppe && self.ppeSliderConfig.min <= ppe && self.ppeSliderConfig.max >= ppe) ? ppe : Math.round(self.creationDescriptif.vf * self.pppSliderConfig.default) / 100;
                    self.creationDescriptif.ppp = _.round((self.creationDescriptif.ppe / self.creationDescriptif.vf) * 10000, 1) / 100;
                } else {
                    self.creationDescriptif.ppe = Math.round(self.creationDescriptif.vf * self.pppSliderConfig.default) / 100;
                    self.creationDescriptif.ppp = self.pppSliderConfig.default;
                }

                self.vfSlider = {
                    options: {
                        animate: true,
                        start: self.creationDescriptif.vf,
                        range: {
                            'min': self.vfSliderConfig.min,
                            'max': self.vfSliderConfig.max
                        },
                        connect: 'lower',
                        step: 0.01,
                        behaviour: 'snap',
                        format: wNumb({
                            decimals: 2
                        })
                    }
                };


                self.pppSlider = {
                    options: {
                        animate: true,
                        start: self.creationDescriptif.ppp, // ou directement 50
                        range: {
                            'min': self.pppSliderConfig.min,
                            'max': self.pppSliderConfig.max
                        },
                        connect: 'lower',
                        step: 0.01,
                        behaviour: 'snap',
                        format: wNumb({
                            decimals: 2
                        })
                    }
                };
            }

            /**
             * When PPP slider is changed
             * @param {type} value - ppp value
             **/
            function onPppChange(value) {
                self.creationDescriptif.ppp = _.round(parseFloat(value[0]), 1);
                self.creationDescriptif.ppe = Math.round(self.creationDescriptif.vf * self.creationDescriptif.ppp) / 100;
                checkVfpp();
            }

            /**
             * When VF slider is changed
             * @param {type} value - vf value
             */
            function onVfChange(value) {
                self.creationDescriptif.vf = parseFloat(value[0]);
                self.ppeSliderConfig.min = Math.round(parseFloat((self.creationDescriptif.vf * self.pppSliderConfig.min / 100)) * 100) / 100;
                self.ppeSliderConfig.max = Math.round(parseFloat((self.creationDescriptif.vf * self.pppSliderConfig.max / 100)) * 100) / 100;
                self.creationDescriptif.ppe = Math.round(parseFloat((self.creationDescriptif.vf * self.creationDescriptif.ppp / 100)) * 100) / 100;
                checkVfpp();
            }
        }
    };

    module.exports = {
        name: 'descriptifsTitre',
        obj: descriptifsTitre
    };
})();