; (function () {
    "use strict";

    var detailRecapitulatif = {
        bindings: {
            valorisation: '<',
            types: '<',
            feedly: '@'
        },
        templateUrl: componentsPath + 'detail/detail.summary.html',
        controller: function ($scope, $rootScope, $state, FeedlyStateService) {
            var self = this;
            
            self.prices = [];

            self.openFeedly = function () {
                $rootScope.$broadcast('onOpenFeedly', self.feedly);
            };

            self.$onChanges = function () {
                initValoPrix();
            };

            self.$onInit = function () {
                self.prices = [];
                initValoPrix();
            };

            function initValoPrix() {
                if (self.prices.length === 0) {
                    for (var i = 0, length = self.types.length; i < length; i++) {
                        self.prices.push({
                            title: self.types[i].title,
                            price: self.valorisation["Montant" + self.types[i].type] || false
                        });
                    }
                } else {
                    for (var i = 0, length = self.types.length; i < length; i++) {
                        if (self.valorisation["Montant" +self.types[i].type] !== self.prices[i].price) {
                            self.prices[i].price = self.valorisation["Montant" + self.types[i].type] || false;
                        }
                    }
                }

            }
        }
    };

    module.exports = {
        name: 'detailRecapitulatif',
        obj: detailRecapitulatif
    };

})();
