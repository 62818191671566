; (function () {
    "use strict";

    var detailRecapitulatifFeedly = {
        bindings: {
            valorisation: '<',
            feedlyOpen: '<',
            valoTypes: '<'
        },
        templateUrl: 'Content/views/feedly/detail-recap-feedly.html',
        controller: function ($scope, StateService) {
            var self = this;

            self.panier = StateService.getPanier();

            self.$onChanges = function (changes) {
                initValoLines();
            }

            self.$onInit = function () {
                initValoLines();
            };

            self.closeMenu = function () {
                $scope.$emit('onCloseFeedly');
            };

            function initValoLines() {
                self.valoLines = [];

                /* TRAITER LE DÉTAIL DE LA VALORISATION */
                for (var i = 0, length = self.valoTypes.length; i < length; i++) {
                    var valorisationUnits = [],
                        valorisationHtTotal = 0,
                        valorisationTvaTotal = 0;

                    _.forEach(self.valoTypes[i].valo, function (v) {
                        var service = _.find(self.valorisation.DetailValorisation[0].ValorisationServices, function (s) {
                            return s.IdentifiantService === v.type;
                        });
                        if (service) {
                            valorisationHtTotal += service.MontantHT;
                            valorisationTvaTotal += service.MontantTVA;
                            valorisationUnits.push({
                                priceUnit: service.PrixUnitaire,
                                quantity: service.Quantite,
                                unitPriceText: v.unitPriceText,
                                quantityText: v.quantityText
                            });
                        }
                    });

                    valorisationHtTotal = valorisationHtTotal || false;
                    valorisationTvaTotal = valorisationTvaTotal || false;

                    self.valoLines.push(
                        {
                            title: self.valoTypes[i].title,
                            description: self.valoTypes[i].description,
                            units: valorisationUnits,
                            priceHtTotal: valorisationHtTotal,
                            priceTvaTotal: valorisationTvaTotal
                        }
                    );
                }

                self.titles = _.sumBy(self.panier.Beneficiaires, function (b) {
                    return parseInt(b.NombreTitres || 0, 10);
                });

                self.valeurFaciale = self.panier.Precommandes[0].ValeurFacialeParDefaut / 100;

                self.coutReel = (self.valorisation.MontantTotalTTC - self.valorisation.MontantVE * (1 - self.panier.Precommandes[0].PartPatronaleParDefaut / 100)).toFixed(2);
            }
        }
    };

    module.exports = {
        name: 'detailRecapitulatifFeedly',
        obj: detailRecapitulatifFeedly
    };
})();
