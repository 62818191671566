; (function () {
    "use strict";

    function devisService(Restangular) {

        var baseDevis = Restangular.all('devis');
        var basePanier = Restangular.all('paniers');

        var envoyerDevis = function (panierId, precommandeId, interlocuteur, urlAction) {
            var baseUrl = basePanier.one(panierId).one('precommandes', precommandeId);
            return urlAction ? baseUrl.post(urlAction, interlocuteur) : baseUrl.post('envoyerdevis', interlocuteur);
        };

        var prepareDevis = function (panierId, precommandeId, interlocuteur, idFederation) {
            var baseUrl = basePanier.one(panierId).one('precommandes', precommandeId);
            return baseUrl.one('interlocuteurs').one('devis').one('preparer', idFederation).post('', interlocuteur);
        };

        var creerDevis = function (devis) {
            return baseDevis.post(devis);
        };

        return {
            envoyerDevis: envoyerDevis,
            creerDevis: creerDevis,
            prepareDevis: prepareDevis
        };
    };


    module.exports = {
        name: "DevisService",
        fn: devisService
    }

})();
