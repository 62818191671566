//<sidebar-connected class="exc-sidebar" expanded="tunnelConnected.sidebarExpanded" infos-client="tunnelConnected.infosClient" on-update="tunnelConnected.sidebarToggle(expanded)" ng-class="{ 'expanded' : tunnelConnected.sidebarExpanded }"></sidebar-connected>

; (function () {
    "use strict";

    var sidebarConnected = {

        bindings: {
            token: '<',
            onUpdate: '&'
        },

        templateUrl: componentsPath + 'sidebar/sidebar.connected.html',
        controller: function ($scope, $window) {
            var self = this;
            self.expanded = $window.innerWidth < 1280 ? false : true;
            self.infosClient = self.token;
            self.raisonSociale = self.infosClient.RaisonSociale;
            self.numeroDivision = self.infosClient.Domaine.split(';')[0];
            self.nomClient = self.infosClient.Prenom + ' ' + self.infosClient.Nom;

            self.$onInit = function () {
                self.onUpdate({
                    expanded: self.expanded
                });
            }

            self.toggle = function () {
                self.expanded = !self.expanded;
                self.onUpdate({
                    expanded: self.expanded
                });
            };
        }
    };

    module.exports = {
        name: 'sidebarConnected',
        obj: sidebarConnected
    };

})();
