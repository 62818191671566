;(function() {
    "use strict";

    function PointLivraison () {
        return function () {
            this.Id = null;
        };
    };

    module.exports = {
        name: "PointLivraison",
        fn: PointLivraison
    }

})();
