; (function () {
    "use strict";

    function contractConfigController($scope, $rootScope, $filter, localStorageService, ContratsService, ContractConfig, Ressources) {
        var self = this;

        ContratsService.getContractRanges().then(function (result) {
            self.contracts = result.plain();
        });

        self.addNewContractType = function (index) {
            self.contracts.splice(index + 1, 0, new ContractConfig());
        };

        self.removeContractType = function (index) {
            self.contracts.splice(index, 1);
        };

        self.submitContracts = function (form) {
            if (form.$invalid) return false;

            var orderedContracts = $filter('orderBy')(self.contracts, ['IdPartenaire', 'TrancheEffectifMin']);

            ContratsService.updateContractRanges(orderedContracts).then(function () {
                alert("Succes");

                return true;
            });

            return false;
        };
    };

    module.exports = {
        name: "ContractConfigController",
        fn: contractConfigController
    };

})();
