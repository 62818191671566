;(function() {
    "use strict";

    function feedlyEtapeService(FeedlyEtapeConfiguration) {
        var etapeActuelle = 0;

        var resetEtape = function() {
            etapeActuelle = 0;
        };

        var getCurrentEtape = function (pageState) {
            if (FeedlyEtapeConfiguration.hasOwnProperty(pageState)) {
                var feedlyEtapeList = FeedlyEtapeConfiguration[pageState];
                var feedlyEtapeToLoad = feedlyEtapeList[etapeActuelle];
                return feedlyEtapeToLoad;
            }
            return null;
        };

        //var getEtape = function (pageState) {
        //    return FeedlyEtapeConfiguration[pageState];
        //};

        var getNextEtape = function (pageState, numEtape) {
            if (FeedlyEtapeConfiguration.hasOwnProperty(pageState)) {
                var feedlyEtapeList = FeedlyEtapeConfiguration[pageState];
                numEtape = numEtape || (etapeActuelle + 1);
                if (feedlyEtapeList.length >= numEtape) {
                    etapeActuelle = numEtape;
                    var feedlyEtapeToLoad = feedlyEtapeList[numEtape];
                    return feedlyEtapeToLoad;
                }
            }
            return null;
        };

        var getPreviewEtape = function (pageState, numEtape) {
            if (FeedlyEtapeConfiguration.hasOwnProperty(pageState)) {
                var feedlyEtapeList = FeedlyEtapeConfiguration[pageState];
                numEtape = numEtape || (etapeActuelle - 1);
                if (feedlyEtapeList.length >= numEtape && numEtape >= 0) {
                    etapeActuelle = numEtape;
                    var feedlyEtapeToLoad = feedlyEtapeList[numEtape];
                    return feedlyEtapeToLoad;
                }
            }
            return null;
        };

        return {
            getCurrentEtape: getCurrentEtape,
            getNextEtape: getNextEtape,
            //getEtape: getEtape,
            getPreviewEtape: getPreviewEtape,
            resetEtape: resetEtape
    };
    };

    module.exports = {
        name: "FeedlyEtapeService",
        fn: feedlyEtapeService
    }
})();
