; (function () {
    "use strict";

    var headerTunnel = {
        bindings: {
            feedlyOpen: '<',
            stepperDisponible: '<',
            etapeSuivanteDisponible: '<',
            isIntroPage: '<',
            etapePrecedente: '&',
            etapeSuivante: '&'
        },
        templateUrl: componentsPath + 'header/header.tunnel.html',
        controllerAs: 'header'
    };

    module.exports = {
        name: 'headerTunnel',
        obj: headerTunnel
    };

})();
