;(function() {
    "use strict";

    function Wording () {
        return function () {
            this.Id = null;
            this.Code = null;
            this.Libelle = null;
            this.CodeClient = null;
        };
    };

    module.exports = {
        name: "Wording",
        fn: Wording
    }

})();
