;(function() {
    "use strict";

    function submissionInProgress() {
        return {
            restrict: 'C',
            scope: {},
            link: function ($scope, $el, $attrs) {

                var initPro = $attrs.readOnly === undefined ? false : true;

                $scope.$on('cfpLoadingBar:loading', function () {
                    $el.find('input').addClass('disabled').attr('disabled', true);
                });

                $scope.$on('cfpLoadingBar:loaded', function(){
                    $el.find('input').removeClass('disabled').attr('disabled', initPro);
                });

            }
        };
    };

    module.exports = {
        name: 'inputWrapper',
        fn: submissionInProgress
    };

})();
