; (function () {
    "use strict";

    function paiementController($scope, $injector, $timeout, $q, $stateParams, localStorageService, PanierService, StateService, ClientService, Ressources, ClickService) {
        var self = this,
            $validationProvider = $injector.get('$validation'),
            panier = StateService.getPanier();

        self.cbReturn = $stateParams.cbReturn;
        self.cbTabActive = true;
        self.hideCbErrors = false;

        if (self.cbReturn == Ressources.TYPES_TRANSACTION.CancelCB) {
            localStorage.setItem('cancelCB', true);
            self.cbTabActive = true;

            panier.Precommandes[0].IdModePaiement = null;
            $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
        }

        var montantsValo = localStorageService.get(Ressources.MONTANTS_KEY);

        if (montantsValo) {
            self.totalTTC = montantsValo.totalTTC;
            self.totalHT = montantsValo.totalHT;
            self.montantTVA = montantsValo.montantTVA;
        }

        self.goToVirementTab = function () {
            self.cbTabActive = false;
        };

        self.goToCBTab = function () {
            self.hideCbErrors = true;
            self.cbTabActive = true;
        };

        self.millesime = null;
        self.millesimeCb = null;
        self.millesimeVirement = null;
        
        ClickService.clickMillesimeInfo()
            .then(function (resp) {
                self.millesime = JSON.parse(resp.target.response);
                self.millesimeCb = self.millesime['vel-ctr-cb-text-millesime'] || null;
                self.millesimeVirement = self.millesime['vel-ctr-virement-text-millesime'] || null;
            })
            .catch(function (e) {
                self.millesime = '';
                console.log('error in loading millesime ', e.statusText);
            });

        self.paiementCB = function () {
            $scope.$emit('toggleLoadingToaster', {
                header: "Redirection vers le paiement par CB",
                caption: "Veuillez patienter s'il vous plaît...",
            });
            var timeout = $timeout(_.noop, 1000);
            var isPromo = (window.location.host.indexOf('promo-ctr') !== -1) ? true : false;
            var promise = PanierService.enregistrerPrecommande(panier.Id, panier.Precommandes[0].Id, { "IdModePaiement": Ressources.MODE_PAIEMENT.CB })
                .then(function() {
                    // var idPartenaire = localStorageService.get(Ressources.FEDERATION_KEY);
                    var idPartenaire = localStorageService.get(Ressources.SELECTED_CONTRACT_KEY)[1];
                    panier.Precommandes[0].IdModePaiement = Ressources.MODE_PAIEMENT.CB;
                    var partnerRegistered = localStorage.getItem('partnerRegistered');
                    var isCancelCb = localStorage.getItem('cancelCB');
                    if ((partnerRegistered && partnerRegistered !== 'null') || (isCancelCb && isCancelCb === 'true')) return
                    return ClientService.creerConfigurationOperationnelle(panier.Client.Id, {
                        IdClientProspect: panier.Client.Id,
                        BIC: null,
                        IBAN: null,
                        IdProduit: 240,
                        EquipePartenaire: "",
                        IdPartenaire: idPartenaire
                    });
                })
                .then(function() {
                    return PanierService.creerPaiementWithEmail(panier.Id, panier.Precommandes[0].Id, panier.Client.Value.Interlocuteur[0].Email, { "ModePaiement": Ressources.MODE_PAIEMENT.CB , "IsPromo": isPromo });
                })
                .then(function (result) {
                    panier.Precommandes[0].IdModePaiement = Ressources.MODE_PAIEMENT.CB;
                    $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                    return $q.resolve(result);
                });

            return $q.all([promise, timeout])
                .then(function (result) {                   
                    var redirectionResult = result[0];
                    var formElement = document.createElement('form');
                    formElement.setAttribute('method', 'POST');
                    formElement.setAttribute('action', redirectionResult.RedirectionUrl);
                    
                    var inputRedirectionDataElement = document.createElement('input');
                    inputRedirectionDataElement.setAttribute('type', 'hidden');
                    inputRedirectionDataElement.setAttribute('name', 'redirectionData');
                    inputRedirectionDataElement.value = redirectionResult.RedirectionData;

                    var inputRedirectionVersionElement = document.createElement('input');
                    inputRedirectionVersionElement.setAttribute('type', 'hidden');
                    inputRedirectionVersionElement.setAttribute('name', 'redirectionVersion');
                    inputRedirectionVersionElement.value = redirectionResult.RedirectionVersion;

                    var submitButtonElement = document.createElement('input')
                    submitButtonElement.setAttribute('type', 'submit');
                    submitButtonElement.setAttribute('name', 'CB');

                    formElement.appendChild(inputRedirectionDataElement);
                    formElement.appendChild(inputRedirectionVersionElement);
                    formElement.appendChild(submitButtonElement);

                    $(formElement).hide().appendTo(document.body).find('[name=CB]').click();
                })
                .catch(function() {
                    $scope.$emit('closeLoadingToaster');
                });
        }

        self.envoiCommandeValidationVirement = function (form) {
            return $validationProvider.validate(form)
                .then(function() {
                    $scope.$emit('toggleLoadingToaster', {
                        header: "Validation de votre commande",
                        caption: "Veuillez patienter s'il vous plaît...",
                    });
                })
                .then(function() {
                    return PanierService.enregistrerPrecommande(panier.Id, panier.Precommandes[0].Id, { "IdModePaiement": Ressources.MODE_PAIEMENT.VIREMENT });
                })
                .then(function() {
                    //var idPartenaire = localStorageService.get(Ressources.FEDERATION_KEY);
                    var idPartenaire = localStorageService.get(Ressources.SELECTED_CONTRACT_KEY)[1];
                    var partnerRegistered = localStorage.getItem('partnerRegistered');
                    var isCancelCb = localStorage.getItem('cancelCB');
                    panier.Precommandes[0].IdModePaiement = Ressources.MODE_PAIEMENT.VIREMENT;
                    if ((partnerRegistered && partnerRegistered !== 'null') || (isCancelCb && isCancelCb === 'true')) return
                    return ClientService.creerConfigurationOperationnelle(panier.Client.Id, {
                        IdClientProspect: panier.Client.Id,
                        BIC: self.BIC,
                        IBAN: self.IBAN,
                        IdProduit: 240,
                        EquipePartenaire: "",
                        IdPartenaire: idPartenaire
                    });
                })
                .then(function() {
                    return PanierService.validerPrecommande(panier.Id, panier.Precommandes[0].Id, panier.Client.Value.Interlocuteur[0].Id);
                })
                .then(function() {
                    $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                    return StateService.next();
                })
                .then(function() {
                    $scope.$emit('closeLoadingToaster');
                })
                .catch(function(e) {
                    $scope.$emit('closeLoadingToaster');
                    $q.reject(e);
                });
        };
    }

    module.exports = {
        name: "PaiementController",
        fn: paiementController
    };
})();
