module.exports=﻿{
	"viewsPath" : "Content/views/",
	
	"APPLICATION_PREFIX" : "excv2",
	"PANIER_KEY" : "panier",
	"BENEFICIARIES_KEY" : "beneficiaires",
	"PAGE_CONFIRMATION_KEY" : "timeline",
	"REFERENCE_ETAPE_TUNNEL_KEY" : "ReferenceEtapeTunnel",
	"WORDING_KEY" : "wordings",
	"CONTRACTS_KEY" : "contracts",
	"TARIF_KEY" : "tarif",
	"SELECTED_CONTRACT_KEY" : "selectedContract",
	"CODE_PRODUIT_KEY" : "VEL",
	"CODE_PRODUIT_KEY_CMA": "CMA",
	"CODE_PRODUIT_KEY_PROMO": "VEL PROMO",
    "CODE_PRODUIT_KEY_DOMTOM": "DOMTOM",
	"CONTRATSRANGE" : "CONTRATSRANGE",
	"CONTRATSTARIF" : "CONTRATSTARIF",
	"INTERLOCUTEUR_DIVISIONS" : "interlocuteurDivisions",
	"MONTANTS_KEY" : "montants",
    "TOKEN_KEY":  "accessToken",
    "FEDERATION_KEY":  "idFederation",
	
	"TUNNEL_CTR_INTRO" : "tunnel-ctr-intro",
	"TUNNEL_CTR_PERSONA" : "tunnel-ctr.persona",
	"TUNNEL_CTR_PERSONA_KO" : "tunnel-ctr.persona-ko",
	"TUNNEL_CTR_VALEURFACIALE" : "tunnel-ctr.valeurfaciale",
	"TUNNEL_CTR_SIMULATION" : "tunnel-ctr.simulation",
	"TUNNEL_CTR_SIMULATION_MODIFICATION" : "tunnel-ctr.simulation-modification",
	"TUNNEL_CTR_BENEFICIAIRE" : "tunnel-ctr.beneficiaire",
	"TUNNEL_CTR_SIRET" : "tunnel-ctr.siret",
	"TUNNEL_CTR_ADRESSE_SAISIE" : "tunnel-ctr.adresse-saisie",
	"TUNNEL_CTR_COORDONNEES" : "tunnel-ctr.coordonnees",
	"TUNNEL_CTR_PRESTATIONS" : "tunnel-ctr.prestations",
	"TUNNEL_CTR_RECAPITULATIF" : "tunnel-ctr.recapitulatif",
	"TUNNEL_CTR_DEVIS" : "tunnel-ctr.devis",
	"TUNNEL_CTR_PAIEMENT" : "tunnel-ctr.paiement",
	"TUNNEL_CTR_CONFIRMATION" : "tunnel-ctr.confirmation",
	"TUNNEL_CTR_SIRET_INTERLOCUTEUR_KO" : "tunnel-ctr.siretInterlocuteurKo",
	"TUNNEL_CTR_SIRET_CHOIX" : "tunnel-ctr.siretChoix",
	"TUNNEL_CTR_SIRET_DEVIS" : "tunnel-ctr.siret-devis",
	"TUNNEL_CTR_CONNECTED_RECAPITULATIF" : "tunnel.connected.recapitulatif",
	"TUNNEL_CTR_CONNECTED_BENEFICIAIRE" : "tunnel.connected.beneficiaire",
    "TUNNEL_CTR_CONNECTED_DESCRIPTIFS_TITRE": "tunnel.connected.mesdescriptifstitre",
	
	"TUNNEL_CMA_INTRO" : "tunnel-ctr-intro",
    "TUNNEL_CMA_SSO": "tunnel-cma.startCma",
	"TUNNEL_CMA_SIRET" : "tunnel-cma.siret",
	"TUNNEL_CMA_ADRESSE" : "tunnel-cma.adresse",
	"TUNNEL_CMA_COORDONNEES" : "tunnel-cma.coordonnees",
	"TUNNEL_CMA_NB_BENEFICIAIRE" : "tunnel-cma.nombre-titres-salaries",
	"TUNNEL_CMA_VALEUR" : "tunnel-cma.valeur",
	"TUNNEL_CMA_PRESTATIONS" : "tunnel-cma.prestations",
	"TUNNEL_CMA_RECAPITULATIF" : "tunnel-cma.recapitulatif",
	"TUNNEL_CMA_CONFIRMATION" : "tunnel-cma.confirmation",
	
	"VALEUR_FACIAL_PAR_DEFAUT" : 900,
	"PART_PATRONALE_PAR_DEFAUT" : 50,
	"LIMITE_EXONERATION" : 650,
	"PPP_MAX_PERCENTAGE" : 60,
	"ID_ARTICLE_CARTE_TICKET_RESTAURANT" : 204,
	"QUOTITE_PAR_DEFAUT" : 20,
	"NB_BENEFS_PAR_DEFAUT" : 10,
	"NB_BENEFS_MAX_CMD" : 50,
	"NB_TITRES_PAR_DEFAUT" : 20,
	"VF_MIN" : 400,
	"VF_MAX" : 1300,
	"VPP_MIN" : 50,
	"VPP_MAX" : 60,

    "CODE_PRODUIT": {
        "CARTE_TICKET_RESTAURANT": 240,
        "TICKET_RESTAURANT": 1,
        "TICKET_SERVICE": 2,
        "TICKET_CAP": 9,
        "TICKET_TRBV": 24
    },

    "PRODUITS": [
        {
            "id": 240,
            "label": "Carte Ticket Restaurant®"
        },
        {
            "id": 1,
            "label": "Ticket Restaurant®"
        },
        {
            "id": 2,
            "label": "Ticket Service®"
        },
        {
            "id": 9,
            "label": "Ticket Service CAP®"
        },
        {
            "id": 24,
            "label": "Ticket Restaurant® bénévoles et volontaires"
        },
        {
            "id": 80,
            "label": "Kadéos®"
        }
    ],

	"VALORISATION_CONST" : {
		"ABONNEMENT" : "130",
		"EMISSIONCARTE" : "70",
		"REMISSIONCARTE" : "71",
		"RENOUVELLEMENTCARTE" : "72",
		"LIVRAISON" : "22",
        "FRAISGESTION": "81"
	},
	
	"EMAIL_SIMULATION" : "simulation@edenred.net",
	
	"PERSONAS": {
        "ASSISTANT": 6,
        "COMPTABLE": 9,
        "DIRECTEUR": 4,
        "GERANT": 18,
        "SECRETAIRE": 7,
        "AUTRE": 2,
        "properties": {
            "6": { "Id": 6, "Libelle": "Assistant" },
            "9": { "Id": 9, "Libelle": "Comptable" },
            "4": { "Id": 4, "Libelle": "Directeur" },
            "18": { "Id": 18, "Libelle": "Gérant" },
            "7": { "Id": 7, "Libelle": "Secrétaire" },
            "2": { "Id": 2, "Libelle": "Autre" }
        }
    },
	
	"EVENT_UPDATE_PANIER" : "updatePanier",
	"EVENT_UPDATE_BENEFICIAIRES" : "updateBeneficiaires",
	"EVENT_CHANGE_TYPE_TUNNEL" : "$changeTypeTunnel",
	
	"MODE_PAIEMENT" : {
		"NONE" : 0,
		"VIREMENT" : 1,
        "PRELEVEMENT": 4,
		"CB" : 7
	},
	
	"TYPE_CHAMPS" : {
		"STRING" : 1,
		"ENTIER" : 2,
		"DATETIME" : 3
	},
	
	"TYPES_TUNNEL" : {
		"Contact" : "Contact",
		"Simulation" : "Simulation",
		"Commande" : "Commande",
		"Devis" : "Devis",
		"CMA" : "CMA"
	},	
	"TYPES_TRANSACTION" : {
		"CancelCB" : "cancelCb"
	},
    "STATUS_PRECOMMANDES": { 
        "Simple_Devis":  256
    },	
	"ESPACE_CLIENT_URL" : "@@EspaceClientUrl@@",
	"FEEDLY_URL" : {
		"recontactezMoi" : "Content/views/feedly/recontacterMoi.html"
	},
	"STEPPER_TEMPLATE" : {
		"Default" : "Content/views/tunnel/stepper.html",
		"Cma" : "Content/views/tunnel/stepper.cma.html"
	},
	"LOGIN_URL_V1" : "@@LoginUrlV1@@",
    "RECAPTCHA_SITE_KEY": "@@RecaptchaSiteKey@@",

    "V3_LOGIN": "@@V3Login@@",
    "VELCTR_HOST": "@@VELCTRHost@@",
	"CLICK_URL": "@@ClickUrl@@"
}