; (function () {
    "use strict";

    function panierService(Restangular, RestangularSansIntercepteur, RestangularHeaders, Panier, Beneficiaire, Precommande, localStorageService, Ressources) {
        var basePaniers = Restangular.all('paniers');
        var basePaniersSI = RestangularSansIntercepteur.all('paniers');
        var basePaniersHead = RestangularHeaders.all('paniers');
        var _panier = null;
        var _isRedirected = false;

        var isRedirectedPrecommand = function () {
            return _isRedirected;
        }

        var setIsRedirectedPrecommand = function (isRedirected) {
            _isRedirected = isRedirected;
        }

        var annulerPanier = function (panierId) {
            return basePaniers.one(panierId).post('annuler');
        };

        var chargerPanier = function (panierId) {
            return basePaniers.one(panierId).get();
        };

        var chargerPanierComplet = function (panierId) {
            return basePaniers.one(panierId).customGET("GetPanierCompletById");
        };

        var chargerPanierCompletWithHeaders = function (panierId) {
            return basePaniersHead.one(panierId).customGET("GetPanierCompletById");
        };

        var creerPanier = function () {
            var newPanier = new Panier();
            return basePaniers.post(newPanier);
        };

        var creerPanierDevis = function () {
            return basePaniers.one("initDevis").post();
        };

        var creerPanierCMA = function () {
            return basePaniers.one("initCma").post();
        };

        var modifierPanierDevis = function (idPrecommande, idLigneCommande, nbBenefs, nbTitres) {
            return basePaniers.one("patchCma").one("precommandes", idPrecommande).one("beneficiaire", idLigneCommande).one("NbBeneficiaireDevis", nbBenefs).one("Quantite", nbTitres).patch();
        };

        var enregistrerPanier = function (panierId, panier) {
            return basePaniers.one(panierId).patch(panier);
        };

        var enregistrerBeneficiaire = function (panierId, precommandeId, beneficiaireId, beneficiaire) {
            return basePaniersSI.one(panierId).one("precommandes", precommandeId).one("beneficiaires", beneficiaireId).patch(beneficiaire);
        };

        var creerBeneficiaire = function (panierId, precommandeId, beneficiaire) {
            if (!(beneficiaire instanceof Beneficiaire))
                return "erreur";
            return basePaniersSI.one(panierId).one("precommandes", precommandeId).post("beneficiaires", beneficiaire);
        };

        var supprimerBeneficiaire = function (panierId, precommandeId, beneficiaire) {
            return basePaniersSI.one(panierId).one("precommandes", precommandeId).one("beneficiaires", beneficiaire.Id).remove();
        };

        var supprimerSalarie = function (panierId, precommandeId, beneficiaire) {
            return basePaniersSI.one(panierId).one("precommandes", precommandeId).one("salaries", beneficiaire.IdSalarie).remove();
        };

        var enregistrerBeneficiaireConnected = function (panierId, precommandeId, beneficiaireId, beneficiaire) {
            return basePaniers.one(panierId).one("precommandes", precommandeId).one("beneficiaires", beneficiaireId).patch(beneficiaire);
        };

        var creerBeneficiaireConnected = function (panierId, precommandeId, beneficiaire) {
            if (!(beneficiaire instanceof Beneficiaire))
                return "erreur";
            return basePaniers.one(panierId).one("precommandes", precommandeId).post("beneficiaires", beneficiaire);
        };

        var supprimerBeneficiaireConnected = function (panierId, precommandeId, beneficiaire) {
            return basePaniers.one(panierId).one("precommandes", precommandeId).one("beneficiaires", beneficiaire.Id).remove();
        };

        var supprimerSalarieConnected = function (panierId, precommandeId, beneficiaire) {
            return basePaniers.one(panierId).one("precommandes", precommandeId).one("salaries", beneficiaire.IdSalarie).remove();
        };

        var chargerPrecommande = function (panierId, precommandeId) {
            return basePaniers.one(panierId).one("precommandes", precommandeId).get();
        };

        var enregistrerPrecommande = function (panierId, precommandeId, precommande) {
            return basePaniers.one(panierId).one("precommandes", precommandeId).patch(precommande);
        };

        var creerPrecommande = function (panierId, precommande) {
            return basePaniers.one(panierId).post("precommandes", precommande);
        };

        var valoriserPrecommande = function (panierId, precommandeId) {
            return basePaniers.one(panierId).one("precommandes", precommandeId).one("valorisation").get();
        };

        var creerPaiement = function (panierId, precommandeId, paiement) {
            return basePaniers.one(panierId).one("precommandes", precommandeId).post("paiement", paiement);
        }

        var creerPaiementWithEmail = function (panierId, precommandeId, email, paiement) {
            return basePaniers.one(panierId).one("precommandes", precommandeId).one("email",email).post("paiement", paiement);
        }

        var validerPrecommande = function (panierId, precommandeId, idInterlocuteur) {
            return basePaniers.one(panierId).one("precommandes", precommandeId).one("validation", idInterlocuteur).put();
        };

        var contractualisationPrecommande = function (panierId, precommandeId, idInterlocuteur) {
            return basePaniers.one(panierId).one("precommandes", precommandeId).one("contractualisation", idInterlocuteur).post();
        };

        var initialiserPrecommande = function (idClient, idInterlocuteur, numeroDivision, quotite, quantiteConditionnement, referencePL, estLivraisonDomicile, interlocuteur) {
            return basePaniers.one(idClient).one('init').post(idInterlocuteur, {
                IdProduit: Ressources.CODE_PRODUIT.CARTE_TICKET_RESTAURANT,
                NumeroDivision: numeroDivision,
                QuotiteParDefaut: quotite,
                QuantiteConditionnement: quantiteConditionnement,
                ReferencePL: referencePL,
                EstLivraisonDomicile: estLivraisonDomicile,
                Interlocuteur: interlocuteur
            });
        };

        var valoriserPrecommandeParNumeroDivision = function (idPanier, idPrecommande, numeroDivision) {
            return basePaniers.one(idPanier).one("precommandes", idPrecommande).one("valorisationdirecte").get({ numeroDivision: numeroDivision });
        };

        var convertirPanierPourCommande = function(panier)
        {
            supprimerBeneficiaire(panier.Id, panier.Precommandes[0].Id, panier.Beneficiaires[0]);
            localStorage[Ressources.APPLICATION_PREFIX +Ressources.PANIER_KEY] = panier.Id;
            enregistrerPrecommande(panier.Id, panier.Precommandes[0].Id, { Statut: 0 });
        }

        return {
            chargerPanier: chargerPanier,
            chargerPanierComplet: chargerPanierComplet,
            chargerPanierCompletWithHeaders: chargerPanierCompletWithHeaders,
            creerPanier: creerPanier,
            creerPanierDevis: creerPanierDevis,
            enregistrerPanier: enregistrerPanier,
            enregistrerBeneficiaire: enregistrerBeneficiaire,
            creerBeneficiaire: creerBeneficiaire,
            supprimerBeneficiaire: supprimerBeneficiaire,
            supprimerSalarie: supprimerSalarie,
            enregistrerBeneficiaireConnected: enregistrerBeneficiaireConnected,
            creerBeneficiaireConnected: creerBeneficiaireConnected,
            supprimerBeneficiaireConnected: supprimerBeneficiaireConnected,
            supprimerSalarieConnected: supprimerSalarieConnected,
            chargerPrecommande: chargerPrecommande,
            enregistrerPrecommande: enregistrerPrecommande,
            creerPrecommande: creerPrecommande,
            creerPaiement: creerPaiement,
            creerPaiementWithEmail: creerPaiementWithEmail,
            valoriserPrecommande: valoriserPrecommande,
            valoriserPrecommandeParNumeroDivision: valoriserPrecommandeParNumeroDivision,
            validerPrecommande: validerPrecommande,
            contractualisationPrecommande: contractualisationPrecommande,
            initialiserPrecommande: initialiserPrecommande,
            convertirPanierPourCommande: convertirPanierPourCommande,
            annulerPanier: annulerPanier,
            modifierPanierDevis: modifierPanierDevis,
            creerPanierCMA: creerPanierCMA,
            setIsRedirectedPrecommand: setIsRedirectedPrecommand,
            isRedirectedPrecommand: isRedirectedPrecommand
        };
    };

    module.exports = {
        name: "PanierService",
        fn: panierService
    }

})();
