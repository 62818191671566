; (function () {
    "use strict";

    function focus($timeout) {
        var _link = function (scope, el, attr, ngModel) {
            scope.$watch('trigger', function (value) {
                if (value === true) {
                    $timeout(function () {
                        el[0].focus();
                        scope.trigger = false;
                    }, 100);
                }
            });
        };

        return {
            restrict: 'A',
            link: _link,
            scope: {
                trigger: '<'
            },
            require: 'ngModel'
        };
    };

    module.exports = {
        name: 'focus',
        fn: focus
    };

})();
