;(function() {
  "use strict";

    function etreRecontacterController ($scope, $injector, $filter, WordingService, localStorageService, Ressources) {
        var self = this;

        //self.envoiFormulaireRecontacte = function(form) {
        //    console.log("Envoi du formulaire!");
        //};
    };

    module.exports = {
        name: "EtreRecontacterController",
        fn: etreRecontacterController
    };

})();
