; (function () {
    "use strict";

    function wordingController($scope, $rootScope, $injector, $filter, WordingService, localStorageService, Ressources, wording) {
        var self = this;

        self.wordings = wording;

        localStorageService.set(Ressources.WORDING_KEY, wording);

        self.getWordings = function () {
            if (self.wordings === null) {
                WordingService.allWording().then(function (result) {
                    self.wordings = result;
                });
            }
            return self.wordings;
        };

        self.saveWording = function (wording) {

            WordingService.updateWording(wording).then(function (result) {
                //Afficher un toastr
                var filtrer = $filter('codeToWording')(wording.Label);

                // TODO : FIXME => Manque la remise en place des parametres pour le preview !!!
                var paramsWordingToReplace = $('#previewWording' + wording.Id).attr('params-wording').replace(/'/g, '"');
                paramsWordingToReplace = angular.fromJson(paramsWordingToReplace);

                angular.forEach(paramsWordingToReplace, function (value, key) {
                    var re = new RegExp("\\{" + (key) + "\\}", "i");
                    filtrer = filtrer.replace(re, value);
                });

                // ToDo :: Pas très très bien de modifier le Dom dans le controleur, à factoriser.
                $('#previewWording' + wording.Id).find('span').html(filtrer);
            });
        };

        self.cancelWording = function (wording) {
            wording.Label = wording.CacheLabelValue;
        };

        self.swapEditor = function (wording) {
            wording.CacheLabelValue = wording.Label;
        }

        self.refresh = function () {
            WordingService.refreshWording().then(function () {
                //Afficher un toastr
            });
        }

        //self.refreshAfterSave = function () {
        //    return WordingService.refreshWording();
        //}

        //self.search = function (item) {
        //    if (!self.filterSearch || (item.Code.toLowerCase().indexOf(self.filterSearch) != -1) || (item.Label.toLowerCase().indexOf(self.filterSearch.toLowerCase()) != -1)) {
        //        return true;
        //    }
        //    return false;
        //};

        // Actions automatiques
        if (self.wordings === null) {
            self.getWordings();
        }
    };

    module.exports = {
        name: "WordingController",
        fn: wordingController
    };

})();
