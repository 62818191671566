// Lazy loading of Google Map API ondemand

;(function() {
    "use strict";

    function loadGoogleMapAPI($window, $q) {
        
        var deferred = $q.defer();

        // Load Google map API script
        function loadScript() {  
            // Use global document since Angular's $document is weak
            var script = document.createElement('script');
            script.src = '//maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyAK9CEcSUwyX-9KK7SibOhLkuSkscmUW_k';
            document.body.appendChild(script);
        }

        // Script loaded callback, send resolve
        $window.initMap = function () {
            deferred.resolve();
        }

        loadScript();

        return deferred.promise;

    };

    module.exports = {
        name: "LoadGoogleMapAPI",
        fn: loadGoogleMapAPI
    }
})();
