; (function () {
    "use strict";

    function nombreBenefsTitres($scope, $injector, $timeout, localStorageService, PanierService, ClientService, StateService, DevisService, Precommande, Beneficiaire, Ressources) {

        var self = this,
            $filter = $injector.get('$filter'),
            panier = StateService.getPanier();

        var $validationProvider = $injector.get('$validation');
        self.feedlyUrlRecontacte = "Content/views/feedly/cmaHelp.html";

        var storedPrecommande = panier.Precommandes[0],
            oldPrecommand = angular.copy(panier.Precommandes[0]);

        self.precommande = storedPrecommande;

        self.isValidate = panier.Precommandes[0].isValidate;

        if (self.isValidate) {
            self.nbBeneficiaires = panier.Precommandes[0].NbBeneficiaireDevis;
            self.nbTitres = panier.Precommandes[0].NbTitresDevis;
        } else {
            self.nbBeneficiaires = null;
            self.nbTitres = null;
        }
        

        self.submitNbBenefsTitres = function (form) {
            $validationProvider.validate(form)
                .then(function() {
                    
                ////    panier.Precommandes[0].NbBeneficiaireDevis = self.nbBeneficiaires;
                ////    panier.Precommandes[0].NbTitresDevis = self.nbTitres;

                ////    //var beneficiaire = new Beneficiaire();
                ////    //beneficiaire.NombreTitres = NombreTitres;

                    //    //    return PanierService.creerBeneficiaire(panier.Id, panier.Precommandes[0].Id, { NbTitres: self.nbTitres });
                    panier.Precommandes[0].NbBeneficiaireDevis = self.nbBeneficiaires;
                    panier.Precommandes[0].NbTitresDevis = self.nbTitres;

                    panier.Client.Value.Effectif = self.nbBeneficiaires;
                    return PanierService.modifierPanierDevis(panier.Precommandes[0].Id, panier.Beneficiaires[0].Id, self.nbBeneficiaires, self.nbTitres);
                })
                .then(function (result) {
                    //panier.Beneficiaires = [];
                    //panier.Beneficiaires.push(result.plain());

                    $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                    StateService.setPanier(panier);

                    panier.Precommandes[0].isValidate = true;

                    self.isValidate = true;

                    return PanierService.enregistrerPrecommande(panier.Id, panier.Precommandes[0].Id, { NbBeneficiaireDevis: self.nbBeneficiaires });
                })
                .then(function () {
                    //if (!StateService.isCompleted(StateService.getCurrent())) {
                    //    var ReferenceEtapeTunnel = Ressources.TYPES_TUNNEL.CMA + ":" + StateService.getCurrent();
                    //    localStorageService.set(Ressources.REFERENCE_ETAPE_TUNNEL_KEY, ReferenceEtapeTunnel);
                    //    var updatePreco = {}; updatePreco.ReferenceEtapeTunnel = ReferenceEtapeTunnel;
                    //    PanierService.enregistrerPrecommande(panier.Id, panier.Precommandes[0].Id, updatePreco);
                    //}


                    return ClientService.enregistrerClient(panier.Client.Value.Id, { Effectif: panier.Client.Value.Effectif }, panier.Precommandes[0].Id);
                })
                .then(function () {
                    //if (!StateService.isCompleted(StateService.getCurrent())) {
                    //    var ReferenceEtapeTunnel = Ressources.TYPES_TUNNEL.CMA + ":" + StateService.getCurrent();
                    //    localStorageService.set(Ressources.REFERENCE_ETAPE_TUNNEL_KEY, ReferenceEtapeTunnel);
                    //    var updatePreco = {}; updatePreco.ReferenceEtapeTunnel = ReferenceEtapeTunnel;
                    //    PanierService.enregistrerPrecommande(panier.Id, panier.Precommandes[0].Id, updatePreco);
                    //}

                    $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                    StateService.setPanier(panier);
                    StateService.next();
                });
        };

        
    };

    module.exports = {
        name: "NombreBenefsTitres",
        fn: nombreBenefsTitres
    };

})();
