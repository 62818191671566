;(function() {
    "use strict";

    function ContractConfig () {
        return function () {
            this.TrancheEffectif = null;
            this.IdPartenaire = null;
            this.IdContratType = null;
            this.NomContrat = null;
            this.IdClientGenerique = null;
        };
    };

    module.exports = {
        name: "ContractConfig",
        fn: ContractConfig
    }

})();
