; (function () {
    "use strict";

    function dateToString() {
        return function (date) {
            if (!date) return '';
            return moment(date).format('DD/MM/YYYY');
        }
    }

    module.exports = {
        name: "dateToString",
        fn: dateToString
    }

})();
