module.exports=﻿{
    "tunnel-ctr.persona": [
        /*{
            "name": "webcallback",
            "tooltip": "WebCallback",
            "templateUrl": "Content/views/feedly/webcallback.html"
        },
        {
            "name": "cobrowse",
            "tooltip": "Cobrowse",
            "templateUrl": "Content/views/feedly/cobrowse.html"
        },*/
        {
            "name": "etrerecontacter",
            "tooltip": "Être recontacté",
            "templateUrl": "Content/views/feedly/recontacterMoi.html"
        }
    ],
    "tunnel-ctr.valeurfaciale": [
        {
            "name": "info",
            "tooltip": "Aide",
            "templateUrl": "Content/views/feedly/help.html"
        },
        /*{
            "name": "webcallback",
            "tooltip": "WebCallback",
            "templateUrl": "Content/views/feedly/webcallback.html"
        },
        {
            "name": "cobrowse",
            "tooltip": "Cobrowse",
            "templateUrl": "Content/views/feedly/cobrowse.html"
        },*/
        {
            "name": "etrerecontacter",
            "tooltip": "Être recontacté",
            "templateUrl": "Content/views/feedly/recontacterMoi.html"
        }
    ],
    "tunnel-ctr.simulation": [
        {
            "name": "info",
            "tooltip": "Aide",
            "templateUrl": "Content/views/feedly/help.html"
        },
        /*{
            "name": "webcallback",
            "tooltip": "WebCallback",
            "templateUrl": "Content/views/feedly/webcallback.html"
        },
        {
            "name": "cobrowse",
            "tooltip": "Cobrowse",
            "templateUrl": "Content/views/feedly/cobrowse.html"
        },*/
        {
            "name": "etrerecontacter",
            "tooltip": "Être recontacté",
            "templateUrl": "Content/views/feedly/recontacterMoi.html"
        }
    ],
    "tunnel-ctr.siret-devis": [
        {
            "name": "etrerecontacter",
            "tooltip": "Être recontacté",
            "templateUrl": "Content/views/feedly/recontacterMoi.html"
        }
    ],
    "tunnel-ctr.beneficiaire": [
        {
            "name": "info",
            "tooltip": "Aide",
            "templateUrl": "Content/views/feedly/help.html"
        },
        /*{
            "name": "webcallback",
            "tooltip": "WebCallback",
            "templateUrl": "Content/views/feedly/webcallback.html"
        },
        {
            "name": "cobrowse",
            "tooltip": "Cobrowse",
            "templateUrl": "Content/views/feedly/cobrowse.html"
        },*/
        {
            "name": "etrerecontacter",
            "tooltip": "Être recontacté",
            "templateUrl": "Content/views/feedly/recontacterMoi.html"
        }
    ],
    "tunnel-ctr.siret": [
        {
            "name": "info",
            "tooltip": "Aide",
            "templateUrl": "Content/views/feedly/help.html"
        },
        /*{
            "name": "webcallback",
            "tooltip": "WebCallback",
            "templateUrl": "Content/views/feedly/webcallback.html"
        },
        {
            "name": "cobrowse",
            "tooltip": "Cobrowse",
            "templateUrl": "Content/views/feedly/cobrowse.html"
        },*/
        {
            "name": "etrerecontacter",
            "tooltip": "Être recontacté",
            "templateUrl": "Content/views/feedly/recontacterMoi.html"
        }
    ],
    "tunnel-ctr.adresse-saisie": [
        {
            "name": "info",
            "tooltip": "Aide",
            "templateUrl": "Content/views/feedly/help.html"
        },
        /*{
            "name": "webcallback",
            "tooltip": "WebCallback",
            "templateUrl": "Content/views/feedly/webcallback.html"
        },
        {
            "name": "cobrowse",
            "tooltip": "Cobrowse",
            "templateUrl": "Content/views/feedly/cobrowse.html"
        },*/
        {
            "name": "etrerecontacter",
            "tooltip": "Être recontacté",
            "templateUrl": "Content/views/feedly/recontacterMoi.html"
        }
    ],
    "tunnel-ctr.coordonnees": [
        {
            "name": "info",
            "tooltip": "Aide",
            "templateUrl": "Content/views/feedly/help.html"
        },
        /*{
            "name": "webcallback",
            "tooltip": "WebCallback",
            "templateUrl": "Content/views/feedly/webcallback.html"
        },
        {
            "name": "cobrowse",
            "tooltip": "Cobrowse",
            "templateUrl": "Content/views/feedly/cobrowse.html"
        },*/
        {
            "name": "etrerecontacter",
            "tooltip": "Être recontacté",
            "templateUrl": "Content/views/feedly/recontacterMoi.html"
        }
    ],
    "tunnel-ctr.prestations": [
        /*{
            "name": "webcallback",
            "tooltip": "WebCallback",
            "templateUrl": "Content/views/feedly/webcallback.html"
        },
        {
            "name": "cobrowse",
            "tooltip": "Cobrowse",
            "templateUrl": "Content/views/feedly/cobrowse.html"
        },*/
        {
            "name": "etrerecontacter",
            "tooltip": "Être recontacté",
            "templateUrl": "Content/views/feedly/recontacterMoi.html"
        }
    ],
    "tunnel-ctr.recapitulatif": [
        /*{
            "name": "webcallback",
            "tooltip": "WebCallback",
            "templateUrl": "Content/views/feedly/webcallback.html"
        },
        {
            "name": "cobrowse",
            "tooltip": "Cobrowse",
            "templateUrl": "Content/views/feedly/cobrowse.html"
        },*/
        {
            "name": "info",
            "tooltip": "Détails",
            "templateUrl": "Content/views/feedly/summary.html"
        },
        {
            "name": "etrerecontacter",
            "tooltip": "Être recontacté",
            "templateUrl": "Content/views/feedly/recontacterMoi.html"
        }
    ],
    "tunnel-ctr.devis": [
        /*{
            "name": "webcallback",
            "tooltip": "WebCallback",
            "templateUrl": "Content/views/feedly/webcallback.html"
        },
        {
            "name": "cobrowse",
            "tooltip": "Cobrowse",
            "templateUrl": "Content/views/feedly/cobrowse.html"
        },*/
        {
            "name": "etrerecontacter",
            "tooltip": "Être recontacté",
            "templateUrl": "Content/views/feedly/recontacterMoi.html"
        }
    ],
    "tunnel-ctr.paiement": [
        /*{
            "name": "webcallback",
            "tooltip": "WebCallback",
            "templateUrl": "Content/views/feedly/webcallback.html"
        },
        {
            "name": "cobrowse",
            "tooltip": "Cobrowse",
            "templateUrl": "Content/views/feedly/cobrowse.html"
        },*/
        {
            "name": "info",
            "tooltip": "Aide",
            "templateUrl": "Content/views/feedly/help.html"
        },
        {
            "name": "etrerecontacter",
            "tooltip": "Être recontacté",
            "templateUrl": "Content/views/feedly/recontacterMoi.html"
        }
    ],
    "tunnel-ctr.confirmation": [
        /*{
            "name": "etrerecontacter",
            "tooltip": "Être recontacté",
            "templateUrl": "Content/views/feedly/recontacterMoi.html"
        },*/
    ],
    "tunnel.connected.recapitulatif": [
        {
            "name": "info",
            "tooltip": "Détails",
            "class": "detail-recapitulatif-feedly",
            "templateUrl": "Content/views/feedly/recap-connected-summary.html"
        }
    ],
    // Tunnel CMA
    "tunnel-cma.siret": [
        {
            "name": "info",
            "tooltip": "Aide",
            "templateUrl": "Content/views/feedly/cma-help.html"
        }
    ],
    "tunnel-cma.adresse": [
        {
            "name": "info",
            "tooltip": "Aide",
            "templateUrl": "Content/views/feedly/cma-help.html"
        }
    ],
    "tunnel-cma.coordonnees": [
        {
            "name": "info",
            "tooltip": "Aide",
            "templateUrl": "Content/views/feedly/cma-help.html"
        }
    ],
    "tunnel-cma.nombre-titres-salaries": [
        {
            "name": "info",
            "tooltip": "Aide",
            "templateUrl": "Content/views/feedly/cma-help.html"
        }
    ],
    "tunnel-cma.valeur": [
        {
            "name": "info",
            "tooltip": "Aide",
            "templateUrl": "Content/views/feedly/cma-help.html"
        }
    ],
    "tunnel-cma.prestations": [
        {
            "name": "info",
            "tooltip": "Aide",
            "templateUrl": "Content/views/feedly/cma-help.html"
        }
    ],
    "tunnel-cma.recapitulatif": [
        {
            "name": "info",
            "tooltip": "Détails",
            "templateUrl": "Content/views/feedly/summary.html"
        },
        {
            "name": "info",
            "tooltip": "Aide",
            "templateUrl": "Content/views/feedly/cma-help.html"
        }
    ]
}
