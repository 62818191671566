; (function () {
    "use strict";

    var feedly = {
        bindings: {
            tabs: '<'
        },
        transclude: true,
        templateUrl: componentsPath + 'feedly/feedly.button.html',
        controller: function ($scope, $element) {
            var self = this;

            self.openFeedly = function (tab) {
                openFeedly(tab);
            };

            self.closeMenu = function () {
                self.feedlyOpen = false;
                $element.find('.help-sidebar').removeClass('open');
            }

            $scope.$on('onCloseFeedly', function (e) {
                self.feedlyOpen = false;
                $element.find('.help-sidebar').removeClass('open');
            });

            $scope.$on('onOpenFeedly', function (e, className) {
                var tab = _.find(self.tabs, function (t) {
                    return t.class === className;
                });

                if (tab) {
                    openFeedly(tab);
                }
            });

            function openFeedly(tab) {
                self.feedlyOpen = true;
                tab.active = true;
                $element.find('.' + tab.class + ' .help-sidebar').addClass('open');
            }
        }
    };

    module.exports = {
        name: 'feedly',
        obj: feedly
    };
})();
