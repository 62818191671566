;(function() {
    "use strict";

    function navbarToggle() {
        return {
            restrict: 'A',
            scope: {},
            priority: 9999,
            link: function (scope, el, attrs) {

                $(el).on('click', function(e){
                    var isActive = $(el).attr('aria-active'),
                        anchor = $(el).attr('aria-controls');

                    if (JSON.parse(isActive)) {
                        return false;
                    }else {
                        // $('[aria-active="true"]').attr('aria-active', 'false').removeClass('animated zoomInBig');
                        $('[aria-active="true"]').attr('aria-active', 'false');
                        $(el).attr('aria-active', 'true');
                        // $('[aria-hidden="false"]').attr('aria-hidden', ' true').addClass('animated zoomInBig');
                        $('[aria-hidden="false"]').attr('aria-hidden', 'true');
                        $('#' + anchor).attr('aria-hidden', 'false');
                    }

                });

            }
        };
    };

    module.exports = {
        name: 'navbarToggle',
        fn: navbarToggle
    };

})();
