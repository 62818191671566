; (function () {
    "use strict";


    // TODO: refacto tout ça, passer l'appel à Google API dans un service
    function adressFillingController($scope, $state, localStorageService, $http, StateService, ValidationService, AddressFillingService, PanierService, ClientService, CompanyService, WordingService, Client, Ressources) {
        // "ngInject";

        var self = this,
            GOOGLE_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json',
            COUNTRY_LIMIT = 'country:FR',
            GOOGLE_API_CITY_KEY = 'locality',
            GOOGLE_API_CODE_POSTAL_KEY = 'postal_code',
            taille_max_champs_adresse = 32,
            panier = StateService.getPanier();

        self.EstXSell = panier.Precommandes[0].EstXSell || panier.Client.Value.EstXSell;

        self.client = panier.Client.Value;

        self.errorMsg = [];    

        if (self.client.Adresse && !self.client.Adresse.EstNormaliseeQAS && !self.client.Adresse.AdresseSaisie) {
            self.client.Adresse.Adresse = null;
            self.client.Adresse = {
            Adresse1: '',
            Adresse2: '',
            Adresse3: ''
        };
        } else {
            if (self.client.Adresse && !self.client.Adresse.InfoComplementLivraison) {
                self.client.Adresse.Adresse = self.client.Adresse.Adresse1;
            }
        }

        self.address = {};

        $scope.$watch(angular.bind(self, function () {
            return self.EstModifier;
        }), function (newVal, oldVal) {
            if (newVal == !oldVal) {
                self.address.selected = null;
            }
        }, true);

        self.listAddress = function (address) {
            var params = { address: address, sensor: false, components: COUNTRY_LIMIT };
            return $http.get(GOOGLE_API_URL, { params: params }).then(function (response) {
                self.addresses = $.map(response.data.results, function (e) {
                    var components = $.map(e.address_components, function (a) {
                        if (a.types[0] == "street_number" ||
                            a.types[0] == "route") return a;
                    })
                    if (components.length == 2)
                        return e;
                });
            });
        };

        self.setNewAddress = function (newAddress) {
            self.errorMsg = [];
            try {
                var adresseRue = $.map(newAddress.address_components, function (e) {
                    if (e.types[0] == "street_number" ||
                        e.types[0] == "route") return e.long_name;
                }).reduce(function (p, c) { return p + " " + c; });
            } catch (e) {
                self.errorMsg = [ValidationService.chargerMessageErreur("ADRESSE_KO")];
                return;
            }

            if (self.client.Adresse && !self.client.Adresse.InfoComplementLivraison) {
                self.client.Adresse.InfoComplementLivraison = "";
            }

            self.client.Adresse.Adresse = adresseRue;

            var address = AddressFillingService.controlAddressMax32ParChamps(adresseRue, self.client.Adresse.InfoComplementLivraison);
            self.client.Adresse.Adresse1 = address.Adresse1;
            self.client.Adresse.Adresse2 = address.Adresse2;
            self.client.Adresse.Adresse3 = address.Adresse3;

            var cityComponent = _.find(newAddress.address_components, function (c) {
                return c.types.indexOf(GOOGLE_API_CITY_KEY) != -1;
            });

            if (cityComponent) {
                self.client.Adresse.Ville = cityComponent.long_name;
            }

            var codePostalComponent = _.find(newAddress.address_components, function (c) {
                return c.types.indexOf(GOOGLE_API_CODE_POSTAL_KEY) != -1;
            });

            if (codePostalComponent) {
                self.client.Adresse.CodePostal = codePostalComponent.long_name;
            }

            self.EstModifier = false;
            self.client.Adresse.AdresseSaisie = true;
        }

        self.changeComplement = function () {
            self.EstModifierComplement = true;
        };

        self.displayAddressForm = function() {
            self.addressFormDisplayed = true;
            self.client.Adresse = {};
            self.villes = [];
            self.client.Adresse.Ville = '';
        };

        self.hideAddressForm = function() {
            self.addressFormDisplayed = false;
        };

        self.getVilles = function() {
            if (self.client.Adresse.CodePostal && self.client.Adresse.CodePostal.length == 5) {
                CompanyService.rechercherVilleParCodePostal(self.client.Adresse.CodePostal).then(function (result) {
                    if (result.plain().length > 0) {
                        self.villes = result.plain();
                        self.client.Adresse.Ville = self.villes[0];
                        self.errorMsg = _.filter(self.errorMsg, function(x) {
                            x != "La ville n'est pas valide.";
                        });
                    } else {
                        self.villes = [];
                        self.villes[0] = 'Aucun résultat trouvé';
                        self.client.Adresse.Ville = self.villes[0];
                        self.errorMsg.push("La ville n'est pas valide.");
                        return;
                    }                    
                }).catch(function (error) {
                    console.log(error);  
                });
            } else {
                self.villes = [];
                self.client.Adresse.Ville = '';
            }            
        };

        self.submitAddress = function () {
            
            if (!self.addressFormDisplayed) {
                if (!self.client.Adresse.EstNormaliseeQAS && !self.client.Adresse.AdresseSaisie) {
                    self.errorMsg.push("L'adresse n'est pas valide.");
                    return;
                } else {
                    var multipleRules = ['ADRESSE_KO2'];
                    self.errorMsg = ValidationService.validerRegles(multipleRules, self.client.Adresse);                    
                    if (self.errorMsg.length > 0) return;
                }
                var address = AddressFillingService.controlAddressMax32ParChamps(self.client.Adresse.Adresse, (self.client.Adresse.InfoComplementLivraison != null ? self.client.Adresse.InfoComplementLivraison : ""));
            
                self.client.Adresse.Adresse1 = address.Adresse1;
                if (address.Adresse2 != null) self.client.Adresse.Adresse2 = address.Adresse2;
                if (address.Adresse3 != null) self.client.Adresse.Adresse3 = address.Adresse3;    
                }
            

            if (self.addressFormDisplayed) {
                self.errorMsg = [];
                    
                if (!self.client.Adresse.Adresse1) {
                    self.errorMsg.push(WordingService.getWording("CHAMP_ADRESSE1_VIDE"));
                }    

                if (!self.client.Adresse.CodePostal) {
                    self.errorMsg.push(WordingService.getWording("CHAMP_CP_VIDE"));
                }

                if (!self.client.Adresse.Ville || self.client.Adresse.Ville == 'Aucun résultat trouvé') {
                    self.errorMsg.push(WordingService.getWording("CHAMP_VILLE_VIDE"));
                }

                if (self.client.Adresse.Adresse1 == undefined) {
                    self.client.Adresse.Adresse1 = ''; 
                }               

                if (self.client.Adresse.Adresse2 == undefined) {
                    self.client.Adresse.Adresse2 = ''; 
                }                

                if (self.client.Adresse.Adresse3 == undefined) {
                    self.client.Adresse.Adresse3 = '';
                }

                if (self.client.Adresse.Adresse1.length > 32 || self.client.Adresse.Adresse2.length > 32 || self.client.Adresse.Adresse3.length > 32) {
                    self.errorMsg.push("L'adresse n'est pas valide.");
                }

                if (self.errorMsg.length > 0) return;

                self.client.Adresse.EstNormaliseeQAS = false;

                if (!self.client.Adresse.Adresse) {
                    self.client.Adresse.Adresse = self.client.Adresse.Adresse1
                    + (!!self.client.Adresse.Adresse2 ?  ' ' + self.client.Adresse.Adresse2 : '')
                    + (!!self.client.Adresse.Adresse3 ?  ' ' + self.client.Adresse.Adresse3 : '');
                }
            }

            panier.Client.Value = self.client;
            $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);

            /* PATCH REQUEST */
            var patchAdresse = {};
            patchAdresse.Adresse = {};
            patchAdresse.Adresse.Adresse1 = self.client.Adresse.Adresse1;
            patchAdresse.Adresse.Adresse2 = self.client.Adresse.Adresse2;
            patchAdresse.Adresse.Adresse3 = self.client.Adresse.Adresse3;
            patchAdresse.Adresse.CodePostal = self.client.Adresse.CodePostal;
            patchAdresse.Adresse.Ville = self.client.Adresse.Ville;
            patchAdresse.Adresse.EstNormaliseeQAS = self.client.Adresse.EstNormaliseeQAS;
            if (self.EstModifier || self.EstModifierComplement || !self.client.Adresse.EstNormaliseeQAS || self.addressFormDisplayed) {
                ClientService.enregistrerClient(panier.Client.Id, patchAdresse).then(function (enregistrerClientResult) {
                        StateService.next();
                    })
                    .catch(function(error) {
                        /* ADRESSE_KO, COMPLEMENT_CARACT_KO */
                        var errorCode = error.data.Content[0].ErrorCode;
                        self.errorMsg = [ValidationService.chargerMessageErreur(errorCode)];
                    });
            } else {
                StateService.next();
            }
        };
    }

    module.exports = {
        name: "AddressFillingController",
        fn: adressFillingController
    };

})();
