; (function () {
    "use strict";

    function contactService(Restangular, $q, localStorageService, ClientService, Interlocuteur, Client, Ressources) {
        var baseRest = Restangular.all('clients');

        var envoiFormulaireRecontacte = function (contact, panier, origin) {
            return baseRest.one(panier.Client.Value.Id).one('precommandes', panier.Precommandes[0].Id).post('EtreRecontacte', { "idPanier": panier.Id, "origin": origin });
        };

        return {
            envoiFormulaireRecontacte: envoiFormulaireRecontacte,
        };
    };

    module.exports = {
        name: "ContactService",
        fn: contactService
    }

})();
