(function () {
    'use strict';

    // require('../modernizr');
    require('./component_modules/tunnel/tunnel');
    require('./component_modules/tunnel/login/login');
    require('./component_modules/tunnel/forgotpassword/forgotpassword');
    require('./component_modules/tunnel/connected/connected');
    require('./component_modules/tunnel/connected/beneficiaire/beneficiaire');
    require('./component_modules/tunnel/connected/recapitulatif/recapitulatif');
    require('./component_modules/tunnel/connected/timeline/timeline');
    require('./component_modules/tunnel/connected/descriptif/descriptif');
    require('./component_modules/tunnel/connected/parametrage/parametrage');
    require('./component_modules/tunnel/connected/factures/factures');
    require('./models/index');
    require('./services/index');
    require('./filters/index');
    require('./directives/index');
    require('./controllers/index');
    require('./components/index');
    require('./animations/index');
    require('angular-g-recaptcha');

    function ressourceWrapper() {
      var partnPosi =  window.location.search.indexOf('idPartenaire');
      var keyPartner = [];
      var isPartnerId = partnPosi > -1;
      if (isPartnerId) {
        var partn = window.location.search.substring(partnPosi);
        keyPartner = partn.split('&')[0].split('=');
      }
      // const idPartenaire = new URLSearchParams(window.location.search).get('idPartenaire');
      var Ressources = require('../assets/config/config.json');
      if (isPartnerId && keyPartner[1]) { Ressources['CODE_PRODUIT_KEY'] = keyPartner[1]; }
      return Ressources;
    }

    angular
        .module('excv2', [
            require('angular-ui-router'),
            require('angular-cookies'),
            require('ng-dialog'),
            'wo.grecaptcha',
            'ngAnimate',
            'validation',
            require('angular-sanitize'),
            require('ui-select'),
            require('angular-local-storage'),
            require('angulartics'),
            require('angulartics-google-tag-manager'),
            'feature-flags',
            'ng.deviceDetector',
            'ya.nouislider',
            'ngScrollbars',
            'ngMask',
            'ngCookies',
            'pickadate',
            'angular-loading-bar',
            'angularPromiseButtons',
            'app.component',
            'app.models',
            'app.services',
            'app.directives',
            'app.controllers',
            'app.filters',
            'app.animation',
            'tunnel',
            'tunnel.connected',
            'tunnel.connected.beneficiaire',
            'tunnel.connected.recapitulatif',
            'tunnel.connected.timeline',
            'tunnel.connected.descriptifs',
            'tunnel.connected.parametrageComptabilite',
            'tunnel.connected.factures',
            'tunnel.login',
            'tunnel.forgotpassword'
        ])
        // .factory('$exceptionHandler', function () {
        //     return function (exception, cause) {
        //         throw exception;
        //     };
        // })

        .factory('RestangularSansIntercepteur', function (Restangular) {
            return Restangular.withConfig(function (RestangularConfigurer) {
                RestangularConfigurer.setErrorInterceptor(function (response) {
                    return response;
                });
            });
        })

        .factory('RestangularHeaders', function (Restangular) {
            return Restangular.withConfig(function (RestangularConfigurer) {
                RestangularConfigurer.setFullResponse(true);
            });
        })


        // Angular App Configs Definitions
        .config(require('./config/restAngular').fn)
        .config(require('./config/routes').fn)
        .config(require('./config/validators').fn)
        .config(require('./config/localStorage').fn)
        .config(require('./config/customScrollbar').fn)
        .config(require('./config/xhrLoader').fn)
        .config(require('./config/angulartics').fn)
        .config(require('./config/promiseBtns').fn)
        .config(require('./config/whiteList').fn)
        .config(function ($grecaptchaProvider, Ressources) {
            $grecaptchaProvider.set({
                sitekey: Ressources.RECAPTCHA_SITE_KEY
            });
        })

        // Angular App Constants Definitions
        .constant('StateMachineConfig',             require('../assets/config/stateMachine.json')) // StateMachine type tunnel : Commande
        .constant('StateMachineContactConfig',      require('../assets/config/stateMachineContact.json')) // StateMachine type tunnel : Contact
        .constant('StateMachineSimulationConfig',   require('../assets/config/stateMachineSimulation.json')) // StateMachine type tunnel : Simulation
        .constant('StateMachineDevisConfig',        require('../assets/config/stateMachineDevis.json')) // StateMachine type tunnel : Devis
        .constant('StateMachineCMAConfig',          require('../assets/config/stateMachineCMA.json')) // StateMachine type tunnel : CMA
        .constant('FeedlyStateConfiguration',       require('../assets/config/feedlyState.json')) // Gère les button afficher (help, callback.. etc)
        .constant('FeedlyEtapeConfiguration',       require('../assets/config/feedlyEtape.json')) // Gère les etapes dans le feedly (ex: rechercheSiret => ListeResultatRechercheSiret)
        .constant('ValidationJSON',                 require('../assets/config/validation.json'))
        .constant('Lexique',                        require('../assets/config/lexique.conf.json'))
        .constant('StepperConfiguration',           require('../assets/config/stepper.conf.json'))
        .constant('StepperCMAConfiguration',        require('../assets/config/stepper.CMA.conf.json'))
        .constant('Ressources',                     ressourceWrapper())

        // Angular App Run functions init
        .run(require('./run/cartRecovery').runFn)
        .run(require('./run/stateAvailableOnDom').runFn)
        .run(require('./run/errorRedirections').runFn)
        .run(require('./run/authHeader').runFn)
        .run(require('./run/featureFlags').runFn);
    // Angular App initialization
    angular.bootstrap(document.body, ['excv2']);

})();
