; (function () {
    "use strict";
    function personaKoController($scope, $window, $state, $stateParams, StateService, PanierService, localStorageService, Ressources, panier, trackingService) {
        var self = this;

        if ($stateParams.obj == null) {
            StateService.restore();
        }
        
        self.active = _.some($stateParams.obj, 'EstClientExcv2');

        self.continuer = function () {
            panier.Client.Value.Interlocuteur = [$stateParams.obj[0].Interlocuteur];
            $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
            trackingService.trackEvent({"event": "erreurEmailConnu", "button_name": "je commande pour une nouvelle entreprise"});// tracking GTM event
            StateService.next();
        };

        self.seConnecter = function () {
            localStorageService.clearAll();
            localStorage.removeItem('excv2panier');
            trackingService.trackEvent({"event": "erreurEmailConnu", "button_name": "je me connecte"});// tracking GTM event
            $state.go("tunnel.login", { login: $stateParams.obj[0].Interlocuteur.Email });
            //$window.location.href = Ressources.ESPACE_CLIENT_URL + "?login=" + $stateParams.obj[0].Interlocuteur.Email;
        };

        self.creerSonCompte = function () {
            trackingService.trackEvent({"event": "erreurEmailConnu", "button_name": "je créé mon compte espace client"});// tracking GTM event
            var annulerPanierPromise;

            if (panier && panier.Precommandes && panier.Precommandes[0].Statut == 0) {
                annulerPanierPromise = PanierService.annulerPanier(panier.Id).then(function () {
                    StateService.reset();
                });
            } else {
                annulerPanierPromise = $q.resolve();
            }

            annulerPanierPromise.then(function() {
                $window.location.href = Ressources.ESPACE_CLIENT_URL + "account?numeroclient=" + $stateParams.obj[0].NumeroDivision + "&email=" + $stateParams.obj[0].Interlocuteur.Email;
                return false;
            });
        };
    };

    module.exports = {
        name: "PersonaKoController",
        fn: personaKoController
    };

})();
