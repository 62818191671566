; (function () {
    "use strict";

    function appController($rootScope, $scope, $timeout, $location, $filter, trackingService, FeedlyStateService, FeedlyEtapeService, PanierService, Ressources, localStorageService, FeedlyStateConfiguration, StateService, ClickService) {
        var self = this;
        self.isLoading = false;
        self.animErrorBox = "fadeInUp";
        self.panier = StateService.getPanier();
        self.feedly = FeedlyStateConfiguration;
        self.feedlyTemplate = "Content/views/feedly/help.html";  // By default
        self.feedlyOpen = false;
        self.footerOpen = false;
        self.footerBarArray = [0,0,0,0,0];
        self.feedlyButtonOpen = false;
        self.isConnected = false;
        self.appLoaded = false;
        self.isExpandedSidebar = false;
        self.partenaireId = null;
        self.showContactButton = true;
        self.limiteExo = ClickService.getLimiteExo()
            .then(function(res){
                // mise à jour de la limite d'exo dans les ressources
                var ress = JSON.parse(res.target.response);
                Ressources.LIMITE_EXONERATION = ress["limite-exoneration-tr"] * 100;
                Ressources.VF_MAX = (ress["limite-exoneration-tr"] * 100) * 2;
            });

        self.isIe = function () {
            var reg1 = /MSIE/
            var reg2 = /Trident\/7\./
            var reg3 = /Edge/
            if (reg1.test(window.navigator.userAgent)) {
                return true; // IE
            }
            if (reg2.test(window.navigator.userAgent) || reg3.test(window.navigator.userAgent)) {
                return true; // Edge
            }
            return false; // ni IE ni Edge
        }

        self.checkPromoSite = function () {
            if (window.location.hostname.indexOf('promo-ctr') !== -1) {
                return true;
            } else {
                return false;
            }
        };

        self.findIndexInFeedly = function (what) {
            var temp = -1;
            if(self.feedly){
                self.feedly.forEach(function(el, index, array) {
                    if(el.name === what) {
                        temp = index;
                        return temp;
                    }
                });
            }
            return temp;
        };

        self.checkEtreRecontacter = function() {
            if (self.mentions && self.mentions['helpContactButton'] === 0 && $location.$$path.indexOf('intro') < 0) {
                var etrerecontacte = self.findIndexInFeedly('etrerecontacter');
                // var etrerecontacte = (self.feedly)? self.feedly.findIndex(function(item) { return item.name === 'etrerecontacter'}) : -1;
                if (etrerecontacte > -1) { 
                    // suppression du bouton dans le feedly
                    self.feedly.splice(etrerecontacte, 1);
                }
                // masquer aussi le nouveau button de contact
                self.showContactButton = false;
            }
        };

        ClickService.getUrl('VEL-CTR/promo_mentions.json')
          .then(function (res) {
            self.mentions = JSON.parse(res.target.response);
            self.mentions = Ressources.CODE_PRODUIT_KEY && self.mentions[Ressources.CODE_PRODUIT_KEY] || self.mentions.default || self.mentions;

            for (var key in self.mentions) {
                if (key !== 'footerLinkContact' && key !== 'footerTelContact' && key !== 'helpContactButton') {
                    self.mentions[key] = ClickService.getBaseUrlForClick() + '/' +  self.mentions[key];
                }
                if (key === 'helpContactButton') {
                    self.mentions[key] = parseInt(self.mentions[key], 10);
                }
            }
            // self.mentions['footerLinkContact'] = 'https://www.google.com'; // 'http://www.ticketrestaurant.fr/formulaire-de-contact'
            // self.mentions['footerTelContact'] = '01 55 55 55 55'; // '01 74 31 81 69'
            // self.mentions['helpContactButton'] = 0; // 1
            // self.mentions.imgPhone = "content/img/cic_mobile.jpg";
            // self.mentions.imgIpad = "content/img/cic_ipad.jpg";
            var nospace = /\s/g;
            self.mentions['cleanedFooterTelContact'] = (self.mentions.hasOwnProperty('footerTelContact') && self.mentions['footerTelContact'] !== '') ? self.mentions['footerTelContact'].substring(1).replace(nospace, '') : '174318169';
            self.checkEtreRecontacter();
            $scope.isPromoSite = self.checkPromoSite();
            if ($scope.isPromoSite) {
                self.mentions['img'] = self.mentions['promo_img'];
            }
            $scope.photoStart = "url("+self.mentions['img']+")";
            $scope.ctrVisu =  self.mentions && self.mentions.visu ? "url("+self.mentions.visu+")" : "url("+ClickService.getBaseUrlForClick()+'/VEL-CTR/Visuel_CTR.png'+")";
          })
          .catch(function (e) {
            self.mentions = {};
          });
        
        $scope.isCma = (window.location.search.indexOf('cma') > -1 || window.location.hash.indexOf('cma') > -1);
        $scope.isVel = !!(window.location.host === "commande.preprod.ticketrestaurant.fr" || window.location.host === "commande.ticketrestaurant.fr" || window.location.host === "fr-efr-r-velctr.edenred.net" || window.location.host === "efr-espaceclientv2-t.edenred.net" || window.location.host === "promo-ctr.fr");
        $scope.isV2Connected = !!(window.location.host === "client.edenred.fr" || window.location.host === "client.preprod.edenred.fr" || window.location.host === "fr-efr-r-frontendclient.edenred.net" || window.location.host === "efr-frontendclient-t.edenred.net");

        
        $scope.isPromoSite = self.checkPromoSite();
        $scope.isIeBrowsing = self.isIe();

        self.obtenirSearchParams = function (name) {
            return unescape(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + escape(name).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
        };

        let isPartenaireId = window.location.search.indexOf('idPartenaire') > -1;
        if (isPartenaireId) {
            self.partenaireId = self.obtenirSearchParams('idPartenaire');
        }
        localStorage.setItem('partnerId', self.partenaireId);

        $scope.$on('onToggleSidebar', function (e, expanded) {
            self.isExpandedSidebar = expanded;
        });

        self.devMode = {
            active: false,
            loaderButton: false,
            firedLoaderButton: function () {
                if (!this.loaderButton) {
                    $rootScope.$broadcast('cfpLoadingBar:loaded');
                } else {
                    $rootScope.$broadcast('cfpLoadingBar:loading');
                }
            },
            reset: function () {
                StateService.reset();
                window.location.reload();
            },
            reload: function () {
                localStorageService.clearAll();
                window.location.reload();
            },
            loadToaster: function () {
                self.toasterOptions.toggle();
            }
        };

        self.toasterOptions = {
            active: false,
            message: {
                header: "Problème de communication avec le serveur",
                caption: "Veuillez patientez s'il vous plaît...",
            },
            icon: "",
            toggle: function () {
                this.active = !this.active;
            }
        };

        $scope.$on('toggleMailSendingToaster', function (e, obj) {
            self.toasterOptions.message = obj;
            self.toasterOptions.toggle();
        });

        $rootScope.$on('toggleLoadingToaster', function (e, options) {
            self.toasterOptions.message = options || {
                header: "Problème de communication avec le serveur",
                caption: "Veuillez patienter s'il vous plaît...",
            };
            self.toasterOptions.icon = options.icon || null;

            self.toasterOptions.toggle();
        });

        $rootScope.$on('closeLoadingToaster', function (e, obj) {
            self.toasterOptions.active = false;
        });

        $rootScope.$on('$stateChangeSuccess', function(e, obj) {
            self.toasterOptions.active = false;
        });

        //$rootScope.$on('cfpLoadingBar:loaded', function () {
        //    self.toasterOptions.active = false;
        //});

        // Haut, haut, bas, bas, gauche, droite, gauche, droite, B, A
        var k = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65],
            n = 0;

        $(document).keyup(function (e) {
            if (e.keyCode === k[n++]) {
                if (n === k.length) {
                    self.devMode.active = true;
                    n = 0;
                    return false;
                }
            }
            else {
                n = 0;
            }
        });

        $scope.$on('onClientConnected', function (e, token) {
            self.isConnected = true;
        });

        $scope.$on('removeLoaderApp', function (e) {
            self.appLoaded = true;
        });

        $scope.$on('isLoadingBasket', function () {
            self.isLoadingBasket = true;
            trackingService.setReprisePanier(true)
        });

        $rootScope.$on(Ressources.EVENT_CHANGE_TYPE_TUNNEL, function (event, typeTunnel) {
            StateService.changeTypeTunnel(typeTunnel);
        });

        $scope.$on('cfpLoadingBar:loading', function () {
            self.submissionInProgress = true;
        });

        $scope.$on('cfpLoadingBar:loaded', function () {
            self.submissionInProgress = false;
        });

        $scope.$on('appLoaded', function () {
            $timeout(function () {
                self.appLoaded = true;
            });
        });

        $scope.$on('onCloseMenu', function (e) {
            self.footerOpen = false;
            self.feedlyOpen = false;
            _resetFeedlyButton();
        });

        self.changeAndTrackFooter = function () {
            self.footerOpen = !self.footerOpen;
            if (self.footerOpen) { // tracking GTM
                trackingService.trackEvent({'event': 'bandeauReassurance', 'page': $location.path()});
            }
        };

        self.trackRecontactMe = function() { //tracking gtm
            trackingService.trackEvent({'event': 'ouvertureEtreRecontacte', 'page': $location.path()});
        };

        self.closeMenu = function () {
            self.footerOpen = false;
            self.feedlyOpen = false;
            _resetFeedlyButton();
        };

        var _lastFeedlyItemIndex = null,
            _resetFeedlyButton = function () {
                _.map(self.feedly, function (f) {
                    f.active = false;
                });
            };

        $scope.$on('openFeedly', function (event, data) {
            self.toggleFeedly(data.templateUrl, 2);
        });

        $scope.$on('toggleFeedlyOpen', function (event) {
            self.feedlyOpen = !self.feedlyOpen;
        });

        $scope.$on('changeEtapeFeedly', function (event, data) {
            self.toggleEtapeFeedly(data.templateUrl);
            self.toggleFeedly(data.templateUrl, 10);
        });

        self.toggleEtapeFeedly = function (templateUrl) { // Open the feedly
            self.feedlyTemplate = templateUrl;
        };

        self.toggleFeedly = function (templateUrl, index) { // Open the feedly
            if (templateUrl === 'Content/views/feedly/recontacterMoi.html') {
                trackingService.trackEvent({'event': 'ouvertureEtreRecontacte', 'page': $location.path()});//tracking GTM Event
            } else if (templateUrl === 'Content/views/feedly/help.html') {
                trackingService.trackEvent({'event': 'ouvertureAide', 'page': $location.path()});//tracking GTM Event
            } else if (templateUrl === 'Content/views/feedly/summary.html') {
                trackingService.trackEvent({'event': 'VotreCommandeEnDetail'});//tracking GTM Event
            }
            if (self.feedlyOpen && _lastFeedlyItemIndex === index) return false;

            if (self.feedlyOpen) { // Try to change the feedly
                _resetFeedlyButton();
            } else {
                self.feedlyOpen = !self.feedlyOpen;
            }
            if (self.feedly.length >= index)
                self.feedly[index].active = true;
            _lastFeedlyItemIndex = index;
            self.feedlyTemplate = templateUrl;
        }

        self.toggleFeedlyButton = function () { // Open feedly Nav with active state on button
            self.feedlyButtonOpen = !self.feedlyButtonOpen;
        }

        self.feedlyHelpWordingCode = "";
        self.feedlyHidden = false;

        /*************
        /*** Methods to apply on $stateChange (hide layers, track special event...)
        **************/

        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            self.feedlyHidden = true;
        });

        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            $timeout(function () {
                self.appLoaded = true;
            });

            /*************
            /*** Track dataLayer Panier if already created or exist
            /*** Begin to track panier after Interlocuteur exist
            **************/
            var _panier = localStorageService.get('panier');
            if ((_panier != null) && (typeof _panier.Client.Value.Interlocuteur[0] !== 'undefined')) {
                trackingService.trackBasketById(_panier.Id);
            }else{
                //track dataLayer with default values
                trackingService.trackBasketNoId();
            }

            $timeout(function () {
                self.feedlyHidden = false;
            }, 1000);

            if (toState.name === Ressources.TUNNEL_CTR_INTRO || toState.name.split('.').length < 2) return false;

            self.feedly = _.map(FeedlyStateService.getEtapes(toState.name), function (f) {
                return angular.extend(f, { active: false });
            });
            var _stateName = toState.name.split('.')[1].toUpperCase();
            if (_stateName) { self.checkEtreRecontacter();}
            self.feedlyHelpWordingCode = "CONTENT_AIDE_" + _stateName;
            self.feedlyHelpTooltipCode = "TOOLTIP_AIDE_" + _stateName;
            FeedlyStateService.resetState();

            var stateFeedly = FeedlyStateService.getCurrentState(toState.name);
            if (stateFeedly != null) self.feedlyTemplate = stateFeedly.templateUrl;


            // Pour la gestion du content feedly par etape
            FeedlyEtapeService.resetEtape();

            var etapeFeedly = FeedlyEtapeService.getCurrentEtape(toState.name);
            if (etapeFeedly != null) self.feedlyTemplate = etapeFeedly.templateUrl;
        });

        $scope.$on(Ressources.EVENT_UPDATE_PANIER, function (event, data) {
            StateService.setPanier(data);
            self.panier = _.merge({}, _.assign(data, { beneficiaires: localStorageService.get(Ressources.BENEFICIARIES_KEY) }));
        });

        $scope.$on(Ressources.EVENT_UPDATE_BENEFICIAIRES, function (event, data) {
            localStorageService.set(Ressources.BENEFICIARIES_KEY, data);
            self.panier.beneficiaires = data;
        });

        self.etapePrecedente = function () {
            StateService.prev();
        };

        self.etapeSuivante = function () {
            StateService.next();
        };

        self.stepperDisponible = function () {
            return self.panier && StateService.getPersona() /*&& StateService.isAvailable(StateService.getPrev()) && StateService.getTypeTunnel() == Ressources.TYPES_TUNNEL.Commande*/;
        };

        self.etapeSuivanteDisponible = function () {
            return StateService.isCompleted();
        };

        $rootScope.$on('$stateChangeStart', function (event, state, params) {
            if (!StateService.isAvailable(state.name)) {
                event.preventDefault();
                StateService.restore();
            }
        });

        self.IsModeCommande = function () {
            return StateService.getTypeTunnel() == Ressources.TYPES_TUNNEL.Commande;
        };

        self.toggleState = function (e) {
            self.footerBarArray[e] = !self.footerBarArray[e];
            for (var i = 0; i < self.footerBarArray.length; i++ ) {
                if (i !== e) {
                    self.footerBarArray[i] = 0;
                }
            };
        };
    };

    module.exports = {
        name: "AppController",
        fn: appController
    };

})();
