; (function () {
    "use strict";

    function clearBenefsErrors(scope) {
        scope.$emit('clearAllValidation');
        if (scope.bnf) {
            //if (scope.bnf.serverKo) scope.bnf.serverKo = false;
            if (scope.bnf.duplicatedBenef) scope.bnf.duplicatedBenef = false;
            if (scope.bnf.validBenefKoCuzOfAdding) scope.bnf.validBenefKoCuzOfAdding = false;
            if (scope.bnf.validBenefKoCuzOfNoneBenef) scope.bnf.validBenefKoCuzOfNoneBenef = false;
            if (scope.bnf.validBenefKoCuzOfModifying) scope.bnf.validBenefKoCuzOfModifying = false;
            if (scope.bnf.addBenefKoCuzOfMaxNbrBenef) scope.bnf.addBenefKoCuzOfMaxNbrBenef = false;
        }
    }

    function validationConfig($validationProvider, ValidationJSON) {
        var validators = {};
        var expression = {
            required: function (value) {
                var valid;
                if (!/\S/.test(value)) //valeur ne contient que les espaces
                    valid = false;
                else if (!!value || value === 0)
                    valid = true;
                else
                    valid = false;
                return valid;
            },
            url: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
            email: function (value) {
                if (!value) return true;
                return value.match(/^[-a-z0-9_]+(\.[-a-z0-9_]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|fr|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|bzh|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i) != null;
            },
            number: /^\d+$/,
            minlength: function (value, scope, element, attrs, param) {
                return value.length >= param;
            },
            maxlength: function (value, scope, element, attrs, param) {
                return value.length <= param;
            },
            titleQuantity: function (value, scope, element, attrs) {
                clearBenefsErrors(scope);

                if (!value) return true;

                var intValue = parseInt(value);
                return (intValue !== 0 && intValue <= 30 && intValue > 0) || intValue === 0;
            },
            titleQuantityConnected: function (value, scope, element, attrs) {
                clearBenefsErrors(scope);

                if (!value) return true;

                var intValue = parseInt(value);
                return (intValue !== 0 && intValue <= 222 && intValue > 0) || intValue === 0;
            },
            titleQuantityCma: function (value, scope, element, attrs) {
                scope.$emit('clearAllValidation');

                if (!value) return true;

                var intValue = parseInt(value);
                return (intValue !== 0 && intValue <= 30 && intValue > 0);
            },
            nbNonZero: function (value, scope, element, attrs) {
                scope.$emit('clearAllValidation');

                if (!value) return true;

                var intValue = parseInt(value);
                return (intValue !== 0);
            },
            name: function (value, scope, element, attrs) {
                clearBenefsErrors(scope);

                if (!value) return true;
                var regex = "^[a-zA-Z \\'\\-èéêëçà]+$",
                    maxLength = 50,
                    rg = new RegExp(regex, 'g');
                return value.match(rg) != null && value.length <= maxLength;
            },
            birthDateInvalid: function (value, scope, element, attrs) {
                clearBenefsErrors(scope);
                if (!value) return true;
                var valid = true;
                var date = moment(value, 'DD/MM/YYYY');
                if (date && !isNaN(date._d.getTime())) {
                    var age = moment().diff(date, 'years');
                    valid = age <= 85 && age >= 16;
                }

                return valid;
            },
            birthDateNonExistent: function (value, scope, element, attrs) {

                clearBenefsErrors(scope);

                if (!value) return true;
                return moment(value, 'DD/MM/YYYY').isValid();
            },
            beneficiariesQuantity: function (value) {
                if (!value) return true;
                var intValue = parseInt(value);
                return intValue <= 50 && intValue >= 1;
            },
            phone: function (value) {
                if (!value) return true;
                var regex = "^((?!08)(?!338)).[0-9]{9}$",
                    rg = new RegExp(regex, 'g'),
                    value = value.split(' ').join('');
                return _.trim(value).match(rg) != null;
            },
            luhn: function (value) {
                function luhnTest(digit) {
                    var arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];

                    var
                      len = digit.length,
                      bit = 1,
                      sum = 0,
                      val;

                    if ([14, 9].indexOf(len) < 0) { return false; }

                    var zero = Number(digit);
                    if (isNaN(zero) || zero === 0) { return false; }

                    while (len) {
                        val = parseInt(digit.charAt(--len), 10);
                        sum += (bit ^= 1) ? arr[val] : val;
                    }

                    if (sum === 0) { return false; }

                    return sum && sum % 10 === 0;
                }

                if (!value) return true;
                if (value.length < 14) return false;
                if (value === "00000000000000") return false;
                if (!luhnTest(value)) return false;
                if (!luhnTest(value.substr(0, 9))) return false;

                return true;
            },
            iban: function (value) {
                // Mod must be calculated on a string (number is too large for JS)
                function mod97(string) {
                    var checksum = string.slice(0, 2), fragment;
                    for (var offset = 2; offset < string.length; offset += 7) {
                        fragment = String(checksum) + string.substring(offset, offset + 7);
                        checksum = parseInt(fragment, 10) % 97;
                    }
                    return checksum;
                }

                if (!value) return true;

                // Sanitize input
                value = value.toUpperCase().replace(/[^A-Z0-9]/g, '');

                if (!value.length == 27)
                    return false;

                // Match and capture (1) country code (only FR), (2) check digits, (3) the rest
                var match = value.match(/^(FR)([0-9]{2})([A-Z0-9]{23})$/);
                if (!match) {
                    return false;
                }

                // Rearrange country code and check digits, convert chars to ints
                var digits = (match[3] + match[1] + match[2]).replace(/[A-Z]/g, function (letter) {
                    return letter.charCodeAt(0) - 55;
                });

                return mod97(digits) === 1;
            },
            bic: function (value) {
                if (!value) return true;
                return value.match(/^[a-zA-Z]{4}FR[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$/) != null;
            },
            complementAdresse: /^[-a-zA-Z0-9 \\,\\'áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ]*$/,
            identique: function (value, scope, element, attrs, param) {
                if (!value) return true;
                var comparedObj = attrs[param];
                if (comparedObj) return value === comparedObj;
                return true;
            },
            referenceClient: function (value) {
                return !value || (value.length <= 50 && value.indexOf(';') === -1);
            },
            alwaysTrue: function (value) {
                return true;
            }
        };
        var defaultMsg = {
            required: {
                error: 'Ce champs est requis !',
                success: ''
            },
            url: {
                error: 'This should be Url',
                success: ''
            },
            email: {
                error: ValidationJSON.REGEX_EMAIL_KO.message,
                success: ''
            },
            number: {
                error: 'This should be Number',
                success: ''
            },
            minlength: {
                error: 'This should be longer',
                success: ''
            },
            maxlength: {
                error: 'This should be shorter',
                success: ''
            },
            titleQuantity: {
                error: '',
                success: ''
            },
            titleQuantityConnected: {
                error: 'Le nombre de jours doit être compris entre 0 et 30.',
                success: ''
            },
            titleQuantityCma: {
                error: '',
                success: ''
            },
            name: {
                error: '',
                success: ''
            },
            birthDateInvalid: {
                error: '',
                success: ''
            },
            birthDateNonExistent: {
                error: '',
                success: ''
            },
            beneficiariesQuantity: {
                error: '',
                success: ''
            },
            phone: {
                error: '',
                success: ''
            },
            luhn: {
                error: '',
                success: ''
            },
            iban: {
                error: 'L\'IBAN n\'est pas valide.',
                success: ''
            },
            bic: {
                error: 'Le BIC n\'est pas valide.',
                success: ''
            },
            complementAdresse: {
                error: 'Le complément ne doit pas contenir de caractères spéciaux',
                success: ''
            },
            identique: {
                error: '',
                success: ''
            },
            referenceClient: {
                error: '',
                success: ''
            },
            nbNonZero: {
                error: '',
                success: ''
            },
            alwaysTrue: {
                error: '',
                success: ''
            }
        };
        $validationProvider.setExpression(expression).setDefaultMsg(defaultMsg);

        $validationProvider.setErrorHTML(function (msg, element, attrs) {
            return '<p class="validation-invalid box-alert  animated">' + msg + '</p>';
        });

        $validationProvider.addMsgElement = function (element) {
            $('#newBeneficiaryForm').append('<span id="forErrors"></div>');
        };

        /**
        * Function to help validator get your Msg Element
        * @param {DOMElement} element - Your input element
        * @return {DOMElement}
        */
        $validationProvider.getMsgElement = function (element) {
            return $('#forErrors');
        };

        $validationProvider.validCallback = function (element) {
            element.parents('form').find('form-error').removeClass('invalid');
            element.parents('.input-wrapper:first').removeClass('has-error');
            element.parents('.select-wrapper:first').removeClass('has-error');
            element.parents('.checkbox-wrapper:first').removeClass('has-error');
            element.parents('.radio-wrapper:first').removeClass('has-error');
        };

        $validationProvider.invalidCallback = function (element) {
            element.parents('form').find('form-error').addClass('invalid');
            element.parents('.input-wrapper:first').addClass('has-error');
            element.parents('.checkbox-wrapper:first').addClass('has-error');
            element.parents('.radio-wrapper:first').addClass('has-error');
            element.parents('.select-wrapper:first').addClass('has-error');
        };

        $validationProvider.resetCallback = function (element) {
            element.parents('form').find('form-error').removeClass('invalid');
            element.parents('.input-wrapper:first').removeClass('has-error');
            element.parents('.select-wrapper:first').removeClass('has-error');
            element.parents('.checkbox-wrapper:first').removeClass('has-error');
            element.parents('.radio-wrapper:first').removeClass('has-error');
        };

        $validationProvider.showSuccessMessage = false;
    };


    module.exports = {
        fn: validationConfig
    };

})();
