;(function() {
    "use strict";

    function scrollbarConfig (ScrollBarsProvider) {
        // the following settings are defined for all scrollbars unless the
        // scrollbar has local scope configuration
        ScrollBarsProvider.defaults = {
            // scrollButtons: {
            //     scrollAmount: 'auto', // scroll amount when button pressed
            //     enable: true // enable scrolling buttons by default
            // },
            scrollInertia: 400, // adjust however you want
            // axis: 'yx', // enable 2 axis scrollbars by default,
            theme: 'minimal-dark',
            axis: 'y',
            autoHideScrollbar: true
        };
    };

    module.exports = {
        fn: scrollbarConfig
    };

})();
