; (function () {
    'use strict';

    var app = angular.module('tunnel.connected.beneficiaire', [
        'ui.router'
    ]).config(function ($stateProvider) {
        var beneficiaireUrl = 'tunnel.connected.beneficiaire';

        $stateProvider.state(beneficiaireUrl, {
            url: '/mes-commandes/ctr/beneficiaire',
            views: {
                'content': {
                    template: '<beneficiaire-form interlocuteur="interlocuteur" client="client" reprise-panier-result="reprisePanierResult"></beneficiaire-form>',
                    controller: function ($scope, $state, Ressources, interlocuteur, client, reprisePanierResult, PanierService) {
                        var panier = reprisePanierResult.panier;

                        $scope.interlocuteur = interlocuteur;
                        $scope.client = client;
                        $scope.reprisePanierResult = reprisePanierResult;

                        if (panier.Precommandes[0].ReferenceEtapeTunnel === "TUNNEL_CTR_CONNECTED_RECAPITULATIF" && !PanierService.isRedirectedPrecommand()) {
                            $state.go(Ressources.TUNNEL_CTR_CONNECTED_RECAPITULATIF);
                            return;
                        }

                    }
                },
                'header@': {
                    template: '<header-connected breadcrumbs="breadcrumbs"></header-connected>',
                    controller: function ($scope, breadcrumbs) {
                        $scope.breadcrumbs = breadcrumbs;
                    }
                }
            },
            resolve: {
                interlocuteur: function (token, ClientService) {
                    return ClientService.getInterlocuteur();
                },
                client: function (token, ClientService) {
                    return ClientService.getClient();
                },
                reprisePanierResult: function ($q, token, ClientService) {
                    if (!token.ClientRedCart) $q.reject();
                    return ClientService.rechercherPanierEnCours();
                },
                breadcrumbs: function (BreadcrumbService) {
                    return BreadcrumbService.selectBreadcrumb(beneficiaireUrl);
                }
            }
        });
    });

    var componentFolders = ({"beneficiaire-detail.component":require("./beneficiaire-detail.component.js"),"beneficiaire-form.component":require("./beneficiaire-form.component.js"),"beneficiaire-row.component":require("./beneficiaire-row.component.js"),"beneficiaire-sort.component":require("./beneficiaire-sort.component.js"),"beneficiaire":require("./beneficiaire.js")});

    for (var prop in componentFolders) {
        if (prop.indexOf('component') != -1) {
            app.component(componentFolders[prop].name, componentFolders[prop].obj);
        }
    }

    module.exports = app;
})();