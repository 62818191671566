;(function() {
    "use strict";

    function Adresse () {
        return function () {
            this.Adresse1 = null;
            this.Adresse2 = null;
            this.Adresse3 = null;
            this.CodePostal = null;
            this.Ville = null;
            this.EstNormaliseeQAS = null;
        };
    };
    
    module.exports = {
        name: "Adresse",
        fn: Adresse
    }

})();
