;(function () {
    "use strict";

    var breadcrumb = {
        transclude: true,
        templateUrl: componentsPath + 'breadcrumb/breadcrumb.nav.html',
        controller: function (BreadcrumbService, $state, $rootScope, $timeout, $element) {
            var self = this;
            
            self.$onInit = function () {
                $rootScope.$on('$stateChangeSuccess', function (e, toState, toParams, fromState) {
                    findBreadCrumb(toState.name);
                });

            };

            self.$postLink = function () {
                findBreadCrumb($state.current.name);
            };

            function findBreadCrumb(stateName) {
                self.breadcrumbs = BreadcrumbService.selectBreadcrumb(stateName);
                self.selectedBreadcrumb = BreadcrumbService.getBreadcrumb(stateName);
                $timeout(function () {
                    $element.find("a").blur();
                });
            }
        }
    };

    module.exports = {
        name: 'breadcrumb',
        obj: breadcrumb
    };

})();
