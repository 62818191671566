;(function() {
  "use strict";

  function customSort() {
      return function (items, header, index, order) {
          var filtered = [];
          angular.forEach(items, function (item) {
              filtered.push(item);
          });

          if (index !== null && index !== undefined) {
              switch (header[index].type) {
                  case 'currency':
                  case 'number':
                  case 'bool':
                      filtered.sort(function (a, b) {
                          var compare = a[index] - b[index];
                          return order ? compare : -compare;
                      });
                      break;
                  default:
                      filtered.sort(function (a, b) {
                          var compare = a[index] ? a[index].localeCompare(b[index]) : 1;
                          return order ? compare : -compare;
                      });
                      break;
              }
          }

          return filtered;
        }
    };

    module.exports = {
        name: "customSort",
        fn: customSort
    }

})();
