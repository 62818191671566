; (function () {
    'use strict';

    var app = angular.module('tunnel.connected.descriptifs', ['ui.router']).config(function ($stateProvider) {
        var descriptifUrl = 'tunnel.connected.descriptifs';

        $stateProvider.state(descriptifUrl, {
            url: '/mesdescriptifstitre?division&feedlyopen&produit&sousproduit&valeur&parte&partp',
            views: {
                'header@': {
                    template: '<header-actions breadcrumb-label="state"></header-actions>',
                    controller: function ($scope) {
                        $scope.state = 'Mes valeurs faciales';
                    }
                },
                'content': {
                    template: '<descriptifs-titre descriptifs="descriptifs" contrats="contrats"></descriptifs-titre>',
                    controller: function ($scope, descriptifs, contrats) {
                        $scope.descriptifs = descriptifs;
                        $scope.contrats = contrats;
                    }
                }
            },
            resolve: {
                descriptifs: function (token, ClientService, $stateParams, $q) {
                    var deferred = $q.defer();

                    if (!$stateParams.division) deferred.reject();
                    
                    ClientService.rechercherDescriptifs($stateParams.division).then(function (result) {
                        deferred.resolve(result.plain());
                    }).catch(function () {
                        deferred.resolve([]);
                    });

                    return deferred.promise;
                },
                contrats: function (token, ClientService, $stateParams, $q) {
                    var deferred = $q.defer();

                    if (!$stateParams.division) deferred.reject();

                    ClientService.rechercherContrat($stateParams.division).then(function (result) {
                        deferred.resolve(result.plain());
                    }).catch(function () {
                        deferred.resolve([]);
                    });

                    return deferred.promise;
                }
            }
        });
    });

    var componentFolders = ({"descriptif.component":require("./descriptif.component.js"),"descriptif":require("./descriptif.js")});

    for (var prop in componentFolders) {
        if (prop.indexOf('component') != -1) {
            app.component(componentFolders[prop].name, componentFolders[prop].obj);
        }
    }

    module.exports = app;
})();