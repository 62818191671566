; (function () {
    "use strict";

    var detailCommand = {
        bindings: {
            beneficiaries: '<',
            precommand: '<'
        },
        templateUrl: componentsPath + 'detail/detail.order.html',
        controller: function ($scope) {
            var self = this;

            initVariables();

            self.$onInit = function () {
                $scope.$watch(angular.bind(self, function () {
                    return self.beneficiaries;
                }), function (newBenefs, oldBenefs) {
                    if (self.precommand && newBenefs) {
                        self.facialValue = self.precommand.ValeurFacialeParDefaut / 100;

                        self.benefsHaveNewCard = _.filter(newBenefs, function(b) {
                            return b.Statut === 0 || b.TypeCreation === 1;
                        });

                        self.allTitleQuantities = _.sumBy(newBenefs, function(b) {
                            return (!b.NombreTitres || isNaN(b.NombreTitres)) ? 0 : parseInt(b.NombreTitres, 10);
                        });

                        self.amountTotal = self.allTitleQuantities * self.facialValue;

                        self.externalBenefs = _.filter(newBenefs, function(b) {
                            return b.Statut === 0;
                        });
                    } else {
                        self.beneficiaries = [];
                        initVariables();
                    }
                }, true);
            };

            function initVariables() {
                self.facialValue = 0;
                self.allTitleQuantities = 0;
                self.amountTotal = 0;
                self.externalBenefs = [];
                self.benefsHaveNewCard = [];
            }
            
        }
    };

    module.exports = {
        name: 'beneficiaireDetail',
        obj: detailCommand
    };

})();
