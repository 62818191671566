module.exports=﻿{
    "config": [
        {
            "persona": "_connected",
            "states": [ 
                {
                    "current": "tunnel-ctr-connected.beneficiaire",
                    "next": "tunnel-ctr-connected.recapitulatif",
                    "previous": null
                },
                {
                    "current": "tunnel-ctr-connected.recapitulatif",
                    "next": "tunnel-ctr-connected.confirmation",
                    "previous": "tunnel-ctr-connected.beneficiaire"
                },
                {
                    "current": "tunnel-ctr-connected.confirmation",
                    "next": null,
                    "previous": "tunnel-ctr-connected.recapitulatif"
                }
            ]
        },
        {
            "persona": "_defaults",
            "states": [
                {
                    "current": "tunnel-cma.startCma",
                    "next": "tunnel-cma.siret",
                    "previous": "tunnel-cma.startCma"
                },
                {
                    "current": "tunnel-cma.siret",
                    "next": null,
                    "previous": "tunnel-ctr.simulSso"
                },
            ]
        },
        {
            "persona": "Directeur",
            "states": [
                {
                    "current": "tunnel-cma.startCma",
                    "next": "tunnel-cma.siret",
                    "previous": null,
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.siret",
                    "next": "tunnel-cma.adresse",
                    "previous": "tunnel-cma.startCma",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.adresse",
                    "next": "tunnel-cma.coordonnees",
                    "previous": "tunnel-cma.siret",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.coordonnees",
                    "next": "tunnel-cma.nombre-titres-salaries",
                    "previous": "tunnel-cma.adresse",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.nombre-titres-salaries",
                    "next": "tunnel-cma.valeur",
                    "previous": "tunnel-cma.coordonnees",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.valeur",
                    "next": "tunnel-cma.prestations",
                    "previous": "tunnel-cma.nombre-titres-salaries",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.prestations",
                    "next": "tunnel-cma.recapitulatif",
                    "previous": "tunnel-cma.valeur",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.recapitulatif",
                    "next": "tunnel-cma.confirmation",
                    "previous": "tunnel-cma.prestations",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.confirmation",
                    "next": null,
                    "previous": "tunnel-cma.recapitulatif",
                    "onlyConnectedUser": false
                }
            ]
        },
        {
            "persona": "Secrétaire",
            "states": [
                {
                    "current": "tunnel-cma.startCma",
                    "next": "tunnel-cma.siret",
                    "previous": null,
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.siret",
                    "next": "tunnel-cma.adresse",
                    "previous": "tunnel-cma.startCma",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.adresse",
                    "next": "tunnel-cma.coordonnees",
                    "previous": "tunnel-cma.siret",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.coordonnees",
                    "next": "tunnel-cma.nombre-titres-salaries",
                    "previous": "tunnel-cma.adresse",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.nombre-titres-salaries",
                    "next": "tunnel-cma.valeur",
                    "previous": "tunnel-cma.coordonnees",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.valeur",
                    "next": "tunnel-cma.prestations",
                    "previous": "tunnel-cma.nombre-titres-salaries",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.prestations",
                    "next": "tunnel-cma.recapitulatif",
                    "previous": "tunnel-cma.valeur",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.recapitulatif",
                    "next": "tunnel-cma.confirmation",
                    "previous": "tunnel-cma.prestations",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.confirmation",
                    "next": null,
                    "previous": "tunnel-cma.recapitulatif",
                    "onlyConnectedUser": false
                }
            ]
        },
        {
            "persona": "Assistant",
            "states": [
                {
                    "current": "tunnel-cma.startCma",
                    "next": "tunnel-cma.siret",
                    "previous": null,
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.siret",
                    "next": "tunnel-cma.adresse",
                    "previous": "tunnel-cma.startCma",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.adresse",
                    "next": "tunnel-cma.coordonnees",
                    "previous": "tunnel-cma.siret",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.coordonnees",
                    "next": "tunnel-cma.nombre-titres-salaries",
                    "previous": "tunnel-cma.adresse",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.nombre-titres-salaries",
                    "next": "tunnel-cma.valeur",
                    "previous": "tunnel-cma.coordonnees",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.valeur",
                    "next": "tunnel-cma.prestations",
                    "previous": "tunnel-cma.nombre-titres-salaries",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.prestations",
                    "next": "tunnel-cma.recapitulatif",
                    "previous": "tunnel-cma.valeur",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.recapitulatif",
                    "next": "tunnel-cma.confirmation",
                    "previous": "tunnel-cma.prestations",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.confirmation",
                    "next": null,
                    "previous": "tunnel-cma.recapitulatif",
                    "onlyConnectedUser": false
                }
            ]
        },
        {
            "persona": "Comptable",
            "states": [
                {
                    "current": "tunnel-cma.startCma",
                    "next": "tunnel-cma.siret",
                    "previous": null,
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.siret",
                    "next": "tunnel-cma.adresse",
                    "previous": "tunnel-cma.startCma",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.adresse",
                    "next": "tunnel-cma.coordonnees",
                    "previous": "tunnel-cma.siret",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.coordonnees",
                    "next": "tunnel-cma.nombre-titres-salaries",
                    "previous": "tunnel-cma.adresse",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.nombre-titres-salaries",
                    "next": "tunnel-cma.valeur",
                    "previous": "tunnel-cma.coordonnees",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.valeur",
                    "next": "tunnel-cma.prestations",
                    "previous": "tunnel-cma.nombre-titres-salaries",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.prestations",
                    "next": "tunnel-cma.recapitulatif",
                    "previous": "tunnel-cma.valeur",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.recapitulatif",
                    "next": "tunnel-cma.confirmation",
                    "previous": "tunnel-cma.prestations",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.confirmation",
                    "next": null,
                    "previous": "tunnel-cma.recapitulatif",
                    "onlyConnectedUser": false
                }
            ]
        },
        {
            "persona": "Gérant",
            "states": [
                {
                    "current": "tunnel-cma.startCma",
                    "next": "tunnel-cma.siret",
                    "previous": null,
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.siret",
                    "next": "tunnel-cma.adresse",
                    "previous": "tunnel-cma.startCma",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.adresse",
                    "next": "tunnel-cma.coordonnees",
                    "previous": "tunnel-cma.siret",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.coordonnees",
                    "next": "tunnel-cma.nombre-titres-salaries",
                    "previous": "tunnel-cma.adresse",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.nombre-titres-salaries",
                    "next": "tunnel-cma.valeur",
                    "previous": "tunnel-cma.coordonnees",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.valeur",
                    "next": "tunnel-cma.prestations",
                    "previous": "tunnel-cma.nombre-titres-salaries",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.prestations",
                    "next": "tunnel-cma.recapitulatif",
                    "previous": "tunnel-cma.valeur",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.recapitulatif",
                    "next": "tunnel-cma.confirmation",
                    "previous": "tunnel-cma.prestations",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.confirmation",
                    "next": null,
                    "previous": "tunnel-cma.recapitulatif",
                    "onlyConnectedUser": false
                }
            ]
        },
        {
            "persona": "Autre",
            "states": [
                {
                    "current": "tunnel-cma.startCma",
                    "next": "tunnel-cma.siret",
                    "previous": null,
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.siret",
                    "next": "tunnel-cma.adresse",
                    "previous": "tunnel-cma.startCma",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.adresse",
                    "next": "tunnel-cma.coordonnees",
                    "previous": "tunnel-cma.siret",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.coordonnees",
                    "next": "tunnel-cma.nombre-titres-salaries",
                    "previous": "tunnel-cma.adresse",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.nombre-titres-salaries",
                    "next": "tunnel-cma.valeur",
                    "previous": "tunnel-cma.coordonnees",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.valeur",
                    "next": "tunnel-cma.prestations",
                    "previous": "tunnel-cma.nombre-titres-salaries",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.prestations",
                    "next": "tunnel-cma.recapitulatif",
                    "previous": "tunnel-cma.valeur",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.recapitulatif",
                    "next": "tunnel-cma.confirmation",
                    "previous": "tunnel-cma.prestations",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-cma.confirmation",
                    "next": null,
                    "previous": "tunnel-cma.recapitulatif",
                    "onlyConnectedUser": false
                }
            ]
        }
    ]
}
