;(function() {
  "use strict";

    function companyService (Restangular) {
        var baseSocietes = Restangular.all('societe');

        var rechercherParSiret = function (siret, email) {
            return baseSocietes.get('bySiret', { siret: siret, email: email });
        };

        var rechercherParCodePostalRaisonSociale = function (codePostal, raisonSociale) {
            return baseSocietes.get('byRaisonSociale', { codePostal: codePostal, raisonSociale: raisonSociale });
        };

        var rechercherVilleParCodePostal = function (codePostal) {
            return baseSocietes.get('villes', { codePostal: codePostal });
        };

        return {
            rechercherParSiret: rechercherParSiret,
            rechercherParCodePostalRaisonSociale: rechercherParCodePostalRaisonSociale,
            rechercherVilleParCodePostal: rechercherVilleParCodePostal
        };
    };

    module.exports = {
        name: "CompanyService",
        fn: companyService
    }

})();
