;(function() {
  "use strict";

    function wordingService ($http, Restangular, localStorageService, Ressources) {
        self.wordings = null;

        var allWording = function () {            
            if (self.wordings === null) {                
                self.wordings = $http.get(Ressources.CLICK_URL + 'Documents_Espace_Client/VEL-CTR/wordings_VEL_CTR.json').then((r) => r.data);                               
            }

            return self.wordings;
        };

        var updateWording = function (wording) {
            return Restangular.one('wordings', wording.Id).patch(wording);
        };

        var refreshWording = function () {
            return Restangular.one('wordings').put();
        };

        var getWording = function (code) {
            var wordings = localStorageService.get(Ressources.WORDING_KEY);
            var wording = _.find(wordings, function (x) {
                return x.Code == code;
            });

            return wording.Label;
        };

        return {
            allWording: allWording,
            updateWording: updateWording,
            refreshWording: refreshWording,
            getWording: getWording
        };
    };

    module.exports = {
        name: "WordingService",
        fn: wordingService
    }

})();
