; (function () {
    "use strict";

    var popupConfirmation = {
        bindings:{
            okFn: '&',
            cancelFn: '&'
        },
        transclude: true,
        templateUrl: componentsPath + 'popup/popup.confirmation.html',
        controller: function () {
            var self = this;
        }
    };

    module.exports = {
        name: 'popupConfirmation',
        obj: popupConfirmation
    };

})();
