;(function() {
    "use strict";

    function carousel() {
        return {
            restrict: 'A',
            scope: {},
            priority: 9999,
            link: function (scope, el, attrs) {
                var options = {
                    slidesToShow: parseInt(attrs.carouselSlidestoshow, 10),
                    slidesToScroll: parseInt(attrs.carouselSlidestoscroll, 10),
                    dots: (attrs.carouselDots === 'true'),
                    arrows: (attrs.carouselArrows === 'true')
                };
                $(el).slick(options);
            }
        };
    };

    module.exports = {
        name: 'carousel',
        fn: carousel
    };

})();
