;(function() {
    "use strict";

    function Contract () {
        return function () {
            this.Id = null;
            this.IdClientProspect = null;
            this.IdProduitIdProduit = null;
            this.CodeContratType = null;
            this.Statut = null;
            this.ConditionCommercialeProduitActive = null;
        };
    };

    module.exports = {
        name: "Contract",
        fn: Contract
    }

})();
