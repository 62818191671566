; (function () {
    "use strict";
    var popupValeurs = {
        bindings: {
            okFn: '&',
            koFn: '&',
            cancelFn: '&'
        },
        transclude: true,
        templateUrl: componentsPath + 'popup/popup.valeurs.html',
        controller: function () {
            var self = this;
        }
    };

    module.exports = {
        name: 'popupValeurs',
        obj: popupValeurs
    };

})();
