;(function() {
    "use strict";

    var toaster = {
        bindings: {
            active: '<',
            message: '<',
            icon: '<'
        },
        template: function ($element, $attrs) {
            return [
                '<div class="toaster" ng-class="{ \'active\': $ctrl.active }">',
                    '<div class="flex-me">',
                        '<p class="toaster-message clearfix">',
                            '<i class="float-left" ng-if="$ctrl.icon">',
                                '<svg class="icon" ng-class="$ctrl.icon">',
                                    '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="{{ $ctrl.iconUrl }}"></use>',
                                '</svg>',
                            '</i>',
                            '<img class="float-left" src="Content/img/toast-loading.gif" width="80" height="58" ng-if="!$ctrl.icon" />',
                            '<span class="float-right"><strong>{{ $ctrl.message.header }}</strong><small>{{ $ctrl.message.caption }}</small></span>',
                        '</p>',
                    '</div>',
                    '<div class="toaster-background"></div>',
                '</div>'
            ].join('');
        },
        controller: function ($timeout) {

            var self = this,
                timer = new Date(),
                delay = 99999999; // milliseconds

            this.$onInit = function () {

                this.iconUrl = self.icon ? 'Content/img/svg.svg#' + self.icon : '';

            };

            this.$onChanges = function (changes) {
                
                if (!self.message) {
                    self.active = false;
                };

                if (self.active) {
                    _disableToaster(changes);
                };

                if(changes.icon) {
                    this.iconUrl = 'Content/img/svg.svg#' + changes.icon.currentValue;
                };

            };

            var _disableToaster = function (options) {
                $timeout(function(){
                    self.active = false;
                }, delay);
            }

        }
    };

    module.exports = {
        name: 'toaster',
        obj: toaster
    };

})();
