;(function() {
    "use strict";

    function authHeader(AuthenticationService, Restangular) {
        Restangular.addFullRequestInterceptor(function(element, operation, what, url, headers, queryParams) {
            if (AuthenticationService.isAuthenticated()) {
                headers = headers || {};
                headers.Authorization = 'Bearer ' + AuthenticationService.getToken().access_token;
            }
            return { headers: headers };
        });
    }

    module.exports = {
        runFn: authHeader
    };
})();