;(function () {
  "use strict";

    var animationFolders = (function () {var f = require("./index.js");f["index"]=require("./index.js");f["slideInOut"]=require("./slideInOut.js");f["toggleFooter"]=require("./toggleFooter.js");return f;})(),
        app = angular.module('app.animation', []);

    for (var prop in animationFolders) {
        if (prop !== "index") {
            var animationObject = require("./" + prop + ".js");
            app.animation(animationObject.className, animationObject.fn);
        }
    }

    module.exports = app;

})();
