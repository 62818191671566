; (function () {
    "use strict";

    function AddressFillingService() {

        var taille_max_champs_adresse = 32;

        var controlAddressMax32ParChamps = function(address, complementLivraison) {
            var retourData = {};

            var adresseRue = address;

            if (adresseRue.length > taille_max_champs_adresse) {
                var decoupageAt = adresseRue.lastIndexOf(' ', taille_max_champs_adresse);
                
                retourData.Adresse1 = adresseRue.substr(0, decoupageAt).trim(); // Valorisation Adresse1

                var adresseRestante = adresseRue.substr(decoupageAt).trim();
                
                if (adresseRestante.length >= taille_max_champs_adresse) {
                    adresseRestante += " " + complementLivraison;
                    var bouclage = adresseRestante.lastIndexOf(' ', taille_max_champs_adresse);

                    if (bouclage == 0) {
                        retourData.Adresse2 = adresseRestante.substr(0).trim(); // Valorisation Adresse2
                        adresseRestante = "";
                    } else {
                        retourData.Adresse2 = adresseRestante.substr(0, bouclage).trim(); // Valorisation Adresse2
                        adresseRestante = adresseRestante.substr(bouclage).trim();
                    }

                    var bouclage = adresseRestante.lastIndexOf(' ', taille_max_champs_adresse);
                    if (adresseRestante.length > taille_max_champs_adresse) {
                        retourData.Adresse3 = adresseRestante.substr(0, bouclage).trim(); // Valorisation Adresse3
                    } else if (adresseRestante.length > 0) {
                        retourData.Adresse3 = adresseRestante.substr(0).trim(); // Valorisation Adresse3
                    }
                } else {
                    retourData.Adresse2 = adresseRestante.trim(); // Valorisation Adresse2
                    retourData.Adresse3 = complementLivraison;
                }
            } else {
                retourData.Adresse1 = adresseRue.trim(); // Valorisation Adresse1
                retourData.Adresse2 = complementLivraison;
            }
            return retourData;
        };

        return {
            controlAddressMax32ParChamps: controlAddressMax32ParChamps
        };
    };

    module.exports = {
        name: "AddressFillingService",
        fn: AddressFillingService
    }
})();