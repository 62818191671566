; (function () {
    "use strict";

    function inputMask() {
        var _link = function (scope, el, attr, ngModel) {
            $(el).inputmask(scope.inputMaskOptions);
        };

        return {
            restrict: 'A',
            link: _link,
            require: 'ngModel',
            scope: {
                inputMaskOptions: '='
            }
        };
    };

    module.exports = {
        name: 'inputMask',
        fn: inputMask
    };

})();
