; (function () {
    "use strict";

    var pageBenefs = {
        bindings: {
            interlocuteur: '<',
            client: '<',
            reprisePanierResult: '<'
        },
        templateUrl: componentsPath + 'beneficiary/beneficiary.form.html',
        controller: function ($scope, $injector, $timeout, $state, ngDialog, Beneficiaire, localStorageService, BreadcrumbService, ClientService, PanierService, AuthenticationService, StateService, Ressources) {
            var self = this,
                $validationProvider = $injector.get('$validation'),
                $filter = $injector.get('$filter'),
                duplicatedBenefs = [],
                inMemoryBenef,
                temporariesBenefsForDeletion,
                panierId,
                precommande,
                isReinitializing = false;
            
            self.formPrefix = 'benefDetail';

            self.$onInit = function () {
                self.panier = self.reprisePanierResult.panier;

                self.searchBoxActive = false;
                precommande = self.panier.Precommandes[0];

                afterPageLoaded();

                /* INIT NEW BENEF */
                initNewBenef();

                /* SORT TABLE */
                self.sortProperty = 'Nom';
                self.sortAscendent = true;
                self.sortProperties = [{
                    prop: 'Nom',
                    label: $filter('codeToWording')('PANIER_CHAMP1'),
                    tooltip: null
                },
                {
                    prop: 'Prenom',
                    label: $filter('codeToWording')('PANIER_CHAMP2'),
                    tooltip: null
                },
                {
                    prop: 'NombreTitres',
                    label: $filter('codeToWording')('PANIER_CHAMP3'),
                    tooltip: 'TOOLTIP_NOMBRE_JOURS'
                },
                {
                    prop: 'DateNaissance',
                    label: $filter('codeToWording')('PANIER_CHAMP4'),
                    tooltip: null
                }];
                self.toggle = function (data) {
                    self.sortProperty = data.label;
                    self.sortAscendent = data.reverse;
                };

                /* VALIDATION_KO */
                reinitAllStatesAndErrors();

                /* SEARCH */
                self.searchText = null;

                /* EVENT LISTENER */
                $scope.$on('clearAllValidation', function (event) {
                    self.serverKo = false;
                    self.validBenefKoCuzOfAdding = false;
                    self.validBenefKoCuzOfNoneBenef = false;
                    self.validBenefKoCuzOfModifying = false;
                    self.validBenefKoCuzOfEmptyPanier = false;
                    self.duplicatedBenef = false;
                });

                self.openBenefInsertion = function () {
                    if (self.onEdit) return;

                    self.revalidateAllBenefs(true);

                    $timeout(function () {
                        initNewBenef(20);
                        self.duplicatedBenef = false;
                        self.onAdd = true;
                        self.onEdit = false;
                    });
                };

                self.validateBenefs = function () {
                    return submitBeneficiariesInformation();
                };

                self.reinitPanier = function () {
                    isReinitializing = true;
                    $scope.$emit('toggleLoadingToaster', {
                        header: "Réinitialisation en cours",
                        caption: "",
                    });

                    self.searchText = null;
                    self.searchBoxActive = false;
                    temporariesBenefsForDeletion = _.filter(self.panier.Beneficiaires, function (b) {
                        return b.Statut === 0;
                    });
                    panierId = self.panier.Id;
                    self.panier = null;
                    StateService.setPanier(self.panier);
                    reinitPanier();
                }
            };

            function deleteNewBenef(newBenef) {
                var index = temporariesBenefsForDeletion.indexOf(newBenef);
                PanierService.supprimerSalarieConnected(panierId, precommande.Id, newBenef).then(function () {
                    temporariesBenefsForDeletion.splice(index, 1);
                    reinitPanier();
                });
            }

            function reinitPanier() {
                if (temporariesBenefsForDeletion.length > 0) {
                    deleteNewBenef(temporariesBenefsForDeletion[0]);
                } else {
                    self.revalidateAllBenefs(true);
                    reinitAllStatesAndErrors();
                    PanierService.annulerPanier(panierId).then(function () {
                        ClientService.rechercherPanierEnCours().then(function (result) {
                            self.panier = result.panier;
                            precommande = self.panier.Precommandes[0];
                            afterPageLoaded();
                        });
                    });
                }
            }

            function reinitAllStatesAndErrors() {
                self.onAdd = false;
                self.onEdit = false;
                reinitAllErrors();
            }

            function reinitAllErrors() {
                self.duplicatedBenef = false;
                self.validBenefKoCuzOfNoneBenef = false;
                self.validBenefKoCuzOfModifying = false;
                self.validBenefKoCuzOfEmptyPanier = false;
                self.validBenefKoCuzOfAdding = false;
                self.serverKo = false;
            }

            self.reinitAllErrors = reinitAllErrors;

            /***************************
             *          LOGIQUE
             ***************************/

            self.searchByNames = function (e) {
                if (!self.searchText) return true;
                return e.Nom.toLowerCase().indexOf(self.searchText.toLowerCase()) != -1
                || e.Prenom.toLowerCase().indexOf(self.searchText.toLowerCase()) != -1;
            };

            self.onCJChecked = function () {
                PanierService.enregistrerPrecommande(self.panier.Id, self.panier.Precommandes[0].Id, {
                    DateAutorisationDonneesTiers: moment().toISOString()
                }).then(function (precommandeResult) {
                    self.panier.Precommandes[0].DateAutorisationDonneesTiers = precommandeResult.plain().DateAutorisationDonneesTiers;
                    StateService.setPanier(self.panier);
                    self.checkJuridique = true;
                }).catch(function (error) {
                    self.checkJuridique = false;
                });
            };

            self.ignoreErreur = function () {
                if (!self.onEdit) {
                    self.addBenef(true);
                } else {
                    self.updateBeneficiary(inMemoryBenef, true);
                }
            };

            self.addBenefAnyway = function () {
                $scope.$broadcast('submit-newBeneficiaryForm');
                self.validBenefKoCuzOfAdding = false;
            };

            self.cancelBenefAdding = function () {
                self.revalidateAllBenefs(true);
                $timeout(function () {
                    initNewBenef(20);
                    self.validBenefKoCuzOfAdding = false;
                });
            };

            self.removeBeneficiary = function () {
                var index = self.panier.Beneficiaires.indexOf(self.selectedBenef);
                self.supprimeEncours = true;
                PanierService.supprimerBeneficiaireConnected(self.panier.Id, self.panier.Precommandes[0].Id, self.selectedBenef).then(function () {
                    self.panier.Beneficiaires.splice(index, 1);
                    self.supprimeEncours = false;

                    StateService.setPanier(self.panier);
                }).catch(function (err) {
                    self.serverKo = true;
                    self.supprimeEncours = false;
                });
            };

            self.openDeletePopupConfirmation = function (benef) {
                if (self.supprimeEncours) return;
                self.selectedBenef = benef;
                ngDialog.open({
                    template: '<popup-confirmation ok-fn="$ctrl.removeBeneficiary()" cancel-fn="closeThisDialog()"></popup-confirmation>',
                    plain: true,
                    scope: $scope
                });
            };

            self.addBenef = function (ignoreDuplication) {
                if (self.ajoutEncours) return;
                self.revalidateAllBenefs(false);
                $timeout(function () {
                    if (!ignoreDuplication && verifyDuplicatedBenef(self.newBenef, false)) {
                        return;
                    }

                    var _postedBenef = angular.extend(new Beneficiaire(), self.newBenef);

                    self.ajoutEncours = true;

                    PanierService.creerBeneficiaireConnected(self.panier.Id, self.panier.Precommandes[0].Id, _postedBenef).then(function (creerBeneficiaireResult) {
                        var _addedBenef = creerBeneficiaireResult.plain();
                        _addedBenef.editable = false;
                        _addedBenef.options = false;
                        _addedBenef.DateNaissance = self.newBenef.DateNaissance;
                        self.panier.Beneficiaires.push(_addedBenef);
                        self.ajoutEncours = false;
                        initNewBenef(_postedBenef.NombreTitres);

                        /* Supprimer l'erreur de dédoublonnage */
                        self.revalidateAllBenefs(true);
                        reinitAllStatesAndErrors();

                        StateService.setPanier(self.panier);
                    }).catch(function (err) {
                        self.serverKo = true;
                        self.ajoutEncours = false;
                    });
                });
            };

            self.updateBeneficiary = function (benef, ignoreDuplication) {
                if (self.updateEncours) return;

                for (var i = 0; i < duplicatedBenefs.length; i++) {
                    if (duplicatedBenefs[i].Id != benef.Id) {
                        self.revalidateBenef(self.formPrefix + duplicatedBenefs[i].Id, false);
                    }
                }

                $timeout(function () {
                    var changedProperties = propertyToUpdate(benef);

                    if (!changedProperties || changedProperties.length === 0) {
                        $scope.$broadcast('closeEdit' + benef.Id);
                        return;
                    }

                    var changeNomOuPrenom = _.findIndex(changedProperties, function (p) {
                        return p === "Nom" || p === "Prenom";
                    });

                    if (!ignoreDuplication && changeNomOuPrenom != -1 && verifyDuplicatedBenef(benef, true)) {
                        inMemoryBenef = benef;
                        return;
                    }

                    var _benefUpdates = _.pick(benef, changedProperties);

                    self.updateEncours = true;

                    PanierService.enregistrerBeneficiaireConnected(self.panier.Id, self.panier.Precommandes[0].Id, benef.Id, _benefUpdates).then(function (updateBeneficiaryResult) {
                        $scope.$broadcast('closeEdit' + benef.Id);
                        self.updateEncours = false;
                        /* Supprimer l'erreur de dédoublonnage */
                        self.revalidateAllBenefs(true);
                        self.duplicatedBenef = false;
                        StateService.setPanier(self.panier);
                    }).catch(function (err) {
                        self.serverKo = true;
                        self.updateEncours = false;
                    });
                });
            };

            function submitBeneficiariesInformation() {
                checkCanSubmit();
                if (self.validBenefKoCuzOfAdding || self.validBenefKoCuzOfNoneBenef || self.validBenefKoCuzOfModifying || self.validBenefKoCuzOfEmptyPanier) {
                    return;
                }

                return saveReferenceClientTunnel(precommande, StateService.getNext($state.current.name)).then(function () {
                    StateService.setPanier(self.panier);
                    return StateService.next();
                });
            };

            function checkCanSubmit() {
                self.validBenefKoCuzOfAdding = newBeneficiaryIsBeingModified(self.newBenef);
                self.validBenefKoCuzOfNoneBenef = self.panier.Beneficiaires.length === 0;
                self.validBenefKoCuzOfModifying = self.onEdit;
                var titlesQuantity = _.sumBy(self.panier.Beneficiaires, function (b) {
                    return parseInt(b.NombreTitres || 0, 10);
                });
                var newCardsQuantity = _.sumBy(self.panier.Beneficiaires, function (b) {
                    return b.TypeCreation === 1 || b.Statut === 0 ? 1 : 0
                });
                self.validBenefKoCuzOfEmptyPanier = titlesQuantity === 0 && newCardsQuantity === 0;
            }

            function afterPageLoaded() {
                if (!precommande.ReferenceEtapeTunnel) {
                    saveReferenceClientTunnel(precommande, $state.current.name);
                }
                if (!self.panier.Beneficiaires) {
                    self.panier.Beneficiaires = [];
                }

                self.checkJuridique = false;

                if (self.reprisePanierResult.reprisePanier && !isReinitializing) {
                    StateService.setPanier(self.panier);
                    $scope.$emit('toggleLoadingToaster', {
                        header: $filter('codeToWording')('PANIER_REPRISE_CTR'),
                        caption: "",
                    });
                    $timeout(function () {
                        $scope.$emit('closeLoadingToaster');
                    }, 2000);
                } else {
                    $scope.$emit('closeLoadingToaster');
                    isReinitializing = false;
                }
            }


            /***************************
            *          END LOGIQUE
            ****************************/

            /***************************
             *          UI
             ***************************/

            self.revalidateAllBenefs = function (addBenefIncluded) {
                $scope.$broadcast('reinitForm', addBenefIncluded);
            };

            self.revalidateBenef = function (formName, addBenefIncluded) {
                $scope.$broadcast('reinitForm' + formName, addBenefIncluded);
            };

            /***************************
             *          END UI
             ***************************/

            /***************************
             *      PRIVATE FUNCTION
             ***************************/

            /* Vérification si on est en train de saisir un nouveau bénéficiaire (pour la validation de la page) */
            function newBeneficiaryIsBeingModified(benef) {
                return benef.Nom
                    || benef.Prenom
                    || benef.DateNaissance;
            }

            function initNewBenef(nombreTitres) {
                self.newBenef = new Beneficiaire();
                self.newBenef.NombreTitres = nombreTitres || Ressources.QUOTITE_PAR_DEFAUT;
                self.newBenef.editable = true;
                self.newBenef.options = false;
                self.newBenef.Statut = 0;
                self.newBenef.Id = 0;
            }

            /* Retourner la liste des propriétés qui est changé la valeur */
            function propertyToUpdate(benef) {
                return _.filter(['Nom', 'Prenom', 'NombreTitres', 'DateNaissance'], function (p) {
                    return benef[p] !== self.tempBenef[p];
                });
            }

            function findDuplicatedBenefs(benef) {
                if (!self.panier.Beneficiaires || self.panier.Beneficiaires.length == 0) return [];

                return _.filter(self.panier.Beneficiaires, function (b) {
                    return b.Nom === benef.Nom && b.Prenom === benef.Prenom
                });
            }

            /* Vérifier si ce bénéficiaire est un doublon */
            function verifyDuplicatedBenef(benef, isOnEdit) {

                duplicatedBenefs = findDuplicatedBenefs(benef);

                var arrLength = duplicatedBenefs.length;

                self.duplicatedBenef = duplicatedBenefs && ((arrLength > 1 && isOnEdit) || (arrLength >= 1 && !isOnEdit));

                if (self.duplicatedBenef) {
                    if (!isOnEdit) {
                        $scope.$broadcast('firstName_-1-invalidate');
                        $scope.$broadcast('lastName_-1-invalidate');
                    } else {
                        $scope.$broadcast('firstName_' + benef.Id + '-invalidate');
                        $scope.$broadcast('lastName_' + benef.Id + '-invalidate');
                    }

                    for (var i = 0, length = duplicatedBenefs.length; i < length; i++) {
                        $scope.$broadcast('firstName_' + duplicatedBenefs[i].Id + '-invalidate');
                        $scope.$broadcast('lastName_' + duplicatedBenefs[i].Id + '-invalidate');
                    }

                    return true;
                }
                return false;
            };

            /* Sauvegarder la référence de l'état du tunnel de pré-commande */
            function saveReferenceClientTunnel(precommande, state_name) {
                var patchedPrecommande = {
                    "ReferenceEtapeTunnel": StateService.getStateNameByValue(state_name)
                };

                return PanierService.enregistrerPrecommande(precommande.IdPanier, precommande.Id, patchedPrecommande).then(function (precommandeResult) {
                    self.panier.Precommandes[0].ReferenceEtapeTunnel = precommandeResult.plain().ReferenceEtapeTunnel;
                });
            }


            /***************************
             *      END PRIVATE FUNCTION
             ***************************/
        }
    };

    module.exports = {
        name: 'beneficiaireForm',
        obj: pageBenefs
    };

})();
