; (function () {
  "use strict";

  function clickService() {

    var getBaseUrlForClick = function () {
      var urlToReturn = null;
      var dahost = (window.location.host === 'commande.ticketrestaurant.fr') ? 'Prod' : (window.location.host === 'commande.preprod.ticketrestaurant.fr') ? 'Preprod' : 'Recette'; // "Recette", "Preprod" ou "Prod"
      switch (dahost) {
          case 'Prod':
              urlToReturn = 'https://click.edenred.fr/Documents_Espace_Client';
              break;
          case 'Preprod':
              urlToReturn = 'https://click.preprod.edenred.fr/Documents_Espace_Client';
              break;
          default:
              urlToReturn = 'https://click.dev.edenred.fr/Documents_Espace_Client';
      }
      return urlToReturn;
    };


    var clickMillesimeInfo = function () {
      return new Promise(function(resolve, reject){
        var urlForClick = getBaseUrlForClick() + '/Commande/text-millesime.json';
        var xhr = new XMLHttpRequest;
        xhr.addEventListener("error", reject);
        xhr.addEventListener("load", resolve);
        xhr.open("GET", urlForClick);
        xhr.send(null);
      })
    };

    var getUrl = function (path) {
      return new Promise(function(resolve, reject){
        var urlForClick = getBaseUrlForClick() + '/' + path;
        var xhr = new XMLHttpRequest;
        xhr.addEventListener("error", reject);
        xhr.addEventListener("load", resolve);
        xhr.open("GET", urlForClick);
        xhr.send(null);
      });
    };

    var getLimiteExo = function() {
      return new Promise(function (resolve, reject) {
        var urlForClick = getBaseUrlForClick() +'/Commande/limite-exoneration-tr.json';
        var xhr = new XMLHttpRequest;
        xhr.addEventListener("error", reject);
        xhr.addEventListener("load", resolve);
        xhr.open("GET", urlForClick);
        xhr.send(null);
      })
    };

    return {
      clickMillesimeInfo: clickMillesimeInfo,
      getUrl: getUrl,
      getBaseUrlForClick: getBaseUrlForClick,
      getLimiteExo: getLimiteExo
    };
  }
  module.exports = {
    name: "ClickService",
    fn: clickService
  };
})();
