; (function () {
    "use strict";

    function errorRedirections($rootScope, $state, $stateParams, $window, localStorageService, Ressources, Restangular) {
        Restangular.setErrorInterceptor(
            function (response) {
                //Comportement sp�cifique pour la recherche de SIRET en cas d'erreur 500
                if (response.config.url.indexOf('byRaisonSociale') === -1) {
                    switch (response.status) {
                        case 500:
                            var token;
                            var storedToken = localStorageService.get(Ressources.TOKEN_KEY);
                            if (storedToken) token = storedToken.access_token;
                            localStorage.clear();
                            sessionStorage.clear();
                            $state.transitionTo('500', { previousState: $state.current.name, token: token }, { location: false });
                            // code d'erreur custom � cr�er dans le back pour rediriger sur l'intro dans certains cas
                            $rootScope.$broadcast('removeLoaderApp');
                            break;
                        case 501:
                            $state.transitionTo('501', {}, { location: false });
                            break;
                        case 502:
                            $state.transitionTo('502', {}, { location: false });
                            break;
                        case 503:
                            $state.transitionTo('503', {}, { location: false });
                            break;
                    }
                }

                return response; // stop la promise
            }
        );
    }

    module.exports = {
        runFn: errorRedirections
    };

})();
