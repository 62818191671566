; (function () {
    "use strict";

    var precommandeConfirmation = {
        bindings: {
            items: '<'
        },
        templateUrl: componentsPath + 'timeline/timeline.order.html',
        controller: function ($scope, $state, $window, Ressources, StateService, localStorageService) {
            var self = this;

            self.$postLink = function () {
                StateService.setPanier(null);
                localStorageService.clearAll();
            };
        },
        controllerAs: 'preCmdConfirm'
    };

    module.exports = {
        name: 'timeline',
        obj: precommandeConfirmation
    };

})();
