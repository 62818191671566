;(function() {
  "use strict";

    function lexiqueService (Lexique) {
        var dictionnaire = dictionnaire || Lexique;

        var chargerValeur = function (code) {
            return dictionnaire.hasOwnProperty(code) ? dictionnaire[code] : null;
        };

        return {
            chargerValeur: chargerValeur
        };
    };

    module.exports = {
        name: "LexiqueService",
        fn: lexiqueService
    }

})();
