; (function () {
    'use strict';

    var app = angular.module('tunnel.connected.recapitulatif', [
        'ui.router'
    ]).config(function ($stateProvider) {
        var recapitulatifUrl = 'tunnel.connected.recapitulatif';

        $stateProvider.state(recapitulatifUrl, {
            url: '/mes-commandes/ctr/recapitulatif?cbReturn',
            views: {
                'content': {
                    template: '<recapitulatif-connected numero-division="numeroDivision" tabs="tabs" mode-payment="modePaiement" reprise-panier-result="reprisePanierResult"></recapitulatif-connected>',
                    controller: function ($scope, numeroDivision, tabs, reprisePanierResult, modePaiement, PanierService) {
                        $scope.numeroDivision = numeroDivision;
                        $scope.modePaiement = modePaiement.CodeModePaiement;
                        $scope.tabs = tabs;
                        $scope.reprisePanierResult = reprisePanierResult;
                        PanierService.setIsRedirectedPrecommand(true);
                    }
                },
                'header@': {
                    template: '<header-connected breadcrumbs="breadcrumbs"></header-connected>',
                    controller: function ($scope, breadcrumbs) {
                        $scope.breadcrumbs = breadcrumbs;
                    }
                }
            },
            resolve: {
                numeroDivision: function (token, AuthenticationService) {
                    return AuthenticationService.getNumeroDivisionClient();
                },
                tabs: function (FeedlyStateService) {
                    return FeedlyStateService.getEtapes(recapitulatifUrl);
                },
                breadcrumbs: function (BreadcrumbService) {
                    return BreadcrumbService.selectBreadcrumb(recapitulatifUrl);
                },
                reprisePanierResult: function ($q, token, ClientService) {
                    if (!token.ClientRedCart) $q.reject();
                    return ClientService.rechercherPanierEnCours();
                },
                modePaiement: function (token, ClientService) {
                    return ClientService.rechercherModePaiement().then(function (result) {
                        return result.plain();
                    });
                }
            }
        });
    });

    var componentFolders = ({"recapitulatif-connected.component":require("./recapitulatif-connected.component.js"),"recapitulatif":require("./recapitulatif.js")});

    for (var prop in componentFolders) {
        if (prop.indexOf('component') != -1) {
            app.component(componentFolders[prop].name, componentFolders[prop].obj);
        }
    }

    module.exports = app;
})();