;(function() {
    "use strict";

    function wordingTransform($compile, localStorageService, WordingService) {

        var compile = function (tElement, tAttributes, transcludeFn) {
            return {
                pre: function prelink(scope, element, attributes, controller, transcludeFn) {

                    var retour = null,
                        listeWording = localStorageService.get('wordings'),
                        codeWordingToTranslate = scope.codeWording ? scope.codeWording : attributes.codeWording;

                    //var codeWordingToTranslate = scope.codeWording ? scope.codeWording : attributes.codeWording;

                    
                    angular.forEach(listeWording, function (value, key) {
                        if (value.Code == codeWordingToTranslate) {
                            retour = value.Label;
                        }
                    });

                    if (retour == null) {
                        scope.wordingCalculated = codeWordingToTranslate;
                    } else {
                        angular.forEach(scope.paramsWording, function (value, key) {
                            var re = new RegExp("\\{" + (key) + "\\}", "i");

                            retour = retour.replace(re, value);
                        });
                        scope.wordingCalculated = retour;
                    }

                },
                post: function postlink(scope, element, attributes, controller, transcludeFn) {
                
                }
            };
        };

        return {
            restrict: 'A',
            compile: compile,
            template: '<span ng-bind-html="wordingCalculated"></span>',
            scope: {
                paramsWording: '=',
                codeWording: '='
            }
        };
    }

    module.exports = {
        name: 'wording',
        fn: wordingTransform
    };

})();
