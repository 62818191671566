;(function() {
  "use strict";

    // ReSharper disable once InconsistentNaming
    function searchWording () {
        return function (datas, code) {
            var retour = [];

            angular.forEach(datas, function (value, key) {
                if (typeof (code) == 'undefined') {
                    retour.push(value);
                } else {
                    if (!code || (value.Code.toUpperCase().indexOf(code.toUpperCase()) != -1) || (value.Label.toLowerCase().indexOf(code.toLowerCase()) != -1)) {
                        retour.push(value);
                    }
                }
            });

            return retour;
        }

    };

    module.exports = {
        name: "searchWording",
        fn: searchWording
    }

})();
