;(function() {
    "use strict";

    function Partenaire() {
        return function () {
            this.Id = null;
            this.Nom = null;
            this.Logo = null;
            this.IdDescriptif = null;
        };
    };

    module.exports = {
        name: "Partenaire",
        fn: Partenaire
    }

})();
