; (function () {
    "use strict";

    var beneficiary = {
        bindings: {
            benef: '<',
            text: '<',
            formName: '@',
            onSubmit: '&',
            onRemove: '&'
        },
        require: {
            beneficiaireForm: '?^^'
        },
        transclude: true,
        templateUrl: componentsPath + 'beneficiary/beneficiary.row.html',
        controller: function ($scope, $element, $transclude, $timeout, $validation) {
            var self = this,
                tempBenef;
            self.$onInit = function () {
                $transclude($scope, function (transcludeEl) {
                    $element.find('.actions-wrapper').append(transcludeEl);
                });

                /* One benef */
                $scope.$on('reinitForm' + self.formName, function (event, addBenefIncluded) {
                    reinitForm(addBenefIncluded);
                });

                /* All benefs */
                $scope.$on('reinitForm', function (event, addBenefIncluded) {
                    reinitForm(addBenefIncluded);
                });

                $scope.$on('closeEdit' + self.benef.Id, function (event) {
                    outOfUpdate();
                });

                $scope.$on('submit-' + self.formName, function (event) {
                    self.submit();
                });
            };

            self.onUpdateTitleQuantity = function (event) {
                self.benef.NombreTitres = event.count;
                $timeout(function () {
                    $validation.validateField($scope[self.formName], event.fieldName);
                });
            };

            self.openOptions = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                self.benef.options = !self.benef.options;
            };

            self.closeOption = function () {
                self.benef.options = false;
            };

            self.openEdit = function () {
                self.benef.options = false;
                if (self.benef.editable
                        || (
                            self.beneficiaireForm
                            && self.beneficiaireForm.onEdit
                            )
                    )
                    return;

                if (self.beneficiaireForm) {
                    self.beneficiaireForm.reinitAllErrors();
                    self.beneficiaireForm.newBenef.editable = false;
                    self.beneficiaireForm.onEdit = true;
                    self.beneficiaireForm.tempBenef = angular.copy(self.benef);
                }

                tempBenef = angular.copy(self.benef);
                self.benef.editable = true;
            };

            self.submit = function ($event) {
                if ($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                }
                $validation.validate($scope[self.formName])
                .success(function () {
                    self.onSubmit({
                        $benef: angular.copy(self.benef)
                    });
                });
            };

            self.removeBenef = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                self.onRemove({
                    $benef: self.benef
                });
                self.closeOption();
            };

            self.cancelModificationBenef = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                reinitBenef();
                outOfUpdate();
            };

            /* Réinitialiser le bénéficiaire quand on veut supprimer la modification actuelle */
            function reinitBenef() {
                var copiedTempBenef = angular.copy(tempBenef);
                self.benef.Nom = copiedTempBenef.Nom;
                self.benef.Prenom = copiedTempBenef.Prenom;
                self.benef.NombreTitres = copiedTempBenef.NombreTitres;
                self.benef.DateNaissance = moment(copiedTempBenef.DateNaissance).toISOString();

                $timeout(function () {
                    $validation.validate($scope[self.formName]);
                });
                tempBenef = null;
            };

            /* Ferme la modification d'un bénéficiaire */
            function outOfUpdate() {
                self.benef.editable = false;
                self.benef.options = false;
                reinitForm(false);
                if (self.beneficiaireForm) {
                    self.beneficiaireForm.newBenef.editable = true;
                    self.beneficiaireForm.onEdit = false;
                }
            }

            function reinitForm(addBenefIncluded) {
                if (self.formName === "newBeneficiaryForm") {
                    if (addBenefIncluded) {
                        $validation.reset($scope[self.formName]);
                    } else {
                        $validation.validate($scope[self.formName]);
                    }
                } else {
                    $validation.validate($scope[self.formName]);
                    self.benef.editable = false;
                    self.benef.options = false;
                }
            }
        }
    };

    module.exports = {
        name: 'beneficiary',
        obj: beneficiary
    };

})();
