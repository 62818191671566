;(function() {
    "use strict";

    function configWhiteList ($sceDelegateProvider) {
        $sceDelegateProvider.resourceUrlWhitelist([
            'self',
            'https://click.edenred.fr/**',
            'https://click.preprod.edenred.fr/**',
            'https://click.dev.edenred.fr/**'

        ]);
    };

    module.exports = {
        fn: configWhiteList
    };

})();
