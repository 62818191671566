;(function () {
  "use strict";

    var componentFolders = (function () {var f = require("./index.js");f["header.actions"]=require("./header.actions.js");f["header.app"]=require("./header.app.js");f["header.blank"]=require("./header.blank.js");f["header.cma"]=require("./header.cma.js");f["header.connected"]=require("./header.connected.js");f["header.connection"]=require("./header.connection.js");f["header.tunnel"]=require("./header.tunnel.js");f["index"]=require("./index.js");return f;})(),
        app = angular.module('app.component', []);

    for (var prop in componentFolders) {
        if (prop !== "index") {
            var componentObject = require("./" + prop + ".js");
            app.component(componentObject.name, componentObject.obj);
        }
    }

    module.exports = app;

})();
