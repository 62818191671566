;(function() {
    "use strict";

    function stateService($state, $q, StateMachineConfig, StateMachineCMAConfig, StepperConfiguration, StepperCMAConfiguration, Ressources, localStorageService, PanierService) {
        var self = this;
        var RESOLVERS = [];
        RESOLVERS[Ressources.TUNNEL_CTR_SIRET] = function (resolveFunc) {
            return panier && panier.Client && panier.Client.Value && panier.Client.Value.Siret != null
                ? resolveFunc(Ressources.TUNNEL_CTR_SIRET)
                : Ressources.TUNNEL_CTR_SIRET;
        }
        var configStepper = StepperConfiguration;
        var statesConf = StateMachineConfig;

        var panier;
        var referenceEtapeTunnel;
        var typeTunnelActif;

        _init();

        /**
         * Initialise le state
         */
        function _init() {
            panier = localStorageService.get(Ressources.PANIER_KEY) || {};
            panier.beneficiaires = localStorageService.get(Ressources.BENEFICIARIES_KEY) || [];
            referenceEtapeTunnel = localStorageService.get(Ressources.REFERENCE_ETAPE_TUNNEL_KEY);

            if (referenceEtapeTunnel && referenceEtapeTunnel.split(':').length > 0) {
                var typeTunnel = referenceEtapeTunnel.split(':')[0];
                //if (typeTunnel != getTypeTunnel()) {
                    changeTypeTunnel(typeTunnel);
                //}
            }

            typeTunnelActif = getTypeTunnel();
        }

        

        /**
         * Retourne le panier courant
         * Remarque: retourne une copie et non pas le panier par référence pour éviter la modification intempestive de son état.
         */
        function getPanier() {
            return angular.copy(panier);
        }
        
        /**
         * Retourne la configuration actuelle du feedly
         */
        function getStepperConf() {
            return configStepper;
        }

        /*
         * Met à jour le panier courant
         * @param {object} panier
         */
        function setPanier(pan) {
            panier = pan;
            localStorageService.set(Ressources.PANIER_KEY, pan);
        }

        /**
         * Retourne le persona courant
         */
        function getPersona(state) {
            state = state || $state.current.name;
            if (state.indexOf("connected") !== -1) return "_connected";
            var interlocuteur = panier != null && panier.Client != null && panier.Client.Value != null && panier.Client.Value.Interlocuteur.length > 0 ? panier.Client.Value.Interlocuteur[0] : null;
            return interlocuteur != null && interlocuteur.Fonction
                ? Ressources.PERSONAS.properties[interlocuteur.Fonction].Libelle 
                : null;
        }
        
        /**
         * Retourne l'état suivant
         * @param {string} state - Etat courant par défaut
         */
        function getPrev(state) {
            state = state || $state.current.name;
            var states = _.find(statesConf.config, { 'persona': getPersona(state) || "_defaults" }).states;
            var curState = _.find(states, { 'current': state });
            var prevState = curState
                ? curState.previous
                : states[0].current;
            prevState = prevState || state;
            return RESOLVERS[prevState]
                ? RESOLVERS[prevState](getPrev)
                : prevState;
        }

        /**
         * Retourne l'état précédent
         * @param {string} state - Etat courant par défaut
         */
        function getNext(state) {
            state = state || $state.current.name;
            var states = _.find(statesConf.config, { 'persona': getPersona(state) || "_defaults" }).states;
            if (!_.find(states, { 'current': state })) {
                _init();
                var states = _.find(statesConf.config, { 'persona': getPersona(state) || "_defaults" }).states;
            }
            var nextState = _.find(states, { 'current': state }).next;
            nextState = nextState || state;
            return RESOLVERS[nextState]
                ? RESOLVERS[nextState](getNext)
                : nextState;
        }

        /**
         * Retourne l'état en cours
         */
        function getCurrent() {
            return $state.current.name;
        }

        /**
         * Navigue vers l'état précédent
         */
        function prev() {
            var prevState = getPrev();
            if (!isAvailable(prevState))
                return $q.reject('unavailable state');
            return $state.go(prevState);
        }

        /**
         * Navigue vers l'état suivant
         * @param {bool} saveState - Persiste ou non l'état du panier (ReferenceEtapeTunnel)
         */
        function next() {
            var nextState = getNext();
            if (!isCompleted($state.current.name))
                return $q.reject('unavailable state');

            if (_shouldUpdateReference(_getReferenceEtapeTunnel(), nextState)) {
                var nextStateName = getStateNameByValue(nextState);
                var refTunnel = typeTunnelActif + ":" + nextStateName;
                return PanierService.enregistrerPrecommande(panier.Id, panier.Precommandes[0].Id, { ReferenceEtapeTunnel: refTunnel })
                    .then(function() {
                        panier.Precommandes[0].ReferenceEtapeTunnel = refTunnel;
                        setPanier(panier);
                        _setReferenceEtapeTunnel(nextStateName);

                        return $state.go(nextState);
                    });
            } else {
                return $state.go(nextState);
            }
        }

        /**
         * Navigue vers un état donné si celui-ci est disponible
         * @param {string} state
         */
        function go(state, params) {
            if (!isAvailable(state))
                return $q.reject('unavailable state');
            return $state.go(state, params);
        }

        /**
         * Reprend la navigation vers le dernier état disponible
         */
        function restore() {
            if (!panier || !panier.Precommandes || !panier.Precommandes[0] || !panier.Precommandes[0].ReferenceEtapeTunnel)
                return $state.go(Ressources.TUNNEL_CTR_INTRO);

            if (!_getReferenceEtapeTunnel()) {
                _setReferenceEtapeTunnel(panier.Precommandes[0].ReferenceEtapeTunnel);
            }

            // Au cas où la référence étape tunnel serait invalide, on se place directement à la fin pour remonter vers la dernière étape disponible.
            var state = Ressources[_getReferenceEtapeTunnel] || Ressources.TUNNEL_CTR_RECAPITULATIF;
            if (isAvailable(state)) {
                return $state.go(state);
            } else {
                var states = _.find(statesConf.config, { 'persona': getPersona(state) || "_defaults" }).states;
                var curState = _.find(states, { 'current': state });
                while (curState && curState.previous) {
                    if (isAvailable(curState.current)) {
                        return $state.go(curState.current);
                    }
                    curState = _.find(states, { 'current': curState.previous });
                }
                return $state.go(Ressources.TUNNEL_CTR_INTRO);
            }
        }

        /**
         * Réinitialise le state en relisant les storage, utile notamment dans les cas de reprise panier
         */
        function reinit() {
            _init();
        }

        /** 
         * Réinitialise entièrement le panier en cours
         */
        function reset() {
            panier = null;
            referenceEtapeTunnel = null;
            localStorageService.remove(Ressources.PANIER_KEY, Ressources.BENEFICIARIES_KEY, Ressources.REFERENCE_ETAPE_TUNNEL_KEY, Ressources.INTERLOCUTEUR_DIVISIONS);
            localStorage.removeItem(Ressources.APPLICATION_PREFIX + Ressources.PANIER_KEY);
        }

        /**
         * Retourne la complétion d'un état
         * @param {string} state - Etat à tester, prends l'état courant si non renseigné
         */
        function isCompleted(state) {
            state = state || $state.current.name;
            var precommande = panier != null && panier.Precommandes != null && panier.Precommandes.length > 0 ? panier.Precommandes[0] : null;
            var beneficiaires = localStorageService.get(Ressources.BENEFICIARIES_KEY);
            var client = panier != null && panier.Client != null && panier.Client.Value != null ? panier.Client.Value : null;
            var interlocuteur = panier != null && panier.Client != null && panier.Client.Value != null && panier.Client.Value.Interlocuteur.length > 0 ? panier.Client.Value.Interlocuteur[0] : null;
            switch (state) {
                case Ressources.TUNNEL_CTR_INTRO:
                    return precommande != null && precommande.NbBeneficiaireDevis != null;
                case Ressources.TUNNEL_CTR_PERSONA:
                    return interlocuteur != null && interlocuteur.Email != null && interlocuteur.Fonction != null;
                case Ressources.TUNNEL_CTR_VALEURFACIALE:
                    return precommande != null && precommande.ValeurFacialeParDefaut != null && precommande.PartPatronaleParDefaut != null;
                case Ressources.TUNNEL_CTR_BENEFICIAIRE:
                    return beneficiaires != null && precommande != null && beneficiaires.length > 0;
                case Ressources.TUNNEL_CTR_SIRET:
                    return client != null && client.Siret != null;
                case Ressources.TUNNEL_CTR_ADRESSE_SAISIE:
                    return client != null && client.RaisonSociale != null && client.Adresse != null;
                case Ressources.TUNNEL_CTR_COORDONNEES:
                    return interlocuteur != null && interlocuteur.Civilite != null && interlocuteur.Nom != null && interlocuteur.Prenom != null && interlocuteur.Tel != null;
                case Ressources.TUNNEL_CTR_PRESTATIONS:
                    return precommande != null && precommande.Contrat != null;
                case Ressources.TUNNEL_CTR_RECAPITULATIF:
                    return precommande != null && precommande.IdInterlocuteurCGV != null;
                case Ressources.TUNNEL_CTR_PAIEMENT:
                    return precommande != null && precommande.IdModePaiement != null;
                case Ressources.TUNNEL_CMA_INTRO:
                    return true;
                case Ressources.TUNNEL_CMA_SSO:
                    return precommande != null;
                case Ressources.TUNNEL_CMA_SIRET:
                    return interlocuteur != null && interlocuteur.Email != null && interlocuteur.Fonction != null && client.Siret != null;
                case Ressources.TUNNEL_CMA_ADRESSE:
                    return client != null && client.RaisonSociale != null && client.Adresse != null;
                case Ressources.TUNNEL_CMA_COORDONNEES:
                    return interlocuteur != null && interlocuteur.Civilite != null && interlocuteur.Nom != null && interlocuteur.Prenom != null && interlocuteur.Tel != null;
                case Ressources.TUNNEL_CMA_NB_BENEFICIAIRE:
                    return precommande != null && precommande.NbBeneficiaireDevis != null && precommande.NbTitresDevis != null;
                case Ressources.TUNNEL_CMA_VALEUR:
                    return precommande != null && precommande.ValeurFacialeParDefaut != null && precommande.PartPatronaleParDefaut != null;
                case Ressources.TUNNEL_CMA_PRESTATIONS:
                    return precommande != null && precommande.Contrat != null;
                case Ressources.TUNNEL_CMA_RECAPITULATIF:
                    return precommande != null && precommande.IdInterlocuteurCGV != null;
                default:
                    // Pour éviter de bloquer la navigation, on considère que tout le reste est toujours disponible
                    return true;
            }
        }

        /**
         * Retourne la disponibilité d'un état
         * @param {string} state - Etat à tester
         */
        function isAvailable(state) {
            state = state || $state.current.name;

            if (state == Ressources.TUNNEL_CTR_INTRO
                || state == Ressources.TUNNEL_CMA_SSO
                || state == Ressources.TUNNEL_CTR_SIMULATION
                || state == Ressources.TUNNEL_CTR_CONNECTED_DESCRIPTIFS_TITRE
                || (state.indexOf("tunnel-ctr") == -1 && state.indexOf("tunnel-cma") == -1))
                return true;

            var states = _.find(statesConf.config, { 'persona': getPersona(state) || "_defaults" }).states;
            var curState = _.find(states, { 'current': state });
            if (!curState)
                return false;
            while (curState && curState.previous) {
                if (!isCompleted(curState.previous))
                    return false;
                curState = _.find(states, { 'current': curState.previous });
            }
            return true;
        }

        /**
         * Retourne le state (au sens route) associé à la référence d'une page
         * @param {string} state
         * @todo Etudier le réel besoin d'exposer cette méthode...
         */
        function getStateNameByValue(state) {
            return _.findKey(Ressources, function(x) { return x == state; });
        }

        /**
          * Récupère l'étape référence
          */
        function _getReferenceEtapeTunnel() {
            return referenceEtapeTunnel
                ? referenceEtapeTunnel.split(':')[1]
                : "TUNNEL_CTR_INTRO";
        }

        /**
          * Met à jour l'étape référence
          * @param {string} ref - Etape référence
          */
        function _setReferenceEtapeTunnel(ref) {
            ref = typeTunnelActif + ":" + ref;
            referenceEtapeTunnel = ref;
            localStorageService.set(Ressources.REFERENCE_ETAPE_TUNNEL_KEY, ref);
        }

        /**
         * Calcule le besoin de mettre à jour l'étape référence enregistrée dans RedCart
         * @param {string} reference - Clé de l'étape référencée dans RedCart
         * @param {string} nextState - State vers lequel on navigue
         */
        function _shouldUpdateReference(reference, nextState) {
            var refState = Ressources[reference];
            if (refState == nextState || nextState === Ressources.TUNNEL_CTR_CONFIRMATION)
                return false;

            var states = _.find(statesConf.config, { 'persona': getPersona() || "_defaults" }).states;
            var state = _.find(states, { 'current': nextState });
            if (state) {
                while (state && state.previous) {
                    if (state.previous === refState)
                        return true;
                    state = _.find(states, { 'current': state.previous });
                }
            }
            return false;
        }

        /**
         * Retourne le type de tunnel courant - Commande par défaut
         */
        function getTypeTunnel() {
            var etape = referenceEtapeTunnel;
            return etape
                ? etape.split(':')[0]
                : Ressources.TYPES_TUNNEL.Commande;
        }

        /**
         * Change le type de tunnel courant
         * @param {string} typeTunnel
         */
        function changeTypeTunnel(typeTunnel) {
            typeTunnelActif = typeTunnel;
            switch (typeTunnel) {
                case Ressources.TYPES_TUNNEL.Commande:
                    statesConf = StateMachineConfig;
                    configStepper = StepperConfiguration;
                    break;
                case Ressources.TYPES_TUNNEL.CMA:
                    statesConf = StateMachineCMAConfig;
                    configStepper = StepperCMAConfiguration;
                    break;
            }

            var etape = referenceEtapeTunnel;
            var parts = null;
            var isArray = false;
            if (etape) {
                parts = etape.split(':')[0];
                isArray = true;
            }
            else {
                parts = typeTunnelActif;
            }
            if (parts != Ressources.TYPES_TUNNEL.Commande) {
                referenceEtapeTunnel = typeTunnelActif + ":" + (isArray ? parts[1] : "tunnel-cma.startCma");
                localStorageService.set(Ressources.REFERENCE_ETAPE_TUNNEL_KEY, referenceEtapeTunnel);
            }
        }

        return {
            getPanier: getPanier,
            setPanier: setPanier,
            getPersona: getPersona,
            getPrev: getPrev,
            getNext: getNext,
            getCurrent: getCurrent,
            prev: prev,
            next: next,
            go: go,
            restore: restore,
            reinit: reinit,
            reset: reset,
            isCompleted: isCompleted,
            isAvailable: isAvailable,
            getStateNameByValue: getStateNameByValue,
            getTypeTunnel: getTypeTunnel,
            changeTypeTunnel: changeTypeTunnel,
            getStepperConf: getStepperConf
        };
    }

    module.exports = {
        name: "StateService",
        fn: stateService
    }
})();