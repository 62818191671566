; (function () {
    "use strict";

    function customFilter() {
        return function (items, search, filterColumnsIndex, header) {
            var filtered = _.filter(items, function (item, index) {
                if (search && !_.some(item, function (cell, idx) {
                    return header[idx].format && header[idx].format(cell).toLowerCase().indexOf(search.toLowerCase()) != -1;
                })) return false;

                var passed = true;
                _.forEach(filterColumnsIndex, function (idx) {
                    switch (header[idx].type) {
                        case "currency":
                        case "number":
                            passed = item[idx] == parseFloat(header[idx].filterValue.replace(',', '.'));
                            break;
                        default:
                            passed = item[idx] == header[idx].filterValue;
                            break;
                    }
                    if (!passed) return false;
                });

                return passed;
            });

            return filtered;
        }
    };

    module.exports = {
        name: "customFilter",
        fn: customFilter
    }

})();
