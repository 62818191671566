; (function () {
    'use strict';

    var tunnelConnected = {
        bindings: {
            token: '<'
        },
        templateUrl: 'Content/views/tunnel-connected/tunnel-connected.html',
        controller: function ($scope, $rootScope, $filter, $state, $window, localStorageService, AuthenticationService, Ressources, deviceDetector) {
            var self = this;

            self.sidebarToggle = function (expanded) {
                self.sidebarExpanded = expanded;
            };

            $scope.$emit('appLoaded');

            if (typeof (self.token != undefined)) {
                /* VERIFIER SI LOCALSTORAGE OU RESOLVE */
                if (!self.token.Domaine) {
                    self.token = self.token.data;
                }
                self.infosClient = self.token;
                $scope.$emit('onClientConnected', self.token);
            }
        }
    };

    var app = angular.module('tunnel.connected', [
        'ui.router'
    ]);

    var componentFolders = ({"beneficiaire":({"beneficiaire-detail.component":require("./beneficiaire\\beneficiaire-detail.component.js"),"beneficiaire-form.component":require("./beneficiaire\\beneficiaire-form.component.js"),"beneficiaire-row.component":require("./beneficiaire\\beneficiaire-row.component.js"),"beneficiaire-sort.component":require("./beneficiaire\\beneficiaire-sort.component.js"),"beneficiaire":require("./beneficiaire\\beneficiaire.js")}),"connected":require("./connected.js"),"descriptif":({"descriptif.component":require("./descriptif\\descriptif.component.js"),"descriptif":require("./descriptif\\descriptif.js")}),"factures":({"factures.component":require("./factures\\factures.component.js"),"factures":require("./factures\\factures.js")}),"parametrage":({"parametrage.component":require("./parametrage\\parametrage.component.js"),"parametrage":require("./parametrage\\parametrage.js")}),"recapitulatif":({"recapitulatif-connected.component":require("./recapitulatif\\recapitulatif-connected.component.js"),"recapitulatif":require("./recapitulatif\\recapitulatif.js")}),"sidebar.component":require("./sidebar.component.js"),"timeline":({"timeline.component":require("./timeline\\timeline.component.js"),"timeline":require("./timeline\\timeline.js")})});

    for (var prop in componentFolders) {
        if (prop.indexOf('component') != -1) {
            app.component(componentFolders[prop].name, componentFolders[prop].obj);
        }
    }

    app.component('tunnelConnected', tunnelConnected)
        .config(function ($stateProvider) {
            $stateProvider.state('tunnel.connected', {
                abstract: true,
                url: '',
                views: {
                    'content': {
                        template: '<tunnel-connected token="token"></tunnel-connected>',
                        controller: function ($scope, token) {
                            $scope.token = token;
                        }
                    },
                    'header@': {
                        template: '<header-connected></header-connected>'
                    },
                    /* 'sidebar@': {
                         template: '<sidebar-connected token="token" on-update="onUpdate(expanded)"></sidebar-connected>',
                         controller: function ($scope, token) {
                             $scope.token = token;

                             $scope.onUpdate = function (expanded) {
                                 $scope.$emit('onToggleSidebar', expanded);
                             }
                         }
                     }*/
                },
                resolve: {
                    token: function ($location, localStorageService, Ressources, ClientService, AuthenticationService) {
                        var path = $location.path();                        
                        var _setClient = function (token) {
                            ClientService.setClient({
                                Id: token.ClientRedCart,
                                ClientId:token.ClientId,
                                NumeroDivision: token.Domaine.split(';')[0],
                                RaisonSociale: token.RaisonSociale,
                                Siret: token.Siret,
                                CodeMarche: 1
                            });
                        }

                        var _setInterlocuteur = function (token) {
                            ClientService.setInterlocuteur({
                                Nom: token.Nom,
                                Prenom: token.Prenom,
                                Email: token.Email,
                                Phone: token.Phone
                            });
                        }

                        function _setClientAndInterlocuteur(token) {
                            _setClient(token);
                            _setInterlocuteur(token);
                        }
                        var token = AuthenticationService.getToken();
                        if (!token) {
                            return AuthenticationService.getAccessTokenV1(path).then(function (result) {
                                if (result) {
                                    _setClientAndInterlocuteur(result.data);
                                    return result.data;
                                }
                                return;
                            });
                        } else {
                            _setClientAndInterlocuteur(token)
                            return token;
                        }
                    }
                }
            });
        });



    module.exports = app;
})();