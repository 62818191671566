; (function () {
    "use strict";

    function beneficiaryController($scope, $state, $injector, localStorageService, StateService, PanierService, ContratsService, ValidationService, ClientService, Beneficiaire, Ressources) {
        var self = this,
            DEFAULT_TITLE_QUANTITY = 20,
            BIRTHDATE_MIN = 16,
            BIRTHDATE_MAX = 85,
            validBenef = true,
            tempBenef = null,
            temp_updateBenef = null,
            orderTable = [],
            startState = null,
            now = new Date(),
            propertyAllowed = ['Nom', 'Prenom', 'DateNaissance', 'NombreTitres'],
            $validationProvider = $injector.get('$validation'),
            $timeout = $injector.get('$timeout'),
            duplicatedBenefs = [],
            panier = StateService.getPanier();


        self.indexOfModifyingBenef = -1;
        self.onEdit = false;
        self.quantityTotal = 0;
        self.ajoutEncours = false;

        /* Affichage des erreurs */
        self.duplicatedBenef = false;
        self.validBenefKoCuzOfAdding = false;
        self.validBenefKoCuzOfModifying = false;
        self.validBenefKoCuzOfNoneBenef = false;
        self.validBenefKoCuzOfZeroTitles = false;
        self.addBenefKoCuzOfMaxNbrBenef = false;
        self.serverKo = false;
        /* Affichage des erreurs */

        self.panier = panier;
        self.beneficiaries = localStorageService.get(Ressources.BENEFICIARIES_KEY) || [];

        if (self.beneficiaries.length) {
            self.beneficiaries = $.map(self.beneficiaries, function (e, i) {
                //e.DateNaissance = moment(e.DateNaissance).format("DD/MM/YYYY");
                e.readonly = true;
                return e;
            });
        }

        self.newBenef = new Beneficiaire();

        if (self.beneficiaries.length > 0)
            self.newBenef.NombreTitres = self.beneficiaries[self.beneficiaries.length - 1].NombreTitres;
        else
            self.newBenef.NombreTitres = DEFAULT_TITLE_QUANTITY;

        if (self.beneficiaries.length > 0) {
            _countTitles();
        }

        var _renderOnSuccess = function (nbrTitres) {
            self.newBenef = new Beneficiaire();
            self.newBenef.NombreTitres = nbrTitres;
            _countTitles();
        };

        /* Quand on clique sur le bouton 'Ignore erreur' */
        self.ignoreErreur = function () {
            if (self.indexOfModifyingBenef === -1) {
                self.addBenef($scope.newBeneficiaryForm, true);
            } else {
                self.updateBeneficiary(temp_updateBenef, true);
            }
        };

        /* Ajouter d'un nouveau bénéficiaire */
        self.addBenef = function (form, ignoreDuplication) {
            self.serverKo = false;
            reinitDuplicatedBenefs();
            $validationProvider.validate(form, [ignoreDuplication])
            .success(_successAddBenefValidation);
        };

        var _successAddBenefValidation = function (ignoreDuplication) {
            if (!ignoreDuplication) {
                duplicatedBenefs = verifyDuplicatedBenef(self.newBenef);
                if (duplicatedBenefs.length) {
                    $scope.$broadcast('DEDOUBLONNAGE_KO_AJOUT_NOM-error-from-server', '');
                    $scope.$broadcast('DEDOUBLONNAGE_KO_AJOUT_PRENOM-error-from-server', '');
                    for (var i = 0, length = duplicatedBenefs.length; i < length; i++) {
                        $scope.$broadcast('DEDOUBLONNAGE_KO_MODIFIER_NOM_' + duplicatedBenefs[i].index + '-error-from-server', '');
                        $scope.$broadcast('DEDOUBLONNAGE_KO_MODIFIER_PRENOM_' + duplicatedBenefs[i].index + '-error-from-server', '');
                    }

                    self.duplicatedBenef = true;
                    return false;
                }
            }

            if (self.beneficiaries.length === 50) {
                self.addBenefKoCuzOfMaxNbrBenef = true;
                return false;
            }

            var _postBenef = angular.extend(new Beneficiaire(), self.newBenef);
            //_postBenef.DateNaissance = moment.utc(self.newBenef.DateNaissance, 'DD/MM/YYYY').toDate();

            var _benefOptions = {
                readonly: true,
                options: false
            };

            _.assign(_postBenef, _benefOptions);

            if (self.ajoutEncours) return;
            self.ajoutEncours = true;

            PanierService.creerBeneficiaire(self.panier.Id, self.panier.Precommandes[0].Id, _postBenef).then(function (creerBeneficiaireResult) {
                var _addedBenef = creerBeneficiaireResult.plain();
                _addedBenef.readonly = true;
                _addedBenef.options = false;
                //_addedBenef.DateNaissance = self.newBenef.DateNaissance;
                self.beneficiaries.push(_addedBenef);
                $scope.$emit(Ressources.EVENT_UPDATE_BENEFICIAIRES, self.beneficiaries);
                self.panier.Precommandes[0].IdInterlocuteurCGV = null;
                $scope.$emit(Ressources.EVENT_UPDATE_PANIER, self.panier);
                _renderOnSuccess(_postBenef.NombreTitres);
                $scope.newBeneficiaryForm.$setDirty(false);
                $scope.newBeneficiaryForm.$setPristine(true);

                if (ignoreDuplication) {
                    reinitDuplicatedBenefs();
                }
                if (!self.panier.CjAccepted) {
                    self.panier.CjAccepted = true;
                    PanierService.enregistrerPrecommande(self.panier.Id, self.panier.Precommandes[0].Id, { DateAutorisationDonneesTiers: moment().toISOString() }).then(function () {
                        $scope.$emit(Ressources.EVENT_UPDATE_PANIER, self.panier);
                    });
                }
                self.ajoutEncours = false;
                self.serverKo = false;
            }).catch(function (err) {
                self.serverKo = true;
                self.ajoutEncours = false;
            });
        };

        /* Tri */
        self.sortTable = {
            label: null,
            reverse: false,
            toggle: function (label) {
                this.label = label;
                this.reverse = !this.reverse;
            }
        }

        /*  */
        self.openOptions = function (benef) {
            angular.forEach(self.beneficiaries, function (b) {
                if (b != benef)
                    b.options = false;
            });
            benef.options = !benef.options;
            event.preventDefault();
        };

        self.closeOption = function (index) {
            if (self.beneficiaries[index])
                self.beneficiaries[index].options = false;
        };

        /* Ouverture la modification du bénéficiaire */
        self.openEdit = function (editBenef, idx) {
            tempBenef = angular.copy(editBenef);
            editBenef.readonly = false;
            editBenef.options = false;
            self.indexOfModifyingBenef = idx;
            self.onEdit = !self.onEdit;
        };

        /* Modification du bénéficiaire */
        self.updateBeneficiary = function (benef, ignoreDouble) {
            self.serverKo = false;
            if (!checkModifiedBenef(benef)) {
                outOfUpdate(benef);
                return false;
            }

            $validationProvider.validate($scope.beneficiariesForm['benefDetail' + self.indexOfModifyingBenef], [benef, ignoreDouble])
            .success(_successUpdateBenefValidation);
        };

        self.reload = function() {
            $state.reload();
        };

        var _successUpdateBenefValidation = function (benef, ignoreDouble) {
            if (!ignoreDouble) {
                temp_updateBenef = benef;
                duplicatedBenefs = verifyDuplicatedBenef(benef);

                if (duplicatedBenefs.length) {
                    for (var i = 0, length = duplicatedBenefs.length; i < length; i++) {
                        $scope.$broadcast('DEDOUBLONNAGE_KO_MODIFIER_NOM_' + duplicatedBenefs[i].index + '-error-from-server', '');
                        $scope.$broadcast('DEDOUBLONNAGE_KO_MODIFIER_PRENOM_' + duplicatedBenefs[i].index + '-error-from-server', '');
                    }

                    $scope.$broadcast('DEDOUBLONNAGE_KO_MODIFIER_NOM_' + self.indexOfModifyingBenef + '-error-from-server', '');
                    $scope.$broadcast('DEDOUBLONNAGE_KO_MODIFIER_PRENOM_' + self.indexOfModifyingBenef + '-error-from-server', '');
                    self.duplicatedBenef = true;
                    return false;
                }
            }

            var _propertyToUpdate = propertyToUpdate(benef);

            var _benefUpdates = _.pick(benef, propertyToUpdate(benef));

            //if (_propertyToUpdate.indexOf('DateNaissance') != -1) {
            //    _benefUpdates.DateNaissance = moment(benef.DateNaissance, 'DD/MM/YYYY').toDate();
            //}

            PanierService.enregistrerBeneficiaire(self.panier.Id, self.panier.Precommandes[0].Id, benef.Id, _benefUpdates).then(function (updateBeneficiaryResult) {
                var updatedBeneficiary = updateBeneficiaryResult.plain();
                updatedBeneficiary.readonly = true;
                updatedBeneficiary.options = false;
                updatedBeneficiary.DateNaissance = benef.DateNaissance;
                self.beneficiaries.splice(self.beneficiaries.indexOf(benef), 1, updatedBeneficiary);
                $scope.$emit(Ressources.EVENT_UPDATE_BENEFICIAIRES, self.beneficiaries);
                _countTitles();
                outOfUpdate(benef);
                if (ignoreDouble) {
                    reinitDuplicatedBenefs();
                }
            }).catch(function (err) {
                self.serverKo = true;
                reinitBenef(self.indexOfModifyingBenef);
                _countTitles();
                outOfUpdate(benef);
            });
        }

        self.addBenefAnyway = function () {
            self.addBenef($scope.newBeneficiaryForm, true);
            self.validBenefKoCuzOfAdding = false;
            self.cancelModificationBenef(self.beneficiaries[self.indexOfModifyingBenef], self.indexOfModifyingBenef);
        };

        self.cancelBenefAdding = function () {
            self.newBenef.Nom = null;
            self.newBenef.Prenom = null;
            self.newBenef.DateNaissance = null;

            $scope.newBeneficiaryForm.$setPristine(true);
            $validationProvider.validate($scope.newBeneficiaryForm);

            self.validBenefKoCuzOfAdding = false;
            self.duplicatedBenef = false;
            reinitDuplicatedBenefs();
        };

        /* Ferme la modification du bénéficiaire */
        self.cancelModificationBenef = function (benef, idx) {
            reinitBenef(idx);
            outOfUpdate(benef);
        };

        /* Supprimer le bénéficiaire */
        self.removeBenef = function (benef) {
            self.serverKo = false;
            deleteBenef(benef);
        };

        /* Valider la page */
        self.submitBeneficiariesInformation = function () {
            var isBeingModifiedBenef = newBeneficiaryIsBeingModified(self.newBenef);

            ////BENEFICIAIRE_MODIFICATION_KO
            if (self.indexOfModifyingBenef != -1) {
                $("#mCSB_1_container").css('top', 0);
                self.validBenefKoCuzOfModifying = true;
                return false;
            } else {
                self.validBenefKoCuzOfModifying = false;
            }

            //BENEFICIAIRE_AJOUT_KO
            if (isBeingModifiedBenef) {
                $("#mCSB_1_container").css('top', 0);
                self.validBenefKoCuzOfAdding = true;
                return false;
            } else {
                self.validBenefKoCuzOfAdding = false;
            }

            //BENEFICIAIRE_KO
            if (self.beneficiaries.length === 0) {
                self.validBenefKoCuzOfNoneBenef = true;
                return false;
            } else {
                self.validBenefKoCuzOfNoneBenef = false;
            }

            // BENEFICIAIRE_VALIDER_KO
            if (_.sumBy(self.beneficiaries, function (ben) { return parseInt(ben.NombreTitres); }) <= 0) {
                $("#mCSB_1_container").css('top', 0);
                self.validBenefKoCuzOfZeroTitles = true;
                return false;
            } else {
                self.validBenefKoCuzOfZeroTitles = false;
            }

            if (!$scope.newBeneficiaryForm.$dirty || !isBeingModifiedBenef)
                $validationProvider.reset($scope.newBeneficiaryForm);

            $scope.$emit(Ressources.EVENT_UPDATE_BENEFICIAIRES, self.beneficiaries);
            if (self.panier.Precommandes[0].NbBeneficiaireDevis != self.beneficiaries.length) {
                var patchPrecommand = {
                    NbBeneficiaireDevis: self.beneficiaries.length
                };

                return PanierService.enregistrerPrecommande(self.panier.Id, self.panier.Precommandes[0].Id, patchPrecommand).then(function (result) {
                    var returnedPrecommand = result.plain();
                    self.panier.Precommandes[0].NbBeneficiaireDevis = returnedPrecommand.NbBeneficiaireDevis;

                    ///* Vérifier si le contrat est changé */
                    //var newIdContratType = ContratsService.mettreAJourContratsDisponibles(self.beneficiaries.length);

                    ///* Si oui, mettre à jour le contrat et aussi le panier (localstorage) */
                    //if (newIdContratType != null) {
                    //    var patchContract = {
                    //        CodeContratType: newIdContratType
                    //    };
                    //    ContratsService.updateContract(self.panier.Precommandes[0].IdContrat, patchContract).then(function (contractResult) {
                    //        var updatedContract = contractResult.plain();
                    //        self.panier.Precommandes[0].Contrat.CodeContratType = updatedContract.CodeContratType;
                    //        //On invalide l'acceptation des CGV de la valo, pour obliger l'utilisateur à repasser par cet écran
                    //        self.panier.Precommandes[0].IdInterlocuteurCGV = null;
                    //        $scope.$emit(Ressources.EVENT_UPDATE_PANIER, self.panier);
                    //        StateService.next();
                    //        return false;
                    //    }).catch(function (err) {
                    //        self.serverKo = true;
                    //        return false;
                    //    });
                    //} else {
                    //    StateService.next();
                    //    return false;
                    //}
                    return StateService.next();
                })
                .then(function () {
                    if (self.panier.Client.Value.Effectif != self.panier.Precommandes[0].NbBeneficiaireDevis) {
                        self.panier.Client.Value.Effectif = self.panier.Precommandes[0].NbBeneficiaireDevis;
                        $scope.$emit(Ressources.EVENT_UPDATE_PANIER, self.panier);
                        return ClientService.enregistrerClient(self.panier.Client.Id, { Effectif: self.panier.Client.Value.Effectif });
                    } else {
                        return $q.resolve();
                    }
                })
                .catch(function (err) {
                    self.serverKo = true;
                    return false;
                });
            } else {
                return StateService.next();
            }
        };

        /* Ferme la modification d'un bénéficiaire */
        function outOfUpdate(benef) {
            benef.readonly = true;
            benef.options = false;
            self.indexOfModifyingBenef = -1;
            self.onEdit = false;
        }

        /* Vérification si on est en train de saisir un nouveau bénéficiaire (pour la validation de la page) */
        function newBeneficiaryIsBeingModified(benef) {
            return benef.Nom
                || benef.Prenom
                || benef.DateNaissance;
        }

        /* Vérification si ce bénéficiaire est modifié */
        function checkModifiedBenef(benef) {
            if (!tempBenef) return false;
            return benef.Nom !== tempBenef.Nom
                || benef.Prenom !== tempBenef.Prenom
                || benef.DateNaissance !== tempBenef.DateNaissance
                || benef.NombreTitres !== tempBenef.NombreTitres;
        }

        /* Retourner la liste des propriétés qui est changé la valeur */
        function propertyToUpdate(benef) {
            return _.filter(propertyAllowed, function (p) { return benef[p] !== tempBenef[p]; });
        }

        /* Réinitialiser le bénéficiaire quand on veut supprimer la modification actuelle */
        function reinitBenef(idx) {
            var copiedTempBenef = angular.copy(tempBenef);
            var benef = _.find(self.beneficiaries, function (b) {
                return b.index == idx;
            });

            benef.Nom = copiedTempBenef.Nom;
            benef.Prenom = copiedTempBenef.Prenom;
            benef.NombreTitres = copiedTempBenef.NombreTitres;
            benef.DateNaissance = moment(copiedTempBenef.DateNaissance).toDate();
            $timeout(function () {
                $validationProvider.validate($scope.beneficiariesForm['benefDetail' + idx]);
            });
            tempBenef = null;
            self.duplicatedBenef = false;
            reinitDuplicatedBenefs();
        };

        function reinitDuplicatedBenefs() {
            /* Reset les formulaires dédoublonné */
            for (var i = 0, length = duplicatedBenefs.length; i < length; i++) {
                var copiedTempBenef = angular.copy(duplicatedBenefs[i]),
                    idx = duplicatedBenefs[i].index;
                $scope.beneficiariesForm['benefDetail' + idx].$setPristine();
                $validationProvider.validate($scope.beneficiariesForm['benefDetail' + idx]);
            }
            duplicatedBenefs = [];
        }

        /* Supprimer le bénéficiaire */
        function deleteBenef(benef) {
            benef.options = false;

            var backupBenef = benef,
                _deleteBenef = angular.extend(new Beneficiaire(), benef),
                index = self.beneficiaries.indexOf(benef);

            PanierService.supprimerBeneficiaire(self.panier.Id, self.panier.Precommandes[0].Id, _deleteBenef).then(function () {
                self.beneficiaries.splice(index, 1);
                $scope.$emit(Ressources.EVENT_UPDATE_BENEFICIAIRES, self.beneficiaries);
                self.panier.Precommandes[0].IdInterlocuteurCGV = null;
                $scope.$emit(Ressources.EVENT_UPDATE_PANIER, self.panier);
                _countTitles();
            }).catch(function (err) {
                _countTitles();
                self.serverKo = true;
            });
        }

        function _sumTitles() {
            return _.sumBy(self.beneficiaries, function (ben) { return parseInt(ben.NombreTitres); });
        }

        /* Calculer le sommaire des nombres de jours des bénéficiaires (pour le wording) */
        function _countTitles() {
            self.quantityTotal = _sumTitles();
            self.duplicatedBenef = false;
            if (self.validBenefKoCuzOfZeroTitles && self.quantityTotal > 0) {
                self.validBenefKoCuzOfZeroTitles = false;
            }
        };

        /* Vérifier si ce bénéficiaire est un doublon */
        function verifyDuplicatedBenef(benef) {
            var duplicatedArr = [];
            var checkDoubleBeneficiaries = angular.copy(self.beneficiaries);

            var idx = _.findIndex(checkDoubleBeneficiaries, function (b) {
                return b.Id === benef.Id;
            });

            if (idx != -1) {
                checkDoubleBeneficiaries.splice(idx, 1);
            }

            for (var i = 0; i < checkDoubleBeneficiaries.length; i++) {
                if (checkDoubleBeneficiaries[i].Nom === benef.Nom && checkDoubleBeneficiaries[i].Prenom === benef.Prenom) {
                    duplicatedArr.push(checkDoubleBeneficiaries[i]);
                }
            }

            return duplicatedArr;
        }
    };

    module.exports = {
        name: "BeneficiaryController",
        fn: beneficiaryController
    };

})();
