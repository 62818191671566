; (function () {
    "use strict";

    function recapController($http, $scope, $injector, $filter, $q, $timeout, $window, localStorageService, StateService, ContactService, ValidationService, DevisService, Ressources, PanierService, valorisation) {
        var self = this,
            beneficiaries = localStorageService.get(Ressources.BENEFICIARIES_KEY),
            $validatorProvider = $injector.get('$validation'),
            panier = StateService.getPanier();
        self.feedlyUrl = "Content/views/feedly/summary.html";
        self.MovaOK = valorisation != null;
        self.panier = panier;
        self.linkReprisePanier = ''+window.location.protocol.toString()+'//'+window.location.host.toString()+'/#/?panid='+panier.Id
        self.linkmailto = ''+panier.Client.Value.Interlocuteur[0].Email+'?subject=Proposition%20commerciale%20Carte%20Ticket%20Restaurant%20EDENRED&body=Bonjour,%0D%0ASuite%20à%20notre%20entretien%20de%20ce%20jour,%20vous%20trouverez%20ci-dessous%20un%20lien%20pour%20finaliser%20votre%20commande%20de%20carte%20Ticket%20Restaurant®:%0D%0A'+self.linkReprisePanier+'%0D%0ACordialement,%0D%0A';
        console.log('reprisePanier:', self.linkReprisePanier)
        isIE();
        if (!self.MovaOK) {
            var message = $filter('codeToWording')("MOVA_KO");
            $scope.$broadcast('MOVA_KO-error-from-server', message);
        }
        self.conditionAgree = false;
        if (StateService.getTypeTunnel() == Ressources.TYPES_TUNNEL.Devis || StateService.getTypeTunnel() == Ressources.TYPES_TUNNEL.CMA) {
            self.nbSalaries = panier.Precommandes[0].NbBeneficiaireDevis;
            self.nbCartes = self.nbSalaries;
            self.totalTitres = self.nbSalaries * panier.Precommandes[0].NbTitresDevis;

        } else {
            self.totalTitres = _.sumBy(beneficiaries, function (e) { return parseInt(e.NombreTitres, 10); });
            if (panier.Precommandes.length > 0) {
                self.nbSalaries = panier.Precommandes[0].NbBeneficiaireDevis || beneficiaries.length;
            } else {
                self.nbSalaries = beneficiaries.length;
            }
            self.nbCartes = self.nbSalaries;
        }
        self.valeurFacialeParDefaut = panier.Precommandes[0].ValeurFacialeParDefaut / 100;
        self.partPatronaleParDefaut = panier.Precommandes[0].PartPatronaleParDefaut / 100;
        self.adresse = panier.Client.Value.Adresse;
        self.raisonSociale = panier.Client.Value.RaisonSociale;
        if (valorisation) {
            self.managementFee = valorisation.MontantFraisGestion;
            self.deliverFee = valorisation.MontantFraisPort;
            self.totalCharge = valorisation.MontantVE;
            self.cardCreationFee = valorisation.MontantEmissionCartes;
            self.totalHT = valorisation.MontantTotalHt;
            self.totalTTC = valorisation.MontantTotalTTC;
            self.totalTVA = valorisation.MontantTVA;
            self.detailValorisation = valorisation.DetailValorisation[0];
            self.abonnementMensuel = _.find(valorisation.DetailValorisation[0].ValorisationServices, function (s) {
                return s.IdentifiantService === Ressources.VALORISATION_CONST.ABONNEMENT;
            });
            self.emissionCarte = _.find(valorisation.DetailValorisation[0].ValorisationServices, function (s) {
                return s.IdentifiantService === Ressources.VALORISATION_CONST.EMISSIONCARTE;
            });
            self.prixLivraison = _.find(valorisation.DetailValorisation[0].ValorisationServices, function (s) {
                return s.IdentifiantService === Ressources.VALORISATION_CONST.LIVRAISON;
            });
            self.fraisGestion = _.find(valorisation.DetailValorisation[0].ValorisationServices, function (s) {
                return s.IdentifiantService === Ressources.VALORISATION_CONST.FRAISGESTION;
            });
        } else {
            self.totalCharge = self.panier.Precommandes[0].ValeurFacialeParDefaut * self.totalTitres / 100;
            self.totalHT = self.totalCharge;
            self.totalTTC = self.totalCharge * 1.2;
            self.totalTVA = ((self.totalTTC * 100) - (self.totalHT * 100)).toFixed(0) / 100; //Manipulations pour corriger le problème d'imprécision
        }
        self.generatePdf = function () {
            var contact = {
                Nom: self.panier.Client.Value.Interlocuteur[0].Nom,
                Prenom: self.panier.Client.Value.Interlocuteur[0].Prenom,
                Tel: self.panier.Client.Value.Interlocuteur[0].Tel,
                Email: self.panier.Client.Value.Interlocuteur[0].Email,
                Siret: self.panier.Client.Value.Siret
            };
            $scope.$emit('toggleLoadingToaster', { header: "Votre mail est en cours d'envoi", caption: "Veuillez patientez s'il vous plaît...", icon: 'icon-mail-sent' });
            return $q.all([
                    DevisService.envoyerDevis(panier.Id, panier.Precommandes[0].Id, panier.Client.Value.Interlocuteur[0]),
                    $timeout(_.noop, 3000)
            ])
                .then(function () {
                    ContactService.envoiFormulaireRecontacte(contact, self.panier, 14);
                    $scope.$emit('closeLoadingToaster');
                })
                .catch(function () {
                    $scope.$emit('closeLoadingToaster');
                });
        }

        self.preparePdf = function () {
            $scope.$emit('toggleLoadingToaster', { header: "Votre devis est en cours de génération", caption: "Veuillez patientez s'il vous plaît..." });
            var idFede = localStorageService.get(Ressources.FEDERATION_KEY);

            if (isIE() && isIE() < 10) {
                var devisUrl = 'api/paniers/' + self.panier.Id + '/precommandes/' + self.panier.Precommandes[0].Id + '/devis/preparer/' + idFede;
                $window.open(devisUrl);
            } else {
                var devisUrl = 'api/paniers/' + self.panier.Id + '/precommandes/' + self.panier.Precommandes[0].Id + '/devis/preparer/' + idFede;

                //$http.post(devisUrl, /*{responseType: 'arraybuffer'}*/JSON.stringify(dataToSend)).then(function (response) {
                $http({
                    url: devisUrl,
                    method: "POST",
                    data: JSON.stringify(self.panier.Client.Value.Interlocuteur[0]),
                    contentType: "application/json",
                    responseType: 'arraybuffer'
                }).then(function (response) {
                    download(response.data, 'devisCma.pdf', 'application\/pdf');
                    $scope.$emit('closeLoadingToaster');
                }).catch(function (error) {
                    $scope.$emit('closeLoadingToaster');
                });
            }
        }

        function download(data, filename, type) {

            var a = document.createElement("a"),
                file = new Blob([data], { type: type });

            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file, filename);
            } else {
                var url = URL.createObjectURL(file);
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                setTimeout(function () {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }, 50);
            };
        }

        function isIE() {
            var myNav = navigator.userAgent.toLowerCase();
            return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
        }

        self.validationValorisation = function () {
            // on recherche ?validation=false sinon est à true
            var interro = window.location.hash.indexOf('?');
            if (interro < 0) { return true; }
            var queries = window.location.hash.substring(interro);
            var indexValidation = queries.indexOf('validation');
            var isValidation = indexValidation > -1;
            var keyValidation = [];
            var result = true;
            if (isValidation) {
                keyValidation = queries.substring(indexValidation).split('&')[0].split('=');
                result = (keyValidation[1] !== 'false') ? true : false;
            }
            // var valid = new URLSearchParams(window.location.hash.substring(interro)).get('validation');
            return result;
        }

        self.masquerCGV = function () {
            if (!self.validationValorisation()) {
                return true;
            }
            return StateService.getTypeTunnel() == Ressources.TYPES_TUNNEL.Devis;
        }

        self.reload = function () {
            location.reload();
        }

        self.isPromo = function () {
            return location.hostname.indexOf('promo-ctr');
        }

        self.submit = function (form) {
            return $validatorProvider.validate(form).then(function () {

                //mettre a jour precommande avec l'idInterlocuteur pour les CGV
                return PanierService.enregistrerPrecommande(self.panier.Id, self.panier.Precommandes[0].Id, { IdInterlocuteurCGV: self.panier.Client.Value.Interlocuteur[0].Id })
                    .then(function (result) {
                        if (valorisation) {
                            localStorageService.set(Ressources.MONTANTS_KEY, { totalTTC: valorisation.MontantTotalTTC, totalHT: valorisation.MontantTotalHt, montantTVA: valorisation.MontantTVA });
                        }
                        self.panier.Precommandes[0].IdInterlocuteurCGV = result.plain().IdInterlocuteurCGV;
                        if (StateService.getTypeTunnel() == Ressources.TYPES_TUNNEL.CMA) {
                            return PanierService.contractualisationPrecommande(self.panier.Id, self.panier.Precommandes[0].Id, self.panier.Client.Value.Interlocuteur[0].Id);
                        }
                    })
                    .then(function () {
                        $scope.$emit(Ressources.EVENT_UPDATE_PANIER, self.panier);
                        return StateService.next();
                    });
            });
        };
    };

    module.exports = {
        name: "RecapController",
        fn: recapController
    }
})();
