; (function () {
    "use strict";

    function siretController($scope, $state, $stateParams, $window, $injector, $timeout, $q, localStorageService, StateService, PanierService, ValidationService, ClientService, FeedlyEtapeService, CompanyService, Client, Adresse, Precommande, Interlocuteur, Ressources, trackingService) {
        var self = this,
            oldInterlocuteur,
            isInterlocutorChanged = false,
            $validationProvider = $injector.get('$validation'),
            $filter = $injector.get('$filter'),
            panier = StateService.getPanier();

        self.resultatRechercheSiret = null;
        self.feedlyUrl = 'Content/views/feedly/siret.html';

        if (/*tunnel.isCMATunnel() && panier && panier.Client &&*/ !panier.Client.Value) {
            panier.Client.Value = new Client();
        }
        var interlocutor = panier.Client.Value.Interlocuteur[0];
        self.client = panier.Client.Value;
        var oldClient = angular.copy(self.client); //pour vérifier le changement
        if ($stateParams.siret)
            self.client.Siret = $stateParams.siret;
        
        self.errorMsg = [];

        var _errorSiretValidation = function () { };

        var _successSiretValidation = function () {
            if (!detectChanges(self.client, oldClient)) {
                return StateService.next();
            } else {
                $scope.$emit('toggleLoadingToaster', {
                    header: "Recherche des informations de votre entreprise",
                    caption: "Veuillez patienter s'il vous plaît...",
                });

                return ClientService.enregistrerClient(panier.Client.Id, { siret: self.client.Siret }, panier.Precommandes[0].Id)
                    .then(function (clientResult) {
                        var client = clientResult.plain();

                        return PanierService.chargerPrecommande(panier.Id, panier.Precommandes[0].Id).then(function (precommandeResult) {
                            var precommande = precommandeResult.plain();
                            panier.Precommandes[0].EstXSell = precommande.EstXSell;
                            panier.Precommandes[0].AConditionCommercialeActive = precommande.AConditionCommercialeActive;

                            self.client.Siret = client.Siret;
                            self.client.RaisonSociale = client.RaisonSociale.substring(0, 50);
                            self.client.Adresse = new Adresse();
                            self.client.Adresse.Adresse1 = client.Adresse.Adresse1;
                            self.client.Adresse.Adresse2 = client.Adresse.Adresse2;
                            self.client.Adresse.Adresse3 = client.Adresse.Adresse3;
                            self.client.Adresse.CodePostal = client.Adresse.CodePostal;
                            self.client.Adresse.Ville = client.Adresse.Ville;
                            self.client.Adresse.EstNormaliseeQAS = client.Adresse.EstNormaliseeQAS;

                            $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                            //$scope.$emit('closeLoadingToaster');
                            return StateService.next();
                        });
                    })
                .catch(function (error) {
                    /* SIRET_INCONNU, SIRET_RADIE, INTERLOCUTEUR_KO, CLIENT_REDIRECTION_EXC */
                    $scope.$emit('closeLoadingToaster');
                    var _errorCode = error.data.Content[0].ErrorCode,
                    message = $filter('codeToWording')(_errorCode);
                    trackingService.trackEvent({'event': 'erreurSiret', 'siret_error_type': _errorCode});
                    
                    switch (_errorCode) {
                        case "CHOIX_DIVISION_CTR":
                            $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                            localStorageService.set(Ressources.INTERLOCUTEUR_DIVISIONS, error.data.Content[0].Data);
                            StateService.go(Ressources.TUNNEL_CTR_SIRET_CHOIX);
                            break;
                        case "CLIENT_REDIRECTION_EXC":
                            if (error.data.Content[0].Data && error.data.Content[0].Data.Division) {
                                var division = error.data.Content[0].Data.Division[0];
                                StateService.reset();
                                if (!division.EstCree) {
                                    $window.location.href = Ressources.ESPACE_CLIENT_URL + "account?numeroclient=" + division.NumeroDivision + "&email=" + division.Email;
                                } else {
                                    $state.go('tunnel.login', { login: division.Email });
                                }
                            } else {
                                $state.go('tunnel.login', { login: interlocutor.Email });
                            }
                            break;
                        case "ERREUR_VELCTR1":
                            var siretKo = self.client.Siret;
                            panier.Client.Value.Siret = null;
                            panier.Client.Value.RaisonSociale = null;
                            panier.Client.Value.CodeNaf = null;
                            panier.Client.Value.CodeMarche = null;
                            panier.Client.Value.Adresse = null;
                            $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                            StateService.go(Ressources.TUNNEL_CTR_SIRET_INTERLOCUTEUR_KO, { errorCode: 1, siretKo: siretKo });
                            break;
                        case "ERREUR_VELCTR2":
                            var siretKo = self.client.Siret;
                            panier.Client.Value.Siret = null;
                            panier.Client.Value.RaisonSociale = null;
                            panier.Client.Value.CodeNaf = null;
                            panier.Client.Value.CodeMarche = null;
                            panier.Client.Value.Adresse = null;
                            $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                            StateService.go(Ressources.TUNNEL_CTR_SIRET_INTERLOCUTEUR_KO, { errorCode: 2, siretKo: siretKo });
                            break;
                        case "ADRESSEREDRESSEEFKO":
                            var siretKo = self.client.Siret;
                            panier.Client.Value.Siret = null;
                            panier.Client.Value.RaisonSociale = null;
                            panier.Client.Value.CodeNaf = null;
                            panier.Client.Value.CodeMarche = null;
                            panier.Client.Value.Adresse = null;
                            $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                            StateService.go(Ressources.TUNNEL_CTR_SIRET_INTERLOCUTEUR_KO, { errorCode: 3, siretKo: siretKo });
                            break
                        default:
                            $scope.$broadcast(_errorCode + '-error-from-server', message);
                            break;
                    }

                    return $q.reject();
                });
            }
        }

        var _successSearchSiret = function () {
            $scope.$emit('toggleLoadingToaster', {
                header: "Recherche de votre SIRET",
                caption: "Veuillez patienter s'il vous plaît...",
            });

            // ToDo :: brancher sur le service feedlyEtape (non fait encore) qui sera branché sur l'Api pour la recherche
            var codePostal = self.postalCode;
            var raisonSociale = self.companyName;

            return CompanyService.rechercherParCodePostalRaisonSociale(codePostal, raisonSociale).then(function (result) {
                self.resultatRechercheSiret = result.plain();
                
                if (self.resultatRechercheSiret.length > 0) {
                    self.siretSelectionne = self.resultatRechercheSiret[0].Siret;
                    self.splittedResultIndex = 0;
                    self.splittedSiretResult = splitSiretResult(self.resultatRechercheSiret);
                    var etapeSuivante = FeedlyEtapeService.getNextEtape(StateService.getCurrent(), 1);
                    self.errorMsg = [];
                    $scope.$emit('changeEtapeFeedly', { templateUrl: etapeSuivante.templateUrl });
                } else {
                    self.splittedSiretResult = [];
                    self.errorMsg = [$filter('codeToWording')("RECHERCHER_SIRET_KO")];
                    //var etapeActuelle = FeedlyEtapeService.getCurrentEtape(StateService.getCurrent(), 1);
                    //$scope.$emit('changeEtapeFeedly', { templateUrl: etapeActuelle.templateUrl });
                }
                $scope.$emit('closeLoadingToaster');
            }).catch(function (error) {
                $scope.$emit('closeLoadingToaster');
                if (error && error.data) {
                    self.errorMsg = [$filter('codeToWording')("RECHERCHER_SIRET_ERREUR")];
                }

                return $q.reject();
            });
        }

        var splitSiretResult = function (siretSearchResult) { return _.chunk(siretSearchResult, 10); };

        var _errorSearchSiret = function (error) {
            if (error && error.data) {
                var _errorCode = error.data.Content[0].ErrorCode,
                message = $filter('codeToWording')(_errorCode);
                $scope.$broadcast(_errorCode + '-error-from-server', message);
            }
        };

        self.searchSiret = function (form) {
            return $validationProvider.validate(form)
           .then(_successSearchSiret)
           .catch(_errorSearchSiret);
        };

        self.clearFeedly = function () {
            self.postalCode = null;
            self.companyName = null;
            self.errorMsg = [];
        };

        self.previousPage = function () {
            self.splittedResultIndex--;
        };

        self.nextPage = function () {
            self.splittedResultIndex++;
        };

        self.goToPage = function (pageIndex) {
            self.splittedResultIndex = pageIndex;
        };

        self.selectionSiret = function (form) {
            var email = self.interlocuteurDevis.Email;
            var fonction = self.interlocuteurDevis.Fonction;

            self.client.Siret = self.siretSelectionne;
            self.interlocuteurDevis.Email = email;
            self.interlocuteurDevis.Fonction = fonction;
        };

        self.submitSiretNumber = function (form) {
            return $validationProvider.validate(form)
                .then(_successSiretValidation)
                .catch(_errorSiretValidation);
        };

        /*
        *  Détecter le changement du numéro siret pour déclencher une nouvelle requête
        */
        function detectChanges(targetClient, sourceClient) {
            return targetClient.Siret !== sourceClient.Siret;
        }

        /*
        *  Partie tunnel devis
        */
        self.interlocuteurDevis = {};
        if (panier.Client.Value.Interlocuteur[0]) {
            self.interlocuteurDevis = panier.Client.Value.Interlocuteur[0];
        }

        self.functions = $.map(Object.keys(Ressources.PERSONAS), function (e) {
            var value = Ressources.PERSONAS[e];
            if (!isNaN(value)) {
                return {
                    value: value,
                    name: Ressources.PERSONAS.properties[value].Libelle
                }
            }
            return null;
        });

        var interlocuteur = panier.Client.Value.Interlocuteur != null && panier.Client.Value.Interlocuteur.length > 0 ? panier.Client.Value.Interlocuteur[0] : null;
        self.interlocuteurDevis = interlocuteur || new Interlocuteur();
        if (self.interlocuteurDevis.Email == Ressources.EMAIL_SIMULATION) {
            self.interlocuteurDevis.Email = "";
            self.interlocuteurDevis.Fonction = null;
        }
        oldInterlocuteur = angular.copy(self.interlocuteurDevis);

        /*
         * Validation du formulaire siret-devis
         * Actions : enregistrement de l'interlocuteur + rechercheSiret et valorisation du client avec l'adresse
         */
        self.submitInformationsInterlocuteurDevis = function (form) {
            $validationProvider.validate(form)
                .then(function () {
                    isInterlocutorChanged = oldInterlocuteur.Email !== self.interlocuteurDevis.Email || oldInterlocuteur.Fonction !== self.interlocuteurDevis.Fonction || form.siret.$dirty;

                    if (isInterlocutorChanged) {
                        $scope.$emit('toggleLoadingToaster', {
                            header: "Recherche des informations de votre entreprise",
                            caption: "Veuillez patienter s'il vous plaît...",
                        });
                        (!oldInterlocuteur.Id
                                ? ClientService.creerInterlocuteur(panier.Client.Id, angular.extend(new Interlocuteur(), self.interlocuteurDevis))
                                : ClientService.enregistrerInterlocuteur(panier.Client.Id, panier.Client.Value.Interlocuteur[0].Id, self.interlocuteurDevis))
                            .then(function (interlocuteurResult) {
                                panier.Client.Value.Interlocuteur = [interlocuteurResult.plain()];
                                return ClientService.patchCmaSiret(panier.Client.Id, panier.Precommandes[0].Id, self.client.Siret);
                            })
                            .then(function (result) {
                                var client = result.plain();
                                self.client.Siret = client.Siret;
                                self.client.RaisonSociale = client.RaisonSociale.substring(0, 50);
                                self.client.Adresse = new Adresse();
                                self.client.Adresse.Adresse1 = client.Adresse.Adresse1;
                                self.client.Adresse.Adresse2 = client.Adresse.Adresse2;
                                self.client.Adresse.Adresse3 = client.Adresse.Adresse3;
                                self.client.Adresse.CodePostal = client.Adresse.CodePostal;
                                self.client.Adresse.Ville = client.Adresse.Ville;
                                self.client.Adresse.EstNormaliseeQAS = client.Adresse.EstNormaliseeQAS;
                                self.client.EstXSell = client.EstXSell;
                                $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);

                                return StateService.next();
                            })
                            .catch(function (error) {
                                /* SIRET_INCONNU, SIRET_RADIE, INTERLOCUTEUR_KO, CLIENT_REDIRECTION_EXC */
                                $scope.$emit('closeLoadingToaster');
                                var errorCode = error.data.Content[0].ErrorCode;
                                var message = $filter('codeToWording')(errorCode);
                                trackingService.trackEvent({'event': 'erreurSiret', 'siret_error_type': errorCode});
                                switch (errorCode) {
                                    case "CHOIX_DIVISION_CTR":
                                    case "CLIENT_REDIRECTION_EXC":
                                    case "INTERLOCUTEUR_KO":
                                        break;
                                    default:
                                        $scope.$broadcast(errorCode + '-error-from-server', message);
                                        break;
                                }
                                return false;
                            });
                    } else {
                        var referenceEtapeTunnel = Ressources.TYPES_TUNNEL.CMA + ":" + StateService.getCurrent();
                        localStorageService.set(Ressources.REFERENCE_ETAPE_TUNNEL_KEY, referenceEtapeTunnel);
                        return PanierService.enregistrerPrecommande(panier.Id, panier.Precommandes[0].Id, { ReferenceEtapeTunnel: referenceEtapeTunnel })
                        .then(function () {
                            return StateService.next();
                        });
                    }
                });
        };
    };

    module.exports = {
        name: "SiretController",
        fn: siretController
    };

})();