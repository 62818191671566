; (function () {
    "use strict";

    var reinitPassword = {
        bindings: {
            requiredInfos: '<'
        },
        templateUrl: 'Content/views/authentication/reinit-password.html',
        controller: function ($scope, $state, $stateParams, $filter, AuthenticationService, $injector) {
            var self = this,
                $validationProvider = $injector.get('$validation');

            var userQuestion = _.find(self.requiredInfos.SecretQuestions, function (q) {
                return q.Id === self.requiredInfos.QuestionId;
            });

            if (userQuestion) {
                self.secretQuestionLabel = userQuestion.Libelle;
            }

            self.submitPasswords = function (form) {
                self.serverKo = false;
                $validationProvider.validate(form)
                .success(_successValidationPasswords);
            };

            self.reinitPasswordUserObj = {
                token: self.requiredInfos.Token,
                login: self.requiredInfos.Login,
                password: null,
                confirmedPassword: null,
                secretQuestionId: self.requiredInfos.QuestionId,
                secretAnswer: null
            };

            var _successValidationPasswords = function () {
                AuthenticationService.reinitPassword(self.reinitPasswordUserObj).then(function () {
                    $state.go('tunnel.forgotpassword-step4', { login: self.requiredInfos.Login });
                }).catch(function (error) {
                    var _errorCode = error.data.Content[0].ErrorCode,
                        message = $filter('codeToWording')(_errorCode);
                    $scope.$broadcast(_errorCode + '-error-from-server', message);
                });
            };
        }
    };

    module.exports = {
        name: "reinitPassword",
        obj: reinitPassword
    };
})();