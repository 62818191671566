; (function () {
    "use strict";

    function breadcrumbService() {

        var breadcrumbs = [
            {
                "text": "PANIER",
                "stateUrl": "tunnel.connected.beneficiaire",
                "filled": false,
                "active": false,
                "clickable": false
            },
            {
                "text": "RÉCAPITULATIF",
                "stateUrl": "tunnel.connected.recapitulatif",
                "filled": false,
                "active": false,
                "clickable": false
            },
            {
                "text": "PAIEMENT",
                "stateUrl": "tunnel.connected.confirmation",
                "filled": false,
                "active": false,
                "clickable": false
            }
        ];

        this.getBreadcrumb = function (stateUrl) {
            return _.find(breadcrumbs, function (b) {
                return b.stateUrl === stateUrl;
            });
        };

        this.selectBreadcrumb = function (stateUrl) {
            /* Reset all active tabs */
            _.map(breadcrumbs, function (t) {
                t.active = false;
                t.clickable = false;
            });

            /* Find last active tab index */
            var _activeTab = _.findIndex(breadcrumbs, { 'stateUrl': stateUrl });

            if (_activeTab !== -1) {
                /* Active last tab selected from state change success */
                for (var i = 0; i < _activeTab; i++) {
                    breadcrumbs[i].filled = true;
                    breadcrumbs[i].clickable = true;
                }

                breadcrumbs[_activeTab].active = true;

                return breadcrumbs;
            }

            return [];
        };
    };

    module.exports = {
        name: "BreadcrumbService",
        fn: breadcrumbService
    }

})();
