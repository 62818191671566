; (function () {
    'use strict';

    var activationExpired = {
        bindings: {
            userGuid: "<"
        },
        templateUrl: 'Content/views/authentication/activation-expired.html',
        controller: function ($scope, $timeout, AuthenticationService) {
            var self = this;
            self.sendActivationEmail = function () {
                $scope.$emit('toggleLoadingToaster', { header: "Envoi en cours", caption: "Veuillez patienter s'il vous plaît..." });

                AuthenticationService.sendActivationEmail(self.userGuid).then(function () {
                    $timeout(function () {
                        $scope.$emit('closeLoadingToaster');
                    }, 2000);
                });
            }
        }
    };

    module.exports = {
        name: 'activationExpired',
        obj: activationExpired
    };

})();