;(function() {
  "use strict";

    function searchService ($rootScope) {

        var _query = '';

        var _getQuery = function () {
            return _query;
        };

        var _setQuery = function (query) {
            _query = query;
            $rootScope.$broadcast('query:updated', _query);
        };

        return {
            query: _query,
            getQuery: _getQuery,
            setQuery: _setQuery
        };
    };

    module.exports = {
        name: "searchService",
        fn: searchService
    }

})();
