; (function () {
    "use strict";

    function parametrageComptableController($scope, $rootScope, $stateParams, $filter, $validation, $window, Ressources, wording, token, ClientService, localStorageService) {
        $rootScope.$broadcast("cartLoaded");
        $rootScope.$broadcast("tarifLoaded"); //pas de chargement de tarifs si pas de reprise
        $rootScope.$broadcast('appLoaded');

        var self = this,
            orderTable = [];
        self.message = $stateParams.message;//TODO: exploiter le retour de la variable pour afficher un message en cas de succès ou d'abandon
        self.errorMsg = [];
        self.serverKo = false;
        self.feedlyUrlModifierCompte = 'Content/views/feedly/modifier-parametrage-comptable.html';
        self.feedlyUrlActiverSEPA = 'Content/views/feedly/passer-au-prelevement.html';
        self.aLesDroits = true;
         
        Object.defineProperty(self, 'compte', {
            get: function () {
                return self._compte;
            },
            set: function (value) {
                this._compte = value;
                if (value && value != '') {
                    var tab = value.split('-');
                    self.IBAN = tab[0];
                    self.BIC = tab[1];
                }
            }
        });

        Object.defineProperty(self, 'idProduit', {
            get: function () {
                return self._idProduit;
            },
            set: function (value) {

                self._idProduit = value;
                if (value != null) {
                    self.IBAN = null;
                    self.BIC = null;
                    var comptes = _.find(self.comptes, { 'idProduit': value });
                    self.compte = comptes.compte;
                }
            }
        });

        self.sidebarExpanded = window.innerWidth < 1280 ? false : true;
        self.sidebarToggle = function (expanded) {
            self.sidebarExpanded = expanded;
        };

        if (typeof (token != undefined)) {
            /* VERIFIER SI LOCALSTORAGE OU RESOLVE */
            if (!token.Domaine) {
                token = token.data;
            }
            self.infosClient = token;
            $scope.$emit('onClientConnected', token);
            self.numeroDivision = token.Domaine.split(';')[0];
            self.idClient = token.ClientId;
            self.interlocuteur = {
                Nom: token.Nom,
                Prenom: token.Prenom,
                Email: token.Email,
                Phone: token.Phone
            };
        }

        /* WORDING */
        if (!localStorageService.get(Ressources.WORDING_KEY)) {
            localStorageService.set(Ressources.WORDING_KEY, wording);
        }

        ClientService.rechercherParametrageComptabilite(self.idClient).then(function (resultats) {
            self.paiements = resultats.plain();
            self.comptes = [];
            $.map(self.paiements, function (e) {
                 
                if (e.CodeModePaiement === 4)//Prélèvement
                    e.Statut = $filter('codeToWording')('PARAM_RGLT_SEPA_FLAG_ACTIVE');
                else if (e.CodeModeFacturation != 0 || $.inArray(e.CodeProduit, [1, 2, 9, 24, 80, 221, 240]) == -1)
                    e.Statut = $filter('codeToWording')('PARAM_RGLT_SEPA_FLAG_INTERDIT');
                var compte = null;
                if (e.IBAN && e.BIC) {
                    compte = (e.IBAN || "") + " - " + (e.BIC || "");
                     
                }
                self.comptes.push({
                    idProduit: e.IdProduit,
                    produit: e.Produit,
                    compte: compte
                });

            });
            self.errorCode = null;
        }).catch(function (erreur) {
            self.errorCode = erreur.data.Content[0].ErrorCode;
        });

        self.peutPasserAuPrevelement = function (paiement) {
            return paiement.CodeModeFacturation == 0 && paiement.Statut == null;
        }

        self.afficherFeedlyModifierCompte = function (app, paiement) {
            self._idProduit = paiement.IdProduit;
            self.compte = null;
            self.IBAN = null;
            self.BIC = null;
            app.toggleFeedly(self.feedlyUrlModifierCompte, 10);
        }

        self.afficherFeedlyActiverSEPA = function (app, paiement) {
           
            self._idProduit = paiement.IdProduit;
            app.toggleFeedly(self.feedlyUrlActiverSEPA, 10);
             

        }

        self.passerAuPrevelement = function (form) {
            $validation.validate(form).then(function () {
                var produitSEPA = _.find(self.paiements, { 'IdProduit': self.idProduit });
                produitSEPA.IBAN = self.IBAN;
                produitSEPA.BIC = self.BIC;

                ClientService.passerAuPrelevement(self.idClient, produitSEPA).then(function (result) {
                    $window.location.href = result;
                }).catch(function (erreur) {
                    self.errorMsg = [$filter('codeToWording')(erreur.data.Content[0].ErrorCode)];
                });
            });
        };

        self.enregistrerCoordonneesBancaires = function (form) {
            $validation.validate(form).then(function () {
                var produitAMAJ = _.find(self.paiements, { 'IdProduit': self.idProduit });
                produitAMAJ.IBAN = self.IBAN.trim();
                produitAMAJ.BIC = self.BIC.trim();

                ClientService.enregistrerCoordonneesBancaires(self.idClient, produitAMAJ).then(function () {
                }).catch(function (erreur) {
                    self.errorMsg = [$filter('codeToWording')(erreur.data.Content[0].ErrorCode)];
                });
            });
        };

        /* Tri */
        self.sortTable = {
            label: null,
            reverse: false,
            type: 1,
            toggle: function (label, type, reverse) {
                this.label = label;
                this.type = type;
                this.reverse = reverse;
            }
        }

        /*  */
        self.openOptions = function (paiement) {
             
            angular.forEach(self.paiements, function (p) {
                if (p != paiement)
                    p.options = false;
            });
            paiement.options = !paiement.options;
        };

        self.closeOption = function (index) {
            if (self.paiements[index])
                self.paiements[index].options = false;
        };

    };

    module.exports = {
        name: "ParametrageComptableController",
        fn: parametrageComptableController
    };

})();
