;(function() {
    "use strict";

    function Interlocuteur () {
        return function () {
            this.Id = null;
            this.Email = null;
            this.Nom = null;
            this.Prenom = null;
            this.Tel = null;
            this.Fonction = null;
            this.Civilite = null;
        };
    };

    module.exports = {
        name: "Interlocuteur",
        fn: Interlocuteur
    }

})();
