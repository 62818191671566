; (function () {
    "use strict";

    function selectricInit($timeout) {
        return {
            restrict: 'A',
            priority: 999,
            link: function (scope, el, attrs, ngModel) {
                scope.$on('LastRepeaterElement', function () {
                    $timeout(function () { // For being sure that ng-repeat value is completly interprated
                        $(el).selectric({
                            onClose: function () {
                                $(el).blur(); // Trigger blur pour la validation 'valid-method="blur"'
                            }
                        });
                    }, 0);
                });
                scope.$watch(attrs.ngModel, function () {
                    el.selectric('refresh');
                }, true);
            }
        };
    };

    module.exports = {
        name: 'selectric',
        fn: selectricInit
    };

})();
