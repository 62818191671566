module.exports=﻿{
    "STEPPER_PANIER": "Mon panier",
    "STEPPER_FONCTION": "Ma fonction",
    "STEPPER_VF": "Valeur faciale",
    "STEPPER_CONTRAT":  "Mon contrat",
    "STEPPER_SALARIE": "Mes salariés",

    "STEPPER_INFOS": "Mes informations",
    "STEPPER_SIRET": "Mon adresse",
    "STEPPER_COORDONNEES": "Mes coordonnées",

    "STEPPER_SERVICE": "Mes services",
    "STEPPER_CONTRAT": "Mon contrat",

    "STEPPER_PAIEMENT": "Paiement",
    "STEPPER_RECAP": "Récapitulatif",
    "STEPPER_DEVIS": "Devis",
    "STEPPER_IBAN": "Moyen de paiement",
    "STEPPER_CONFIRMATION": "Confirmation",
}
