;(function() {
  "use strict";

    // ReSharper disable once InconsistentNaming
    function codeToWording (localStorageService, Ressources) {
        return function (code, datas) {

            var retour = null,
                wordings = localStorageService.get(Ressources.WORDING_KEY);

            angular.forEach(wordings, function (value, key) {
                if (value.Code == code) {
                    retour = value.Label;
                }
            });

            if (retour === null) {
                return code;
            } else {
                angular.forEach(datas, function (value, key) {
                    var re = new RegExp("\\{" + (key) + "\\}", "i");
                    retour = retour.replace(re, value);
                });
                return retour;
            }
        }

    };

    module.exports = {
        name: "codeToWording",
        fn: codeToWording
    }

})();
