;(function () {
    "use strict";

    var servicesFolders = (function () {var f = require("./index.js");f["addressFilling.service"]=require("./addressFilling.service.js");f["authentication.service"]=require("./authentication.service.js");f["breadcrumbs.service"]=require("./breadcrumbs.service.js");f["click.service"]=require("./click.service.js");f["client.service"]=require("./client.service.js");f["company.service"]=require("./company.service.js");f["contact.service"]=require("./contact.service.js");f["contrats.service"]=require("./contrats.service.js");f["descriptif.service"]=require("./descriptif.service.js");f["devis.service"]=require("./devis.service.js");f["feedlyEtape.service"]=require("./feedlyEtape.service.js");f["feedlyState.service"]=require("./feedlyState.service.js");f["googleApi.service"]=require("./googleApi.service.js");f["index"]=require("./index.js");f["lexique.service"]=require("./lexique.service.js");f["panier.service"]=require("./panier.service.js");f["partenaires.service"]=require("./partenaires.service.js");f["search.service"]=require("./search.service.js");f["state.service"]=require("./state.service.js");f["tracking.service"]=require("./tracking.service.js");f["validation.service"]=require("./validation.service.js");f["wording.service"]=require("./wording.service.js");return f;})(),
        services = angular.module('app.services', [
    'app.models',
    'restangular'
]);

    for (var prop in servicesFolders) {
        if (prop !== "index") {
            var servicesObject = require("./" + prop + ".js");
            services.service(servicesObject.name, servicesObject.fn);
        }
    }

    module.exports = services;

})();