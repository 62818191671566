; (function () {
    "use strict";

    var headerConnection = {
        templateUrl: componentsPath + 'header/header.connection.html',
        controllerAs: 'headerConnection',
        controller: function(Ressources){
            var self = this;
            self.urlHomePageV1 = Ressources.ESPACE_CLIENT_URL;

            self.headerDrawerOpen = false;

            self.toggleDrawer = function(){
                self.headerDrawerOpen = !self.headerDrawerOpen;
            }
        }
    };

    module.exports = {
        name: 'headerConnection',
        obj: headerConnection
    };

})();
