; (function () {
    "use strict";
    function siretChoixController($scope, $injector, $window, $q, StateService, ClientService, PanierService, Ressources, divisions) {
        var self = this,
            $validationProvider = $injector.get('$validation'),
            panier = StateService.getPanier();

        panier.Client.Value.Siret = null;
        $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);

        if (divisions) {
            self.divisions = divisions.Division;
        }

        self.submitSelectionDivision = function (form) {
            panier = StateService.getPanier();
            $validationProvider.validate(form)
            .success(_successValidationDivision)
            .error(_errorValidationDivision);
        };

        var _successValidationDivision = function () {
            var annulerPanierPromise;
           
            if (panier && panier.Precommandes && panier.Precommandes[0].Statut == 0) {
                annulerPanierPromise = PanierService.annulerPanier(panier.Id).then(function () {
                    StateService.reset();
                });
            } else {
                annulerPanierPromise = $q.resolve();
            }

            annulerPanierPromise.then(function () {
                if (!self.selectedDivision.EstCree) {
                    $window.location.href = Ressources.ESPACE_CLIENT_URL + "account?numeroclient=" + self.selectedDivision.NumeroDivision + "&email=" + self.selectedDivision.Email;
                    return false;
                }
                $state.go('tunnel.login', { login: self.selectedDivision.Email });
            });
        };

        var _errorValidationDivision = function () {
        };
    };

    module.exports = {
        name: "SiretChoixController",
        fn: siretChoixController
    };

})();
