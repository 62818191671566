module.exports=﻿{
    "SALARIES_MAX_KO": {
        "nom": "NbBeneficiaireDevis",
        "min": 1,
        "max": 50,
        "message": "Le nombre de bénéficiaires doit être compris entre 1 et 50."
    },
    "SALARIES_KO": {
        "nom": "NbBeneficiaireDevis",
        "regex": "^[0-9]+$",
        "nullable": false,
        "message": "Veuillez renseigner un nombre de bénéficiaires."
    },
    "FONCTION_KO": {
        "nom": "Fonction",
        "nullable": false,
        "message": "Veuillez sélectionner votre fonction."
    },
    "EMAIL_KO": {
        "nom": "Email",
        "nullable": false,
        "message": "Veuillez renseigner votre email."
    },
    "REGEX_EMAIL_KO": {
        "nom": "Email",
        "regex": "^[a-zA-Z0-9._-]+@(([a-zA-Z0-9._-])\\2?(?!\\2))+\\.[a-z]+$",
        "message": "L'adresse email saisie est invalide. Merci de vérifier votre saisie."
    },
    "QAS_EMAIL_KO": {
        "message": "L'adresse email saisie est invalide. Merci de vérifier votre saisie."
    },
    "QAS_EMAIL_TIMEOUT": {
        "message": "La demande n’a pu être prise en compte, merci de valider à nouveau votre saisie."
    },
    "VALEUR_FACIALE_KO": {
        "nom": "ValeurFacialeParDefaut",
        "nullable": false,
        "min": 400,
        "max": 1200,
        "message": "La valeur faciale saisie est invalide."
    },
    "PART_PATRONALE_KO": {
        "nom": "PartPatronaleParDefaut",
        "nullable": false,
        "min": 50,
        "max": 60,
        "message": "La part patronale saisie est invalide."
    },
    "CGU_KO": {
        "message": "Vous devez valider les CGU pour continuer."
    },
    "CGV_KO": {
        "nullable": false,
        "message": "Vous devez valider les CGV pour continuer."
    },
    "BENEFICIAIRE_MAX_KO": {
        "nom": "length",
        "maxSize": 50,
        "message": "Le nombre de bénéficiaires maximum pour votre commande est atteint."
    },
    "CHAMPS_KO": {
        "nom": "Nom,Prenom,DateNaissance,NombreTitres",
        "nullable": false,
        "message": "Tous les champs sont obligatoires."
    },
    "NOM_KO": {
        "nom": "Nom",
        "regex": "^[a-zA-Z \\'\\-èéêëçà]+$",
        "maxLength": 50,
        "message": "Le nom contient des caractères spéciaux."
    },
    "PRENOM_KO": {
        "nom": "Prenom",
        "regex": "^[a-zA-Z \\'\\-èéêëçà]+$",
        "maxLength": 50,
        "message": "Le prénom contient des caractères spéciaux."
    },
    "NB_JOURS_KO": {
        "nom": "NombreTitres",
        "min": 0,
        "max": 25,
        "message": "Le nombre de jours doit être compris entre 0 et 25."
    },
    "NB_JOURS_KO_CONNECTED": {
        "nom": "NombreTitres",
        "min": 0,
        "max": 222,
        "message": "Le nombre de jours doit être compris entre 0 et 25."
    },
    "AGE_KO": {
        "nom": "DateNaissance",
        "min": 16,
        "max": 85,
        "message": "L'âge du bénéficiaire doit être compris entre 16 ans et 85 ans."
    },
    "AGE_INCOHERENT": {
        "nom":  "DateNaissance",
        "message": "L'âge du bénéficiaire doit être compris entre 16 ans et 85 ans."
    },
    "BENEFICIAIRE_KO": {
        "nom": "length",
        "minSize": 1,
        "message": "Aucun bénéficiaire n'est renseigné, veuillez valider votre ajout de vos bénéficiaires avant de continuer."
    },
    "BENEFICIAIRE_AJOUT_KO": {
        "message": "Veuillez valider votre ajout de bénéficiaire avant de continuer."
    },
    "BENEFICIAIRE_MODIFICATION_KO": {
        "message": "Veuillez valider votre modification de bénéficiaire avant de continuer."
    },
    "CHAMPS_EMAIL_KO": {
        "nullable": false,
        "message": "Tous les champs emails sont obligatoires."
    },
    "CHAMPS_ADRESSE_KO": {
        "nullable": false,
        "message": "Tous les champs adresses sont obligatoires."
    },
    "NB_TITRES_SALARIE_KO": {
        "min": 0,
        "max": 25,
        "message": "Le nombre de titres doit être compris entre 0 et 25."
    },
    "SIRET_KO": {
        "message": "Siret invalide."
    },
    "SIRET_RADIE": {
        "message": "Le SIRET correspond à une entreprise fermée ou radiée."
    },
    "SIRET_INCONNU": {
        "message": "Le SIRET n'est pas reconnu."
    },
    "INTERLOCUTEUR_KO": {
        "message": "INTERLOCUTEUR_KO"
    },
    "CLIENT_REDIRECTION_EXC": {
        "message": "CLIENT_REDIRECTION_EXC"
    },
    "ADRESSE_KO": {
        "nom": "Adresse1",
        "message": "L'adresse n'est pas valide."
    },
    "ADRESSE_KO2": {
        "nom": "Adresse1,Ville,CodePostal",
        "message": "L'adresse n'est pas valide."
    },
    "COMPLEMENT_CARACT_KO": {
        "message": "Le complément d'adresse contient des caractères spéciaux."
    },
    "SERVEUR_KO": {
        "message": "La demande n’a pu être prise en compte, merci de recharger la page."
    },
    "DEDOUBLONNAGE_KO": {
        "message": "Nous avons détecté une anomalie, serait-ce un <strong>doublon</strong>? <br />Vous pouvez modifier le bénéficiaire, le supprimer ou ignorer l'erreur et conserver les deux."
    },
    "TELEPHONE_KO": {
        "nom": "Tel",
        "regex": "^((?!08)(?!338)).[0-9]{9}$",
        "message": "Le téléphone est incorrect."
    },
    "INTERLOCUTEUR_CHAMPS_KO": {
        "nom": "Nom,Prenom,Tel,Email,Fonction,Civilite",
        "nullable": false,
        "message": "Tous les champs sont obligatoires."
    }
}
