; (function () {
    "use strict";

    function simuSSoController($scope, $state, $window, $injector, localStorageService, StateService, PanierService, ValidationService, ClientService, WordingService, FeedlyEtapeService, CompanyService, Client, Adresse, Precommande, Interlocuteur, Ressources, tunnel) {
        var self = this,
            oldInterlocuteur,
            isInterlocutorChanged = false,
            $validationProvider = $injector.get('$validation'),
            $filter = $injector.get('$filter'),
            panier = StateService.getPanier();

            /* WORDING */ /* A retirer quand ca passera correctement dans tunnel controler */
            if (!localStorageService.get(Ressources.WORDING_KEY)) {
                WordingService.allWording().then(function (result) {
                    localStorageService.set(Ressources.WORDING_KEY, result);
                });
            }

            
            var ReferenceEtapeTunnel = Ressources.TYPES_TUNNEL.CMA + ":" + StateService.getCurrent();

            localStorageService.set(Ressources.REFERENCE_ETAPE_TUNNEL_KEY, ReferenceEtapeTunnel);

            PanierService.creerPanierCMA()
                .then(function (creerPanierResult) {
                    var nouveauPanier = creerPanierResult.m_Item1;
                    nouveauPanier.Beneficiaires = [];
                    nouveauPanier.Beneficiaires.push(creerPanierResult.m_Item2);
                    $scope.$emit(Ressources.EVENT_UPDATE_PANIER, nouveauPanier);

                    localStorageService.remove(Ressources.PAGE_CONFIRMATION_KEY);

                    localStorage[Ressources.APPLICATION_PREFIX + Ressources.PANIER_KEY] = nouveauPanier.Id;

                    var updatePreco = {};
                    updatePreco.ReferenceEtapeTunnel = ReferenceEtapeTunnel;
                    PanierService.enregistrerPrecommande(nouveauPanier.Id, nouveauPanier.Precommandes[0].Id, updatePreco);

                    // var partenaire = localStorageService.get(Ressources.FEDERATION_KEY);
                    var idPartenaire = localStorageService.get(Ressources.SELECTED_CONTRACT_KEY)[1];

                    ClientService.creerConfigurationOperationnelle(nouveauPanier.Client.Id, {
                        IdClientProspect: nouveauPanier.Client.Id,
                        BIC: "",
                        IBAN: "",
                        IdProduit: 240,
                        EquipePartenaire: "",
                        IdPartenaire: idPartenaire
                    });

                    localStorage[Ressources.APPLICATION_PREFIX + Ressources.PANIER_KEY] = nouveauPanier.Id;//Inscription de l'ID du panier dans le local storage en cas de fermeture de la fenêtre
                    $scope.$emit(Ressources.EVENT_UPDATE_PANIER, nouveauPanier);
                    StateService.next();
                    //StateService.go("tunnel-cma.siret");
                })
                .catch(function (error) { });

    };

    module.exports = {
        name: "SimuSSoController",
        fn: simuSSoController
    };

})();