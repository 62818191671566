;(function() {
    "use strict";

    function numberWithSpaces(){
        return function (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
    }

    module.exports = {
        name: "numberWithSpaces",
        fn: numberWithSpaces
    }

})();
