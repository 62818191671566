; (function () {
    "use strict";

    var simulation = {
        bindings: {
            vf: '<',
            ppp: '<',
            stepper: '<',
            onUpdate: '&'
        },
        template: function ($element, $attrs) {
            return [
                '<div id="slider"></div>' +
                // '<div class="slider-limit center" style="width: {{ $ctrl.limitWidth }}%">' +
                '<div class="slider-limit center" ng-style="$ctrl.limitStyle">' +
                    '<div class="limit-wrapper">' +
                        '<span class="limit-text">Limite<br> d\'exonération</span>' +
                    '</div>' +
                '</div>'
            ].join('');
        },
        controller: function ($scope, $element, $injector, Ressources) {

            this.$onInit = function () {
                this.vf = this.vf || null;
                this.ppp = this.ppp || Ressources.PART_PATRONALE_PAR_DEFAUT;
            };

            this.$onChanges = function (changes) {
                if (changes.vf !== undefined) {
                    _updateLimitExo(changes.vf.currentValue);
                }
            };

            this.limitWidth = this.limitWidth || 0;
            this.limitStyle = {width: this.limitWidth + '%'};

            var self = this,
                limitWidth = 0,
                $filter = $injector.get('$filter'),
                tolerance = 0,
                limitExoInCents = Ressources.LIMITE_EXONERATION,
                vppMax = Ressources.PPP_MAX_PERCENTAGE;

            var slider = $element.querySelectorAll('#slider')[0],
                _limitExo = 0,
                rangeValue = 10,
                $colorBefore = '#dadada',
                $colorAfter = '#f4f4f4',
                $body = $('body');

            noUiSlider.create(slider, {
                start: [self.ppp],
                range: {
                    'min': 50,
                    '50%': 55,
                    'max': 60,
                },
                snap: true,
                pips: {
                    mode: 'range',
                    density: 5
                },
                tooltips: wNumb({
                    decimals: 2,
                    encoder: function (a) {
                        var displayValue = parseFloat($filter('centsToEuros')(parseInt(self.vf * a / 100)), 10);
                        return displayValue;
                    }
                })
            });

            var noUiBases = slider.querySelectorAll('.noUi-base')[0],
                pips = slider.querySelectorAll('.noUi-marker-large');

            slider.noUiSlider.on('slide', function (values, handle, unencoded, tap, positions) {

                if ((_limitExo < 100) && (this.get() >= limitRatio)) {
                    this.set(limitRatio);
                };

                var positionHandle = positions[0],
                    limitRatio = parseFloat(50 + (_limitExo / 10)).toFixed(2);

                _updateRangeBackgroundGradient(positionHandle, noUiBases, pips);

                $scope.$emit('PPP_updated', values[0]);

            });

            var _updateLimitExo = function () {

                /* Calculate the limit ExoMax value in cents from the PPP_MAX_PERCENTAGE constant to update the position of limitExo element */
                var _limitExoMax = self.vf * (vppMax / 100);

                if ((_limitExoMax - limitExoInCents) >= 100) {
                    self.limitWidth = 100;
                } else if ((_limitExoMax - limitExoInCents) <= 0) {
                    self.limitWidth = 0;
                } else {
                    self.limitWidth = _limitExoMax - limitExoInCents;
                };

                self.limitStyle = {width: self.limitWidth + '%'};

                if (self.stepper) {
                    var _limitExoStep = self.limitWidth;
                    _reloadRange(_limitExoStep);
                };

            };

            var _reloadRange = function (_limitExoStep) {

                if (_limitExoStep > 100 || _limitExoStep < 0) return false;

                var newRange = {};
                _limitExo = Math.round((100 - _limitExoStep) * 100) / 100; /* update local _limitExo with new VF from parent value $CTRL binding */
                if (_limitExo > 50 && _limitExo <= 100) {
                    newRange = {
                        'min': 50,
                        '50%': 55,
                        'max': 60
                    };
                } else if (_limitExo <= 50) {
                    newRange = {
                        'min': 50,
                        'max': 60
                    };
                };

                /* Add on range object the limitExo value if L.E. is between 0, 50 & 100 with tolerance variable */
                if ((((0 + tolerance) <= _limitExo) && (_limitExo <= (50 - tolerance))) || (((50 + tolerance) <= _limitExo) && (_limitExo < (100 - tolerance)))) {
                    var stepRangeValue = parseFloat(((limitExoInCents / self.vf) * 100).toFixed(2), 10);
                    _updateRangeWithLimit(newRange, _limitExo);
                };

                /* Update the slider on updated params & visuals display on VF changes only if ranges options change */
                if (newRange !== slider.noUiSlider.options.range) {
                    _sliderUpdate(newRange, stepRangeValue);
                    var $textLimitEl = $('.limit-text');

                    $('.noUi-value').each(function () {
                        var _bubbleStyle = $(this)[0].style.left;
                        if ((_limitExo !== 100) && (_bubbleStyle === _limitExo + '%')) {
                            if ($textLimitEl.hasClass('hidden')) $textLimitEl.removeClass('hidden');
                            $(this).addClass('limit-exo-value');
                            $(this).prev('.noUi-marker').addClass('limit-exo-bubble');
                            $(this).nextAll('div').hide();
                        } else if (_limitExo === 100 || _limitExo <= 50) {
                            if (!$textLimitEl.hasClass('hidden')) $textLimitEl.addClass('hidden');
                        }
                    });
                };
            };

            /* Set slider on click on big pips styled in CSS  */
            $body.on('click', '.noUi-marker', function (e) {
                var percentage = $(this).next('.noUi-value').text().slice(0, -1);
                slider.noUiSlider.set(parseFloat(percentage, 10));
            });

            $body.on('click', '.noUi-value', function (e) {
                var percentage = $(this).text().slice(0, -1);
                slider.noUiSlider.set(parseFloat(percentage, 10));
            });

            var limitExoRangeValue = null;

            var _updateRangeWithLimit = function (range, limit) {
                var stepRangeValue = parseFloat(((limitExoInCents / self.vf) * 100).toFixed(2), 10);
                limitExoRangeValue = stepRangeValue;
                return range[limit + '%'] = stepRangeValue;
            };

            var _sliderUpdate = function (newRange, stepRangeValue) {
                slider.noUiSlider.destroy();
                self.limitReached = _limitExo === 0 ? true : false;

                if (stepRangeValue) {
                    self.ppp = stepRangeValue < self.ppp ? stepRangeValue : self.ppp;
                }

                noUiSlider.create(slider, {
                    start: [self.ppp],
                    range: newRange,
                    snap: true,
                    pips: {
                        mode: 'steps',
                        format: wNumb({
                            decimals: 1,
                            postfix: '%'
                        })
                    },
                    tooltips: wNumb({
                        decimals: 2,
                        encoder: function (a) {
                            var displayValue = self.limitReached ? parseFloat($filter('centsToEuros')(parseInt(limitExoInCents), 10)) : (Math.round(self.vf * (a / 100)) / 100);
                            // On remet la valeur du tooltip à 5.37 si elle dépasse la valeur max
                            displayValue = displayValue > Ressources.LIMITE_EXONERATION / 100 ? Ressources.LIMITE_EXONERATION / 100 : displayValue;
                            return displayValue;
                        },
                    })
                });

                var noUiBases = slider.querySelectorAll('.noUi-base')[0],
                    pips = slider.querySelectorAll('.noUi-marker-large'),
                    $textLimitEl = $('.limit-text');

                var _blockSliderOnLimit = function () {
                    var limitRatio = parseFloat(50 + (_limitExo / 10)).toFixed(2);

                    if (!$textLimitEl.hasClass('hidden')) {
                        $textLimitEl.addClass('hidden');
                    }
                    this.set(limitRatio);
                };

                slider.noUiSlider.on('slide', function (values, handle, unencoded, tap, positions) {
                    var positionHandle = positions[0],
                        limitRatio = parseFloat(50 + (_limitExo / 10)).toFixed(2),
                        isOnLimit = parseFloat(this.get(), 10) >= parseFloat(limitExoRangeValue, 10);

                    /* If slider value is on LimiExo only we block it but break this function to prevent $emit with real upper slider value */
                    if (isOnLimit) {
                        if (!$textLimitEl.hasClass('hidden')) $textLimitEl.addClass('hidden');
                        _blockSliderOnLimit.apply(this);
                        return false;
                    }


                    if ((_limitExo < 100) && isOnLimit) {
                        _blockSliderOnLimit.apply(this);
                    } else {
                        if ($textLimitEl.hasClass('hidden')) $textLimitEl.removeClass('hidden');
                        _updateRangeBackgroundGradient(positionHandle, noUiBases, pips);
                    }

                    $scope.$emit('PPP_updated', values[0]);

                });

                slider.noUiSlider.on('update', function (values, handle, unencoded, tap, positions) {
                    var positionHandle = positions[0],
                        limitRatio = parseFloat(50 + (_limitExo / 10)).toFixed(2),
                        isOnLimit = parseFloat(this.get(), 10) >= parseFloat(limitExoRangeValue, 10);
                        
                        if (isOnLimit && !$textLimitEl.hasClass('hidden')) {
                            $textLimitEl.addClass('hidden');
                        }

                    _updateRangeBackgroundGradient(positionHandle, noUiBases, pips);
                    $scope.$emit('PPP_updated', values[0]);
                });

            };

            var _updateRangeBackgroundGradient = function (positionHandle, noUiBases, pips) {

                _.map(pips, function (p) {
                    p.style.backgroundColor = p.style.left.slice(0, -1) > positionHandle ? $colorAfter : $colorBefore;
                });

                positionHandle = positionHandle + 4.8;

                var linearGradient = 'linear-gradient( to right, ' + $colorBefore + ' 4.4%, ' + $colorBefore + ' ' + positionHandle + '%, ' + $colorAfter + ' ' + positionHandle + '%, ' + $colorAfter + ' ' + (_limitExo - 7) + '%, transparent ' + (_limitExo - 5.5) + '%, transparent 100%)';
                noUiBases.style.backgroundImage = linearGradient;

            }
        }
    };

    module.exports = {
        name: 'simulation',
        obj: simulation
    };

})();
