;(function() {
  "use strict";

    function validationService (ValidationJSON, Restangular) {
        var parametrage = parametrage || ValidationJSON;

        var chargerMessageErreur = function (code) {
            if (parametrage.hasOwnProperty(code))
                return parametrage[code].message;
            return null;
        };

        var validationQASEmail = function (adresseEmail) {
            return Restangular.all("qas").get("email", { email: adresseEmail });
        };

        var validationLuhn = function (siret) {
            var arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];

            var
              len = siret.length,
              bit = 1,
              sum = 0,
              val;

            if ([14, 9].indexOf(len) < 0) { return false; }

            var zero = Number(siret);
            if (isNaN(zero) || zero === 0) { return false; }

            while (len) {
                val = parseInt(siret.charAt(--len), 10);
                sum += (bit ^= 1) ? arr[val] : val;
            }

            if (sum === 0) { return false; }

            return sum && sum % 10 === 0;
        }; // test Luhn pour le SIRET - format d'entrée : longueur doit être = 14, ne doit pas être = 0

        //Valide les règles sur tous les objets passés en paramètre
        var validerReglesArray = function (regles, objets) {
            var erreurs = [];
            for (var i = 0; i < objets.length; i++) {
                erreurs = erreurs.concat(validerRegles(regles, objets[i]));
            }

            return erreurs.filter(function (elem, index, self) {
                return index == self.indexOf(elem);
            });
        };

        //Valide les règles pour l'objet passé en paramètre
        var validerRegles = function (regles, objet) {
            var erreurs = [];
            for (var i = 0; i < regles.length; i++) {
                var champs = parametrage[regles[i]].nom.split(",");
                for (var j = 0; j < champs.length; j++) {
                    var valeur = objet[champs[j]]
                        var valide = true;
                    if (regles[i] === "AGE_KO") {
                        var age = moment().diff(moment(valeur, 'DDMMYYYY'), 'years');
                        if (valeur == undefined)
                            valide = true;
                        else
                            valide = age <= 85 && age >= 16;
                    } else {
                        valide = validerChamp(regles[i], valeur);
                    }

                    if (!valide) {
                        erreurs.push(regles[i]);
                        break;
                    }
                }
            }

            return erreurs;
        };

        var validerChamp = function (nomChamp, valeur) {
            if (!parametrage.hasOwnProperty(nomChamp)) return true;
            //if (valeur === null || valeur === undefined) return false;
            var regle = parametrage[nomChamp];
            var valid = true;
            if (regle.hasOwnProperty("min") && valeur < regle.min) valid = false;
            else if (regle.hasOwnProperty("max") && valeur > regle.max) valid = false;
            else if (regle.hasOwnProperty("nullable") && !regle.nullable && ((!valeur && valeur !== 0) || (valeur && new RegExp(/^ *$/).exec(valeur) !== null))) valid = false;
            else if (regle.hasOwnProperty("maxLength") && valeur && valeur.length > regle.maxLength) valid = false;
            else if (regle.hasOwnProperty("minSize") && valeur < regle.minSize) valid = false;
            else if (regle.hasOwnProperty("maxSize") && valeur >= regle.maxSize) valid = false;
            else if (regle.hasOwnProperty("regex")) {
                if (valeur == null)
                    valid = true;
                else {
                    var rg = new RegExp(regle.regex, 'g');
                    valid = valeur.toString().match(rg) != null;
                }
            }

            return valid;
        };

        return {
            validerChamp: validerChamp,
            validerRegles: validerRegles,
            validerReglesArray: validerReglesArray,
            validationQASEmail: validationQASEmail,
            chargerMessageErreur: chargerMessageErreur,
            validerSIRET: validationLuhn
        };
    };

    module.exports = {
        name: "ValidationService",
        fn: validationService
    }

})();
