;(function() {
  "use strict";

    function customOrderByType (Ressources) {
        return function (items, field, type, order) {
            var filtered = [];
            angular.forEach(items, function (item) {
                filtered.push(item);
            });
            if (field) {
                if (type === Ressources.TYPE_CHAMPS.STRING) {
                    filtered.sort(function (a, b) {
                        var compare = (a[field]|| "").localeCompare(b[field]);
                        return order ? compare : -compare;
                    });
                } else if (type === Ressources.TYPE_CHAMPS.ENTIER) {
                    filtered.sort(function (a, b) {
                        var compare = a[field] - b[field];
                        return order ? compare : -compare;
                    });
                } else {
                    filtered.sort(function (a, b) {
                        var compare = moment(a[field], "DD/MM/YYYY").toDate() - moment(b[field], "DD/MM/YYYY").toDate();
                        return order ? compare : -compare;
                    });
                }
            }
            return filtered;
        }
    };

    module.exports = {
        name: "customOrderByType",
        fn: customOrderByType
    }

})();
