; (function () {
    "use strict";

    function confirmationController($scope, $http, $window, StateService, localStorageService, Ressources, PanierService, trackingService) {
        var self = this,
            confirmationInfos = {},
            panier = StateService.getPanier();

        if (panier) {
            PanierService.chargerPrecommande(panier.Id, panier.Precommandes[0].Id).then(function (result) {
                var data = result.plain();

                confirmationInfos.NumeroCommande = data.ReferenceClient;
                confirmationInfos.IsCB = data.IdModePaiement == Ressources.MODE_PAIEMENT.CB;
                confirmationInfos.IsVirement = data.IdModePaiement == Ressources.MODE_PAIEMENT.VIREMENT;

                self.NumeroCommande = confirmationInfos.NumeroCommande;
                self.IsCB = confirmationInfos.IsCB;
                self.IsVirement = confirmationInfos.IsVirement;

                localStorageService.set(Ressources.PAGE_CONFIRMATION_KEY, confirmationInfos);
                trackingService.sendTransactionCompleted(panier.Id, data);

                StateService.reset();
            });
        } else {
            confirmationInfos = localStorageService.get(Ressources.PAGE_CONFIRMATION_KEY);
            self.NumeroCommande = confirmationInfos.NumeroCommande;
            self.IsCB = confirmationInfos.IsCB;
            self.IsVirement = confirmationInfos.IsVirement;
        }

        self.preparePdf = function () {
            $scope.$emit('toggleLoadingToaster', {header: "Votre devis est en cours de génération", caption: "Veuillez patientez s'il vous plaît..." + "il vous plaît..." });

            var idFede = localStorageService.get(Ressources.FEDERATION_KEY);

            if (isIE() && isIE() < 10) {
                var devisUrl = 'api/paniers/' + panier.Id + '/precommandes/' + panier.Precommandes[0].Id + '/devis/preparer/' + idFede;
                $window.open(devisUrl);
            } else {
                var devisUrl = 'api/paniers/' + panier.Id + '/precommandes/' + panier.Precommandes[0].Id + '/devis/preparer/' + idFede;

                //$http.post(devisUrl, /*{responseType: 'arraybuffer'}*/JSON.stringify(dataToSend)).then(function (response) {
                $http({
                    url: devisUrl,
                    method: "POST",
                    data: JSON.stringify(panier.Client.Value.Interlocuteur[0]),
                    contentType: "application/json",
                    responseType: 'arraybuffer'
                }).then(function (response) {
                    download(response.data, 'devisCma.pdf', 'application\/pdf');
                    $scope.$emit('closeLoadingToaster');
                }).catch(function (error) {
                    $scope.$emit('closeLoadingToaster');
                });
            }
        }

        function download(data, filename, type) {
            var a = document.createElement("a"),
                file = new Blob([data], { type: type });
            if (window.navigator.msSaveOrOpenBlob)
                window.navigator.msSaveOrOpenBlob(file, filename);
            else {
                var url = URL.createObjectURL(file);
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                setTimeout(function () {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }, 50);
            }
        }

        function isIE() {
            var myNav = navigator.userAgent.toLowerCase();
            return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
        }

    }

    module.exports = {
        name: "ConfirmationController",
        fn: confirmationController
    };

})();
