;(function() {
  "use strict";

  function contactDetailController ($scope, $injector, $q, localStorageService, StateService, ValidationService, ClientService, PanierService, Interlocuteur, Ressources) {
    var self = this,
        $validationProvider = $injector.get('$validation'),
        panier = StateService.getPanier();

    self.interlocutor = panier.Client.Value.Interlocuteur[0];
    var tempInterlocutor = angular.copy(self.interlocutor); // To detect changes

    self.submitContactDetail = function (form) {
        return $validationProvider.validate(form)
            .then(_successValidation)
            .catch(_errorValidation);
    };

    var _errorValidation = function () {
    };
    var _successValidation = function () {
        $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);

        if (JSON.stringify(self.interlocutor) !== JSON.stringify(tempInterlocutor)) { // Has changed
            var patchInterlocutor = {};

            var changedProperties = findChangedProperties(self.interlocutor);

            for (var i = 0, length = changedProperties.length; i < length; i++) {
                patchInterlocutor[changedProperties[i]] = self.interlocutor[changedProperties[i]];
            }

            return ClientService.enregistrerInterlocuteur(panier.Client.Id, self.interlocutor.Id, patchInterlocutor).then(function (enregistrerInterlocuteurResult) {

                //var ReferenceEtapeTunnel = StateService.getTypeTunnel() + ":" + StateService.getCurrent();
                //localStorageService.set(Ressources.REFERENCE_ETAPE_TUNNEL_KEY, ReferenceEtapeTunnel);
                //var updatePreco = {};updatePreco.ReferenceEtapeTunnel = ReferenceEtapeTunnel;
                //PanierService.enregistrerPrecommande(panier.Id, panier.Precommandes[0].Id, updatePreco);

                panier.Client.Value.Interlocuteur[0] = enregistrerInterlocuteurResult.plain();
                $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                return StateService.next();
            }).catch(function (error) {
                return $q.reject();
            });
        } else { // Nothing changes

            //var ReferenceEtapeTunnel = StateService.getTypeTunnel() + ":" + StateService.getCurrent();
            //localStorageService.set(Ressources.REFERENCE_ETAPE_TUNNEL_KEY, ReferenceEtapeTunnel);
            //var updatePreco = {};updatePreco.ReferenceEtapeTunnel = ReferenceEtapeTunnel;
            //PanierService.enregistrerPrecommande(panier.Id, panier.Precommandes[0].Id, updatePreco);

            return StateService.next();
        }
    };

    function findChangedProperties(interlocutor) {
        var _arr = [];
        for (var prop in interlocutor) {
            if (interlocutor[prop] !== tempInterlocutor[prop]) _arr.push(prop);
        }
        return _arr;
    }
};

    module.exports = {
        name: "ContactDetailController",
        fn: contactDetailController
    };

})();
