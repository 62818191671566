module.exports=﻿{
    "config": [
        {
            "persona": "_connected",
            "states": [ 
                {
                    "current": "tunnel.connected.beneficiaire",
                    "next": "tunnel.connected.recapitulatif",
                    "previous": null
                },
                {
                    "current": "tunnel.connected.recapitulatif",
                    "next": "tunnel.connected.confirmation",
                    "previous": "tunnel.connected.beneficiaire"
                },
                {
                    "current": "tunnel.connected.confirmation",
                    "next": null,
                    "previous": "tunnel.connected.recapitulatif"
                }
            ]
        },
        {
            "persona": "_defaults",
            "states": [
                {
                    "current": "tunnel-ctr-intro",
                    "next": "tunnel-ctr.persona",
                    "previous": null
                },
                {
                    "current": "tunnel-ctr.simulation",
                    "next": "tunnel-ctr.persona",
                    "previous":  "tunnel-ctr.intro"
                },
                {
                    "current": "tunnel-ctr.persona",
                    "next": null,
                    "previous": "tunnel-ctr-intro"
                }
            ]
        },
        {
            "persona": "Directeur",
            "states": [
                {
                    "current": "tunnel-ctr-intro",
                    "next": "tunnel-ctr.persona",
                    "previous": null,
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.simulation",
                    "next": "tunnel-ctr.persona",
                    "previous":  "tunnel-ctr.intro"
                },
                {
                    "current": "tunnel-ctr.persona",
                    "next": "tunnel-ctr.valeurfaciale",
                    "previous": "tunnel-ctr-intro",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.persona-ko",
                    "next": "tunnel-ctr.valeurfaciale",
                    "previous": "tunnel-ctr.persona",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.valeurfaciale",
                    "next": "tunnel-ctr.beneficiaire",
                    "previous": "tunnel-ctr.persona",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.beneficiaire",
                    "next": "tunnel-ctr.siret",
                    "previous": "tunnel-ctr.valeurfaciale",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siret",
                    "next": "tunnel-ctr.adresse-saisie",
                    "previous": "tunnel-ctr.beneficiaire",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.adresse-saisie",
                    "next": "tunnel-ctr.coordonnees",
                    "previous": "tunnel-ctr.siret",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.coordonnees",
                    "next": "tunnel-ctr.recapitulatif",
                    "previous": "tunnel-ctr.adresse-saisie",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.recapitulatif",
                    "next": "tunnel-ctr.paiement",
                    "previous": "tunnel-ctr.coordonnees",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.recapitulatif",
                    "next": "tunnel-ctr.devis",
                    "previous": "tunnel-ctr.recapitulatif",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.paiement",
                    "next": "tunnel-ctr.confirmation",
                    "previous": "tunnel-ctr.recapitulatif",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.confirmation",
                    "next": null,
                    "previous": "tunnel-ctr.paiement",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siretInterlocuteurKo",
                    "next": null,
                    "previous": "tunnel-ctr.beneficiaire",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siretChoix",
                    "next": null,
                    "previous": "tunnel-ctr.siret",
                    "onlyConnectedUser": false
                }
            ]
        },
        {
            "persona": "Secrétaire",
            "states": [
                {
                    "current": "tunnel-ctr-intro",
                    "next": "tunnel-ctr.persona",
                    "previous": null,
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.simulation",
                    "next": "tunnel-ctr.persona",
                    "previous":  "tunnel-ctr.intro"
                },
                {
                    "current": "tunnel-ctr.persona",
                    "next": "tunnel-ctr.valeurfaciale",
                    "previous": "tunnel-ctr-intro",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.persona-ko",
                    "next": "tunnel-ctr.valeurfaciale",
                    "previous": "tunnel-ctr.persona",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.valeurfaciale",
                    "next": "tunnel-ctr.beneficiaire",
                    "previous": "tunnel-ctr.persona",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.beneficiaire",
                    "next": "tunnel-ctr.siret",
                    "previous": "tunnel-ctr.valeurfaciale",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siret",
                    "next": "tunnel-ctr.adresse-saisie",
                    "previous": "tunnel-ctr.beneficiaire",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.adresse-saisie",
                    "next": "tunnel-ctr.coordonnees",
                    "previous": "tunnel-ctr.siret",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.coordonnees",
                    "next": "tunnel-ctr.recapitulatif",
                    "previous": "tunnel-ctr.adresse-saisie",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.recapitulatif",
                    "next": "tunnel-ctr.paiement",
                    "previous": "tunnel-ctr.coordonnees",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.devis",
                    "next": "tunnel-ctr.recapitulatif",
                    "previous": "tunnel-ctr.recapitulatif",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.paiement",
                    "next": "tunnel-ctr.confirmation",
                    "previous": "tunnel-ctr.recapitulatif",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.confirmation",
                    "next": null,
                    "previous": "tunnel-ctr.paiement",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siretInterlocuteurKo",
                    "next": null,
                    "previous": "tunnel-ctr.beneficiaire",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siretChoix",
                    "next": null,
                    "previous": "tunnel-ctr.siret",
                    "onlyConnectedUser": false
                }
            ]
        },
        {
            "persona": "Assistant",
            "states": [
                {
                    "current": "tunnel-ctr-intro",
                    "next": "tunnel-ctr.persona",
                    "previous": null,
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.simulation",
                    "next": "tunnel-ctr.persona",
                    "previous":  "tunnel-ctr.intro"
                },
                {
                    "current": "tunnel-ctr.persona",
                    "next": "tunnel-ctr.valeurfaciale",
                    "previous": "tunnel-ctr-intro",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.persona-ko",
                    "next": "tunnel-ctr.valeurfaciale",
                    "previous": "tunnel-ctr.persona",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.valeurfaciale",
                    "next": "tunnel-ctr.beneficiaire",
                    "previous": "tunnel-ctr.persona",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.beneficiaire",
                    "next": "tunnel-ctr.siret",
                    "previous": "tunnel-ctr.valeurfaciale",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siret",
                    "next": "tunnel-ctr.adresse-saisie",
                    "previous": "tunnel-ctr.beneficiaire",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.adresse-saisie",
                    "next": "tunnel-ctr.coordonnees",
                    "previous": "tunnel-ctr.siret",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.coordonnees",
                    "next": "tunnel-ctr.recapitulatif",
                    "previous": "tunnel-ctr.adresse-saisie",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.recapitulatif",
                    "next": "tunnel-ctr.paiement",
                    "previous": "tunnel-ctr.coordonnees",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.devis",
                    "next": "tunnel-ctr.recapitulatif",
                    "previous": "tunnel-ctr.recapitulatif",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.paiement",
                    "next": "tunnel-ctr.confirmation",
                    "previous": "tunnel-ctr.recapitulatif",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.confirmation",
                    "next": null,
                    "previous": "tunnel-ctr.paiement",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siretInterlocuteurKo",
                    "next": null,
                    "previous": "tunnel-ctr.beneficiaire",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siretChoix",
                    "next": null,
                    "previous": "tunnel-ctr.siret",
                    "onlyConnectedUser": false
                }
            ]
        },
        {
            "persona": "Comptable",
            "states": [
                {
                    "current": "tunnel-ctr-intro",
                    "next": "tunnel-ctr.persona",
                    "previous": null,
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.simulation",
                    "next": "tunnel-ctr.persona",
                    "previous":  "tunnel-ctr.intro"
                },
                {
                    "current": "tunnel-ctr.persona",
                    "next": "tunnel-ctr.valeurfaciale",
                    "previous": "tunnel-ctr-intro",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.persona-ko",
                    "next": "tunnel-ctr.valeurfaciale",
                    "previous": "tunnel-ctr.persona",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.valeurfaciale",
                    "next": "tunnel-ctr.beneficiaire",
                    "previous": "tunnel-ctr.persona",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.beneficiaire",
                    "next": "tunnel-ctr.siret",
                    "previous": "tunnel-ctr.valeurfaciale",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siret",
                    "next": "tunnel-ctr.adresse-saisie",
                    "previous": "tunnel-ctr.beneficiaire",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.adresse-saisie",
                    "next": "tunnel-ctr.coordonnees",
                    "previous": "tunnel-ctr.siret",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.coordonnees",
                    "next": "tunnel-ctr.recapitulatif",
                    "previous": "tunnel-ctr.adresse-saisie",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.recapitulatif",
                    "next": "tunnel-ctr.paiement",
                    "previous": "tunnel-ctr.coordonnees",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.devis",
                    "next": "tunnel-ctr.recapitulatif",
                    "previous": "tunnel-ctr.recapitulatif",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.paiement",
                    "next": "tunnel-ctr.confirmation",
                    "previous": "tunnel-ctr.recapitulatif",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.confirmation",
                    "next": null,
                    "previous": "tunnel-ctr.paiement",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siretInterlocuteurKo",
                    "next": null,
                    "previous": "tunnel-ctr.beneficiaire",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siretChoix",
                    "next": null,
                    "previous": "tunnel-ctr.siret",
                    "onlyConnectedUser": false
                }
            ]
        },
        {
            "persona": "Gérant",
            "states": [
                {
                    "current": "tunnel-ctr-intro",
                    "next": "tunnel-ctr.persona",
                    "previous": null,
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.simulation",
                    "next": "tunnel-ctr.persona",
                    "previous":  "tunnel-ctr.intro"
                },
                {
                    "current": "tunnel-ctr.persona",
                    "next": "tunnel-ctr.valeurfaciale",
                    "previous": "tunnel-ctr-intro",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.persona-ko",
                    "next": "tunnel-ctr.valeurfaciale",
                    "previous": "tunnel-ctr.persona",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.valeurfaciale",
                    "next": "tunnel-ctr.beneficiaire",
                    "previous": "tunnel-ctr.persona",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.beneficiaire",
                    "next": "tunnel-ctr.siret",
                    "previous": "tunnel-ctr.valeurfaciale",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siret",
                    "next": "tunnel-ctr.adresse-saisie",
                    "previous": "tunnel-ctr.beneficiaire",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.adresse-saisie",
                    "next": "tunnel-ctr.coordonnees",
                    "previous": "tunnel-ctr.siret",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.coordonnees",
                    "next": "tunnel-ctr.recapitulatif",
                    "previous": "tunnel-ctr.adresse-saisie",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.recapitulatif",
                    "next": "tunnel-ctr.paiement",
                    "previous": "tunnel-ctr.coordonnees",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.devis",
                    "next": "tunnel-ctr.recapitulatif",
                    "previous": "tunnel-ctr.recapitulatif",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.paiement",
                    "next": "tunnel-ctr.confirmation",
                    "previous": "tunnel-ctr.recapitulatif",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.confirmation",
                    "next": null,
                    "previous": "tunnel-ctr.paiement",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siretInterlocuteurKo",
                    "next": null,
                    "previous": "tunnel-ctr.beneficiaire",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siretChoix",
                    "next": null,
                    "previous": "tunnel-ctr.siret",
                    "onlyConnectedUser": false
                }
            ]
        },
        {
            "persona": "Autre",
            "states": [
                {
                    "current": "tunnel-ctr-intro",
                    "next": "tunnel-ctr.persona",
                    "previous": null,
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.simulation",
                    "next": "tunnel-ctr.persona",
                    "previous":  "tunnel-ctr.intro"
                },
                {
                    "current": "tunnel-ctr.persona",
                    "next": "tunnel-ctr.valeurfaciale",
                    "previous": "tunnel-ctr-intro",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.persona-ko",
                    "next": "tunnel-ctr.valeurfaciale",
                    "previous": "tunnel-ctr.persona",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.valeurfaciale",
                    "next": "tunnel-ctr.beneficiaire",
                    "previous": "tunnel-ctr.persona",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.beneficiaire",
                    "next": "tunnel-ctr.siret",
                    "previous": "tunnel-ctr.valeurfaciale",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siret",
                    "next": "tunnel-ctr.adresse-saisie",
                    "previous": "tunnel-ctr.beneficiaire",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.adresse-saisie",
                    "next": "tunnel-ctr.coordonnees",
                    "previous": "tunnel-ctr.siret",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.coordonnees",
                    "next": "tunnel-ctr.recapitulatif",
                    "previous": "tunnel-ctr.adresse-saisie",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.recapitulatif",
                    "next": "tunnel-ctr.paiement",
                    "previous": "tunnel-ctr.coordonnees",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.devis",
                    "next": "tunnel-ctr.recapitulatif",
                    "previous": "tunnel-ctr.recapitulatif",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.paiement",
                    "next": "tunnel-ctr.confirmation",
                    "previous": "tunnel-ctr.recapitulatif",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.confirmation",
                    "next": null,
                    "previous": "tunnel-ctr.paiement",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siretInterlocuteurKo",
                    "next": null,
                    "previous": "tunnel-ctr.beneficiaire",
                    "onlyConnectedUser": false
                },
                {
                    "current": "tunnel-ctr.siretChoix",
                    "next": null,
                    "previous": "tunnel-ctr.siret",
                    "onlyConnectedUser": false
                }
            ]
        }
    ]
}
