; (function () {
    "use strict";

    function homeController($scope, $filter, $injector, $q, StateService, Ressources, PanierService, ClientService, Precommande, localStorageService, wording, $location, trackingService, ContratsService) {
        var self = this,
            $validationProvider = $injector.get('$validation'),
            panier = StateService.getPanier();
            
        $scope.$emit('appLoaded');
        
        // TODO Les wordings chargent mal si on ne le fait pas ici... à étudier plus tard
        if (!localStorageService.get(Ressources.WORDING_KEY)) {
            localStorageService.set(Ressources.WORDING_KEY, wording);
        }

        self.NbBeneficiaireDevis = panier && panier.Precommandes && panier.Precommandes[0]
            ? panier.Precommandes[0].NbBeneficiaireDevis
            : null;
        
        // $scope.isPromoSite = (window.location.hostname.indexOf('promo-ctr') !== -1) ? true : false; --> déportée dans app.controller
        self.faqOpen = true;
        self.answerOpen = false;
        self.showFaq = function () {
            self.faqOpen = !self.faqOpen;
        };

        self.toggleAnswer = function () {
            self.answerOpen = !self.answerOpen;
        };

        self.checkMax = function () {
            if (self.NbBeneficiaireDevis > 50) { 
                trackingService.trackEvent({'event': 'surplusBeneficiaires', 'contact_number_intro': self.NbBeneficiaireDevis});
            }
        };

        self.rerouteFormLead = function () {
            if (self.NbBeneficiaireDevis > 50) { 
                // reroutage vers formlead prod
                window.location.assign('https://contact.edenred.fr/ticket-restaurant?effectif=' + self.NbBeneficiaireDevis);
            }
        }

        self.submit = function (form) {
            self.checkMax();
            self.rerouteFormLead();
            return $validationProvider.validate(form)
                .then(function () {
                    if (StateService.getTypeTunnel() != Ressources.TYPES_TUNNEL.Commande) {
                        StateService.changeTypeTunnel(Ressources.TYPES_TUNNEL.Commande);
                    }
                    var refTunnel = StateService.getTypeTunnel() + ":" + StateService.getCurrent();
                    localStorageService.set(Ressources.REFERENCE_ETAPE_TUNNEL_KEY, refTunnel);
                    return panier && panier.Id
                        ? $q.resolve()
                        : PanierService.creerPanier()
                        .then(function (result) {
                            panier = result.plain();
                            localStorage.setItem(Ressources.APPLICATION_PREFIX + Ressources.PANIER_KEY, panier.Id);
                            var idPartenaire = localStorage.getItem('partnerId');
                            if (idPartenaire && idPartenaire !== 'null') {
                                ContratsService.getContractRanges()
                                .then(function (result) {
                                    const config = result.plain()
                                    var partnerContract = _.find(config, function (r) {
                                        return r.IdPartenaire === idPartenaire
                                            && r.TrancheEffectifMax >= 0
                                            && r.TrancheEffectifMin <= 5;
                                    });

                                    if (partnerContract) {
                                        ClientService.creerConfigurationOperationnelle(panier.Client.Id, {
                                            IdClientProspect: panier.Client.Id,
                                            BIC: null,
                                            IBAN: null,
                                            IdProduit: 240,
                                            EquipePartenaire: "",
                                            IdPartenaire: partnerContract.PtnId
                                        });
                                        localStorage.setItem('partnerRegistered', true);
                                    }
                                })
                            }
                        });
                })
                .then(function () {
                    if (panier.Precommandes && panier.Precommandes[0]) {
                        if (panier.Precommandes[0].NbBeneficiaireDevis !== self.NbBeneficiaireDevis) {
                            return PanierService.enregistrerPrecommande(panier.Id, panier.Precommandes[0].Id, { NbBeneficiaireDevis: self.NbBeneficiaireDevis })
                                .then(function (result) {
                                    panier.Precommandes[0].NbBeneficiaireDevis = result.plain().NbBeneficiaireDevis;
                                });
                        } else {
                            return $q.resolve();
                        }
                    } else {
                        var precommande = new Precommande();
                        precommande.NbBeneficiaireDevis = self.NbBeneficiaireDevis;
                        precommande.IdPointLivraisonParDefaut = panier.Client.Value.PointLivraison[0].Id;
                        precommande.ReferencePLParDefaut = panier.Client.Value.PointLivraison[0].Reference;
                        return PanierService.creerPrecommande(panier.Id, precommande)
                            .then(function (result) {
                                panier.Precommandes = [result.plain()];
                            });
                    }
                })
                .then(function () {
                    if (!panier.Client.Value.Effectif) {
                        panier.Client.Value.Effectif = parseInt(self.NbBeneficiaireDevis);
                        $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                        return ClientService.enregistrerClient(panier.Client.Id, { Effectif: panier.Client.Value.Effectif });
                    } else {
                        return $q.resolve();
                    }
                })
                .then(function () {
                    return StateService.next();
                });
        }

        self.submitSimulation = function (form) {
            //return $validationProvider.validate(form)
            //    .then(function () {
            //        return StateService.go(Ressources.TUNNEL_CTR_SIMULATION, { nbBeneficiaires: self.NbBeneficiaireDevis });
            //    });

            if (!self.NbBeneficiaireDevis) {
                var message = $filter('codeToWording')('SALARIES_KO');
                $scope.$broadcast('SALARIES_KO' + '-error-from-server', message);
                return false;
            } else {
                return StateService.go(Ressources.TUNNEL_CTR_SIMULATION, { nbBeneficiaires: self.NbBeneficiaireDevis });
            }
        }
    }

    module.exports = {
        name: "HomeController",
        fn: homeController
    };

})();
