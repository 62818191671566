;(function() {
    "use strict";

    function Valorisation () {
        return function () {
            this.Id = null;
            this.MontantServicesOptionnels = null;
            this.MontantFraisPort = null;
            this.MontantRemise = null;
            this.MontantVE = null;
            this.MontantFraisServices = null;
            this.MontantTVA = null;
            this.MontantTotalTTC = null;
            this.Date = null;
            this.Type = null;
        };
    };

    module.exports = {
        name: "Valorisation",
        fn: Valorisation
    }

})();
