;(function() {
    "use strict";

    var counter = {
        bindings: {
            limit: '<',
            count: '=',
            index: '<',
            actif: '<'
        },
        transclude: true,
        template: function ($element, $attrs) {
            if (typeof $attrs.class === 'undefined') {
                return [
                    '<p class="counter">' +
                        '<span class="counter-input" ng-transclude></span>' +
                        '<button type="button" class="counter-increment" ng-click="$ctrl.increment()" tabindex="-1">' +
                            '<svg class="icon icon-plus">' +
                                '<use xlink:href="Content/img/svg.svg#icon-plus"></use>' +
                            '</svg>' +
                        '</button>' +
                        '<button type="button" class="counter-decrement" ng-click="$ctrl.decrement()" tabindex="-1">'+
                            '<svg class="icon icon-minus">' +
                                '<use xlink:href="Content/img/svg.svg#icon-minus"></use>' +
                            '</svg>' +
                        '</button>' +
                    '</p>'
                ].join('');
            } else {
                return [
                    '<p class="counter">' +
                        '<span class="counter-input" ng-transclude></span>' +
                        '<button type="button" class="counter-increment" ng-click="$ctrl.increment()" tabindex="-1">' +
                            '<svg class="icon icon-up">' +
                                '<use xlink:href="Content/img/svg.svg#icon-up"></use>' +
                            '</svg>' +
                        '</button>' +
                        '<button type="button" class="counter-decrement" ng-click="$ctrl.decrement()" tabindex="-1">'+
                            '<svg class="icon icon-down">' +
                                '<use xlink:href="Content/img/svg.svg#icon-up"></use>' +
                            '</svg>' +
                        '</button>' +
                    '</p>'
                ].join('');
            }
        },
    controller: function(){
        var self = this;
        this.count = this.count || 0;
        this.limit = this.limit || [];
        this.actif = this.actif || true;

        this.increment = function(){
            if ( this.count >= parseInt(this.limit[1], 10) ) {
                return false;
            }
            this.count++;
        };

        this.decrement = function(){
            if ( this.count <= parseInt(this.limit[0], 10) ) {
                return false;
            }
            this.count--;
        };

    }
};

module.exports = {
    name: 'counter',
    obj: counter
};

})();
