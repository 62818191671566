;(function() {
    "use strict";

    function Contact () {
        return function () {
            this.nom = null;
            this.prenom = null;
            this.telephone = null;
            this.email = null;
            this.siret = null;
        };
    };

    module.exports = {
        name: "Contact",
        fn: Contact
    }

})();
