;(function () {
    "use strict";

    function Devis() {
        return function () {
            this.html = null;
        };
    };

    module.exports = {
        name: "Devis",
        fn: Devis
    }

})();
