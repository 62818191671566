;(function() {
    "use strict";

    function slideInOut ($window) {
        var duration = 350,
            $body = $('body');

        return {
            enter: function(element, done) {
                $body.toggleClass('overflow-body');
                element.css({ display: 'none' });
                element.velocity({ opacity: [ 1, 0 ], translateY: [ '0%', '50%' ], translateZ: 0 }, { easing: [ .26, .6, .67, .97 ], duration: duration, display: 'block', delay: duration })
            },
            leave: function(element, done) {
                element.velocity({ translateY: '-50%', opacity: 0, display: 'none' }, { easing: [ .26, .6, .67, .97 ], duration: duration })
                $window.setTimeout(done, duration);
                $window.setTimeout(function(){
                    $body.toggleClass('overflow-body');
                }, duration*3);
            }
        };
    }

    module.exports = {
        className: '.toggle-footer',
        fn: slideInOut
    };

})();
