; (function () {
    "use strict";

    var parametrage = {

        bindings: {
            client: '<',
            parametresCompte: '<',
            interlocuteur: '<',
            message: '<'
        },
        templateUrl: componentsPath + 'parametrage/comptabilite.html',
        controller: function ($scope, $rootScope, $stateParams, $filter, $validation, $window, $timeout, $compile, Ressources, ClientService, localStorageService) {
            this.select = function (row) {
                self.selectedRow = row;

                if (self.nextDisplayActiverSEPA == true) {

                    self.afficherFeedlyActiverSEPAIfTrue(row);
                }
            }
            var self = this, orderTable = [];
            self.messageCode = self.message;//TODO: exploiter le retour de la variable pour afficher un message en cas de succès ou d'abandon
            self.feedlyUrlModifierCompte = 'Content/views/feedly/modifier-parametrage-comptable.html';
            self.feedlyUrlActiverSEPA = 'Content/views/feedly/passer-au-prelevement.html';
            self.aLesDroits = true;
            self.comptes = [];
            self.thereIsOneOrMoreAccount = false;
            self.division = $stateParams.division;
            if (containsOtherProduct())
                $.map(self.parametresCompte, function (e) {
                    if (e.CodeModePaiement != 4 && _.includes([1, 2, 9, 24, 80, 221, 240], e.CodeProduit) && e.CodeModeFacturation == 0) {
                        e.Statut = '<a class="button valid">Passer au prélèvement</a>';
                    }

                    var compte = null;

                    if (e.IBAN || e.BIC) {
                        compte = (e.IBAN || "") + " - " + (e.BIC || "");
                    }

                    switch (e.CodeProduit) {
                        case 1:
                        case 240:
                        case 80:
                        case 221:
                        case 220:
                        case 2:
                        case 9:
                        case 24:
                            self.comptes.push({
                                codeProduit: e.CodeProduit,
                                produit: e.Produit,
                                compte: compte
                            });
                            break;
                    }
                });


            self.hideThreeDot = true;

            self.dictTitre = {
                'TR': 'Ticket Restaurant®',
                'TS': 'Ticket Service®',
                'TRBV': 'Ticket Restaurant® bénévoles et volontaires',
                'TK': 'Kadéos®',
                'CAP': 'Ticket Service® CAP',
                'TSCAP': 'Ticket Service® CAP',
                'CTR': 'Ticket Restaurant® Carte',
                'TKU': 'Kadéos® Universel',
                'CTK': 'Carte Kadéos®'
            };

            for (var a = 0; a != self.comptes.length; a++) {
                self.comptes[a].produit = self.comptes[a].produit.replace(/\s+/g, '');
            }

            self.validAccounts = _.filter(self.comptes, function (e) {
                return e.compte != null;
            });

            $timeout(function () {
                var activerPrelevementButtons = $("a:contains('Passer au prélèvement')");
                _.forEach(activerPrelevementButtons, function (e) {
                    $(e).replaceWith($(e));
                    $(e).attr('ng-click', '$ctrl.afficherFeedlyActiverSEPA($ctrl.select(row))');
                    $compile($(e))($scope);
                });
            }, 1000);

            Object.defineProperty(self, 'compte', {
                get: function () {
                    return self._compte;
                },
                set: function (value) {
                    this._compte = value;
                    if (value && value != '') {
                        var tab = value.split('-');
                        self.IBAN = tab[0].replace(/\s+/g, '');
                        self.BIC = tab[1].replace(/\s+/g, '');
                    }
                }
            });

            Object.defineProperty(self, 'codeProduit', {
                get: function () { return self._codeProduit; },
                set: function (value) {
                    self._codeProduit = value;
                    if (value != null) {
                        self.IBAN = null;
                        self.BIC = null;
                        var comptes = _.find(self.comptes, { 'codeProduit': value });
                        self.compte = comptes.compte;
                    }
                }
            });
            function containsOtherProduct() {
                var i = self.parametresCompte.length;
                while (i--) {
                    if (self.parametresCompte[i].CodeProduit !== 80 && self.parametresCompte[i].CodeProduit !== 221) {
                        return true;
                    }
                }
                return false;
            }
            self.getSelectedRowData = function () {
                if (!self.selectedRow) return {};
                return _.find(self.parametresCompte, { 'CodeProduit': self.selectedRow[0] });
            };

            self.sidebarExpanded = window.innerWidth < 1280 ? false : true;
            self.sidebarToggle = function (expanded) {
                self.sidebarExpanded = expanded;
            };

            self.peutPasserAuPrevelement = function () {
                return self.getSelectedRowData().CodeModeFacturation == 0 && self.getSelectedRowData().Statut == null;
            }

            self.afficherFeedlyModifierCompte = function () {
                self._codeProduit = self.getSelectedRowData().CodeProduit;
                self.compte = null;
                self.IBAN = null;
                self.BIC = null;
                self.openFeedly(self.feedlyUrlModifierCompte);
            }

            self.afficherFeedlyActiverSEPA = function () {
                self.nextDisplayActiverSEPA = true;
            }

            self.afficherFeedlyActiverSEPAIfTrue = function (row) {
                self.nextDisplayActiverSEPA = false;
                self._codeProduit = self.getSelectedRowData().CodeProduit;
                self.openFeedly(self.feedlyUrlActiverSEPA);
            }

            self.passerAuPrevelement = function (form) {
                return $validation.validate(form).then(function () {
                    var produitSEPA = _.find(self.parametresCompte, { 'CodeProduit': self.codeProduit });
                    produitSEPA.IBAN = self.IBAN;
                    produitSEPA.BIC = self.BIC;
                    produitSEPA.Interlocuteur = self.interlocuteur;
                    produitSEPA.Client = self.client.client;

                    return ClientService.passerAuPrelevement(self.division, produitSEPA).then(function (result) {
                        $window.location.href = result;
                        self.closeFeedly();
                        self.$onInit();
                    }).catch(function (erreur) {
                        var erreurCode = erreur.data.Content[0].ErrorCode;
                        if (erreurCode == "PASSER_PRELEVEMENT_KO") {
                            self.parametresCompte[0].PeuxPasserAuPrelevement = false;
                        }
                        else {
                            self.errorMsg = [$filter('codeToWording')(erreur.data.Content[0].ErrorCode)];
                            self.closeFeedly();
                            self.$onInit();
                        }

                    });

                });
            };

            self.enregistrerCoordonneesBancaires = function (form) {
                $validation.validate(form).then(function () {
                    var produitAMAJ = _.find(self.parametresCompte, { 'CodeProduit': self.codeProduit });
                    produitAMAJ.IBAN = self.IBAN;
                    produitAMAJ.BIC = self.BIC;
                    ClientService.enregistrerCoordonneesBancaires($stateParams.division, produitAMAJ).then(function () {
                        self.messageFeedlyCode = 'MISE_A_JOUR_OK';
                        self.closeFeedly();
                        self.$onInit();
                    }).catch(function (erreur) {
                        self.errorMsg = [$filter('codeToWording')(erreur.data.Content[0].ErrorCode)];
                        self.closeFeedly();
                        self.$onInit();
                    });
                    self.$onInit();
                });
            };

            self.emptyDescriptifNotification = "Vous n'avez aucun produit de disponible";

            var filteredProductList = _.filter(self.parametresCompte, function (p) {
                if (p.IBAN != null) {
                    if (p.IBAN.length > 0) {
                        self.hideCompte = false;
                        self.saisirNouveauCompte = false;
                        self.saisirCompte = true;
                        self.thereIsOneOrMoreAccount = true;
                    }
                }
                else if (self.thereIsOneOrMoreAccount == false) {
                    self.hideCompte = true;
                    self.saisirNouveauCompte = true;
                    self.saisirCompte = false;
                }

                return p.Produit;
            });

            var header = [
                {
                    text: null,
                    type: 'key',
                    getValue: function (d) { return d.CodeProduit; },
                },
                {
                    text: 'Produit',
                    type: 'text',
                    tooltip: null,
                    getValue: function (d) { return d.Produit; }
                },
                {
                    text: 'Mode de règlement',
                    type: 'text',
                    tooltip: null,
                    getValue: function (d) { return d.ModePaiement; }
                },
                {
                    text: 'Condition de règlement',
                    type: 'text',
                    tooltip: null,
                    getValue: function (d) { return d.DelaiPaiement; }
                },
                {
                    text: 'IBAN',
                    type: 'text',
                    tooltip: null,
                    getValue: function (d) { return d.IBAN; }
                },
                {
                    text: 'BIC',
                    type: 'text',
                    tooltip: null,
                    getValue: function (d) { return d.BIC; }
                },
                {
                    text: '                 ',
                    type: 'text',
                    tooltip: null,
                    getValue: function (d) { return d.Statut; },
                    format: function (value) {
                        if (value)
                            return value;
                    }
                }
            ];

            $scope.$watch(angular.bind(self, function () {
                return self.filter;
            }), function (filter) {
            }, true);

            self.resetFilter = function () {
                self.filteredColumnsIndex = [];
                _.forEach(self.head, function (f) {
                    f.filterValue = null;
                });
            };

            self.filteredColumnsIndex = [];

            self.updateFilter = function (column) {
                if (column.filterValue) {
                    if (self.filteredColumnsIndex.indexOf(self.head.indexOf(column)) === -1) {
                        self.filteredColumnsIndex.push(self.head.indexOf(column));
                    }
                } else {
                    self.removeFilter(self.head.indexOf(column));
                }
            };

            self.removeFilter = function (indexFilteredColumn) {
                if (self.filteredColumnsIndex.indexOf(indexFilteredColumn) != -1) {
                    self.filteredColumnsIndex.splice(self.filteredColumnsIndex.indexOf(indexFilteredColumn), 1);
                    self.head[indexFilteredColumn].filterValue = null;
                }
            };

            self.$onInit = function () {
                var body = [];
                var parametrageData = [];

                _.forEach(self.parametresCompte, function (d) {
                    switch (d.CodeProduit) {
                        case 1:
                        case 240:
                        case 80:
                        case 221:
                        case 220:
                        case 2:
                        case 9:
                        case 24:
                            if (self.dictTitre[d.Produit] != undefined && self.dictTitre[d.Produit] != null)
                                d.Produit = self.dictTitre[d.Produit];
                            if (d.DelaiPaiement.indexOf('Delai') !== -1)
                                d.DelaiPaiement = d.DelaiPaiement.replace('Delai', 'Délai');
                            _.forEach(header, function (h) {
                                parametrageData.push(h.getValue(d));
                            });
                            body.push(parametrageData);
                            parametrageData = [];
                            break;
                    }
                });

                self.head = header;
                self.body = body;
            };

            self.openFeedly = function (url) {
                self.feedlyOpen = true;
                self.feedlyUrl = url;
            };

            self.closeFeedly = function () {
                self.feedlyOpen = false;
            };
        }
    };

    module.exports = {
        name: "parametrageComptabilite",
        obj: parametrage
    };

})();