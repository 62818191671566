;(function() {
    "use strict";

    function isoToDate() {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, el, attr, ngModel) {
			    var format = 'DD/MM/YYYY';
			    ngModel.$parsers.unshift(function (value) {
			        if (!value) return null;
			        var momentDate = moment(value, format);
			        if (!momentDate.isValid()) return null;
			        var date = momentDate.toDate();
			        date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
			        return date.toISOString();
				});
			    ngModel.$formatters.unshift(function (value) {
			        if (value === null) return null;
			        return moment(value).format(format);
				});
			}
		};
    };

    module.exports = {
        name: 'isoToDate',
        fn: isoToDate
    };

})();
