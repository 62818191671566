; (function () {
    "use strict";

    /* SORT COMPONENT */

    var benefSort = {
        bindings: {
            inputs: '<',
            onToggle: '&'
        },
        templateUrl: componentsPath + 'beneficiary/beneficiary.sort.html',
        controller: function ($injector) {
            var self = this;

            self.reverse = true;

            self.toggle = function (label) {
                self.label = label;
                self.reverse = !self.reverse;
                self.onToggle({
                    $sortData: {
                        label: label,
                        reverse: self.reverse
                    }
                });
            };
        },
        controllerAs: 'sortCtrl'
    };

    module.exports = {
        name: 'sort',
        obj: benefSort
    };

})();
