; (function () {
    "use strict";

    var headerApp = {
        templateUrl: componentsPath + 'header/header.app.html',
        controllerAs: 'header'
    };

    module.exports = {
        name: 'headerApp',
        obj: headerApp
    };

})();
