;(function() {
    "use strict";

    function xhrLoader (cfpLoadingBarProvider) {

        cfpLoadingBarProvider.latencyThreshold = 2000;
        cfpLoadingBarProvider.includeBar = false;
        cfpLoadingBarProvider.includeSpinner = false;

    };

    module.exports = {
        fn: xhrLoader
    };

})();
