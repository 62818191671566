;(function() {
  "use strict";

  function valeurFacialController($scope, $injector, $analytics, PanierService, StateService, localStorageService, Precommande, Ressources) {
        var self = this,
            isFacialValueChanged = false,
            panier = StateService.getPanier();
            // $analyticsProvider = $injector.get('$analyticsProvider');

        self.feedlyUrlRecontacte = Ressources.FEEDLY_URL.recontactezMoi;
        self.VPP_MIN = Ressources.VPP_MIN;
        self.VPP_MAX = Ressources.VPP_MAX;
        self.VPP = Ressources.PART_PATRONALE_PAR_DEFAUT;
        self.limitWidth = null,
        self.economy = {},
        self.simulation = {
            nbrSalaries: 1,
            monthTitles: 1,
            totalTitles: 0,
            openDays: 0
        };

        /* Initialiser une précommande de base (au cas où localstorage n'a pas de valeur de Panier) */
        var storedPrecommande = panier.Precommandes[0],
            oldPrecommand = angular.copy(panier.Precommandes[0]);

        storedPrecommande.ValeurFacialeParDefaut = storedPrecommande.ValeurFacialeParDefaut ? storedPrecommande.ValeurFacialeParDefaut : Ressources.VALEUR_FACIAL_PAR_DEFAUT;
        storedPrecommande.PartPatronaleParDefaut = storedPrecommande.PartPatronaleParDefaut ? storedPrecommande.PartPatronaleParDefaut : Ressources.PART_PATRONALE_PAR_DEFAUT;

        self.precommande = storedPrecommande;

        if (panier.Precommandes[0].NbTitresDevis) {

            self.nbTitres = panier.Precommandes[0].NbTitresDevis;
            self.nbBeneficiaires = panier.Precommandes[0].NbBeneficiaireDevis;

            self.precommande.NbTitresDevis = self.nbTitres;
            self.precommande.NbBeneficiaireDevis = self.nbBeneficiaires;

        } else {

            if (self.precommande.NbBeneficiaireDevis) {
                self.nbBeneficiaires = self.precommande.NbBeneficiaireDevis;
            } else {
                self.nbBeneficiaires = Ressources.NB_BENEFS_PAR_DEFAUT;
                self.precommande.NbBeneficiaireDevis = Ressources.NB_BENEFS_PAR_DEFAUT;
                panier.Precommandes[0] = self.precommande;
                //$scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
            }

            if (!self.precommande.NbTitresDevis) {
                self.nbTitres = Ressources.NB_TITRES_PAR_DEFAUT;
                self.precommande.NbTitresDevis = Ressources.NB_TITRES_PAR_DEFAUT;
                panier.Precommandes[0] = self.precommande;
                //$scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
            }

        }

        var modifiedPrecommande = {};
        modifiedPrecommande.PartPatronaleParDefaut = self.precommande.PartPatronaleParDefaut;
        modifiedPrecommande.ValeurFacialeParDefaut = self.precommande.ValeurFacialeParDefaut;
        PanierService.enregistrerPrecommande(panier.Id, self.precommande.Id, modifiedPrecommande);

        /* SLIDER VFP */
        self.sliderVFP = {
            options: {
                animate: true,
                start: [self.precommande.ValeurFacialeParDefaut],
                range: {
                    'min': [Ressources.VF_MIN],
                    'max': [Ressources.VF_MAX]
                },
                tooltips: wNumb({
                    decimals: 2,
                    encoder: function (a) {
                        return a / 100;
                    },
                }),
                connect: 'lower',
                step: 5,
                behaviour: 'snap'
            },
            events: {
                change: function (value, handle, unencoded) {
                    self.precommande.ValeurFacialeParDefaut = parseInt(value[0].toString(), 10);
                    _updateLimitExo();

                    $analytics.eventTrack('slide_vf', {
                        eventType: 'click',
                        category: 'velctr_event_category',
                        label: 'montant_titre',
                        url: 'commande/ctr/valeur',
                        value: self.precommande.ValeurFacialeParDefaut
                    });
                }
            }
        };

        $scope.$on('PPP_updated', function (e, newValue) {
            self.precommande.PartPatronaleParDefaut = parseFloat(newValue, 10);

            $analytics.eventTrack('slide_pp', {
                eventType: 'click',
                category: 'velctr_event_category',
                label: 'prise_en_charge_entreprise',
                url: 'commande/ctr/valeur',
                value: self.precommande.PartPatronaleParDefaut
            });

            // tc_events_7 (this, 'eventTracker ', {'tcevent_category' : 'velctr_event_category', 'tcevent_action' : 'prise_en_charge_entreprise', 'tcevent_url' : 'commande/ctr/valeur'})

        });

        /* SUBMIT */
        self.submitValeurFacial = function () {
            isFacialValueChanged = false;

            if (oldPrecommand.Id !== self.precommande.Id
                || oldPrecommand.PartPatronaleParDefaut !== self.precommande.PartPatronaleParDefaut
                || oldPrecommand.ValeurFacialeParDefaut !== self.precommande.ValeurFacialeParDefaut) {
                isFacialValueChanged = true;
            }

            if (isFacialValueChanged || StateService.getTypeTunnel() == Ressources.TYPES_TUNNEL.CMA) {
                panier.Precommandes[0].IdInterlocuteurCGV = null;
                $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                /* ASSIGN CHANGED PROPERTIES (PartPatronaleParDefaut, ValeurFacialeParDefaut) */
                var modifiedPrecommande = {};
                modifiedPrecommande.PartPatronaleParDefaut = self.precommande.PartPatronaleParDefaut;
                modifiedPrecommande.ValeurFacialeParDefaut = self.precommande.ValeurFacialeParDefaut;

                return PanierService.enregistrerPrecommande(panier.Id, self.precommande.Id, modifiedPrecommande).then(function(enregistrerPrecommandeResult) {

                    //var ReferenceEtapeTunnel = StateService.getTypeTunnel() + ":" + StateService.getCurrent();
                    //localStorageService.set(Ressources.REFERENCE_ETAPE_TUNNEL_KEY, ReferenceEtapeTunnel);
                    //var updatePreco = {ReferenceEtapeTunnel: ReferenceEtapeTunnel};
                    //PanierService.enregistrerPrecommande(panier.Id, panier.Precommandes[0].Id, updatePreco);

                    /* PASSE EN PROCHAIN ÉTAPE */
                    return StateService.next();
                    /* PASSE EN PROCHAIN ÉTAPE */
                });
            } else {
                //var ReferenceEtapeTunnel = StateService.getTypeTunnel() + ":" +StateService.getCurrent();
                //localStorageService.set(Ressources.REFERENCE_ETAPE_TUNNEL_KEY, ReferenceEtapeTunnel);
                //var updatePreco = {};updatePreco.ReferenceEtapeTunnel = ReferenceEtapeTunnel;
                //PanierService.enregistrerPrecommande(panier.Id, panier.Precommandes[0].Id, updatePreco);

                /* PASSE EN PROCHAIN ÉTAPE */
                return StateService.next();
            }
        };

        var _updateLimitExo = function () {

            var _limitExoMax = self.precommande.ValeurFacialeParDefaut * (Ressources.VPP_MAX / 100),
                positionLimit = _limitExoMax - Ressources.LIMITE_EXONERATION;

            if (positionLimit >= 100) {
                self.limitWidth = 100;
            } else if (positionLimit <= 0) {
                self.limitWidth = 0;
            } else {
                self.limitWidth = positionLimit;
            }

        };

        var _placeIdsInSliders = function () {
            var ids = document.getElementsByClassName('noUi-handle noUi-handle-lower');
            window.setTimeout(function(){
                ids[0].setAttribute("id", "curseur-vf")
                ids[1].setAttribute("id", "curseur-pp")
            }, 600)
        }

        _updateLimitExo();
        _placeIdsInSliders();
    };

    module.exports = {
        name: "ValeurFacialController",
        fn: valeurFacialController
    };

})();
