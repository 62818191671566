;(function () {
    "use strict";

    var headerActions = {
        bindings: {
            breadcrumbLabel: '<'
        },
        templateUrl: componentsPath + 'header/header.actions.html',
        controllerAs: 'headerActions',
        controller: function ($rootScope, localStorageService, Ressources) {
            var self = this;
            var storedToken = localStorageService.get(Ressources.TOKEN_KEY);
            self.breadcrumbLabel = this.breadcrumbLabel;
            self.urlHomePageV1 = Ressources.ESPACE_CLIENT_URL + "Account/SignInToken?token=" + storedToken.access_token;
            
            self.actionFromHeader = function(actions) {
                $rootScope.$broadcast('action:fromHeader', actions);
            }
            
            if ($rootScope.$location.$$path == "/parametres/comptabilite") {
                self.ShowCompParam = true;
            }
        }
    };

    module.exports = {
        name: 'headerActions',
        obj: headerActions
    };

})();
