;(function() {
    "use strict";

    function featureFlag(featureFlags, $http) {
        featureFlags.set($http.get('api/features').then(function(result) {
            return result.data.Content[0];
        }));
    }

    module.exports = {
        runFn: featureFlag
    };
})();