;(function() {
    "use strict";

    function numberToString() {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, el, attr, ngModel) {
			    ngModel.$parsers.push(function (value) {
					return parseInt(value, 10);
				});
				ngModel.$formatters.push(function (value) {
					if (value === null) return null;
					return '' + value;
				});
			}
		};
    };

    module.exports = {
        name: 'numberToString',
        fn: numberToString
    };

})();
