; (function () {
    'use strict';

    var app = angular.module('tunnel.connected.factures', [
        'ui.router'
    ]).config(function ($stateProvider) {
        var facturesUrl = 'tunnel.connected.factures';

        $stateProvider.state(facturesUrl, {
            url: '/factures?division&numeroFacture&raisonSociale',
            views: {
                'header@': {
                    template: '<header-blank breadcrumb-label="state"></header-blank>',
                    controller: function ($scope) {
                        $scope.state = 'Factures et avoirs';
                    }
                },
                'content': {
                    template: '<factures factures="factures"></factures>',
                    controller: function ($scope, factures) {
                        $scope.factures = factures;
                    }
                }
            },
            resolve: {
                factures: function (token, ClientService, $stateParams, $q) {
                    if (!$stateParams.division) {
                        return $q.reject();
                    }

                    return ClientService.getFactures($stateParams.division).then(function (result) {
                        return result;
                    }, function (error) {
                        return error;
                    });
                }
            }
        });
    });

    var componentFolders = ({"factures.component":require("./factures.component.js"),"factures":require("./factures.js")});

    for (var prop in componentFolders) {
        if (prop.indexOf('component') != -1) {
            app.component(componentFolders[prop].name, componentFolders[prop].obj);
        }
    }

    module.exports = app;
})();