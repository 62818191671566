;(function () {
    "use strict";

    var filtersFolders = (function () {var f = require("./index.js");f["centsToEuros"]=require("./centsToEuros.js");f["codeToWording"]=require("./codeToWording.js");f["customFilter.filter"]=require("./customFilter.filter.js");f["customOrder.filter"]=require("./customOrder.filter.js");f["customOrderByType.filter"]=require("./customOrderByType.filter.js");f["customSort.filter"]=require("./customSort.filter.js");f["dateToString.filter"]=require("./dateToString.filter.js");f["index"]=require("./index.js");f["numberWithSpace"]=require("./numberWithSpace.js");f["searchWording"]=require("./searchWording.js");f["unique.filter"]=require("./unique.filter.js");return f;})(),
        filters = angular.module('app.filters', []);

    for (var prop in filtersFolders) {
        if (prop !== "index") {
            var filtersObject = require("./" + prop + ".js");
            filters.filter(filtersObject.name, filtersObject.fn);
        }
    }

    module.exports = filters;

})();
