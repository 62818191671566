;(function () {
    "use strict";

    var search = {
        bindings:{
            searchBoxActive: '<',
            searchQuery: '<',
        },
        templateUrl: componentsPath + 'search/search.html',
        controllerAs: '$searchBox',
        controller: function ($scope, searchService) {
            var self = this;

            self.searchBoxActive = this.searchBoxActive || false;
            self.searchQuery = this.searchQuery || null;

            self.$onInit = function(){
                self.searchBoxActive = typeof(self.searchBoxActive !== 'undefined') ? self.searchBoxActive : false;
                self.searchQuery = typeof(self.searchQuery !== 'undefined') ? self.searchQuery : null;
            }

            self.clearQuery = function(e){
                self.searchBoxActive = false;
                self.searchQuery = null;
            }

            $scope.$watch(angular.bind(self, function () {
                return self.searchQuery;
            }), function (newVal, oldVal) {
                if (newVal != oldVal) {
                    searchService.setQuery(self.searchQuery);
                }
            }, true);

        }
    };

    module.exports = {
        name: 'search',
        obj: search
    };

})();
