; (function () {
    "use strict";

    var stepper = {
        bindings: {
            disponible: '<',
            panier: '<'
        },
        templateUrl: 'Content/views/components/stepper/stepper.tunnel.html',
        controller: function ($rootScope, $state, Ressources, LexiqueService, StateService, localStorageService) {
            var self = this;

            $('body').on('click', '.stepper-link.active, .stepper-link.filled', function (e) {
                $state.go($(this).data('state'));
            });

            this.$onChanges = function (changesObj) {
                if (!changesObj.panier)
                    return;
                var model = changesObj.panier.currentValue;
                var fonction = _.find(Ressources.PERSONAS.properties, function (e) { return e.Libelle == StateService.getPersona(); });
                if (!fonction)
                    return;
                var stepperConf = StateService.getStepperConf()[fonction.Id.toString()];
                self.sections = stepperConf.sections;
                _.flatMap(stepperConf.sections, 'entrees').forEach(function (entree) {
                    if (entree.ignore)
                        return;
                    entree.estValide = entree.estActif = false;
                    var url = self.chargerUrl(entree.url);
                    var states = _(_.map(entree.multiActif, self.chargerUrl))
                        .concat(url)
                        .compact()
                        .uniq()
                        .value();
                    var activeState = _.findLast(states, function(s) { return StateService.isAvailable(s); });
                    var estActif = !!activeState;
                    var current = _.some(states, function(s) { return StateService.getCurrent() === s; });
                    var estValide = estActif && StateService.isCompleted(activeState);
                    if (self["charger" + entree.propriete]) {
                        var valeur = self["charger" + entree.propriete](model);
                        if (valeur || valeur === 0) {
                            if (entree.hasOwnProperty("prefix"))
                                valeur = entree.prefix + valeur;
                            if (entree.hasOwnProperty("suffixe"))
                                valeur = valeur + entree.suffixe;
                        }
                        entree.valeur = valeur;
                    }
                    entree.estValide = estValide;
                    entree.estActif = estActif;
                    entree.current = current;
                    entree.state = activeState || url;
                });
                
                _.forEach(self.sections, function (section) {
                    section.dernier = _.every(section.entrees, function(entree) { return entree.estValide; });
                });

                self.sections = _.merge({}, self.sections);
            }

            $rootScope.$on('$stateChangeSuccess', function(event, toState) {
                _.flatMap(self.sections, 'entrees').forEach(function(entree) {
                    var states = _(entree.state)
                        .concat(_.map(entree.multiActif, self.chargerUrl))
                        .compact()
                        .uniq()
                        .value();
                    entree.current = _.some(states, function (s) { return toState.name === s; });
                });
            });

            self.chargerTitre = function (code) {
                return LexiqueService.chargerValeur(code);
            }

            self.chargerEmail = function (panier) {
                if (panier.Client && panier.Client.Value && panier.Client.Value.Interlocuteur.length > 0) {
                    return panier.Client.Value.Interlocuteur[0].Email;
                }
                return null;
            }

            self.chargerValeurFaciale = function (panier) {
                if (panier.Precommandes.length > 0 && panier.Precommandes[0].ValeurFacialeParDefaut)
                    return panier.Precommandes[0].ValeurFacialeParDefaut / 100;
                return null;
            }

            self.chargerContrat = function (panier) {
                var tarifs = localStorageService.get(Ressources.SELECTED_CONTRACT_KEY);
                if (panier.Precommandes && panier.Precommandes[0].Contrat) {
                    var tarif = _.find(tarifs, function (e) {
                        return e.IdContratType == panier.Precommandes[0].Contrat.CodeContratType;
                    });
                    if (tarif) {
                        return tarif.FraisAbonnement / 100;
                    }
                }
                return null;
            }

            self.chargerSalaries = function (model) {
                //if (StateService.getTypeTunnel() == Ressources.TYPES_TUNNEL.CMA) {
                //    return model.Precommandes[0].NbBeneficiaireDevis
                //    ? model.Precommandes[0].NbBeneficiaireDevis
                //    : null;
                //}

                //return model.beneficiaires && model.beneficiaires.length > 0
                //    ? model.beneficiaires.length
                //    : null;
                if (StateService.getTypeTunnel() != Ressources.TYPES_TUNNEL.CMA && model.beneficiaires && model.beneficiaires.length > 0) {
                    return model.beneficiaires.length;
                } else if (StateService.getTypeTunnel() == Ressources.TYPES_TUNNEL.CMA && model.Precommandes[0].NbBeneficiaireDevis) {
                    return model.Precommandes[0].NbBeneficiaireDevis;
                } else {
                    return null;
                }
            }

            self.chargerRaisonSociale = function (panier) {
                if (panier.Client && panier.Client.Value)
                    return panier.Client.Value.RaisonSociale;
                return null;
            }

            self.chargerInterlocuteur = function (panier) {
                if (panier.Client && panier.Client.Value && panier.Client.Value.Interlocuteur.length > 0) {
                    var interlocuteur = panier.Client.Value.Interlocuteur[0];
                    if (interlocuteur.Prenom && interlocuteur.Nom) {
                        return interlocuteur.Prenom + " " + interlocuteur.Nom;
                    }
                }
                return null;
            }

            self.chargerUrl = function (code) {
                return Ressources[code] || code;
            }

            self.chargerRecapitulatif = function (panier) {
                return panier.Precommandes[0].IdInterlocuteurCGV != null ? LexiqueService.chargerValeur("STEPPER_RECAP") : null;
            }
        },
        controllerAs: 'stepper'
    };

    module.exports = {
        name: 'stepper',
        obj: stepper
    };

})();
