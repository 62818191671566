;(function() {
    "use strict";

    function stateAvailableOnDom ($rootScope, $http, $state, $stateParams, $location) {
        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;
        $rootScope.$location = $location;
    }

    module.exports = {
        runFn: stateAvailableOnDom
    };

})();
