;(function() {
    "use strict";

    function emitLastRepeaterElement() {
        return {
            restrict: 'A',
            link: function (scope, el, attr) {
                if (scope.$last){
                    scope.$emit('LastRepeaterElement');
                }
            }
        };
    };

    module.exports = {
        name: 'emitLastRepeaterElement',
        fn: emitLastRepeaterElement
    };

})();
