;(function() {
    "use strict";

    function centsToEuros(){
        return function (cents) {
            return (parseInt(cents) / 100).toFixed(2);
        }
    }

    module.exports = {
        name: "centsToEuros",
        fn: centsToEuros
    }

})();
