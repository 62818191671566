;(function() {
    "use strict";

    function localStorageConfig (localStorageServiceProvider) {
        localStorageServiceProvider
            .setPrefix('excv2')
            .setStorageType('sessionStorage')
            .setNotify(true, true);
    };

    module.exports = {
        fn: localStorageConfig
    };

})();
