;(function() {
    "use strict";
    var isReprisePanier = false;
  
      function trackingService(PanierService, $analytics, $location, deviceDetector) {
          
          var setReprisePanier = function (val) {
            isReprisePanier = val;
          }
  
          var getStepping = function() {
              let stepping = ['/intro', '/commande/ctr/email', '/commande/ctr/valeur', '/commande/ctr/beneficiaire', '/commande/ctr/siret', '/commande/ctr/adresse/saisie', '/commande/ctr/coordonnees', '/commande/ctr/recapitulatif', '/commande/ctr/paiement/', '/commande/ctr/confirmation'];
              let dapath = $location.path();
              return (stepping.indexOf(dapath) !== -1) ? stepping.indexOf(dapath) : null
          };
          var getProfession = function (prof) {
              var translProf = null
              switch (prof) {
                  case 6:
                      translProf = 'Assistant';
                      break;
                  case 9:
                      translProf = 'Comptable';
                      break;
                  case 4:
                      translProf = 'Directeur';
                      break;
                  case 18:
                      translProf = 'Gérant';
                      break;
                  case 7:
                      translProf = 'Secrétaire';
                      break;
                  case 2:
                  default:
                      translProf = 'Autre';
              }
              return translProf;
          };
          var getEuroFromCents = function (nb) {
              if (!nb || parseFloat(nb,10) == NaN) { return null }
              return (parseFloat(nb,10)/100).toFixed(2)
          };

          var calldrift = function (url) {
              if (window.drift) {
                window.drift.page(url)
              }
          }
  
          var trackEvent = function(info) {
              let dataLayer = window.dataLayer || [];
              let linfo = JSON.parse(JSON.stringify(info));
              if (dataLayer && linfo) {
                  dataLayer.push(linfo);
                  // console.log('event:', linfo);
              }
          };
  
          var trackBasket55 = function (id) {
              var dataLayer = window.dataLayer || null;
              if (dataLayer && id === 'noId') {
                  const tempSession = JSON.parse(sessionStorage.getItem('excv2.panier'));
                  let myLayer = {
                      'event': 'virtualPageView',
                      'website': 'VEL_CTR',
                      'env_work': ($location.host() === 'commande.ticketrestaurant.fr') ? 'Prod' : ($location.host() === 'commande.preprod.ticketrestaurant.fr') ? 'Preprod' : 'Recette', // "Recette", "Preprod" ou "Prod"
                      'page': $location.path(),
                      'page_category': 'tunnel',
                      'partner_id': localStorage.getItem('partnerId')
                  }
                  if (myLayer.page != '/intro') {
                      var chkStep = getStepping(tempSession);
                      if (chkStep !== null && chkStep !== 9) { // pas de eeAction en step9
                          myLayer['eeAction'] = 'eeCheckout';
                          myLayer['checkoutStep'] = chkStep;
                      }
                      myLayer['funnel_entrance_type'] = (isReprisePanier)? 'Reprise de panier' : 'Nouvelle commande'; // "Reprise de panier" ou "Nouvelle commande"
                      myLayer['contact_number_intro'] = tempSession ? tempSession.Client.Value.Effectif : null; // Nombre de bénéficiaires saisis à l'étape intro
                      myLayer['products'] = [{
                          'name': 'CTR',
                          'id': 240,
                          'variant': null
                      }]
                  }
                  dataLayer.push(myLayer);
                  // console.log('tracker55noId:', myLayer);
                  calldrift($location.path());
                  return
              }
              if (dataLayer && id && id !== 'noId') {
                  PanierService.chargerPanierCompletWithHeaders(id)
                  .then(function(_panier){
                      var daheaders = JSON.parse(JSON.stringify(_panier.headers()));
                      var redCartOrderId = JSON.parse(daheaders['x-exc-tracking']).OrderId || null;
                      _panier = _panier.data.plain();
                      var device = deviceDetector.isMobile() ? 'mobile'
                          : deviceDetector.isDesktop() ? 'desktop' : 'tablet';
                      var order_payement_methods = null;
                      var transacMethod =  _panier.Precommandes[0].IdModePaiement;
                      var chkStep = getStepping(_panier);

                      if (redCartOrderId != null) {
                        redCartOrderId = redCartOrderId.replace("RedCart_", "")
                      }

                      switch(transacMethod){
                          case 1:
                              order_payement_methods = 'Virement';
                              break;
                          case 2:
                              order_payement_methods = 'Chèque';
                              break;
                          case 4:
                              order_payement_methods = 'Prélevement';
                              break;
                          case 7:
                              order_payement_methods = 'CB';
                              break;
                          default :
                              order_payement_methods = transacMethod;
                      }
                      let myLayer = {
                          'event': 'virtualPageView',
                          'website': 'VEL_CTR',
                          'env_work': ($location.host() === 'commande.ticketrestaurant.fr') ? 'Prod' : ($location.host() === 'commande.preprod.ticketrestaurant.fr') ? 'Preprod' : 'Recette', // "Recette", "Preprod" ou "Prod"
                          'page': $location.path(),
                          'page_category': 'tunnel',
                          'partner_id': localStorage.getItem('partnerId'),
                          'funnel_entrance_type': (isReprisePanier)? 'Reprise de panier' : 'Nouvelle commande', // "Reprise de panier" ou "Nouvelle commande"
                          'products': [{
                              'name': 'CTR',
                              'id': 240,
                              'variant': null
                          }]
                      }
                      if (chkStep < 9) {
                        myLayer['checkoutStep'] = chkStep;
                      }
                      if (redCartOrderId != null) {
                          myLayer['order_id'] = redCartOrderId;
                      }
                      if (chkStep > 0 && chkStep < 9) {
                          myLayer['eeAction'] = 'eeCheckout';
                      }
                      if (chkStep === 1) {
                          myLayer['contact_number_intro'] = _panier ? _panier.Precommandes[0].NbBeneficiaireDevis : null; // Nombre de bénéficiaires saisis à l'étape intro
                      }
                      if (chkStep === 2) {
                          myLayer['profession'] = _panier ? getProfession(_panier.Client.Value.Interlocuteur[0].Fonction) : null;
                      }
                      if (chkStep === 3) {
                          myLayer['valeur_faciale'] = _panier ? getEuroFromCents(_panier.Precommandes[0].ValeurFacialeParDefaut) : null;
                          myLayer['part_patronale_pourcentage'] = _panier ? _panier.Precommandes[0].PartPatronaleParDefaut : null;
                          myLayer['part_patronale_valeur'] = _panier ?  getEuroFromCents(_panier.Precommandes[0].ValeurFacialeParDefaut * _panier.Precommandes[0].PartPatronaleParDefaut / 100) : null;
                      }
                      if (chkStep > 3 && chkStep < 10) {
                          myLayer.products[0].quantity = _panier ? _panier.Beneficiaires.length : null;
                      }
                      if (chkStep > 3 && chkStep < 8) {
                          myLayer['contact_number'] = _panier ? _panier.Beneficiaires.length : null;
                      }
                      if (chkStep > 6  || chkStep < 10) {
                        myLayer.products[0]['price'] = (_panier && _panier.Precommandes[0].Valorisation != null && _panier.Beneficiaires.length)? (parseFloat(_panier.Precommandes[0].Valorisation.MontantVE, 10) / _panier.Beneficiaires.length).toFixed(2) : null; // somme du chargement HT / quanti
                      }
                      if (chkStep === 9) {
                          myLayer['order_payment_method'] = order_payement_methods;
                          myLayer['order_service_charges'] = (_panier && _panier.Precommandes[0].Valorisation != null)? (_panier.Precommandes[0].Valorisation.MontantEmissionCartes + _panier.Precommandes[0].Valorisation.MontantFraisGestion + _panier.Precommandes[0].Valorisation.MontantServicesOptionnels).toFixed(2) : null;
                          myLayer['transactionId'] =  (_panier && _panier.Precommandes[0].Valorisation != null)? _panier.Precommandes[0].Valorisation.Id : null;
                          myLayer['transactionTotal'] = (_panier && _panier.Precommandes[0].Valorisation != null)? parseFloat(_panier.Precommandes[0].Valorisation.MontantTotalHt, 10).toFixed(2) : null;
                          myLayer['transactionTax'] = (_panier && _panier.Precommandes[0].Valorisation != null)? parseFloat(_panier.Precommandes[0].Valorisation.MontantTVA, 10).toFixed(2) : null;
                          myLayer['transactionShipping'] = (_panier && _panier.Precommandes[0].Valorisation != null)? parseFloat(_panier.Precommandes[0].Valorisation.MontantFraisPort, 10).toFixed(2) : null;
                          // myLayer.products[0]['sku'] =  _panier.Precommandes[0].IdArticle.toString() || _panier.Precommandes[0].IdProduit.toString();
                      }
                      dataLayer.push(myLayer);
                      // console.log('tracker55widthId:', id, myLayer);
                      calldrift($location.path());
                  })
                  .catch(function(error){
                      throw('getPanierKo');
                  });
              }
          };

  
          var trackBasketNoId = function () {            
              trackBasket55('noId');
          };
          
          var trackBasketById = function (id) {
              trackBasket55(id);
          };
  
          var sendTransactionCompleted = function(id, commande) {
              // trackBasket55(id);
  
              /*
              var dataLayer = window.dataLayer = window.dataLayer || [];
              
              PanierService.chargerPanierComplet(id)
                  .then(function(_panier){
  
                      _panier = _panier.plain();
  
                      var nbBenefs = 0;
                      if (_panier.Beneficiaires != null && _panier.Beneficiaires.length > 0) {
                          nbBenefs = _panier.Beneficiaires.length;
                      } else {
                          nbBenefs = _panier.Precommandes[0].NbBeneficiaireDevis;
                      }
                      
                      
                      var myObj = {
                          'event': 'transaction_completed',
                          'transactionId': commande.ReferenceClient, // id de la transaction
                          'transactionAffiliation': commande.IdModePaiement, // en minuscule par exemple ‘cb’ ou ‘virement’
                          'transactionTotal': parseFloat(_panier.Precommandes[0].Valorisation.MontantTotalHt.toFixed(2), 10), // toujours deux decimales après la virgule somme du ticket caise HT (correspond entre autres au basket_amount)
                          'transactionTax': parseFloat(_panier.Precommandes[0].Valorisation.MontantTVA.toFixed(2), 10), // TVA toujours en euros
                          'transactionShipping': _panier.Precommandes[0].Valorisation.FraisPort, // frais de gestion
                          'transactionProducts': [{
                              'sku': _panier.Precommandes[0].IdArticle.toString() || _panier.Precommandes[0].IdProduit.toString(), // product_id
                              'name': 'ctr', // ‘ctr’
                              'category': '', // laisser vide
                              'price': parseFloat(_panier.Precommandes[0].Valorisation.MontantVE.toFixed(2), 10), // somme du chargement HT
                              'quantity': nbBenefs // nombre de benefs
                          }]
                      };
  
                      // dataLayer.push(myObj);
                  })
                  .catch(function(error){
                      throw('getPanierKo');
                  });
              */
          }
  
          return {
              trackBasketNoId: trackBasketNoId,
              trackBasketById: trackBasketById,
              trackEvent : trackEvent,
              sendTransactionCompleted: sendTransactionCompleted,
              setReprisePanier: setReprisePanier
          };
      };
  
      module.exports = {
          name: "trackingService",
          fn: trackingService
      }
  
  })();
  