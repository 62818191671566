; (function () {
    'use strict';

    var app = angular.module('tunnel.forgotpassword', ['ui.router']);

    var componentFolders = ({"forgotpassword-emailsent.component":require("./forgotpassword-emailsent.component.js"),"forgotpassword-reinit.component":require("./forgotpassword-reinit.component.js"),"forgotpassword.component":require("./forgotpassword.component.js"),"forgotpassword":require("./forgotpassword.js")});

    for (var prop in componentFolders) {
        if (prop.indexOf('component') != -1) {
            app.component(componentFolders[prop].name, componentFolders[prop].obj);
        }
    }

    app.config(function ($stateProvider) {
        $stateProvider
            .state('tunnel.forgotpassword-step1', {
                url: '/forgotpassword/step1',
                params: {
                    tokenKo: null,
                    login: null
                },
                views: {
                    'header@': {
                        template: '<header-connection></header-connection>'
                    },
                    'content': {
                        template: '<forgot-password class="connection-wrapper"></forgot-password>'
                    }
                }
            })
            .state('tunnel.forgotpassword-step2', {
                url: '/forgotpassword/step2',
                views: {
                    'header@': {
                        template: '<header-connection></header-connection>'
                    },
                    'content': {
                        template: '<email-sent forgot-password-obj="forgotPasswordObj" class="connection-wrapper"></email-sent>',
                        controller: function ($scope, forgotPasswordObj) {
                            $scope.forgotPasswordObj = forgotPasswordObj;
                        }
                    }
                },
                resolve: {
                    forgotPasswordObj: function ($state, $timeout, AuthenticationService) {
                        var forgotPasswordObj = AuthenticationService.getUserInfo();
                        if (!forgotPasswordObj || !forgotPasswordObj.isEmailSent) {
                            $timeout(function () {
                                $state.go('tunnel.forgotpassword-step1');
                            });
                        }
                        return forgotPasswordObj;
                    }
                }
            })
            .state('tunnel.forgotpassword-step3', {
                url: '/forgotpassword/step3?token',
                views: {
                    'header@': {
                        template: '<header-connection></header-connection>'
                    },
                    'content': {
                        template: '<reinit-password required-infos="requiredInfos" class="connection-wrapper"></reinit-password>',
                        controller: function ($scope, requiredInfos) {
                            $scope.requiredInfos = requiredInfos;
                        }
                    }
                },
                resolve: {
                    requiredInfos: function ($state, $stateParams, $timeout, AuthenticationService) {
                        return AuthenticationService.validateTokenPassword($stateParams.token).then(function (result) {
                            return result.data.Content[0];
                        }).catch(function (error) {
                            $timeout(function () {
                                $state.go('tunnel.forgotpassword-step1', { tokenKo: true });
                            });
                        });
                    }
                }
            })
            .state('tunnel.forgotpassword-step4', {
                url: '/forgotpassword/step4',
                params: {
                    login: null
                },
                views: {
                    'header@': {
                        template: '<header-connection></header-connection>'
                    },
                    'content': {
                        template: '<login class="connection-wrapper"></login>'
                    }
                }
            });
    });
})();