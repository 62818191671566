; (function () {
    "use strict";

    function tunnelController($rootScope, $scope, $state, $timeout, $injector, $compile, $filter, $q, LexiqueService, ContratsService,
        ContactService, PanierService, ClientService, CompanyService, localStorageService, WordingService, Ressources, StateService, Precommande, Adresse, wording, tunnel, $location, trackingService) {

        var self = this;
        self.panier = null;
        self.stepperTemplateUrl = Ressources.STEPPER_TEMPLATE.Default;
        var $validationProvider = $injector.get('$validation');
        var panier = StateService.getPanier();
        self.typeStepper = StateService.getStepperConf();

        //var referenceTunnel = localStorageService.get(Ressources.REFERENCE_ETAPE_TUNNEL_KEY);
        //if (referenceTunnel && referenceTunnel.split(':').length > 0) {
        //    var typeTunnel = referenceTunnel.split(':')[0];
        //    if (typeTunnel != StateService.getTypeTunnel()) {
        //        StateService.changeTypeTunnel(typeTunnel);
        //    }
        //}

        /* WORDING */
        if (!localStorageService.get(Ressources.WORDING_KEY)) {
            var wordings = wording;
            if (wordings.length > 0)
                localStorageService.set(Ressources.WORDING_KEY, wordings);
            else
                WordingService.allWording().then(function (result) {
                    localStorageService.set(Ressources.WORDING_KEY, result);
                });
        }

        /* TUNNEL => gérer dans les resovlers des routes abstracts */
        StateService.changeTypeTunnel(tunnel);

        $scope.$emit('appLoaded');

        //console.log(StateService.getTypeTunnel());

        self.isCMATunnel = function () {
            return (StateService.getTypeTunnel() == Ressources.TYPES_TUNNEL.CMA);
        };

        $scope.$watch("$parent.app.panier", function (panier, o) {
            if (panier) {
                self.panier = panier;
                self.hydrateFormulaireEtreRecontacte(panier);
            }
        }, true);

        /* ETRE RECONTACTER */
        self.contactForEtreRecontacteFormulaire = {
            Nom: "",
            Prenom: "",
            Tel: "",
            Email: "",
            Siret: "",
            EmailReadonly: false,
            SiretReadonly: false
        }

        self.recontactAvailable = function() {
            return !panier || !panier.Client || !panier.Client.Value || panier.Client.Value.CodeStatutDivision == 0;
        };

        self.hydrateFormulaireEtreRecontacte = function (panier) {
            if (panier && panier.Client && panier.Client.Value) {
                self.contactForEtreRecontacteFormulaire.Siret = panier.Client.Value.Siret;
            }
            if (panier && panier.Client && panier.Client.Value && panier.Client.Value.Interlocuteur.length > 0) {
                self.contactForEtreRecontacteFormulaire.Nom = panier.Client.Value.Interlocuteur[0].Nom;
                self.contactForEtreRecontacteFormulaire.Prenom = panier.Client.Value.Interlocuteur[0].Prenom;
                self.contactForEtreRecontacteFormulaire.Tel = panier.Client.Value.Interlocuteur[0].Tel;
                if (panier.Client.Value.Interlocuteur[0].Email != Ressources.EMAIL_SIMULATION) {
                    self.contactForEtreRecontacteFormulaire.Email = panier.Client.Value.Interlocuteur[0].Email;
                } else {
                    self.contactForEtreRecontacteFormulaire.Email = null;
                }
            }
            if (self.contactForEtreRecontacteFormulaire.Email && self.contactForEtreRecontacteFormulaire.Siret) {
                self.contactForEtreRecontacteFormulaire.EmailReadonly = true;
                self.contactForEtreRecontacteFormulaire.SiretReadonly = true;
            }
        }

        self.CmaHelp = function () {
            if (StateService.getCurrent() === Ressources.TUNNEL_CMA_ADRESSE) {
                return "CMA_CONTENT_AIDE_ADRESSE-SAISIE";
            } else if (StateService.getCurrent() === Ressources.TUNNEL_CMA_COORDONNEES) {
                return "CMA_CONTENT_AIDE_COORDONNEES";
            } else if (StateService.getCurrent() === Ressources.TUNNEL_CMA_VALEUR) {
                return "CMA_CONTENT_AIDE_VALEURFACIALE";
            } else if (StateService.getCurrent() === Ressources.TUNNEL_CMA_NB_BENEFICIAIRE) {
                return "CMA_CONTENT_AIDE_SALARIES_TITRES";
            } else if (StateService.getCurrent() === Ressources.TUNNEL_CMA_RECAPITULATIF) {
                return "CMA_CONTENT_AIDE_RECAP";
            } else {
                return "CMA_HELP_CONTENT";
            }
        },

        self.envoiFormulaireRecontacte = function (form) {
            trackingService.trackEvent({'event': 'validationEtreRecontacte', 'page': $location.path()});
            panier = StateService.getPanier();
            return $validationProvider.validate(form)
                .then(function (result) {
                    var contact = {};
                    contact.Nom = form.lastname.$viewValue;
                    contact.Prenom = form.firstname.$viewValue;
                    contact.Tel = form.phone.$viewValue;
                    contact.Email = form.email.$viewValue;
                    contact.Siret = form.siret.$viewValue;

                    var panierPromise = function() {
                        return panier && panier.Id
                            ? $q.resolve()
                            : PanierService.creerPanier()
                                .then(function (panierResult) {
                                    panier = panierResult.plain();
                                    localStorage.setItem(Ressources.APPLICATION_PREFIX + Ressources.PANIER_KEY, panier.Id);
                                })
                                .then(function() {
                                    var precommande = new Precommande();
                                    precommande.NbBeneficiaireDevis = self.NbBeneficiaireDevis;
                                    precommande.IdPointLivraisonParDefaut = panier.Client.Value.PointLivraison[0].Id;
                                    precommande.ReferencePLParDefaut = panier.Client.Value.PointLivraison[0].Reference;
                                    return PanierService.creerPrecommande(panier.Id, precommande);
                                })
                                .then(function (precoResult) {
                                    panier.Precommandes = [precoResult.plain()];
                                });
                    };

                    var clientPromise = function () {
                        return panier.Client.Value.Siret && panier.Client.Value.RaisonSociale && panier.Client.Value.Adresse && panier.Client.Value.Siret === contact.Siret
                            ? $q.resolve()
                            : ClientService.enregistrerClient(panier.Client.Id, { siret: contact.Siret }, panier.Precommandes[0].Id)
                                .then(function (clientResult) {
                                    var client = clientResult.plain();
                                    panier.Client.Value.Siret = client.Siret;
                                    panier.Client.Value.RaisonSociale = client.RaisonSociale;
                                    panier.Client.Value.Adresse = new Adresse();
                                    panier.Client.Value.Adresse.Adresse1 = client.Adresse.Adresse1;
                                    panier.Client.Value.Adresse.Adresse2 = client.Adresse.Adresse2;
                                    panier.Client.Value.Adresse.Adresse3 = client.Adresse.Adresse3;
                                    panier.Client.Value.Adresse.CodePostal = client.Adresse.CodePostal;
                                    panier.Client.Value.Adresse.Ville = client.Adresse.Ville;
                                    panier.Client.Value.Adresse.EstNormaliseeQAS = client.Adresse.EstNormaliseeQAS;
                                })
                                .catch(function (error) {
                                    var errorCode;
                                    var message;
                                    switch (error.data.Content[0].ErrorCode) {
                                        case "ERREUR_VELCTR1":
                                        case "ERREUR_VELCTR2":
                                            errorCode = "INTERLOCUTEUR_KO";
                                            message = $filter('codeToWording')('FEEDLY_CONTACT_' + errorCode);
                                            break;
                                        case "CHOIX_DIVISION_CTR":
                                        case "CLIENT_REDIRECTION_EXC":
                                            errorCode = "EXC";
                                            message = $filter('codeToWording')('FEEDLY_CONTACT_' + errorCode);
                                            break;
                                        default:
                                            errorCode = error.data.Content[0].ErrorCode;
                                            message = $filter('codeToWording')(errorCode);
                                    }
                                    $scope.$broadcast('FEEDLY_CONTACT_' + errorCode + '-error-from-server', message);
                                    return $q.reject();
                                });
                    };

                    var interlocuteurPromise = function () {
                        if (panier.Client.Value.Interlocuteur && panier.Client.Value.Interlocuteur.length > 0) {
                            var differences = _.differenceWith(
                                _.toPairs(_.pick(contact, ['Nom', 'Prenom', 'Tel', 'Email'])),
                                _.toPairs(_.pick(panier.Client.Value.Interlocuteur[0], ['Nom', 'Prenom', 'Tel', 'Email'])),
                                _.isEqual);
                            if (differences.length === 0) {
                                return $q.resolve();
                            } else {
                                return ClientService.enregistrerInterlocuteur(panier.Client.Value.Id, panier.Client.Value.Interlocuteur[0].Id, _.fromPairs(differences))
                                    .then(function (interlocuteurResult) {
                                        panier.Client.Value.Interlocuteur[0] = interlocuteurResult.plain();
                                    })
                                    .catch(function (error) {
                                        var errorCode = error.data.Content[0].ErrorCode;
                                        var message = $filter('codeToWording')(errorCode);
                                        $scope.$broadcast('FEEDLY_CONTACT_' + errorCode + '-error-from-server', message);
                                        return $q.reject();
                                    });
                            }
                        } else {
                            return ClientService.creerInterlocuteur(panier.Client.Value.Id, _.pick(contact, ['Nom', 'Prenom', 'Tel', 'Email']))
                                .then(function (interlocuteurResult) {
                                    panier.Client.Value.Interlocuteur = panier.Client.Value.Interlocuteur || [];
                                    panier.Client.Value.Interlocuteur.push(interlocuteurResult.plain());
                                })
                                .catch(function (error) {
                                    var errorCode = error.data.Content[0].ErrorCode;
                                    var message = $filter('codeToWording')(errorCode);
                                    $scope.$broadcast('FEEDLY_CONTACT_' + errorCode + '-error-from-server', message);
                                    return $q.reject();
                                });
                        }
                    };


                    // Les promises ne peuvent pas être lancées en simultané: si l'interlocuteur n'existe pas on ne peut pas mettre à jour le siret...
                    return panierPromise()
                        .then(interlocuteurPromise)
                        .then(clientPromise)
                        .then(function () {
                            $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                            return ContactService.envoiFormulaireRecontacte(contact, panier, 15)
                                .then(function () {
                                    panier.Client.Value.CodeStatutDivision = 1;
                                    $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                                });
                        })
                        .catch(function (error) {
                            if (!error) {
                                return $q.reject();
                            }
                            var errorCode = error.data.Content[0].ErrorCode;
                            var message = error.data.Content[0].Message;
                            $scope.$broadcast(errorCode + '-error-from-server', message);
                            return $q.reject();
                        });
                });
        };
        /* ETRE RECONTACTE */

        self.stepperDisponible = function () {
            return self.panier
                && StateService.getPersona()
            /*&& StateService.isAvailable(StateService.getPrev())*/
            /*&& StateService.getTypeTunnel() == Ressources.TYPES_TUNNEL.Commande*/;
        };

        self.etapeSuivanteDisponible = function () {
            return StateMachineService.isNextStateAvailable();
        }

        self.changeState = function (state) {
            var erreur = StateMachineService.goTo(state);
            // Code hyper temporaire à remplacer lorsque les messages d'erreurs génériques seront disponibles en toaster
            if (erreur == true && $('#forNavigationKo').length == 0) {
                $compile($('#errorBox').append(
                    '<p class="validation-invalid box-alert  animated" id="forNavigationKo" ng-class="app.animErrorBox" ng-bind-html="\'NAVIGATION_KO\' | codeToWording"></p>'
                ))($scope);
            }
        }
    };

    module.exports = {
        name: "TunnelController",
        fn: tunnelController
    };

})();
