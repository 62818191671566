;(function() {
    "use strict";

    function feedlyStateService(FeedlyStateConfiguration) {
        var etapeActuelle = 0;

        var resetState = function() {
            etapeActuelle = 0;
        };

        var getCurrentState = function (pageState) {
            if (FeedlyStateConfiguration.hasOwnProperty(pageState)) {
                var feedlyStateList = FeedlyStateConfiguration[pageState];
                var feedlyStateToLoad = feedlyStateList[etapeActuelle];
                return feedlyStateToLoad;
            }
            return null;
        };

        var getEtapes = function (state) {
            return FeedlyStateConfiguration[state];
        };

        var getNextState = function (pageState) {
            if (FeedlyStateConfiguration.hasOwnProperty(pageState)) {
                etapeActuelle++;
                var feedlyStateList = FeedlyStateConfiguration[pageState];
                if (feedlyStateList.length >= etapeActuelle) {
                    var feedlyStateToLoad = feedlyStateList[etapeActuelle];
                    return feedlyStateToLoad;
                }
            }
            return null;
        };

        var getPreviewState = function (pageState) {
            if (FeedlyStateConfiguration.hasOwnProperty(pageState)) {
                etapeActuelle--;
                var feedlyStateList = FeedlyStateConfiguration[pageState];
                if (feedlyStateList.length >= etapeActuelle) {
                    var feedlyStateToLoad = feedlyStateList[etapeActuelle];
                    return feedlyStateToLoad;
                }
            }
            return null;
        };

        return {
            getCurrentState: getCurrentState,
            getNextState: getNextState,
            getEtapes: getEtapes,
            getPreviewState: getPreviewState,
            resetState: resetState
    };
    };

    module.exports = {
        name: "FeedlyStateService",
        fn: feedlyStateService
    }
})();
