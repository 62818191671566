; (function () {
    "use strict";

    function descriptifService($filter, Ressources) {

        var SEUIL_EXO = Ressources.LIMITE_EXONERATION;
        var MAX_VF_TR_CTR = 19;
        var MAX_VF_CAP = 500;
        var MAX_VF_TS = 865;
        var MAX_VF_TRBV_BENEVOLE = 6.3;
        var MAX_PPP_TR_CTR = 60;

        var MIN_VF_TRBV = 1;
        var MIN_VF_CAP_TS = 0.01;
        var MIN_PPP_TR_CTR = 50;

        var DEFAULT_PPP_TR_CTR = 50; // was 55 

        var CTR_CODE = 240;
        var TR_CODE = 1;
        var TS_CODE = 2;
        var CAP_CODE = 9;
        var TRBV_CODE = 24;


        var facialValueSetting = [
            {
                productId: CTR_CODE,
                facialValues: [
                    {
                        min: null,
                        max: MAX_VF_TR_CTR,
                        subProductId: null
                    }
                ]
            }, {
                productId: TR_CODE,
                facialValues: [
                    {
                        min: null,
                        max: MAX_VF_TR_CTR,
                        subProductId: null
                    }
                ]

            }, {
                productId: TS_CODE,
                facialValues: [
                    {
                        min: MIN_VF_CAP_TS,
                        max: MAX_VF_TS,
                        subProductId: null
                    }
                ]

            }, {
                productId: CAP_CODE,
                facialValues: [
                    {
                        min: MIN_VF_CAP_TS,
                        max: MAX_VF_CAP,
                        subProductId: null
                    }
                ]

            }, {
                productId: TRBV_CODE,
                facialValues: [
                    {
                        min: MIN_VF_TRBV,
                        max: MAX_VF_TRBV_BENEVOLE,
                        subProductId: 139
                    },
                    {
                        min: MIN_VF_TRBV,
                        max: SEUIL_EXO,
                        subProductId: 140
                    }
                ]
            }
        ];

        var pppSliderConfig = {
            min: MIN_PPP_TR_CTR,
            max: MAX_PPP_TR_CTR,
            'default': DEFAULT_PPP_TR_CTR
        };

        var productList = [{
            value: CTR_CODE,
            text: "Ticket Restaurant® Carte",
            code: "CTR",
            showSubProduct: false
        }, {
            value: TR_CODE,
            text: "Ticket Restaurant®",
            code: "TR",
            showSubProduct: false
        }, {
            value: TS_CODE,
            text: "Ticket Service®",
            code: "TS",
            showSubProduct: true
        }, {
            value: CAP_CODE,
            text: "Ticket Service® CAP",
            code: "CAP",
            showSubProduct: true
        }, {
            value: TRBV_CODE,
            text: "Ticket Restaurant® bénévoles et volontaires",
            code: "TRBV",
            showSubProduct: true
        }];


        var nameOnPaperTypeList = [{
            value: 0,
            text: "Non"
        }, {
            value: 1,
            text: "Premier Titre uniquement"
        }, {
            value: 2,
            text: "Sur tous les titres"
        }];

        var headerColumns = [
            {
                text: "id",
                type: "key",
                getValue: function (d) {
                    return d.IdentifiantDescriptif;
                }
            },
            {
                text: 'Produit',
                type: 'number',
                tooltip: null,
                getValue: function (d) {
                    return d.Produit;
                },
                format: function (value) {
                    var product = _.find(productList, function (p) {
                        return p.value === value
                    });
                    if (product) {
                        return product.text;
                    }
                    return "";
                },
                filterValue: null,
                filterType: "select",
                filterOptions: null
            },
            {
                text: 'Libellé',
                type: 'text',
                tooltip: null,
                getValue: function (d) {
                    return d.Libelle;
                },
                format: function (value) {
                    return value;
                },
                filterValue: null,
                filterType: null
            },
            {
                text: 'Valeur faciale',
                type: 'currency',
                tooltip: null,
                getValue: function (d) {
                    return d.Montant;
                },
                format: function (value) {
                    /* TO DO : bind $filter directly into the view */
                    return $filter("currency")(value);
                },
                filterValue: null,
                filterType: "text"
            },
            {
                text: 'Part employeur (%)',
                type: 'number',
                tooltip: null,
                getValue: function (d) {
                    return d.PartPatronalePourcentage;
                },
                format: function (value) {
                    return value + ' %';
                },
                filterValue: null,
                filterType: "text"
            },
            {
                text: 'Part employeur (€)',
                type: 'currency',
                tooltip: null,
                getValue: function (d) {
                    return d.PartPatronaleMontant;
                },
                format: function (value) {
                    return $filter("currency")(value);
                },
                filterValue: null,
                filterType: "text"
            },
            {
                text: 'Etat',
                type: 'text',
                tooltip: null,
                getValue: function (d) {
                    return d.Etat;
                },
                format: function (value) {
                 return value ? 'actif ' : 'inactif';
                },
                filterValue: null,
                filterType: null
            }
            //{
            //    text: 'Logo',
            //    type: 'bool',
            //    tooltip: null,
            //    getValue: function (d) {
            //        return d.Logo;
            //    },
            //    format: function (value) {
            //        return value ? 'Oui' : 'Non';
            //    },
            //    filterValue: null,
            //    filterType: null
            //},
            //{
            //    text: 'Nom sur les titres papier',
            //    type: 'number',
            //    tooltip: null,
            //    getValue: function (d) {
            //        return d.TypePersonnalisation;
            //    },
            //    format: function (value) {
            //        switch (value) {
            //            case 0:
            //                return "Non";
            //            case 1:
            //                return "Premier Titre uniquement";
            //            case 2:
            //                return "Sur tous les titres";
            //            default:
            //                return "";
            //        }
            //    },
            //    filterValue: null,
            //    filterType: "select",
            //    filterOptions: nameOnPaperTypeList
            //}
        ];

        /* 
         *  Former la configuration des slider de valeur faciale des produits
         */
        var getFacialValueSettings = function (descriptifs, products) {
            var settings = _.filter(facialValueSetting, function (s) {
                var product = _.find(products, function (p) {
                    return p.value === s.productId;
                });

                if (!product) return false;

                if (product.value === TR_CODE || product.value === CTR_CODE) {
                    s.facialValues[0].min = _.minBy(descriptifs, function (d) {
                        return d.Produit === s.productId ? d.Montant : 99999999; //999999999: plus gros possible
                    }).Montant;
                    s.facialValues[0].default = s.facialValues[0].min >= (Ressources.LIMITE_EXONERATION * 2 / 100) ? s.facialValues[0].min : (Ressources.LIMITE_EXONERATION * 2 / 100);
                }

                return true;
            });

            return settings;
        };

        var getTableHeaders = function (descriptifs) {
            var filteredProductList = _.filter(productList, function (p) {
                return _.find(descriptifs, function (d) {
                    return d.Produit === p.value;
                });
            });

            headerColumns[1].filterOptions = filteredProductList;

            return headerColumns;
        };

        var getProducts = function (clientContracts, descriptifs) {
            //var monoDescriptifs = _.filter(descriptifs, function (d) {
            //    return !d.IsMultiVF;
            //});

            for (var a = 0; a != descriptifs.length; a++) {
                if (descriptifs[a].Produit != 1 && descriptifs[a].Produit != 240 && descriptifs[a].Produit != 241) {
                    descriptifs.splice(a, 1);
                    a--;
                }
            }
            var creationProducts = _.filter(productList, function (p) {
                return _.some(descriptifs, function (d) {
                    return d.Produit === p.value;
                });
            });

            _.forEach(creationProducts, function (p) {
                if (!p.showSubProduct) return;

                p.creationSubProducts = [];

                var productDescriptifs = _.filter(descriptifs, function (d) {
                    return d.Produit === p.value;
                });

                _.forEach(productDescriptifs, function (pd) {
                    if (_.every(p.creationSubProducts, function (sp) {
                        return sp.value !== pd.IdentifiantSegment;
                    })) {
                        p.creationSubProducts.push({
                            value: pd.IdentifiantSegment,
                            text: pd.LibelleSegment
                        });
                    }
                });
            });


            return creationProducts;
        };

        var canBeDuplicated = function (idProduit, clientContracts) {
            var contract = _.find(clientContracts, function (c) {
                return c.IdentifiantProduit == idProduit;
            });

            if (!contract) return false;

            return contract.GestionValeurFaciale;
        }

        var getPPPSetting = function () {
            return pppSliderConfig;
        };

        var getNameOnPaperType = function () {
            return nameOnPaperTypeList;
        };

        return {
            getTableHeaders: getTableHeaders,
            getProducts: getProducts,
            getNameOnPaperType: getNameOnPaperType,
            getFacialValueSettings: getFacialValueSettings,
            getPPPSetting: getPPPSetting,
            canBeDuplicated: canBeDuplicated
        };
    };


    module.exports = {
        name: "DescriptifService",
        fn: descriptifService
    }

})();
