; (function () {
    "use strict";

    function prestationController($scope, $q, localStorageService, StateService, ContratsService, PanierService, Ressources) {
        var self = this,
            panier = StateService.getPanier();

        self.feedlyTemplate = "Content/views/feedly/prestations.html";
        
        self.contrats = ContratsService.mettreAJourContratsDisponibles(Ressources.CODE_PRODUIT_KEY_CMA, panier.Precommandes[0].NbBeneficiaireDevis);
        if (panier.Precommandes[0].Contrat) {
            var selected = _.find(self.contrats, function (c) {
                return panier.Precommandes[0].Contrat.CodeContratType == c.IdContratType;
            });

            if (selected) {
                self.contratSelectionne = selected;
            } else {
                self.contratSelectionne = self.contrats[0];
            }
        } else {
            self.contratSelectionne = self.contrats[0];
        }

        self.multiContrat = function() {
            return self.contrats.length > 1;
        };

        self.fraisGestionMensuel = self.contratSelectionne.FraisAbonnement / 100;
        self.FraisEmission = self.contratSelectionne.FraisEmission / 100;

        self.selectContrat = function (contrat) {
            self.contratSelectionne = contrat;
        };

        self.submitPrestationInformation = function () {
            var contractPromise;
            if (panier.Precommandes[0].IdContrat && panier.Precommandes[0].Contrat) {
                var patchContract = { IdProduit: self.contratSelectionne.CodeProduit, CodeContratType: self.contratSelectionne.IdContratType };
                contractPromise = ContratsService.updateContract(panier.Precommandes[0].IdContrat, patchContract)
                    .then(function (result) {
                        panier.Precommandes[0].Contrat = result.plain();
                        $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                    });
            } else if (!panier.Precommandes[0].IdContrat) {
                var newContrat = {
                    IdClientProspect: panier.Client.Id,
                    IdProduit: self.contratSelectionne.CodeProduit,
                    CodeContratType: self.contratSelectionne.IdContratType
                };
                contractPromise = ContratsService.createContract(newContrat)
                    .then(function(result) {
                        panier.Precommandes[0].Contrat = result.plain();
                        return PanierService.enregistrerPrecommande(panier.Id, panier.Precommandes[0].Id, { IdContrat: panier.Precommandes[0].Contrat.Id });
                    })
                    .then(function(result) {
                        panier.Precommandes[0].IdContrat = result.IdContrat;
                        $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                    });
            } else {
                contractPromise = $q.resolve();
            }

            return contractPromise.then(function() {
                StateService.next();
            });
        };

        /* Vérifier si le contrat est changé */
        function verifyChangeContract() {
            var oldContrat = panier.Precommandes[0].Contrat;
            var newContrat = self.contratSelectionne;
            return oldContrat.IdProduit !== newContrat.CodeProduit
                || oldContrat.CodeContratType !== newContrat.IdContratType;
        }
    };

    module.exports = {
        name: "PrestationController",
        fn: prestationController
    };

})();
