; (function () {
    "use strict";

    var recapitulatif = {
        bindings: {
            feedlyOpen: '<',
            numeroDivision: '<',
            modePayment: '<',
            tabs: '<',
            reprisePanierResult: '<'
        },
        templateUrl: componentsPath + 'summary/basket.html',
        controllerAs: 'recapBasket',
        controller: function ($scope, $timeout, $state, $stateParams, $validation, $filter, $window, localStorageService, Ressources, StateService, ClientService, DevisService, PanierService, AuthenticationService) {
            var self = this;
            var actionEncours = false;

            self.panier = self.reprisePanierResult.panier;
            self.cbReturn = $stateParams.cbReturn;
            self.acceptCgv = false;
            if (self.modePayment !== Ressources.MODE_PAIEMENT.PRELEVEMENT) {
                self.modePayment = Ressources.MODE_PAIEMENT.VIREMENT;
            }

            self.validatePaymentBtnLabel = self.modePayment === Ressources.MODE_PAIEMENT.PRELEVEMENT ?
                $filter('codeToWording')('EXC_RECAPITULATIF_PAYER_PRELEVEMENT_BOUTON') :
                $filter('codeToWording')('EXC_RECAPITULATIF_PAYER_VIREMENT_BOUTON');

            if (!self.panier.Precommandes) {
                $window.location.href = Ressources.ESPACE_CLIENT_URL;
                return false;
            }

            if (self.reprisePanierResult.reprisePanier) {
                $scope.$emit('toggleLoadingToaster', {
                    header: $filter('codeToWording')('PANIER_REPRISE_CTR'),
                    caption: "",
                });

                $timeout(function () {
                    $scope.$emit('closeLoadingToaster');
                }, 2000);
            }

            self.cardQuantities = _.filter(self.panier.Beneficiaires, function (b) {
                return b.Statut === 0 || b.TypeCreation === 1;
            }).length;

            var precommande = self.panier.Precommandes[0];
            var oldReferencePL = precommande.ReferencePLParDefaut;

            var interlocuteur = _.findLast(self.panier.Client.Value.Interlocuteur, function (x) {
                return x.CodeContact && x.CodeContact != 0;
            });

            if (!interlocuteur) {
                interlocuteur = self.panier.Client.Value.Interlocuteur[self.panier.Client.Value.Interlocuteur.length - 1];
            }

            valorisation();


            var _adresse = precommande.PointLivraison.Adresse;

            self.adresse = [
                _adresse.Adresse1,
                _adresse.Adresse2,
                _adresse.Adresse3,
                '-',
                _adresse.CodePostal + ',',
                _adresse.Ville
            ].join(' ');

            self.priceTypes = [
                {
                    title: 'EXC_RECAPITULATIF_VALO_VE',
                    type: 'VE'
                },
                {
                    title: 'EXC_RECAPITULATIF_VALO_ABONNEMENT',
                    type: 'FraisGestion'
                },
                {
                    title: 'EXC_RECAPITULATIF_VALO_LIVRAISON',
                    type: 'FraisPort'
                },
                {
                    title: 'EXC_RECAPITULATIF_VALO_CARTE',
                    type: 'EmissionCartes'
                }
            ];


            self.feedlyPriceTypes = [
                {
                    title: 'EXC_FEEDLY_RECAPITULATIF_TITRE2',
                    description: 'EXC_FEEDLY_RECAPITULATIF_CORPS2',
                    valo: [
                        {
                            type: Ressources.VALORISATION_CONST.ABONNEMENT,
                            unitPriceText: "d'abonnement",
                            quantityText: "salarié(s)"
                        }
                    ]
                },
                {
                    title: 'EXC_FEEDLY_RECAPITULATIF_TITRE3',
                    description: 'EXC_FEEDLY_RECAPITULATIF_CORPS3',
                    valo: [
                        {
                            type: Ressources.VALORISATION_CONST.EMISSIONCARTE,
                            unitPriceText: '',
                            quantityText: "carte(s) à émettre"
                        },
                        {
                            type: Ressources.VALORISATION_CONST.REMISSIONCARTE,
                            unitPriceText: '',
                            quantityText: "carte(s) à réémettre"
                        },
                        {
                            type: Ressources.VALORISATION_CONST.RENOUVELLEMENTCARTE,
                            unitPriceText: '',
                            quantityText: "carte(s) à renouveler"
                        }
                    ]
                },
                {
                    title: 'EXC_FEEDLY_RECAPITULATIF_TITRE4',
                    description: 'EXC_FEEDLY_RECAPITULATIF_CORPS4',
                    valo: [
                        {
                            type: Ressources.VALORISATION_CONST.LIVRAISON,
                            unitPriceText: '',
                            quantityText: "carte(s)"
                        }
                    ]
                }
            ];

            self.validatePayment = function () {
                return validationAndPayment(self.recapForm);
            }

            self.generateDevis = function () {
                var devisUrl = 'api/paniers/' + self.panier.Id + '/interlocuteurs/' + interlocuteur.Id + '/devis';
                $window.open(devisUrl, '_blank');
            };

            self.sendDevis = function () {
                $scope.$emit('toggleLoadingToaster', { header: $filter('codeToWording')("DEVIS_ENVOI_OK"), caption: "Veuillez patientez s'il vous plaît..." });

                DevisService.envoyerDevis(self.panier.Id, precommande.Id, interlocuteur, "envoyerdevisconnecte").then(function () {
                    $timeout(function () {
                        $scope.$emit('closeLoadingToaster');
                    }, 2000);
                });
            }

            self.onPaymentChange = function () {
                if (self.modePayment === Ressources.MODE_PAIEMENT.CB) {
                    self.validatePaymentBtnLabel = $filter('codeToWording')('EXC_RECAPITULATIF_PAYER_CB_BOUTON');
                } else if (self.modePayment === Ressources.MODE_PAIEMENT.VIREMENT) {
                    self.validatePaymentBtnLabel = $filter('codeToWording')('EXC_RECAPITULATIF_PAYER_VIREMENT_BOUTON');
                }
            };

            self.onPLChange = function () {

                $scope.$emit('toggleLoadingToaster', { header: "Valorisation en cours", caption: "Veuillez patientez s'il vous plaît..." });

                PanierService.enregistrerPrecommande(self.panier.Id, precommande.Id, {
                    "ReferencePLParDefaut": precommande.ReferencePLParDefaut
                }).then(function () {
                    StateService.setPanier(self.panier);
                    valorisation();
                }).catch(function () {
                    $scope.$emit('closeLoadingToaster');
                });

            };

            self.openCGV = function() {
                window.open('/api/divisions/' + self.numeroDivision + '/cgv', '_blank');
            }

            function valorisation() {
                PanierService.valoriserPrecommandeParNumeroDivision(self.panier.Id, precommande.Id, self.numeroDivision).then(function (valorisationResult) {
                    var valorisation = valorisationResult.plain();
                    self.valorisation = valorisation;

                    var feedlyPriceType = _.find(self.feedlyPriceTypes, function (pt) {
                        return _.some(pt.valo, function (v) {
                            return v.type === Ressources.VALORISATION_CONST.LIVRAISON;
                        });
                    });

                    if (feedlyPriceType) {
                        if (precommande.ReferencePLParDefaut === "BENEFS") {
                            feedlyPriceType.title = "EXC_FEEDLY_RECAPITULATIF_TITRE4_BENEF";
                            feedlyPriceType.description = "EXC_FEEDLY_RECAPITULATIF_CORPS4_BENEF";
                        } else {
                            feedlyPriceType.title = "EXC_FEEDLY_RECAPITULATIF_TITRE4_SITE";
                            feedlyPriceType.description = "EXC_FEEDLY_RECAPITULATIF_CORPS4_SITE";
                        }
                    }

                    $scope.$emit('closeLoadingToaster');
                }).catch(function (error) {
                    self.movaKo = true;
                    $scope.$emit('closeLoadingToaster');
                });
            }

            function validationAndPayment(form) {
                if (actionEncours) return;
                return $validation.validate(form)
                    .then(function () {
                        self.cbReturn = null;
                        StateService.setPanier(self.panier);
                        actionEncours = true;

                        var updatedPrecommande = {
                            ReferenceClient: precommande.ReferenceClient,
                            IdInterlocuteurCGV: interlocuteur.Id
                        };

                        if (precommande.ReferencePLParDefaut != oldReferencePL) {
                            updatedPrecommande.ReferencePLParDefaut = precommande.ReferencePLParDefaut;
                        }

                        var isPromo = (window.location.host.indexOf('promo-ctr') !== -1) ? true : false;
                        var createdPayment = {
                            IsClientConnected: true,
                            IsPromo: isPromo
                        };


                        updatedPrecommande.IdModePaiement = self.modePayment;
                        createdPayment.ModePaiement = self.modePayment;

                        return validatePayment(self.panier.Id, precommande.Id, updatedPrecommande, createdPayment).then(function (result) {
                            switch (self.modePayment) {
                                case Ressources.MODE_PAIEMENT.CB:
                                    afterValidatePaymentCB(result);
                                    break;
                                default:
                                    return afterValidatePaymentPrelevementOrVirement();
                                    break;
                            }
                            return;
                        }).catch(function () {
                            actionEncours = false;
                        });
                    });
            }

            function afterValidatePaymentCB(result) {
                self.panier.Precommandes[0].IdModePaiement = Ressources.MODE_PAIEMENT.CB;
                var formElement = document.createElement('form');
                formElement.setAttribute('method', 'POST');
                formElement.setAttribute('action', result.RedirectionUrl);
                
                var inputRedirectionDataElement = document.createElement('input');
                inputRedirectionDataElement.setAttribute('type', 'hidden');
                inputRedirectionDataElement.setAttribute('name', 'redirectionData');
                inputRedirectionDataElement.value = result.RedirectionData;

                var inputRedirectionVersionElement = document.createElement('input');
                inputRedirectionVersionElement.setAttribute('type', 'hidden');
                inputRedirectionVersionElement.setAttribute('name', 'redirectionVersion');
                inputRedirectionVersionElement.value = result.RedirectionVersion;

                var submitButtonElement = document.createElement('input')
                submitButtonElement.setAttribute('type', 'submit');
                submitButtonElement.setAttribute('name', 'CB');

                formElement.appendChild(inputRedirectionDataElement);
                formElement.appendChild(inputRedirectionVersionElement);
                formElement.appendChild(submitButtonElement);

                $(formElement).hide().appendTo(document.body).find('[name=CB]').click();
                actionEncours = false;
            }

            function afterValidatePaymentPrelevementOrVirement() {
                return PanierService.validerPrecommande(self.panier.Id, precommande.Id, interlocuteur.Id).then(function () {
                    actionEncours = false;
                    return StateService.next();
                });
            }

            function validatePayment(panierId, precommandeId, precommande, payment) {
                return PanierService.enregistrerPrecommande(panierId, precommandeId, precommande).then(function () {
                    return PanierService.creerPaiement(panierId, precommandeId, payment);
                });
            }
        }
    };

    module.exports = {
        name: 'recapitulatifConnected',
        obj: recapitulatif
    };

})();
