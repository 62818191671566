;
(function() {
    "use strict";

    function authenticationService($http, $state, Ressources, localStorageService) {

        var _token = localStorageService.get(Ressources.TOKEN_KEY);
        var _userInformation = {
            isEmailSent: false,
            email: null,
            userId: null
        };

        var setUserInfo = function(isEmailSent, email, userId) {
            _userInformation.isEmailSent = isEmailSent;
            _userInformation.email = email;
            _userInformation.userId = userId;
        }

        var getUserInfo = function() {
            return _userInformation;
        }

        var setToken = function(token) {
            localStorageService.set(Ressources.TOKEN_KEY, token);
            _token = token;
        }

        var getAccessToken = function(username, password) {
            return $http({
                    method: 'POST',
                    url: 'api/token',
                    data: $.param({
                        'grant_type': 'password',
                        'username': username,
                        'password': password
                    }),
                    headers: {
                        'Content-Type': 'application/x-form-www-urlencoded'
                    }
                })
                .then(function(result) {
                    setToken(result.data);
                    return result;
                });
        };

        var getAccessTokenV1 = function(returnUrl) {
            return $http.get(Ressources.ESPACE_CLIENT_URL + 'account/getV2token', { withCredentials: true })
                .then(function(result) {
                    return $http({
                        method: 'POST',
                        url: 'api/token',
                        data: 'grant_type=v1token&token=' + result.data.token,
                        headers: {
                            'Content-Type': 'application/x-form-www-urlencoded'
                        }
                    }).then(function(tokenResult) {
                        setToken(tokenResult.data);
                        return tokenResult;
                    });
                }).catch(function(error) {
                    $state.go('tunnel.login', { returnUrl: returnUrl });
                    return null;
                });
        }

        var validateTokenPassword = function(token) {
            return $http.get('api/account?token=' + token);
        };

        var activateAccount = function(token) {
            return $http.put('api/account?token=' + token);
        };

        var sendForgetPasswordEmail = function(email) {
            return $http.post('api/account/password/sendforgetpasswordemail?email=' + email);
        };

        var reinitPassword = function(data) {
            var token = data.token;
            var login = data.login;
            var password = data.password;
            var confirmPwd = data.confirmedPassword;
            var secretQuestionId = data.secretQuestionId;
            var secretAnswer = data.secretAnswer;

            return $http.post('api/account/password/reinit', {
                token: token,
                login: login,
                password: password,
                confirmPwd: confirmPwd,
                secretQuestionId: secretQuestionId,
                secretAnswer: secretAnswer
            });
        }

        var getToken = function() {
            return _token;
        };

        var getNumeroDivisionClient = function() {
            if (!_token) return null;
            return _token.Domaine.split(';')[0];
        }

        var isAuthenticated = function() {
            return !!localStorageService.get(Ressources.TOKEN_KEY);
        }

        var verifyCaptcha = function(recaptchaToken) {
            return $http.post('api/account/recaptcha/verify?captchaResponse=' + recaptchaToken);
        };

        var sendActivationEmail = function(userId) {
            return $http.post('api/account?userId=' + userId);
        }

        var hasRole = function(role) {
            if (!isAuthenticated()) return false;
            return _.includes(getToken().Role, role);
        }

        return {
            getAccessToken: getAccessToken,
            getAccessTokenV1: getAccessTokenV1,
            getToken: getToken,
            validateTokenPassword: validateTokenPassword,
            sendForgetPasswordEmail: sendForgetPasswordEmail,
            reinitPassword: reinitPassword,
            isAuthenticated: isAuthenticated,
            verifyCaptcha: verifyCaptcha,
            getNumeroDivisionClient: getNumeroDivisionClient,
            getUserInfo: getUserInfo,
            setUserInfo: setUserInfo,
            activateAccount: activateAccount,
            sendActivationEmail: sendActivationEmail,
            hasRole: hasRole
        };
    }

    module.exports = {
        name: "AuthenticationService",
        fn: authenticationService
    }

})();