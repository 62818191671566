;(function() {
    "use strict";

    function positionOnWindow() {
        return {
            restrict: 'A',
            scope: {
              position: "="
            },
            link: function (scope, el, attrs) {

              var bodyRect = document.body.getBoundingClientRect(),
                  elemRect = el[0].getBoundingClientRect(),
                  elWidth  = el[0].offsetWidth,
                  elHeight = el[0].offsetHeight,
                  elPos    = {
                    x: Math.round((elemRect.left - bodyRect.left) + (elWidth / 2)),
                    y: Math.round((elemRect.top - bodyRect.top) + (elHeight / 2))
                  },
                  clientWidth = window.innerWidth,
                  clientHeight = window.innerHeight;

              if (clientWidth < clientHeight) {
                scope.position = elPos.y > Math.round(clientHeight / 2) ? 'to-top' : 'to-bottom';
              } else {
                scope.position = elPos.x < Math.round(clientWidth / 2) ? 'to-left' : 'to-right';
              }

            }
        };
    };

    module.exports = {
        name: 'position',
        fn: positionOnWindow
    };

})();
