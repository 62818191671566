;(function () {
    "use strict";

    var headerBlank = {
        bindings: {
            breadcrumbLabel: '<'
        },
        templateUrl: componentsPath + 'header/header.blank.html',
        controllerAs: 'headerBlank',
        controller: function ($rootScope, localStorageService, Ressources) {
            var self = this;
            var storedToken = localStorageService.get(Ressources.TOKEN_KEY);
            self.breadcrumbLabel = this.breadcrumbLabel;
            self.urlHomePageV1 = Ressources.ESPACE_CLIENT_URL + "Account/SignInToken?token=" + storedToken.access_token;
            
            self.updateProduct = function() {
                $rootScope.$broadcast('productUpdated', self.selectedProductId);
            };

            self.updateYear = function() {
                $rootScope.$broadcast('yearUpdated', self.selectedYear);
            };

            var yl = new Date();
            var thisYear = yl.getFullYear();
            var startYear = 2008;
            self.yearList = [];
            for (var i = thisYear; i >= startYear; i--) { self.yearList.push(i);}


            $rootScope.$on('facturesLoaded', function (event, factures) {
                self.products = _.map(factures, 'Produit');
                self.products = _.uniqBy(self.products, 'Id');
            });
        }
    };

    module.exports = {
        name: 'headerBlank',
        obj: headerBlank
    };
})();
