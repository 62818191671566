;(function() {
    "use strict";

    function Beneficiaire () {
        return function () {
            this.Id = null;
            this.Matricule = null;
            this.Nom = null;
            this.Prenom = null;
            this.DateNaissance = null;
            this.NombreTitres = 20;
        };
    };

    module.exports = {
        name: "Beneficiaire",
        fn: Beneficiaire
    }

})();
