; (function () {
    "use strict";

    function routesConfig($stateProvider, $urlRouterProvider) {
        // $urlRouterProvider.otherwise('/commande/ctr/intro');

        /***
        ** Router states config
        ***/

        $urlRouterProvider.otherwise(function ($injector, $location) {
            var state = $injector.get('$state');
            state.go('tunnel-ctr-intro');
            return $location.path();
        });

        $stateProvider
            /*
             *
             *   AUTHENTICATION
             *
            */

            /*
             * Erreurs
             */
            .state('404', {
                url: '/error/404',
                views: {
                    'wrapper': {
                        templateUrl: 'Content/views/errors/404.html'
                    }
                }
            })
            .state('500', {
                url: '/error/500',
                params: {
                    previousState: null,
                    token: null
                },
                views: {
                    'wrapper': {
                        templateUrl: 'Content/views/errors/500.html',
                        controller: ['$scope', '$state', '$stateParams', '$window', 'Ressources', function ($scope, $state, $stateParams, $window, Ressources) {
                            $scope.returnToHomePage = function () {
                                if ($stateParams.token && $stateParams.previousState && $stateParams.previousState.indexOf('tunnel.connected') != -1)
                                    $window.location.href = Ressources.ESPACE_CLIENT_URL + "Account/SignInToken?token=" + $stateParams.token;
                                else
                                    $state.go('tunnel-ctr-intro');
                                return;
                            };
                        }]
                    }
                },
                resolve: {
                    init: function ($rootScope) {
                        $rootScope.$broadcast('appLoaded');
                    }
                }
            })
            .state('501', {
                url: '/error/501',
                views: {
                    'wrapper': {
                        templateUrl: 'Content/views/errors/501.html'
                    }
                },
                resolve: {
                    init: function ($rootScope) {
                        $rootScope.$broadcast('appLoaded');
                    }
                }
            })
            .state('502', {
                url: '/error/502',
                views: {
                    'wrapper': {
                        templateUrl: 'Content/views/errors/502.html'
                    }
                },
                resolve: {
                    init: function ($rootScope) {
                        $rootScope.$broadcast('appLoaded');
                    }
                }
            })
            .state('503', {
                url: '/error/503',
                views: {
                    'wrapper': {
                        templateUrl: 'Content/views/errors/503.html'
                    }
                },
                resolve: {
                    init: function ($rootScope) {
                        $rootScope.$broadcast('appLoaded');
                    }
                }
            })

            /*
             *
             *   TUNNEL VEL
             *
             *
            */
            .state('tunnel-ctr-intro', {
                url: '/intro',
                views: {
                    'header': {
                        // template: '<header-app></header-app>'
                        templateUrl: 'Content/views/components/header/header.app.html'
                    },
                    'wrapper': {
                        controller: 'HomeController',
                        controllerAs: 'home',
                        templateUrl: 'Content/views/tunnel/introduction.html'
                    },
                    'footer': {
                        templateUrl: 'Content/views/footer.html'
                    }
                },
                resolve: {
                    wording: function (WordingService, localStorageService, Ressources) {
                        var wordings = localStorageService.get(Ressources.WORDING_KEY);
                        return wordings ? wordings : WordingService.allWording();
                    },
                    recoverPanier: function ($location, $rootScope, $timeout, StateService, Ressources, localStorageService, PanierService) {
                        var panId = null;

                        if ($location.search().panid) {
                            panId = $location.search().panid;
                            $location.url($location.path());
                            localStorage[Ressources.APPLICATION_PREFIX + Ressources.PANIER_KEY] = panId;
                        }
                        else if (localStorage[Ressources.APPLICATION_PREFIX + Ressources.PANIER_KEY] && !localStorageService.get(Ressources.PANIER_KEY)) {
                            panId = localStorage[Ressources.APPLICATION_PREFIX + Ressources.PANIER_KEY];
                        }
                        if (panId) {
                            $rootScope.$broadcast('isLoadingBasket');
                            var tempSucces =  false;
                            return PanierService.chargerPanierComplet(panId)
                                .then(function (panierResult) {
                                    tempSucces = true;
                                    try {
                                        var panier = panierResult.plain();
                                        if (panier.Precommandes[0].Statut !== 0) {
                                            StateService.reset();
                                            location.reload();
                                            return;
                                        }
                                        var referenceTunnel = panier.Precommandes[0].ReferenceEtapeTunnel.split(":");
                                        if (referenceTunnel[1] == StateService.getStateNameByValue(Ressources.TUNNEL_CTR_PAIEMENT)) {
                                            panier.Precommandes[0].IdInterlocuteurCGV = null;
                                            panier.Precommandes[0].ReferenceEtapeTunnel = referenceTunnel[0] + ":" + "TUNNEL_CTR_RECAPITULATIF";
                                        }
                                        localStorageService.set(Ressources.REFERENCE_ETAPE_TUNNEL_KEY, panier.Precommandes[0].ReferenceEtapeTunnel);
                                        $rootScope.$broadcast(Ressources.EVENT_UPDATE_PANIER, panier);
                                        $rootScope.$broadcast(Ressources.EVENT_UPDATE_BENEFICIAIRES, panier.Beneficiaires);
                                        StateService.reinit();
                                        return StateService.restore();
                                    } catch (ex) {
                                        // Paranoïa totale, on veut vraiment pas que la reprise de panier puisse échouer
                                        StateService.reset();
                                        location.reload();
                                    }
                                })
                                .catch(function (e) {
                                    // La reprise de panier a échoué, réinitialisation complète du panier pour s'assurer de ne pas rester bloqué dans un état irrécupérable
                                    if (!tempSucces) { // si le panier recupéré on veut eviter que l'erreur transition superseded n'efface le panier
                                        StateService.reset();
                                        location.reload();
                                    }
                                });
                        }
                    },
                }
            })
            .state('tunnel-ctr', {
                "abstract": true,
                url: '/commande/ctr',
                views: {
                    'header': {
                        template: '<header-tunnel feedly-open="app.feedlyOpen" '+
                                    'stepper-disponible="app.stepperDisponible()" '+
                                    'etape-precedente="app.etapePrecedente()" '+
                                    'etape-suivante="app.etapeSuivante()" '+
                                    'etape-suivante-disponible="app.etapeSuivanteDisponible()" '+
                                    'is-intro-page="$state.includes(\'tunnel-ctr-intro\')"> '+
                                  '</header-tunnel>',
                    },
                    'wrapper': {
                        controller: 'TunnelController',
                        controllerAs: 'tunnel',
                        templateUrl: 'Content/views/tunnel/tunnel.html'
                    },
                    'footer': {
                        templateUrl: 'Content/views/footer.html'
                    }
                },
                resolve: {
                    tunnel: function (Ressources) {
                        return Ressources.TYPES_TUNNEL.Commande;
                    },
                    wording: function (WordingService, localStorageService, Ressources) {
                        var wordings = localStorageService.get(Ressources.WORDING_KEY);
                        return wordings ? wordings : WordingService.allWording();
                    },
                    recoverPanier: function ($location, $rootScope, $timeout, StateService, Ressources, localStorageService, PanierService) {
                        var panId = null;

                        if ($location.search().panid) {
                            panId = $location.search().panid;
                            $location.url($location.path());
                            localStorage[Ressources.APPLICATION_PREFIX + Ressources.PANIER_KEY] = panId;
                        }
                        else if (localStorage[Ressources.APPLICATION_PREFIX + Ressources.PANIER_KEY] && !localStorageService.get(Ressources.PANIER_KEY)) {
                            panId = localStorage[Ressources.APPLICATION_PREFIX + Ressources.PANIER_KEY];
                        }

                        if (panId) {
                            $rootScope.$broadcast('isLoadingBasket');
                            return PanierService.chargerPanierComplet(panId)
                                .then(function (panierResult) {
                                    try {
                                        var panier = panierResult.plain();
                                        if (panier.Precommandes[0].Statut !== 0) {
                                            StateService.reset();
                                            location.reload();
                                            return;
                                        }
                                        var referenceTunnel = panier.Precommandes[0].ReferenceEtapeTunnel.split(":");
                                        if (referenceTunnel[1] == StateService.getStateNameByValue(Ressources.TUNNEL_CTR_PAIEMENT)) {
                                            panier.Precommandes[0].IdInterlocuteurCGV = null;
                                            panier.Precommandes[0].ReferenceEtapeTunnel = referenceTunnel[0] + ":" + "TUNNEL_CTR_RECAPITULATIF";
                                        }
                                        localStorageService.set(Ressources.REFERENCE_ETAPE_TUNNEL_KEY, panier.Precommandes[0].ReferenceEtapeTunnel);
                                        $rootScope.$broadcast(Ressources.EVENT_UPDATE_PANIER, panier);
                                        $rootScope.$broadcast(Ressources.EVENT_UPDATE_BENEFICIAIRES, panier.Beneficiaires);
                                        StateService.reinit();
                                        return StateService.restore();
                                    } catch (ex) {
                                        // Paranoïa totale, on veut vraiment pas que la reprise de panier puisse échouer
                                        StateService.reset();
                                        location.reload();
                                    }
                                })
                                .catch(function () {
                                    // La reprise de panier a échoué, réinitialisation complète du panier pour s'assurer de ne pas rester bloqué dans un état irrécupérable
                                    StateService.reset();
                                    location.reload();
                                });
                        }
                    }
                }
            })
            .state('tunnel-ctr.persona', {
                url: '/email',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/persona.html',
                        controller: 'PersonaController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    panier: function (localStorageService, Ressources) {
                        return localStorageService.get(Ressources.PANIER_KEY);
                    }
                }
            })
            .state('tunnel-ctr.persona-ko', {
                url: '/email/ko',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/persona-ko.html',
                        controller: 'PersonaKoController',
                        controllerAs: 'persona'
                    }
                },
                params: {
                    obj: null
                },
                resolve: {
                    panier: function (localStorageService, Ressources) {
                        return localStorageService.get(Ressources.PANIER_KEY);
                    }
                }
            })
            .state('tunnel-ctr.valeurfaciale', {
                url: '/valeur',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/valeurfaciale.html',
                        controller: 'ValeurFacialController',
                        controllerAs: 'vf'
                    }
                },
                resolve: {
                    panier: function (localStorageService, Ressources) {
                        return localStorageService.get(Ressources.PANIER_KEY);
                    }
                }
            })
            .state('tunnel-ctr.simulation', {
                url: '/simulation',
                params: {
                    nbBeneficiaires: null
                },
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/simulation.html',
                        controller: 'SimulationController',
                        controllerAs: 'simu'
                    }
                }
            })
            .state('tunnel-ctr.simulation-modification', {
                url: '/simulation-modification',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/simulation-modification.html',
                        controller: 'SimulationController',
                        controllerAs: 'simu'
                    }
                }
            })
            .state('tunnel-ctr.beneficiaire', {
                url: '/beneficiaire',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/beneficiaire.html',
                        controller: 'BeneficiaryController',
                        controllerAs: 'bnf'
                    }
                }
            })
            .state('tunnel-ctr.siret', {
                url: '/siret',
                params: {
                    siret: null
                },
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/siret.html',
                        controller: "SiretController",
                        controllerAs: "siret"
                    }
                }
            })
            .state('tunnel-ctr.siret-devis', {
                url: '/siret/devis',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/siret-devis.html',
                        controller: "SiretController",
                        controllerAs: "siret"
                    }
                }
            })
            .state('tunnel-ctr.siretChoix', {
                url: '/siret/choix',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/siret-choix-division.html',
                        controller: "SiretChoixController",
                        controllerAs: "siretChoix"
                    }
                },
                resolve: {
                    divisions: function (localStorageService, Ressources) {
                        return localStorageService.get(Ressources.INTERLOCUTEUR_DIVISIONS);
                    }
                }
            })
            .state('tunnel-ctr.siretInterlocuteurKo', {
                url: '/siret/ko',
                params: {
                    errorCode : null,
                    siretKo : null
                },
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/siret-interlocuteur-ko.html',
                        controller: "SiretKoController",
                        controllerAs: "siretKo"
                    }
                }
            })
            .state('tunnel-ctr.adresse-saisie', {
                url: '/adresse/saisie',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/adresse-saisie.html',
                        controller: "AddressFillingController",
                        controllerAs: "addressFill"
                    }
                }
            })
            .state('tunnel-ctr.coordonnees', {
                url: '/coordonnees',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/coordonnees.html',
                        controller: 'ContactDetailController',
                        controllerAs: 'contact'
                    }
                }
            })
            .state('tunnel-ctr.prestations', {
                url: '/prestations',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/prestations.html',
                        controller: 'PrestationController',
                        controllerAs: 'prestation'
                    }
                },
                resolve: {
                    panier: function (localStorageService, Ressources) {
                        return localStorageService.get(Ressources.PANIER_KEY);
                    }
                }
            })
            .state('tunnel-ctr.recapitulatif', {
                url: '/recapitulatif',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/recap.html',
                        controller: 'RecapController',
                        controllerAs: 'recap'
                    }
                },
                resolve: {
                    contrats: function ($q, ContratsService, localStorageService, Ressources) {
                        var contrats = localStorageService.get(Ressources.CONTRACTS_KEY);
                        return contrats
                            ? $q.resolve(contrats)
                            : ContratsService.getContractRanges()
                                .then(function(result) {
                                    contrats = result.plain();
                                    localStorageService.set(Ressources.CONTRACTS_KEY, contrats);
                                    return contrats;
                                });
                    },
                    tarifs: function ($q, ContratsService, localStorageService, Ressources) {
                        var tarifs = localStorageService.get(Ressources.TARIF_KEY);
                        return tarifs
                            ? $q.resolve(tarifs)
                            : ContratsService.getContractTarifs()
                                .then(function(result) {
                                    tarifs = result.plain();
                                    localStorageService.set(Ressources.TARIF_KEY, tarifs);
                                    return tarifs;
                                });
                    },
                    contract: function (contrats, tarifs, StateService, localStorageService, ContratsService, PanierService, Ressources) {
                        var panier = StateService.getPanier();
                        var beneficiaires = localStorageService.get(Ressources.BENEFICIARIES_KEY);
                        var listDomTom = ['971', '972', '973', '974', '975', '976', '977', '978', '984', '986', '987', '988'];
                        var isDomTom = listDomTom.indexOf(panier.Client.Value.Adresse.CodePostal.substr(0,3)) > -1;
                        var idPartenaire = localStorage.getItem('partnerId')
                        if(isDomTom){
                            // console.log('CODE_PRODUIT_KEY_DOMTOM', Ressources.CODE_PRODUIT_KEY_DOMTOM);
                            ContratsService.mettreAJourContratsDisponibles(Ressources.CODE_PRODUIT_KEY_DOMTOM, beneficiaires.length);
                        }else{
                            if(window.location.search.indexOf('idPartenaire') > -1){
                                ContratsService.mettreAJourContratsDisponibles(idPartenaire, beneficiaires.length);
                            }else{
                                ContratsService.mettreAJourContratsDisponibles(Ressources.CODE_PRODUIT_KEY, beneficiaires.length);
                            }
                        }
                        var contrats = localStorageService.get(Ressources.SELECTED_CONTRACT_KEY);
                        var contrat = contrats[0];
                        contrat.CodeProduit = panier.Precommandes[0].IdProduit;
                        if (panier.Precommandes[0].IdContrat && panier.Precommandes[0].Contrat && panier.Precommandes[0].Contrat.CodeContratType == contrat.IdContratType) {
                            return ContratsService.updateContract(panier.Precommandes[0].IdContrat, { IdProduit: contrat.CodeProduit, CodeContratType: contrat.IdContratType })
                                .then(function (result) {
                                    panier.Precommandes[0].Contrat = result.plain();
                                    StateService.setPanier(panier);
                                });
                        } else {
                            return ContratsService.createContract({ IdClientProspect: panier.Client.Id, IdProduit: contrat.CodeProduit, CodeContratType: contrat.IdContratType })
                                .then(function(result) {
                                    panier.Precommandes[0].Contrat = result.plain();
                                    return PanierService.enregistrerPrecommande(panier.Id, panier.Precommandes[0].Id, { IdContrat: panier.Precommandes[0].Contrat.Id });
                                })
                                .then(function (result) {
                                    panier.Precommandes[0].IdContrat = result.IdContrat;
                                    StateService.setPanier(panier);
                                });
                        }
                    },
                    // Ne pas retirer l'argument contract: il n'est pas utilisé mais permet d'indiquer à
                    // l'injection de dépendance de résoudre les resolvers séquentiellement plutôt qu'en parallèle
                    valorisation: function ($q, $rootScope, contract, StateService, PanierService) {
                        $rootScope.$broadcast('toggleLoadingToaster', {
                            header: "Valorisation de votre commande",
                            caption: "Veuillez patienter s'il vous plaît...",
                        });
                        var panier = StateService.getPanier();
                        return PanierService.valoriserPrecommande(panier.Id, panier.Precommandes[0].Id).then(function (result) {
                            //$rootScope.$broadcast('closeLoadingToaster');
                            return $q.resolve(result);
                        });
                    }
                }
            })
            .state('tunnel-ctr.devis', {
                url: '/devis',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/devis.html',
                        controller: 'DevisController',
                        controllerAs: 'devis'
                    }
                }
            })
            .state('tunnel-ctr.paiement', {
                url: '/paiement/{cbReturn}',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/paiement.html',
                        controller: 'PaiementController',
                        controllerAs: 'paiement'
                    }
                }
            })
            .state('tunnel-ctr.confirmation', {
                url: '/confirmation',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/confirmation.html',
                        controller: 'ConfirmationController',
                        controllerAs: 'confirmation'
                    }
                },
                resolve: {
                    checkPaiement: function ($q, Ressources, PanierService, StateService) {
                        var panier = StateService.getPanier();
                        return PanierService.chargerPanierComplet(panier.Id).then(function(result) {
                            var pan = result.plain();

                            if (pan.Precommandes[0].IdModePaiement == Ressources.MODE_PAIEMENT.VIREMENT
                                || (pan.Precommandes[0].IdModePaiement == Ressources.MODE_PAIEMENT.CB && _.some(pan.Precommandes[0].Valorisation.Paiements, ['Statut', 2])))
                                return $q.resolve();
                            return StateService.restore();
                        });
                    }
                }
            })
            .state('tunnel-ctr.styleguide', {
                url: '/styleguide',
                views: {
                    'content': {
                        templateUrl: 'Content/views/styleguide.html'
                    }
                }
            })

            /*
             *
             *   BACK OFFICE
             *
             *
            */
            .state('backoffice', {
                'abstract': true,
                views: {
                    'wrapper': {
                        template: '<ui-view />'
                    }
                },
                resolve: {
                    authorization: function($q, AuthenticationService) {
                        return AuthenticationService.hasRole('AdministrateurEdenred')
                            ? $q.resolve()
                            : $q.reject();
                    }
                }
            })
            .state('backoffice.wording', {
                url: '/wording',
                templateUrl: 'Content/views/back-office/wording.html',
                controller: 'WordingController',
                controllerAs: 'wording',
                resolve: {
                    wording: function (WordingService) {
                        return WordingService.allWording();
                    }
                }
            })
            .state('backoffice.contractType', {
                url: '/contracts',
                templateUrl: 'Content/views/back-office/contrat.type.html',
                controller: 'ContractConfigController',
                controllerAs: 'contractConfig'
            })
            .state('backoffice.contractTarif', {
                url: '/contracts/tarif',
                templateUrl: 'Content/views/back-office/contrat.tarif.html',
                controller: 'ContractTarifController',
                controllerAs: 'contractTarif'
            })
            .state('backoffice.partenaires', {
                url: '/partenaires',
                templateUrl: 'Content/views/back-office/partenaires.html',
                controller: 'PartenairesController',
                controllerAs: 'partenaires'
            })

            /***************************************
             *
             *   CONNECTED: PARAMETRAGE COMPTABLE
             *
             ***************************************/
            //.state('parametrage-comptable', {
            //    url: '/parametres/comptabilite?message',
            //    views: {
            //        'wrapper': {
            //            templateUrl: 'Content/views/tunnel-connected/parametrage-comptable.html',
            //            controller: 'ParametrageComptableController',
            //            controllerAs: 'pcc'
            //        }
            //    },
            //    resolve: {
            //        token: function (localStorageService, Ressources, AuthenticationService) {
            //            var token = localStorageService.get(Ressources.TOKEN_KEY);
            //            if (token) return token;
            //            return AuthenticationService.getAccessTokenV1();
            //        },
            //        wording: function (WordingService, localStorageService, Ressources) {
            //            var wordings = localStorageService.get(Ressources.WORDING_KEY);
            //            return wordings ? wordings : WordingService.allWording();
            //        }
            //    }
            //})

            /***************
             *
             *   TUNNEL CMA
             *
            ****************/
            .state('tunnel-cma', {
                "abstract": true,
                url: '/cma',
                views: {
                    'header': {
                        template: '<header-cma feedly-open="app.feedlyOpen" ' +
                                    'stepper-disponible="app.stepperDisponible()" ' +
                                    'etape-precedente="app.etapePrecedente()" ' +
                                    'etape-suivante="app.etapeSuivante()" ' +
                                    'etape-suivante-disponible="app.etapeSuivanteDisponible()" ' +
                                    'is-intro-page="$state.includes(\'tunnel-ctr-intro\')"> ' +
                                  '</header-cma>',
                    },
                    'wrapper': {
                        controller: 'TunnelController',
                        controllerAs: 'tunnel',
                        templateUrl: 'Content/views/tunnel/tunnel.html'
                    },
                    'footer': {
                        templateUrl: 'Content/views/footer.html'
                    }
                },
                resolve: {
                    tunnel: function (Ressources) {
                        return Ressources.TYPES_TUNNEL.CMA;
                    },
                    wording: function (WordingService, localStorageService, Ressources) {
                        var wordings = localStorageService.get(Ressources.WORDING_KEY);
                        return wordings ? wordings : WordingService.allWording();
                    },
                }
            })
            .state('tunnel-cma.startCma', {
                url: '/start-cma',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/startCma.html',
                        controller: "SimuSSoController",
                        controllerAs: "startCma"
                    }
                },
                resolve: {
                    tunnel: function (Ressources) {
                        return Ressources.TYPES_TUNNEL.CMA;
                    }
                }
            })
            .state('tunnel-cma.siret', {
                url: '/siret',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/siret-devis.html',
                        controller: "SiretController",
                        controllerAs: "siret"
                    }
                }
            })
            .state('tunnel-cma.adresse', {
                url: '/adresse',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/adresse-saisie.html',
                        controller: "AddressFillingController",
                        controllerAs: "addressFill"
                    }
                }
            })
            .state('tunnel-cma.coordonnees', {
                url: '/coordonnees',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/coordonnees.html',
                        controller: 'ContactDetailController',
                        controllerAs: 'contact'
                    }
                }
            })
            .state('tunnel-cma.nombre-titres-salaries', {
                url: '/nombre-titres-salaries',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/simulation-modification.html',
                        controller: 'NombreBenefsTitres',
                        controllerAs: 'nbbt'
                    }
                }
            })
            .state('tunnel-cma.valeur', {
                url: '/valeur',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/valeurfaciale.html',
                        controller: 'ValeurFacialController',
                        controllerAs: 'vf'
                    }
                },
                resolve: {
                    panier: function (localStorageService, Ressources) {
                        return localStorageService.get(Ressources.PANIER_KEY);
                    }
                }
            })
            .state('tunnel-cma.prestations', {
                url: '/prestations',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/prestationsCma.html',
                        controller: 'PrestationController',
                        controllerAs: 'prestation'
                    }
                },
                resolve: {
                    panier: function (localStorageService, Ressources) {
                        return localStorageService.get(Ressources.PANIER_KEY);
                    },
                    contrats: function ($q, ContratsService, localStorageService, Ressources) {
                        var contrats = localStorageService.get(Ressources.CONTRACTS_KEY);
                        return contrats
                            ? $q.resolve(contrats)
                            : ContratsService.getContractRanges()
                                .then(function (result) {
                                    contrats = result.plain();
                                    localStorageService.set(Ressources.CONTRACTS_KEY, contrats);
                                    return contrats;
                                });
                    },
                    tarifs: function ($q, ContratsService, localStorageService, Ressources) {
                        var tarifs = localStorageService.get(Ressources.TARIF_KEY);
                        return tarifs
                            ? $q.resolve(tarifs)
                            : ContratsService.getContractTarifs()
                                .then(function (result) {
                                    tarifs = result.plain();
                                    localStorageService.set(Ressources.TARIF_KEY, tarifs);
                                    return tarifs;
                                });
                    }
                }
            })
            .state('tunnel-cma.recapitulatif', {
                url: '/recapitulatif',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/recapCma.html',
                        controller: 'RecapController',
                        controllerAs: 'recap'
                    }
                },
                resolve: {
                    contract: function (StateService, localStorageService, ContratsService, PanierService, Ressources) {
                        var panier = StateService.getPanier();
                        //var beneficiaires = localStorageService.get(Ressources.BENEFICIARIES_KEY);
                        var nbBenefs = panier.Precommandes[0].NbBeneficiaireDevis || Ressources.NB_BENEFS_PAR_DEFAUT;
                        ContratsService.mettreAJourContratsDisponibles(Ressources.CODE_PRODUIT_KEY_CMA, nbBenefs);
                        var contrats = localStorageService.get(Ressources.SELECTED_CONTRACT_KEY);

                        var contrat = null;
                        if(panier.Precommandes[0].Contrat) {
                            contrat = panier.Precommandes[0].Contrat;
                        }else {
                            contrat = contrats[0];
                        }

                        if (panier.Precommandes[0].IdContrat && panier.Precommandes[0].Contrat && panier.Precommandes[0].Contrat.CodeContratType == contrat.CodeContratType) {
                            return ContratsService.updateContract(panier.Precommandes[0].IdContrat, { IdProduit: contrat.CodeProduit, CodeContratType: contrat.CodeContratType })
                                .then(function (result) {
                                    panier.Precommandes[0].Contrat = result.plain();
                                    StateService.setPanier(panier);
                                });
                        } else {
                            return ContratsService.createContract({ IdClientProspect: panier.Client.Id, IdProduit: contrat.CodeProduit, CodeContratType: contrat.CodeContratType })
                                .then(function (result) {
                                    panier.Precommandes[0].Contrat = result.plain();
                                    return PanierService.enregistrerPrecommande(panier.Id, panier.Precommandes[0].Id, { IdContrat: panier.Precommandes[0].Contrat.Id });
                                })
                                .then(function (result) {
                                    panier.Precommandes[0].IdContrat = result.IdContrat;
                                    StateService.setPanier(panier);
                                });
                        }
                    },
                    // Ne pas retirer l'argument contract: il n'est pas utilisé mais permet d'indiquer à
                    // l'injection de dépendance de résoudre les resolvers séquentiellement plutôt qu'en parallèle
                    valorisation: function ($q, $rootScope, contract, StateService, PanierService) {
                        $rootScope.$broadcast('toggleLoadingToaster', {
                            header: "Valorisation de votre commande",
                            caption: "Veuillez patienter s'il vous plaît...",
                        });
                        var panier = StateService.getPanier();
                        return PanierService.valoriserPrecommande(panier.Id, panier.Precommandes[0].Id).then(function (result) {
                            //$rootScope.$broadcast('closeLoadingToaster');
                            return $q.resolve(result);
                        });
                    }
                }
            })
            .state('tunnel-cma.confirmation', {
                url: '/confirmation',
                views: {
                    'content': {
                        templateUrl: 'Content/views/tunnel/confirmationCma.html',
                        controller: 'ConfirmationController',
                        controllerAs: 'confirmation'
                    }
                }
            })
            ;
    }

    module.exports = {
        fn: routesConfig
    };

})();
