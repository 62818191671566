module.exports=﻿{
    "1": {
        "sections": [
            {
                "libelle": "STEPPER_PANIER",
                "entrees": [
                    {
                        "defaut": "STEPPER_FONCTION",
                        "propriete": "Email",
                      "url": "TUNNEL_CTR_PERSONA",
                      "data_rnx" :  "stepper_email"
                    },
                    {
                        "defaut": "STEPPER_VF",
                        "propriete": "ValeurFaciale",
                        "suffixe": "€ par titre",
                        "url": "TUNNEL_CTR_VALEURFACIALE",
                      "data_rnx" :  "stepper_vf"
                    },
                    {
                        "defaut": "STEPPER_SALARIE",
                        "propriete": "Salaries",
                        "suffixe": " salarié(s)",
                        "url": "TUNNEL_CTR_BENEFICIAIRE",
                      "data_rnx" :  "stepper_salarie"
                    }
                ]
            },
            {
                "libelle": "STEPPER_INFOS",
                "entrees": [
                    {
                        "defaut": "STEPPER_SIRET",
                        "propriete": "RaisonSociale",
                        "multiActif": ["TUNNEL_CTR_SIRET", "TUNNEL_CTR_ADRESSE_SAISIE"],
                        "url": "TUNNEL_CTR_SIRET",
                      "data_rnx" :  "stepper_siret"
                    },
                    {
                        "defaut": "STEPPER_COORDONNEES",
                        "propriete": "Interlocuteur",
                        "url": "TUNNEL_CTR_COORDONNEES",
                      "data_rnx" :  "stepper_coordonnees"
                    }
                ]
            },
            {
                "libelle": "STEPPER_PAIEMENT",
                "entrees": [
                    {
                        "defaut": "STEPPER_RECAP",
                        "propriete": "Recapitulatif",
                        "url": "TUNNEL_CTR_RECAPITULATIF",
                        "data_rnx" :  "stepper_recap"
                    },
                    {
                        "defaut": "STEPPER_IBAN",
                        "propriete": "Paiement",
                      "url": "TUNNEL_CTR_PAIEMENT",
                      "data_rnx" :  "stepper_paiement"
                    },
                    {
                        "defaut": "STEPPER_CONFIRMATION",
                        "propriete": "Confirmation",
                        "url": "TUNNEL_CTR_CONFIRMATION",
                      "ignore": true,
                      "data_rnx" :  "stepper_confirmation"
                    }
                ]
            },
        ]
    },
    "2": {
        "sections": [
            {
                "libelle": "STEPPER_PANIER",
                "entrees": [
                    {
                        "defaut": "STEPPER_FONCTION",
                        "propriete": "Email",
                      "url": "TUNNEL_CTR_PERSONA",
                      "data_rnx" :  "stepper_email"
                    },
                    {
                        "defaut": "STEPPER_VF",
                        "propriete": "ValeurFaciale",
                        "suffixe": "€ par titre",
                        "url": "TUNNEL_CTR_VALEURFACIALE",
                      "data_rnx" :  "stepper_vf"
                    },
                    {
                        "defaut": "STEPPER_SALARIE",
                        "propriete": "Salaries",
                        "suffixe": " salarié(s)",
                        "url": "TUNNEL_CTR_BENEFICIAIRE",
                      "data_rnx" :  "stepper_salarie"
                    }
                ]
            },
            {
                "libelle": "STEPPER_INFOS",
                "entrees": [
                    {
                        "defaut": "STEPPER_SIRET",
                        "propriete": "RaisonSociale",
                        "multiActif": ["TUNNEL_CTR_SIRET", "TUNNEL_CTR_ADRESSE_SAISIE"],
                        "url": "TUNNEL_CTR_SIRET",
                      "data_rnx" :  "stepper_rs"
                    },
                    {
                        "defaut": "STEPPER_COORDONNEES",
                        "propriete": "Interlocuteur",
                        "url": "TUNNEL_CTR_COORDONNEES",
                      "data_rnx" :  "stepper_interlocuteur"
                    }
                ]
            },
            {
                "libelle": "STEPPER_PAIEMENT",
                "entrees": [
                    {
                        "defaut": "STEPPER_RECAP",
                        "propriete": "Recapitulatif",
                        "url": "TUNNEL_CTR_RECAPITULATIF",
                      "data_rnx" :  "stepper_recap"
                    },
                    {
                        "defaut": "STEPPER_IBAN",
                        "propriete": "Paiement",
                        "url": "TUNNEL_CTR_PAIEMENT",
                      "data_rnx" :  "stepper_paiement"
                    },
                    {
                        "defaut": "STEPPER_CONFIRMATION",
                        "propriete": "Confirmation",
                        "url": "TUNNEL_CTR_CONFIRMATION",
                        "ignore": true,
                      "data_rnx" :  "stepper_confirmation"
                    }
                ]
            },
        ]
    },
    "3": {
        "sections": [
            {
                "libelle": "STEPPER_PANIER",
                "entrees": [
                    {
                        "defaut": "STEPPER_FONCTION",
                        "propriete": "Email",
                        "url": "TUNNEL_CTR_PERSONA",
                      "data_rnx" :  "stepper_email"
                    },
                    {
                        "defaut": "STEPPER_VF",
                        "propriete": "ValeurFaciale",
                        "suffixe": "€ par titre",
                        "url": "TUNNEL_CTR_VALEURFACIALE",
                      "data_rnx" :  "stepper_vf"
                    },
                    {
                        "defaut": "STEPPER_SALARIE",
                        "propriete": "Salaries",
                        "suffixe": " salarié(s)",
                        "url": "TUNNEL_CTR_BENEFICIAIRE",
                      "data_rnx" :  "stepper_salarie"
                    }
                ]
            },
            {
                "libelle": "STEPPER_INFOS",
                "entrees": [
                    {
                        "defaut": "STEPPER_SIRET",
                        "propriete": "RaisonSociale",
                        "multiActif": ["TUNNEL_CTR_SIRET", "TUNNEL_CTR_ADRESSE_SAISIE"],
                        "url": "TUNNEL_CTR_SIRET",
                      "data_rnx" :  "stepper_siret"
                    },
                    {
                        "defaut": "STEPPER_COORDONNEES",
                        "propriete": "Interlocuteur",
                        "url": "TUNNEL_CTR_COORDONNEES",
                      "data_rnx" :  "stepper_interlocteur"
                    }
                ]
            },
            {
                "libelle": "STEPPER_PAIEMENT",
                "entrees": [
                    {
                        "defaut": "STEPPER_RECAP",
                        "propriete": "Recapitulatif",
                        "url": "TUNNEL_CTR_RECAPITULATIF",
                      "data_rnx" :  "stepper_recap"
                    },
                    {
                        "defaut": "STEPPER_IBAN",
                        "propriete": "Paiement",
                        "url": "TUNNEL_CTR_PAIEMENT",
                      "data_rnx" :  "stepper_paiement"
                    },
                    {
                        "defaut": "STEPPER_CONFIRMATION",
                        "propriete": "Confirmation",
                        "url": "TUNNEL_CTR_CONFIRMATION",
                      "ignore": true,                      
                      "data_rnx" :  "stepper_confirmation"
                    }
                ]
            },
        ]
    },
    "4": {
        "sections": [
            {
                "libelle": "STEPPER_PANIER",
                "entrees": [
                    {
                        "defaut": "STEPPER_FONCTION",
                        "propriete": "Email",
                        "url": "TUNNEL_CTR_PERSONA",
                      "data_rnx" :  "stepper_email"
                    },
                    {
                        "defaut": "STEPPER_VF",
                        "propriete": "ValeurFaciale",
                        "suffixe": "€ par titre",
                        "url": "TUNNEL_CTR_VALEURFACIALE",
                      "data_rnx" :  "stepper_vf"
                    },
                    {
                        "defaut": "STEPPER_SALARIE",
                        "propriete": "Salaries",
                        "suffixe": " salarié(s)",
                        "url": "TUNNEL_CTR_BENEFICIAIRE",
                      "data_rnx" :  "stepper_salarie"
                    }
                ]
            },
            {
                "libelle": "STEPPER_INFOS",
                "entrees": [
                    {
                        "defaut": "STEPPER_SIRET",
                        "propriete": "RaisonSociale",
                        "multiActif": ["TUNNEL_CTR_SIRET", "TUNNEL_CTR_ADRESSE_SAISIE"],
                        "url": "TUNNEL_CTR_SIRET",
                      "data_rnx" :  "stepper_siret"
                    },
                    {
                        "defaut": "STEPPER_COORDONNEES",
                        "propriete": "Interlocuteur",
                        "url": "TUNNEL_CTR_COORDONNEES",
                      "data_rnx" :  "stepper_interlocuteur"
                    }
                ]
            },
            {
                "libelle": "STEPPER_PAIEMENT",
                "entrees": [
                    {
                        "defaut": "STEPPER_RECAP",
                        "propriete": "Recapitulatif",
                        "url": "TUNNEL_CTR_RECAPITULATIF",
                      "data_rnx" :  "stepper_recap"
                    },
                    {
                        "defaut": "STEPPER_IBAN",
                        "propriete": "Paiement",
                        "url": "TUNNEL_CTR_PAIEMENT",
                      "data_rnx" :  "stepper_paiement"
                    },
                    {
                        "defaut": "STEPPER_CONFIRMATION",
                        "propriete": "Confirmation",
                        "url": "TUNNEL_CTR_CONFIRMATION",
                        "ignore": true,
                      "data_rnx" :  "stepper_confirmation"
                    }
                ]
            },
        ]
    },
     "5": {
        "sections": [
            {
                "libelle": "STEPPER_PANIER",
                "entrees": [
                    {
                        "defaut": "STEPPER_FONCTION",
                        "propriete": "Email",
                        "url": "TUNNEL_CTR_PERSONA",
                      "data_rnx" :  "stepper_email"
                    },
                    {
                        "defaut": "STEPPER_VF",
                        "propriete": "ValeurFaciale",
                        "suffixe": "€ par titre",
                        "url": "TUNNEL_CTR_VALEURFACIALE",
                      "data_rnx" :  "stepper_vf"
                    },
                    {
                        "defaut": "STEPPER_SALARIE",
                        "propriete": "Salaries",
                        "suffixe": " salarié(s)",
                        "url": "TUNNEL_CTR_BENEFICIAIRE",
                      "data_rnx" :  "stepper_salarie"
                    }
                ]
            },
            {
                "libelle": "STEPPER_INFOS",
                "entrees": [
                    {
                        "defaut": "STEPPER_SIRET",
                        "propriete": "RaisonSociale",
                        "multiActif": ["TUNNEL_CTR_SIRET", "TUNNEL_CTR_ADRESSE_SAISIE"],
                        "url": "TUNNEL_CTR_SIRET",
                      "data_rnx" :  "stepper_siret"
                    },
                    {
                        "defaut": "STEPPER_COORDONNEES",
                        "propriete": "Interlocuteur",
                        "url": "TUNNEL_CTR_COORDONNEES",
                      "data_rnx" :  "stepper_interlocuteur"
                    }
                ]
            },
            {
                "libelle": "STEPPER_PAIEMENT",
                "entrees": [
                    {
                        "defaut": "STEPPER_RECAP",
                        "propriete": "Recapitulatif",
                        "url": "TUNNEL_CTR_RECAPITULATIF",
                      "data_rnx" :  "stepper_recap"
                    },
                    {
                        "defaut": "STEPPER_IBAN",
                        "propriete": "Paiement",
                        "url": "TUNNEL_CTR_PAIEMENT",
                      "data_rnx" :  "stepper_paiement"
                    },
                    {
                        "defaut": "STEPPER_CONFIRMATION",
                        "propriete": "Confirmation",
                        "url": "TUNNEL_CTR_CONFIRMATION",
                        "ignore": true,
                      "data_rnx" :  "stepper_confirmation"
                    }
                ]
            },
        ]
    },
     "6": {
        "sections": [
            {
                "libelle": "STEPPER_PANIER",
                "entrees": [
                    {
                        "defaut": "STEPPER_FONCTION",
                        "propriete": "Email",
                        "url": "TUNNEL_CTR_PERSONA",
                      "data_rnx" :  "stepper_email"
                    },
                    {
                        "defaut": "STEPPER_VF",
                        "propriete": "ValeurFaciale",
                        "suffixe": "€ par titre",
                        "url": "TUNNEL_CTR_VALEURFACIALE",
                      "data_rnx" :  "stepper_vf"
                    },
                    {
                        "defaut": "STEPPER_SALARIE",
                        "propriete": "Salaries",
                        "suffixe": " salarié(s)",
                        "url": "TUNNEL_CTR_BENEFICIAIRE",
                      "data_rnx" :  "stepper_salarie"
                    }
                ]
            },
            {
                "libelle": "STEPPER_INFOS",
                "entrees": [
                    {
                        "defaut": "STEPPER_SIRET",
                        "propriete": "RaisonSociale",
                        "multiActif": ["TUNNEL_CTR_SIRET", "TUNNEL_CTR_ADRESSE_SAISIE"],
                        "url": "TUNNEL_CTR_SIRET",
                        "data_rnx" :  "stepper_rs"
                    },
                    {
                        "defaut": "STEPPER_COORDONNEES",
                        "propriete": "Interlocuteur",
                        "url": "TUNNEL_CTR_COORDONNEES",
                      "data_rnx" :  "stepper_interlocuteur"
                    }
                ]
            },
            {
                "libelle": "STEPPER_PAIEMENT",
                "entrees": [
                    {
                        "defaut": "STEPPER_RECAP",
                        "propriete": "Recapitulatif",
                        "url": "TUNNEL_CTR_RECAPITULATIF",
                      "data_rnx" :  "stepper_recap"
                    },
                    {
                        "defaut": "STEPPER_IBAN",
                        "propriete": "Paiement",
                        "url": "TUNNEL_CTR_PAIEMENT",
                      "data_rnx" :  "stepper_paiement"
                    },
                    {
                        "defaut": "STEPPER_CONFIRMATION",
                        "propriete": "Confirmation",
                        "url": "TUNNEL_CTR_CONFIRMATION",
                        "ignore": true,
                      "data_rnx" :  "stepper_confirmation"
                    }
                ]
            },
        ]
    }
}
