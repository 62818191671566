;(function() {
  "use strict";

  function contratsService(Restangular, localStorageService, Ressources) {
        var baseContratPanier = Restangular.all('contratsPanier');

        var getContrats = function (idPartenaire, nombreBeneficiaire) {
                return Restangular.one('contracts', idPartenaire).one("details", nombreBeneficiaire).get();
        };

        var getContractRanges = function () {
                return Restangular.one("contracts").one("config").get();
        };

        var updateContractRanges = function (contractRanges) {
                return Restangular.one("contracts").one("config").customPUT(contractRanges);
        };

        var getContractTarifs = function () {
                return Restangular.one("contracts").one("tarifs").get();
        };

        var createContract = function (contratToCreateInRedCart) {
            return Restangular.one('contratsPanier').post('', contratToCreateInRedCart);
        };

        var updateContract = function (idContrat, contrat) {
            return Restangular.one('contratsPanier').one(idContrat).patch(contrat);
        };
        
        var mettreAJourContratsDisponibles = function (codeProduit, nbrBenef) {
            if (!nbrBenef) nbrBenef = localStorageService.get(Ressources.BENEFICIARIES_KEY) ?
                                        localStorageService.get(Ressources.BENEFICIARIES_KEY).length :
                                        (localStorageService.get(Ressources.PANIER_KEY)) ?
                                                (localStorageService.get(Ressources.PANIER_KEY).Precommandes) ?
                                                        localStorageService.get(Ressources.PANIER_KEY).Precommandes[0].NbBeneficiaireDevis :
                                                        null
                                                 : null;

            var contratRanges = localStorageService.get(Ressources.CONTRACTS_KEY),
                contratTarifs = localStorageService.get(Ressources.TARIF_KEY),
                selectedContrats = [],
                newIdContratType = null;

            var range = _.find(contratRanges, function (r) {
                return r.IdPartenaire === codeProduit
                    && r.TrancheEffectifMax >= nbrBenef
                    && r.TrancheEffectifMin <= nbrBenef;
            });
            if (!range) {
                range = _.find(contratRanges, function (r) {
                    return r.IdPartenaire === codeProduit
                        && r.TrancheEffectifMax >= 0
                        && r.TrancheEffectifMin <= 5;
                });
            }
            var idClientGeneriques = range.IdClientGenerique.split(',');
            var nomContrat = range.NomContrat.split(',');
            var ptnId = range.PtnId
            for (var i = 0, length = idClientGeneriques.length; i < length; i++) {
                var tarif = _.find(contratTarifs, function (t) {
                    return t.IdClientGenerique == idClientGeneriques[i]
                        && t.NomContrat == nomContrat[i];
                });
                if (tarif) {
                    selectedContrats.push(tarif);
                    if (i === 0) {
                        newIdContratType = tarif.IdContratType;
                    }
                }
                if (ptnId) {
                    selectedContrats.push(ptnId);
                }
            }
            localStorageService.set(Ressources.SELECTED_CONTRACT_KEY, selectedContrats);

            return selectedContrats;
        }

        return {
            createContract: createContract,
            getContrats: getContrats,
            getContractRanges: getContractRanges,
            updateContractRanges: updateContractRanges,
            getContractTarifs: getContractTarifs,
            updateContract: updateContract,
            mettreAJourContratsDisponibles: mettreAJourContratsDisponibles
        };
    };

    module.exports = {
        name: "ContratsService",
        fn: contratsService
    }

})();
