; (function () {
    'use strict';

    var forgot = {
        templateUrl: 'Content/views/authentication/forgot-password.html',
        controller: function($scope, $state, $stateParams, $injector, $filter, AuthenticationService) {
            var self = this,
                $validationProvider = $injector.get('$validation');
        
            if ($stateParams.tokenKo) {
                self.tokenKo = true;
            }

            self.email = $stateParams.login;

            self.submitEmail = function (form) {
                $validationProvider.validate(form)
                .success(_successValidationEmail);
            };

            var _successValidationEmail = function () {
                AuthenticationService.sendForgetPasswordEmail(self.email).then(function (result) {
                    AuthenticationService.setUserInfo(true, self.email);
                    $state.go('tunnel.forgotpassword-step2');
                }).catch(function (error) {
                    var errorCode = error.data.Content[0].ErrorCode;
                    $scope.$broadcast(errorCode + '-error-from-server', $filter('codeToWording')(errorCode));
                });
            };
        }
    };

    module.exports = {
        name: 'forgotPassword',
        obj: forgot
    }
})();