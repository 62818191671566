; (function () {
    "use strict";

    var headerCma = {
        bindings: {
            feedlyOpen: '<',
            stepperDisponible: '<',
            etapeSuivanteDisponible: '<',
            isIntroPage: '<',
            etapePrecedente: '&',
            etapeSuivante: '&'
        },
        templateUrl: componentsPath + 'header/header.cma.html',
        controllerAs: 'header',
        controller: function (PartenairesService, localStorageService, StateService, Ressources) {
            var self = this;

            self.idFederation = localStorageService.get(Ressources.FEDERATION_KEY);

            self.partenaire = null;

            if (self.idFederation) {
                PartenairesService.get(self.idFederation).then(function (result) {
                    self.partenaire = result.plain()[0];
                });
            }

            self.isDisplayedArrowNavigation = function() {
                return StateService.getCurrent() != Ressources.TUNNEL_CMA_SIRET;
            };
        }
    };

    module.exports = {
        name: 'headerCma',
        obj: headerCma
    };

})();
