;(function () {
  "use strict";

    var controllerFolders = (function () {var f = require("./index.js");f["SimuSSo.controller"]=require("./SimuSSo.controller.js");f["addressFilling.controller"]=require("./addressFilling.controller.js");f["app.controller"]=require("./app.controller.js");f["beneficiary.controller"]=require("./beneficiary.controller.js");f["confirmation.controller"]=require("./confirmation.controller.js");f["contactDetail.controller"]=require("./contactDetail.controller.js");f["contractTarif.controller"]=require("./contractTarif.controller.js");f["contractsType.controller"]=require("./contractsType.controller.js");f["devis.controller"]=require("./devis.controller.js");f["erteRecontacter.controller"]=require("./erteRecontacter.controller.js");f["home.controller"]=require("./home.controller.js");f["index"]=require("./index.js");f["nombreBenefsTitres.controller"]=require("./nombreBenefsTitres.controller.js");f["paiement.controller"]=require("./paiement.controller.js");f["parametrageComptable.controller"]=require("./parametrageComptable.controller.js");f["partenaires.controller"]=require("./partenaires.controller.js");f["persona.controller"]=require("./persona.controller.js");f["personaKo.controller"]=require("./personaKo.controller.js");f["prestation.controller"]=require("./prestation.controller.js");f["recap.controller"]=require("./recap.controller.js");f["simulation.controller"]=require("./simulation.controller.js");f["siret.controller"]=require("./siret.controller.js");f["siretChoix.controller"]=require("./siretChoix.controller.js");f["siretKo.controller"]=require("./siretKo.controller.js");f["tunnel.controller"]=require("./tunnel.controller.js");f["valeurFacial.controller"]=require("./valeurFacial.controller.js");f["wording.controller"]=require("./wording.controller.js");return f;})(),
        app = angular.module('app.controllers', []);

    for (var prop in controllerFolders) {
        if (prop !== "index") {
            var controllerObject = require("./" + prop + ".js");
            app.controller(controllerObject.name, controllerObject.fn);
        }
    }

  module.exports = app;

})();
