; (function () {
    "use strict";

    function contractTarifController($scope, $rootScope, ContratsService) {
        var self = this;

        ContratsService.getContractTarifs().then(function (result) {
            self.tarifs = result.plain();
        });

        function findFee(fees, feeName) {
            var fee = _.find(fees, function (p) {
                return p.Nom === feeName;
            });

            if (fee) return fee.ValeurFacturee * 100;
            return null;
        }
    };

    module.exports = {
        name: "ContractTarifController",
        fn: contractTarifController
    };

})();
