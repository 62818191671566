;(function() {
    "use strict";

    function customMask() {
        jQuery.mask.definitions['n'] = "[a-zA-Z \\'\\-èéêëçà]";
        jQuery.mask.definitions['j'] = "[a-zA-Z0-9 \\,\\'\\-áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ]";
        jQuery.mask.definitions['k'] = "[a-zA-Z0-9_\\@\\*\\/\\- \\'\\.\\-áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ]";
        jQuery.mask.definitions['b'] = "[a-zA-Z0-9_\\@\\*\\/\\- \\'\\.\\-\;\²\&\~\\\\#\\^\°\=+€¨$€£¤µ<>?:!,§áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ]";

        var _link = function (scope, el, attr, ngModel) {

            var option = {
                autoclear: false,
                placeholder: scope.maskPlaceholder
            };

            $(el).mask(scope.maskDefinition, option);

            $(el).blur(function (e) {
                ngModel.$setViewValue($(el).val());
                ngModel.$render();
            });

        };

        return {
            restrict: 'A',
            link: _link,
            scope: {
                maskDefinition: '@',
                maskPlaceholder: '@'
            },
            require: 'ngModel'
        };
    };

    module.exports = {
        name: 'customMask',
        fn: customMask
    };

})();
