;(function() {
  "use strict";

    function personaController ($scope, $injector, $q, ClientService, StateService, ValidationService, Client, Interlocuteur, Ressources, trackingService) {
        var self = this,
            oldInterlocuteur,
            isInterlocutorChanged = false,
            $validationProvider = $injector.get('$validation'),
            $filter = $injector.get('$filter'),
            panier = StateService.getPanier();

        self.errorFromServer = false;

        if (!panier.Client.Value) {
            panier.Client.Value = new Client();
        }
        var interlocuteur = panier.Client.Value.Interlocuteur != null && panier.Client.Value.Interlocuteur.length > 0 ? panier.Client.Value.Interlocuteur[0] : null;

        self.nouveauInterlocuteur = interlocuteur || new Interlocuteur();
        if (self.nouveauInterlocuteur.Email == Ressources.EMAIL_SIMULATION) {
            self.nouveauInterlocuteur.Email = "";
            self.nouveauInterlocuteur.Fonction = null;
        }
        oldInterlocuteur = angular.copy(self.nouveauInterlocuteur);

        self.functions = $.map(Object.keys(Ressources.PERSONAS), function (e) {
            var value = Ressources.PERSONAS[e];
            if (!isNaN(value)) {
                return {
                    value: value,
                    name: Ressources.PERSONAS.properties[value].Libelle
                }
            }
            return null;
        });

        var _successValidation = function () {

            // Si on valide un EDIO en page persona les conséquences sont cataclysmiques à moins de recharger le panier
            panier = StateService.getPanier();
            oldInterlocuteur = panier.Client.Value.Interlocuteur != null && panier.Client.Value.Interlocuteur.length > 0 ? panier.Client.Value.Interlocuteur[0] : new Interlocuteur();

            isInterlocutorChanged = oldInterlocuteur.Email !== self.nouveauInterlocuteur.Email || oldInterlocuteur.Fonction !== self.nouveauInterlocuteur.Fonction ? true : false;
            
            if (isInterlocutorChanged) {
                $scope.$emit('toggleLoadingToaster', {
                    header: "Nous vérifions vos informations",
                    caption: "Veuillez patienter s'il vous plaît...",
                });
                return (!oldInterlocuteur.Id
                    ? ClientService.creerInterlocuteur(panier.Client.Id, angular.extend(new Interlocuteur(), self.nouveauInterlocuteur))
                    : ClientService.enregistrerInterlocuteur(panier.Client.Id, oldInterlocuteur.Id, _.pick(self.nouveauInterlocuteur, ['Email', 'Fonction'])))
                    .catch(function(error) {
                        _errorValidation(error);
                        return $q.reject();
                    })
                    .then(function (result) {
                        panier.Client.Value.Interlocuteur = [result.plain()];
                        $scope.$emit(Ressources.EVENT_UPDATE_PANIER, panier);
                        return ClientService.estInterlocuteurClientV2(panier.Client.Id, self.nouveauInterlocuteur.Email);
                    })
                    .then(function (result) {
                        var clients = result.plain();
                        if (clients.length > 0) {
                            clients[0].Interlocuteur = panier.Client.Value.Interlocuteur[0];
                            trackingService.trackEvent({"event": "erreurEmail","email_error_type": 'email_connu'});// tracking GTM event
                            return StateService.go(Ressources.TUNNEL_CTR_PERSONA_KO, { obj: clients });
                        } else {
                            return StateService.next();
                        }
                    });
            } else {
                return StateService.next();
            }
            /* PASSE EN PROCHAIN ÉTAPE */
        };

        /* Validation from server */
        var _errorValidation = function (error) {
            $scope.$emit('closeLoadingToaster');
            if (error && error.data) {
                var _errorCode = error.data.Content[0].ErrorCode,
                    message = $filter('codeToWording')(_errorCode);
                $scope.$broadcast(_errorCode + '-error-from-server', message);
                trackingService.trackEvent({"event": "erreurEmail","email_error_type": _errorCode});// tracking GTM event
            } else {
                trackingService.trackEvent({"event": "erreurEmail","email_error_type": "invalid_email"});// tracking GTM event
            }
        };

        self.submitPersona = function (form) {
            return $validationProvider.validate(form)
                .then(_successValidation)
                .catch(_errorValidation);
        }
    };

    module.exports = {
        name: "PersonaController",
        fn: personaController
    };

})();
