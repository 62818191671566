;(function() {
    "use strict";

    function angularticsConfig ($analyticsProvider) {

        $analyticsProvider.registerEventTrack(function (action, properties) {
                        
            var dataLayer = window.dataLayer = window.dataLayer || [];
            properties = properties || {};

            dataLayer.push({
                'event': properties.eventType || 'interaction',
                'target': properties.category, // angulartics event category --> target
                'action': action,
                'target-properties': properties.label,
                'content-name': properties.url,
                'value': parseInt(properties.value, 10),
                'interaction-type': properties.noninteraction || ''
            });

        });

    };

    module.exports = {
        fn: angularticsConfig
    };

})();
